import validationTests from './validationTests';

export default function (text, validationNames, type) {
    return new Promise((resolve, reject) => {
        const testResult = [];
        let generalTestPassed = false;
        if (validationNames.length === 0) {
            resolve(true);
        }
        if (!['every', 'some'].includes(type)) {
            reject(new Error('Type parameter not valid'));
        }
        const testResultArray = validationNames.map((validationName) => {
            const validationFound = validationTests.find(regexPart => regexPart.name === validationName);
            if (!validationFound) {
                reject(new Error('Validation not found'));
            }
            const { validateFunc } = validationFound;
            const testPassHandler = validateFunc(text);
            return {
                handler: testPassHandler,
                ...validationFound,
            };
        });
        Promise.all(testResultArray.map(({ handler }) => handler)).then((handlerValues) => {
            handlerValues.forEach((value, index) => {
                const { name, inverse, instructions } = testResultArray[index];
                const testValue = inverse ? !value : value;
                testResult.push({
                    name,
                    testValue,
                    instructions,
                });
            });
            generalTestPassed = testResult[type](validationCollection => validationCollection.testValue);
            resolve(generalTestPassed || testResult);
        });
    });
}
