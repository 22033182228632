import { shippingMethodsCodes } from '~/src/config/dictionary/shippingMethods';

const translateCode = {
    courier: shippingMethodsCodes.DPD,
    casto: shippingMethodsCodes.CASTO,
    inpost: shippingMethodsCodes.INPOST,
};

export default function (shippingMethods) {
    const normalizedShippingMethods = [];
    Object.keys(shippingMethods).forEach((sourceCode) => {
        const normalizedCode = translateCode[sourceCode] || sourceCode;
        normalizedShippingMethods.push({
            code: normalizedCode,
        });
    });
    return normalizedShippingMethods;
}
