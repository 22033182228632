<template>
    <div class="mobile-application-bar__container container">
        <template
            v-if="visibleElement === 2"
        >
            <h3 class="mobile-application-bar__title">
                Kupuj wygodnie z aplikacją
            </h3>
            <h4 class="mobile-application-bar__logo">
                Castorama
            </h4>
        </template>
        <template
            v-else
        >
            <h3 class="mobile-application-bar__title">
                Pobierz aplikację
            </h3>
        </template>
    </div>
</template>

<script>
export default {
    name: 'MobileApplicationBarBottom',
    props: {
        visibleElement: {
            type: Number,
            required: true,
        },
    },
};
</script>

<style lang="scss">
    .mobile-application-bar__container {
        text-align: left;
        float: left;
        width: 50%;
    }
</style>
