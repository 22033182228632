<template>
    <div class="custom-navigation-carousel">
        <!--eslint-disable-next-line-->
        <BaseButton
            v-if="pageActive !== pageCount"
            name="Następny"
            class="navigation navigation__forward"
            @click.native="navigateForward"
        >
            next
        </BaseButton>
        <BaseButton
            v-if="pageActive !== 1"
            name="Poprzedni"
            class="navigation navigation__backward"
            @click.native="navigateBackward"
        >
            prev
        </BaseButton>
    </div>
</template>

<script>
import BaseButton from '~/components/common/Button/BaseButton';

export default {
    name: 'CustomNavigation',
    inject: [
        'carousel',
        'navigateForward',
        'navigateBackward',
    ],
    components: {
        BaseButton,
    },
    data() {
        return {
            pageActive: 1,
        };
    },
    computed: {
        pageCount() {
            return this.carousel ? this.carousel.pageCount : 0;
        },
    },
    mounted() {
        this.$parent.$on('pageChange', (index) => {
            this.pageActive = index + 1;
        });
    },
};
</script>

<style lang="scss">
    .VueCarousel {
        .navigation {
            cursor: pointer;
            position: absolute;
            width: 34px;
            height: 80px;
            border: 1px solid $color-slate10-dark;
            top: calc(50% - 50px);
            background: $color-castoWhite url('~static/images/blue-arrow-left.svg') no-repeat center center;
            color: transparent;
            font-size: 0;

            &__backward {
                left: -10px;
            }
            &__forward {
                right: -10px;
                transform: rotate(180deg);
            }
        }
    }
</style>
