<template>
    <form
        :id="blockId"
        :action="searchUrl"
        method="get"
        class="search-bar__wrapper"
    >
        <slot />
    </form>
</template>

<script>
export default {
    name: 'SearchBarWrapper',
    props: {
        searchUrl: {
            type: String,
            required: true,
        },
        blockId: {
            type: String,
            default: '',
        },
    },
};
</script>
