import axios from 'axios';
import { REMOVE_ALL_ITEMS } from './api.config';

export default function () {
    return axios({
        method: 'get',
        url: REMOVE_ALL_ITEMS,
        responseType: 'json',
    });
}
