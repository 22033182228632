<template>
    <simple>
        <template #beforeFooter>
            <client-only>
                <RecentlyProducts :ids-excluded="idsExcluded" />
                <RecentlyViewedCategories />
            </client-only>
        </template>
    </simple>
</template>

<script>
import { mapState } from 'vuex';
import simple from './simple';
import { TYPES as ROUTE_TYPES } from '~/src/config/dictionary/route';
import RecentlyProducts from '~/components/RecentlyProducts';
import RecentlyViewedCategories from '~/components/common/Category/RecentlyViewedCategories';

export default {
    name: 'LayoutsDefault',
    components: {
        simple,
        RecentlyProducts,
        RecentlyViewedCategories,
    },
    computed: {
        ...mapState({
            pageId: state => state.pageId,
            pageType: state => state.pageType,
        }),
        idsExcluded() {
            return this.pageType === ROUTE_TYPES.product && this.pageId ? [this.pageId] : [];
        },
    },
};
</script>
