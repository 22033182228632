import { GLOBAL_VARS } from '~/config';
import loadScript from '~/assets/services/loadScript';
import { googleTagManager as googleTagManagerLibUrl } from '~/src/config/externalLibsUrl';
import { loaderWrapperForExternals } from '~/src/utils/helpers';

export default () => {
    // eslint-disable-next-line
    loaderWrapperForExternals( (app) => {
        const MAIN_PLUGIN_KEY = 'gtm';
        const { GOOGLE_TAG_MANAGER_ID } = GLOBAL_VARS;
        const url = `${googleTagManagerLibUrl}?id=${GOOGLE_TAG_MANAGER_ID}`;

        const runExternalCallback = (state) => {
            const externalCallbackWrapper = window[
                GLOBAL_VARS.EXTERNAL_CALLBACKS_WRAPPER_NAME
            ].getBySource(MAIN_PLUGIN_KEY);

            if (externalCallbackWrapper) {
                externalCallbackWrapper.callback(state);
            }
        };

        loadScript({
            url,
            callback() {
                app.dataLayer = app.dataLayer || [];
                app.dataLayer.push({
                    'gtm.start': new Date().getTime(),
                    event: 'gtm.js',
                });
                app[GLOBAL_VARS.BLOCKS_LOADED_STATE].add({ blockName: MAIN_PLUGIN_KEY });
                runExternalCallback(true);
            },
            fail() {
                app[GLOBAL_VARS.BLOCKS_LOADED_STATE].fail({ blockName: MAIN_PLUGIN_KEY });
                runExternalCallback(false);
            },
        });
    });
};
