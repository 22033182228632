import deleteUndefinedFromObject from '~/assets/services/helper/deleteUndefinedFromObject';
import { item as normalizeCategoryMenuItem } from './categoryMenu';

export default function (item) {
    const { value: id, label: name, count: productCount, url } = item;

    const itemLikeInCategoryMenu = normalizeCategoryMenuItem({
        id,
        name,
        url,
    });

    deleteUndefinedFromObject(itemLikeInCategoryMenu);

    return {
        ...itemLikeInCategoryMenu,
        productCount,
    };
}
