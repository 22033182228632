export const types = {
    SET_INSTANCE: 'SET_INSTANCE',
    SET_OPTIONAL_PROPS: 'SET_OPTIONAL_PROPS',
    SET_OPTIONAL_LISTENERS: 'SET_OPTIONAL_LISTENERS',
};

const mutations = {
    [types.SET_INSTANCE](state, instance) {
        state.instance = instance;
    },
    [types.SET_OPTIONAL_PROPS](state, props) {
        state.optionalProps = props;
    },
    [types.SET_OPTIONAL_LISTENERS](state, listeners) {
        state.optionalListeners = listeners;
    },
};

export default mutations;
