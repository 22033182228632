import { FETCH_ALL_MARKETS_INFO } from './api.config';
import 'url-search-params-polyfill';
import CacheWrapper from '~/assets/services/axiosWrapper/cacheWrapper';
import { availabilityInOtherStores } from '~/assets/services/availability';


export default async function ({ sku, qty = 1, showAllMarkets = true }) {
    const search = new URLSearchParams({
        qty,
        sku,
        show_all_markets: showAllMarkets,
    });

    try {
        const { success } = await new CacheWrapper({
            method: 'post',
            data: search.toString(),
            url: FETCH_ALL_MARKETS_INFO,
            storageKey: `${FETCH_ALL_MARKETS_INFO}<${sku}>`,
            timestamp: 15,
        }).getValue();

        return availabilityInOtherStores(success);
    } catch (message) {
        // @todo log errors
        return null;
    }
}
