<template>
    <section class="col-xs-6 col-sm-6 social-link">
        <p class="block-single__title no-padd-small--social-media">
            Social Media
        </p>
        <div class="so-ico">
            <BaseLink
                :href="cmsPages.socialMedia.fb"
                class="so-ico__so-fb"
                title="Facebook"
                target="_blank"
            >
                Facebook
            </BaseLink>
            <BaseLink
                :href="cmsPages.socialMedia.yt"
                class="so-ico__so-youtube"
                title="Youtube"
                target="_blank"
            >
                Youtube
            </BaseLink>
            <BaseLink
                :href="cmsPages.socialMedia.instagram"
                class="so-ico__so-instagram"
                title="Instagram"
                target="_blank"
            >
                Instagram
            </BaseLink>
            <BaseLink
                :href="cmsPages.socialMedia.linkedin"
                class="so-ico__so-linkedin"
                title="Linkedin"
                target="_blank"
            >
                Linkedin
            </BaseLink>
        </div>
    </section>
</template>

<script>
import BaseLink from '~/components/common/Link/BaseLink';
import cmsPages from '~/assets/services/cmsPagesUrl';

export default {
    name: 'SocialLink',
    components: {
        BaseLink,
    },
    data() {
        return {
            cmsPages,
        };
    },
};
</script>

<style lang="scss">
    @import '~/assets/css/sprites/footer-desktop.scss';

    .social-link {
        .so-ico {
            margin: 0;
            white-space: nowrap;
            float: left;
            width: 100%;
            position: relative;
            z-index: 2;

            a {
                display: inline-block;
                text-indent: -999em;
                margin-right: 10px;

                &:hover {
                    cursor: pointer;
                }
            }

            &__so-fb {
                @include colored-icon('facebook', $color-slate20, 11px 22px);
                margin-left: 0;

                &:hover {
                    @include colored-icon('facebook', $color-castoWhite);
                }
            }

            &__so-youtube {
                @include colored-icon('youtube', $color-slate20, 28px 22px);

                &:hover {
                    @include colored-icon('youtube', $color-castoWhite);
                }
            }

            &__so-instagram {
                @include colored-icon('instagram', $color-slate20, 22px);

                &:hover {
                    @include colored-icon('instagram', $color-castoWhite);
                }
            }

            &__so-linkedin {
                @include colored-icon('linkedin', $color-slate20, 22px);

                &:hover {
                    @include colored-icon('linkedin', $color-castoWhite);
                }
            }
        }
    }

    @media (min-width: $breakpoint-tablet) and (max-width: $breakpoint-big-desktop) {
        .social-link .no-padd-small {
            padding-left: 0;
        }
    }

    @media screen and (max-width: $breakpoint-tablet) {
        .social-link .block-single__title.no-padd-small--social-media {
            display: none;
        }

        .social-link .so-ico {
            text-align: center;
            margin: 24px 0 8px 0;
            a {
                margin: 0 26px;
            }

            &__so-fb,
            &__so-youtube,
            &__so-instagram,
            &__so-linkedin {
                transform: scale(1.5);
            }
        }
    }
</style>
