export default function () {
    return {
        items: [],
        totalPrice: 0,
        paymentInfo: [],
        formKey: '',
        itemsCount: 0,
        needFetch: true,
    };
}
