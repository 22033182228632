import { loaderWrapperForExternals } from '~/src/utils/helpers';
import { googleApi as googleApiLibUrl } from '~/src/config/externalLibsUrl';
import { GLOBAL_VARS } from '~/config';
import loadScript from '~/assets/services/loadScript';
import checkGAPI from '~/assets/services/googleApi';

export default () => {
    // eslint-disable-next-line
    loaderWrapperForExternals( (app) => {
        const MAIN_PLUGIN_KEY = 'gapi';

        const gapiIsLoaded = new Promise((resolve) => {
            if (checkGAPI()) {
                resolve();
            } else {
                loadScript({
                    url: googleApiLibUrl,
                    callback: resolve,
                });
            }
        });

        const runExternalCallback = (state) => {
            const externalCallbackWrapper = window[
                GLOBAL_VARS.EXTERNAL_CALLBACKS_WRAPPER_NAME
            ].getBySource(MAIN_PLUGIN_KEY);

            if (externalCallbackWrapper) {
                externalCallbackWrapper.callback(state);
            }
        };

        gapiIsLoaded.then(() => {
            runExternalCallback(true);
            app[GLOBAL_VARS.BLOCKS_LOADED_STATE].add({ blockName: MAIN_PLUGIN_KEY });
        }, () => {
            runExternalCallback(false);
            app[GLOBAL_VARS.BLOCKS_LOADED_STATE].fail({ blockName: MAIN_PLUGIN_KEY });
        });
    });
};
