import { types } from './mutations';
import fetchMarketInfo from '~/assets/services/market/fetchMarketInfo';
import { nearestMarketWithProductAvailable, fetchAllMarkets } from '~/assets/services/market';
import { showNearestMarketWithProductAvailableCondition } from '~/assets/services/product/config';
import setAvailabilityReminder from '~/assets/services/market/setAvailabilityReminder';
import { separateItemsFromStructure } from '~/src/utils/helpers';
import {
    isShopSelected,
    setShopIds,
} from '~/assets/services/storages/cookies/shop';

export default {
    fetchAvailableMarkets({ commit, state }) {
        return new Promise((resolve) => {
            if (state.marketsAvailable.length > 0) {
                resolve(state.marketsAvailable);
            } else {
                fetchAllMarkets().then((response) => {
                    commit(types.FETCH_ALL_MARKETS, response);
                    resolve(response);
                });
            }
        });
    },
    async changeShopIds({ commit, dispatch }, { shopId, storeId }) {
        commit(types.SET_SHOP, { shopId, storeId });
        setShopIds({ shopId, storeId });
        if (!isShopSelected({ shopId, storeId })) {
            commit(types.SET_SHOP_SELECTED, false);
            return false;
        }
        try {
            commit(types.SET_SHOP_SELECTED, true);
            const storeInfo = await fetchMarketInfo({ storeId, shopId });
            if (storeInfo) {
                const separationResult = separateItemsFromStructure(storeInfo, [
                    'santanderEnabled',
                    'santanderMinPrice',
                    'santanderLandingPageUrl',
                    'santanderInstallmentsNumber',
                    'santanderShopNumber',
                    'santanderDateStart',
                    'santanderDateEnd',
                ]);

                dispatch('santanderInstallments/setData', separationResult.separatedItems, { root: true });
                commit(types.SET_STORE_INFO, { storeInfo: separationResult.itemsLeft });
            }
        } catch (error) {
            if (this.app && this.app.$logger) {
                this.app.$logger({
                    message: 'Cannot fetch marketInfo inside changeShopIds',
                    source: 'store/markets/actions.js',
                });
            }
            commit(types.SET_SHOP_SELECTED, false);
        }
    },
    getNearestMarketWithProductAvailable({ commit, state }, { productId, availability, productType }) {
        const { selectedShopIds } = state;
        if (
            selectedShopIds &&
            showNearestMarketWithProductAvailableCondition({ storeId: selectedShopIds.storeId, availability, productType })
        ) {
            const { storeId } = selectedShopIds;
            nearestMarketWithProductAvailable({ storeId, productId })
                .then(({ data }) => {
                    try {
                        const { markets, status } = data;
                        if (status) {
                            commit(types.SET_NEAREST_STORE_DATA_WITH_PRODUCT_AVAILABLE, markets[0]);
                        }
                    } catch (err) {
                        throw new Error('Nearest market not detected');
                    }
                })
                .catch(() => {
                    throw new Error('Cannot fetch nearest market');
                });
        } else {
            commit(types.FLUSH_NEAREST_STORE_DATA_WITH_PRODUCT_AVAILABLE);
        }
    },
    async setAvailabilityStoreReminder({ state }, { productId, email, isStoreSent }) {
        const { selectedShopIds } = state;
        const { storeId } = selectedShopIds;
        return setAvailabilityReminder({
            storeId: isStoreSent ? storeId : null,
            productId,
            email,
        });
    },
    setFetchStartStateDone({ commit }) {
        commit(types.SET_FETCH_START_STATE_DONE);
    },
};
