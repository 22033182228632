<template>
    <section
        v-is-in-viewport
        class="product-tail"
        itemprop="itemListElement"
        itemscope
        itemtype="http://schema.org/ListItem http://schema.org/Offer"
        :class="[{ 'product-tail--rejected': statuses[requestStatusesDictionary.isRejected] }, ...getBemModifiers()]"
        @mouseenter="wasHovered=true"
        @isInViewportSuccess="wasInViewPort=true"
    >
        <meta
            itemprop="position"
            :content="position"
        >
        <meta
            itemprop="url"
            :content="url"
        >
        <meta
            itemprop="priceCurrency"
            :content="currentCurrencyCode"
        >
        <ProductLabel
            v-if="labelId"
            class="product-tail__label"
            :label-id="labelId"
        />
        <ImageProductTail
            :image="image"
            :url="url"
            :name="name"
            itemprop="image"
            :content="image"
            class="product-tail__image"
        />
        <NameProductTail
            :name-dom-tag="nameDomTag"
            :name="name"
            :url="url"
            class="product-tail__name"
            itemprop="name"
        />
        <CategoryProductTail
            v-if="categoryUrl && categoryName"
            :category-url="categoryUrl"
            :category-name="categoryName"
        />
        <PriceProductTail
            v-if="!statuses[requestStatusesDictionary.isResolved] || price.value"
            v-bind="priceProductTailData"
            class="product-tail__price"
            bem-modifiers="small-warn"
            itemprop="price"
            :is-in-promo="isInPromo"
            :content="priceToDisplay.value"
        />
        <template v-else>
            <CheckInOtherMarketButton
                v-if="storeSelected"
                class="change-shop-button"
            />
            <ChooseShopButton v-else />
        </template>
        <section
            v-if="renderHoverSection"
            class="product-tail__hover-section"
        >
            <AvailabilityText
                :availability="availability"
                :quantity="qty"
                bem-modifiers="product-tail"
                class="product-tail__availability"
            />
            <p
                v-if="!storeSelected"
                class="product-tail__info mobile-hide"
            >
                Sprawdź cenę oraz dostępność po wyborze sklepu.
            </p>
            <ClickToActionProductTail
                class="product-tail__click-to-action"
                :availability="availability"
                :product-id="productId"
                :name="name"
                :image="image"
                :sku="sku"
                :is-sale-possible="isSalePossible"
                :statuses="statuses"
                :url="url"
                :only-in-store="onlyInStore"
                :type="type"
                :price="{ ...price }"
            />
        </section>
    </section>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';
import ImageProductTail from '~/components/ProductTail/components/ImageProductTail';
import NameProductTail from '~/components/ProductTail/components/NameProductTail';
import PriceProductTail from '~/components/ProductTail/components/PriceProductTail';
import AvailabilityText from '~/src/components/AvailabilityText';
import ClickToActionProductTail from '~/components/ProductTail/components/ClickToActionProductTail';
import isProductSalePossible from '~/assets/services/product/isProductSalePossible';
import CheckInOtherMarketButton from '~/components/common/Button/CheckInOtherMarketButton';
import ChooseShopButton from '~/components/common/Button/ChooseShopButton';
import CategoryProductTail from '~/components/ProductTail/components/CategoryProductTail';
import requestStatusesDictionary from '~/src/config/dictionary/requestStatuses';
import dictionaryPriceCurrency from '~/src/config/dictionary/priceCurrency';
import ProductLabel from '~/components/common/Product/ProductLabel';
import priceToDisplay from '~/src/mixins/priceToDisplay';

const { mapState: mapStateMarkets } = createNamespacedHelpers('markets');
const { mapState: mapStateWindowOptions } = createNamespacedHelpers('windowOptions');

export default {
    name: 'ProductTail',
    components: {
        ProductLabel,
        CategoryProductTail,
        ChooseShopButton,
        CheckInOtherMarketButton,
        ClickToActionProductTail,
        AvailabilityText,
        PriceProductTail,
        NameProductTail,
        ImageProductTail,
    },
    mixins: [priceToDisplay],
    props: {
        nameDomTag: {
            type: String,
            default: 'h3',
        },
        sku: {
            type: [String, Number],
            required: true,
        },
        productId: {
            type: Number,
            required: true,
        },
        name: {
            type: String,
            required: true,
        },
        image: {
            type: String,
            required: true,
        },
        /**
         * Product in promotion
         */
        isPromoForceDisabled: {
            type: Boolean,
            default: false,
        },
        url: {
            type: String,
            default: 'placeholder',
        },
        statuses: {
            type: Object,
            default: () => ({}),
        },
        price: {
            type: Object,
            default: () => ({}),
        },
        qty: {
            type: Number,
            default: 0,
        },
        availability: {
            type: String,
            default: 'default',
        },
        energyClass: {
            type: String,
            default: '',
        },
        type: {
            type: String,
            default: '',
        },
        onlyInStore: {
            type: Boolean,
            default: false,
        },
        labelId: {
            type: Number,
            default: undefined,
        },
        showProductTailHoverSection: {
            type: Boolean,
            default: true,
        },
        categoryUrl: {
            type: String,
            default: '',
        },
        categoryName: {
            type: String,
            default: '',
        },
        position: {
            type: Number,
            default: 1,
        },
    },
    data() {
        return {
            requestStatusesDictionary,
            wasHovered: false,
            wasInViewPort: false,
            currentCurrencyCode: dictionaryPriceCurrency.pl.iso4217,
        };
    },
    computed: {
        ...mapStateMarkets({
            storeSelected: state => state.storeSelected,
        }),
        ...mapStateWindowOptions({
            isMobile: state => state.isMobile,
        }),
        isSalePossible() {
            const { $props, storeSelected } = this;
            return isProductSalePossible({
                productData: $props,
                storeSelected,
            });
        },
        priceProductTailData() {
            const { price: fullPriceData, statuses, energyClass } = this;
            const { convertedData, ...price } = fullPriceData;
            return {
                statuses,
                ...(convertedData || price),
                energyClass,
            };
        },
        renderHoverSection() {
            return (!this.isMobile && this.wasHovered) || (this.isMobile && this.wasInViewPort) || this.showProductTailHoverSection;
        },
        hasPrice() {
            const { price } = this;

            return price && price.value && price.unit;
        },
        isInPromo() {
            return !this.isPromoForceDisabled && this.price.isInPromo;
        },
    },
};
</script>

<style lang="scss">
    $mobileHoverSectionSize: 142px;

    .product-tail {
        $self: &;

        position: relative;
        background: $color-castoWhite;
        box-sizing: border-box;
        text-align: center;
        box-shadow: 0 0 0 2px transparent;
        border: none;
        max-width: 100%;
        line-height: 0;

        &--products-carousel {
            position: relative;
            display: inline-block;

            #{$self}__image {
                min-height: 162px;
            }
        }

        &--cta-hidden {
            #{$self}__hover-section {
                display: none;
            }
        }

        &--rejected {
            #{$self}__price {
                padding: 0 12px;
                display: flex;
                justify-content: center;
            }
        }

        &--recently-viewed {
            padding-top: 10px;

            .name-product-tail {
                margin-bottom: 13px;
                height: 83px;
            }
        }

        &__label {
            position: absolute;
            left: 12px;
            top: 12px;
        }

        &__price {
            margin: 0;
        }

        &__info {
            color: $color-slate70-dark;
            font: $font-weight-regular 12px/16px $baseFontFamily;
            width: 84%;
            margin: -12px auto auto auto;
            padding-bottom: 22px;
            text-transform: none;
        }

        &__hover-section {
            z-index: 2;
            display: block;
            width: 100%;
            position: relative;
            border: none;
            padding-bottom: 10px;
            box-shadow: none;
            background: $color-castoWhite;

            .add-to-cart-button {
                margin: 0;
            }

            .add-to-compare,
            .add-to-wishlist-text__button {
                color: $color-slate70;
                font-size: 12px;
            }
        }

        &__image img {
            display: inline-block;
            text-align: center;
        }

        &__availability {
            display: none;
        }

        .click-to-action-product {
            .button.add-to-wishlist, .button.add-to-cart-button, .change-shop-button {
                height: 60px;
            }

            .add-to-wishlist__wrapper {
                display: flex;
                align-items: center;
                margin-bottom: 41px;
            }

            .only-in-store {
                display: flex;
                font-size: 13px;
                line-height: 15px;
                padding: 3px 1px;
            }
        }

        .change-shop-button {
            display: inline-block;
            width: 100%;
            margin: 0;
            height: 46px;
        }
    }

    @media only screen and (min-width: $mf-breakpoint-tablet) {
        .product-tail {
            $self: &;

            max-width: 228px;

            &__availability {
                display: block;
                width: 100%;
                padding: 7px 0 22px 0;
            }

            &--cta-hidden {
                #{$self}__hover-section {
                    display: inherit;
                }
            }

            &:hover {
                z-index: 2;
                box-shadow: 0 0 0 2px $color-slate20;

                #{$self} {
                    &__price {
                        width: 100%;
                        margin: 0;
                    }

                    &__hover-section {
                        display: block;
                    }
                }

                .wrapper-price.is-special-price {
                    border-color: $color-slate20;
                }
            }

            &--products-carousel {
                width: auto;

                #{$self}__image {
                    height: 190px;
                }
            }

            &--products-carousel#{&}--narrow &__image {
                height: 222px;
            }

            &--not-tall {
                #{$self}__availability, #{$self}__info {
                    display: none;
                }

                #{$self}__click-to-action .click-to-action-product__row {
                    display: none;
                }

                #{$self}__hover-section {
                    .add-to-cart-button, .choose-shop-button {
                        margin-bottom: 0;
                    }
                }
            }

            &--recently-viewed {
                padding-top: initial;

                .name-product-tail {
                    margin-bottom: initial;
                    height: initial;
                }
            }

            &__hover-section {
                width: 100%;
                display: none;
                position: absolute;
                top: 100%;
                background: $color-castoWhite;
                padding-bottom: 15px;
                padding-top: 15px;
                box-shadow: 0 2px 0 $color-slate20, 2px 2px 0 $color-slate20, -2px 2px 0 $color-slate20;

                .add-to-cart-button {
                    margin: 0 18px 18px 18px;
                }

                .add-to-wishlist {
                    margin: 0 18px 18px 18px;
                }

                .add-to-wishlist-text__button {
                    color: $color-castoBlue;

                    &:hover {
                        color: $color-slate70;
                        text-decoration: underline;
                    }
                }
            }

            .change-shop-button, .choose-shop-button {
                display: inline-block;
                width: calc(100% - 32px);
                margin: 0 0 18px 0;
            }
        }
    }

    @media only screen and (min-width: $mf-breakpoint-small-desktop) {
        .product-tail {
            $self: &;

            max-width: 220px;

            &--products-carousel {
                #{$self}__image {
                    height: 220px;
                }
            }

            &--products-carousel#{&}--narrow &__image {
                height: 208px;
            }
        }
    }

    @media only screen and (min-width: $mf-breakpoint-big-desktop) {
        .product-tail {
            $self: &;

            max-width: 270px;

            .change-shop-button, .choose-shop-button {
                width: calc(100% - 32px);
                margin: 0 0 18px 0;
            }

            &--products-carousel {
                #{$self}__image {
                    height: 270px;
                }
            }

            &--products-carousel#{&}--narrow &__image {
                height: 191px;
            }
        }
    }

</style>
