<template>
    <Transition name="fade">
        <div :class="['field-warning', ...getBemModifiers()]">
            {{ text }}
        </div>
    </Transition>
</template>

<script>
export default {
    name: 'FieldWarning',
    props: {
        text: {
            type: String,
            default: 'To pole jest wymagane.',
        },
    },
};
</script>

<style lang="scss">
.field-warning {
    text-align: left;
    min-height: 13px;
    margin: 3px 0;
    padding-left: 17px;
    font: $font-weight-regular 10px/13px $baseFontFamily;
    background: url('~static/images/validation_advice_bg.gif') 2px 1px no-repeat;
    color: $color-deepCanynon-dark;

    &--no-icon {
        padding-left: 0;
        background: none;
    }

    &--center {
        width: 100%;
        text-align: center;
    }

    &--m0 {
        margin: 0;
    }

    &--login-box {
        margin: -6px 0 12px 0;
    }

    &--product-qty-box {
        font: $font-weight-regular 11px/12px $goodHomeFontFamily;
    }
}
</style>
