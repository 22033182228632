import Vue from 'vue';
import camelCaseToKebabCase from '~/src/utils/stringTransform/camelCaseToKebabCase';

const plugin = { install: (VueInstance) => {
    function getCssClassName(type, baseClass) {
        return `${baseClass}--${type}`;
    }

    VueInstance.mixin({
        props: {
            bemModifiers: {
                type: [Array, String],
                default: () => [],
            },
        },
        methods: {
            getBemModifiers(rootClass) {
                const types = (typeof this.bemModifiers === 'string') ? [this.bemModifiers] : this.bemModifiers;
                const baseClass = rootClass || camelCaseToKebabCase(this.$options.name);
                return types.map((type) => {
                    if (typeof type === 'object') {
                        if (Object.values(type)[0]) {
                            return getCssClassName(Object.keys(type)[0], baseClass);
                        }
                        return undefined;
                    }
                    return getCssClassName(type, baseClass);
                }).filter(cssClassName => typeof cssClassName === 'string');
            },
        },
    });
} };

Vue.use(plugin);

export default plugin;
