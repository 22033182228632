<template>
    <BaseLink
        class="cookie-bar-settings-link"
        :bem-modifiers="['background-casto-yellow', 'button-like']"
        :href="`${baseUrl}/regulaminy/pliki-cookies`"
    >
        Zmień ustawienia
    </BaseLink>
</template>

<script>
import BaseLink from '~/components/common/Link/BaseLink';

export default {
    name: 'CookieBarSettingsLink',
    components: {
        BaseLink,
    },
    data() {
        return {
            baseUrl: process.env.baseUrl,
        };
    },
};
</script>

<style lang="scss">
    .cookie-bar-settings-link {
        float: left;
        color: $color-slate100;
        background-color: $color-castoYellow;
        display: flex;
        align-items: center;
        margin-right: 10px;
        width: 106px;
        height: 36px;
        font: $font-weight-light 15px/15px $goodHomeFontFamily;
        text-align: center;

        &:hover {
            text-decoration: none;
            background-color: $color-castoYellow;
        }
    }
</style>
