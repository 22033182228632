<script>
const BaseLink = () => import('~/components/common/Link/BaseLink' /* webpackChunkName: "conditionallyNuxtLink" */);

export default {
    name: 'ConditionallyNuxtLink',
    props: {
        href: {
            type: String,
            required: true,
        },
        title: {
            type: String,
            required: true,
        },
        text: {
            type: String,
            default: undefined,
        },
        useNuxtLink: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            baseUrl: process.env.baseUrl,
        };
    },
    render(createElement) {
        const bemModifiers = this.getBemModifiers();
        const commonOptions = {
            class: `conditionally-nuxt-link ${bemModifiers || ''}`,
        };
        if (this.useNuxtLink) {
            return createElement(
                'NuxtLink',
                {
                    ...commonOptions,
                    props: {
                        to: this.href.replace(this.baseUrl, ''),
                        title: this.title,
                    },
                },
                this.text || this.$slots.default,
            );
        }
        return createElement(
            BaseLink,
            {
                ...commonOptions,
                props: {
                    href: this.href,
                    title: this.title,
                    text: this.text,
                },
            },
            this.text || this.$slots.default,
        );
    },
};
</script>

<style lang="scss">
    .conditionally-nuxt-link {
        text-decoration: none;

        &:hover {
            text-decoration: underline;
        }

        &--color-slate-50 {
            color: $color-slate50;
        }

        &--active {
            color: $color-castoBlue;
            font-weight: $font-weight-bold;
        }
    }
</style>
