import axios from 'axios';
import { FETCH_ARTICLE_GRID_URL } from './api.config';

export default async function ({ categoryId, limit }) {
    const params = {
        category: categoryId,
        limit,
    };
    try {
        const response = await axios({
            method: 'get',
            params,
            url: FETCH_ARTICLE_GRID_URL,
        });
        const { data } = response;
        return data;
    } catch (ignore) {
        // @todo log errors
        return [];
    }
}
