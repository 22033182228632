export const types = {
    RESIZE_WINDOW: 'RESIZE_WINDOW',
    SET_MOBILE_STATE: 'SET_MOBILE_STATE',
    SET_TABLET_STATE: 'SET_TABLET_STATE',
    SET_DESKTOP_STATE: 'SET_DESKTOP_STATE',
    SET_MOBILE_DEVICE: 'SET_MOBILE_DEVICE',
    SET_LEVEL_PAGE_URL: 'SET_LEVEL_PAGE_URL',
};

const mutations = {
    [types.RESIZE_WINDOW](state, data) {
        state.width = data;
    },
    [types.SET_MOBILE_DEVICE](state, currentDevice) {
        state.isMobileDevice = currentDevice;
    },
    [types.SET_MOBILE_STATE](state, currentState) {
        state.isMobile = currentState;
    },
    [types.SET_TABLET_STATE](state, currentState) {
        state.isTablet = currentState;
    },
    [types.SET_DESKTOP_STATE](state, currentState) {
        state.isDesktop = currentState;
    },
    [types.SET_LEVEL_PAGE_URL](state, level) {
        state.levelPageUrl = level;
    },
};

export default mutations;
