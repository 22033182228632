export const types = {
    SET_MODE: 'SET_MODE',
    SET_ROBOTS_FOLLOW: 'SET_ROBOTS_FOLLOW',
    SET_ADBLOCK_STATE: 'SET_ADBLOCK_STATE',
    INCREASE_REQUESTS: 'INCREASE_REQUESTS',
    DECREASE_REQUESTS: 'DECREASE_REQUESTS',
    ADD_LINK_HEAD: 'ADD_LINK_HEAD',
    SET_PAGE_TYPE: 'SET_PAGE_TYPE',
    SET_PAGE_ID: 'SET_PAGE_ID',
    SET_PAGE_TEMPLATE: 'SET_PAGE_TEMPLATE',
    SET_SAUTE: 'SET_SAUTE',
};

const mutations = {
    [types.SET_MODE](state, modeName) {
        state.mode = modeName;
    },
    [types.SET_ADBLOCK_STATE](state, adBlockState) {
        state.adblock = adBlockState;
    },
    [types.INCREASE_REQUESTS](state) {
        state.requestsCount += 1;
    },
    [types.DECREASE_REQUESTS](state) {
        state.requestsCount -= 1;
    },
    [types.SET_ROBOTS_FOLLOW](state, data) {
        state.robotsFollow = data;
    },
    [types.ADD_LINK_HEAD](state, link) {
        state.headLinks = [...state.headLinks, link];
    },
    [types.SET_PAGE_TYPE](state, type) {
        state.pageType = type;
    },
    [types.SET_PAGE_ID](state, id) {
        state.pageId = id;
    },
    [types.SET_PAGE_TEMPLATE](state, layout) {
        state.pageTemplate = layout;
    },
    [types.SET_SAUTE](state, payload) {
        state.saute = payload;
    },
};

export default mutations;
