import loadScript from '~/assets/services/loadScript';
import { easypack as easypackUrl } from '~/src/config/externalLibsUrl';

const options = {
    defaultLocale: 'pl',
    closeTooltip: false,
    map: {
        initialTypes: ['parcel_locker'],
    },
    points: {
        types: ['parcel_locker'],
    },
};

const loadImpostFunctionality = () => new Promise((resolve, reject) => {
    if (window.easyPack) {
        return resolve(window.easyPack);
    }

    window.easyPackAsyncInit = () => {
        window.easyPack.init(options);

        resolve(window.easyPack);
    };

    loadScript({ url: easypackUrl, fail: reject });
});

export default function (ignore, inject) {
    inject('inpost', loadImpostFunctionality);
}
