import restApiRequestFactory from '~/src/utils/helpers/restApiRequestFactory';
import { GET_PROVINCES_LIST_WITH_AVAILABLE_PRODUCT as urlTemplate } from './api.config';
import CacheWrapper from '~/assets/services/axiosWrapper/cacheWrapper';

export default function (productId) {
    return new CacheWrapper({
        requestHandler: restApiRequestFactory,
        urlTemplate,
        urlTemplateProps: {
            id: productId,
        },
        method: 'get',
        storageKey: `${urlTemplate}${productId}`,
        timestamp: 15,
        config: {
            headers: {
                'Content-Type':
                    'application/x-www-form-urlencoded; charset=UTF-8',
            },
        },
    }).getValue();
}
