import { getFileNameWithoutExtension, getFirstPositiveOfPromises, imageAvailabilityChecking } from '~/assets/services/helpers';
import webpSupportChecking from '~/assets/services/browser';

const webp = 'webp';
const extensionsInOrder = [webp, 'jpg', 'png', 'gif'];
const attributesNames = {
    image: 'data-src',
    supportWebp: 'data-webp',
};

const errorImageSource = require('~/static/images/placeholder-error.png');

function getProgressiveImageData(element, progressiveImageData) {
    let events;
    try {
        events = {
            before: new Event('ProgressiveImageLazyLoadBefore'),
            success: new Event('ProgressiveImageLazyLoadSuccess'),
            change: new Event('ProgressiveImageLazyLoadChange'),
            error: new Event('ProgressiveImageLazyLoadError'),
        };
    } catch (e) {
        events = null;
    }

    const data = {
        src: element.getAttribute(attributesNames.image),
        supportWebp: element.getAttribute(attributesNames.supportWebp) === '1',
        errorImage: errorImageSource,
        ...progressiveImageData,
    };

    return {
        ...data,
        onBefore() {
            if (data.onBefore && typeof data.onBefore === 'function') {
                data.onBefore();
            }
            if (events) {
                element.dispatchEvent(events.before);
            }
        },
        onSuccess() {
            if (data.onSuccess && typeof data.onSuccess === 'function') {
                data.onSuccess();
            }
            if (events) {
                element.dispatchEvent(events.success);
            }
        },
        onChange() {
            if (data.onSuccess && typeof data.onSuccess === 'function') {
                data.onChange();
            }
            if (events) {
                element.dispatchEvent(events.change);
            }
        },
        onError() {
            if (data.onError && typeof data.onError === 'function') {
                data.onError();
            }
            if (events) {
                element.dispatchEvent(events.error);
            }
        },
    };
}

async function getPossibleFileSources(data) {
    const browserSupportWebp = await webpSupportChecking();
    const { supportWebp, src } = data;
    const extensionSupported = {
        [webp]: browserSupportWebp && supportWebp,
    };
    const fileSources = [];

    extensionsInOrder.forEach((extension) => {
        if (extensionSupported[extension] === undefined || extensionSupported[extension] === true) {
            const newFileUrl = `${getFileNameWithoutExtension(src)}.${extension}`;
            fileSources.push(newFileUrl);
        }
    });

    return fileSources;
}

export default async function (target, props = {}) {
    const data = getProgressiveImageData(target, props);
    const fileSources = await getPossibleFileSources(data);
    const { onBefore, onSuccess, onChange, onError, errorImage } = data;
    const imagesAvailabilityChecking = [];

    fileSources.forEach((fileSource) => {
        imagesAvailabilityChecking.push(imageAvailabilityChecking.bind(this, fileSource, target));
    });

    onBefore();
    return getFirstPositiveOfPromises(imagesAvailabilityChecking).then((srcChanged) => {
        if (srcChanged) {
            target.removeAttribute(attributesNames.image);
            onSuccess();
        } else {
            target.src = errorImage;
            onError();
        }
        onChange();
    });
}
