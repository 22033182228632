import axios from 'axios';
import { UPDATE_ITEM_QTY_URL } from './api.config';
import 'url-search-params-polyfill';

export default async function ({ wishlistItemId, qty }) {
    const search = new URLSearchParams({
        isAjax: 1,
        item: wishlistItemId,
        qty,
    });
    try {
        return await axios({
            method: 'post',
            url: UPDATE_ITEM_QTY_URL,
            data: search.toString(),
            config: {
                headers: {
                    'Content-Type':
                        'application/x-www-form-urlencoded; charset=UTF-8',
                },
            },
        });
    } catch {
        throw new Error('Cannot change qty of this product');
    }
}
