import { FETCH_ALL_MARKETS } from './api.config';
import CacheWrapper from '~/assets/services/axiosWrapper/cacheWrapper';
import normalizeShopIds from './normalizeData/normalizeShopIds';

export default async function () {
    try {
        const data = await new CacheWrapper({
            method: 'get',
            url: FETCH_ALL_MARKETS,
            timeStamp: 15,
        }).getValue();

        return normalizeShopIds(data);
    } catch {
        return [];
    }
}
