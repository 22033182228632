export const types = {
    SET_FOOTER: 'SET_FOOTER',
};

const mutations = {
    [types.SET_FOOTER](state, footerSections) {
        state.footerSections = footerSections;
    },
};

export default mutations;
