<template>
    <p class="cookie-bar-text">
        Serwis w celu prawidłowego świadczenia usług, jak również w celach statystycznych oraz reklamowych,
        korzysta z informacji zamieszczanych na urządzeniach końcowych użytkowników (plików cookies).
        Jeżeli w ustawieniach przeglądarki dopuściłeś przechowywanie plików cookies,
        będą one zamieszczone w Twoim urządzeniu końcowym.
        Możesz określić warunki przechowywania lub dostępu do plików cookies w Twojej przeglądarce.
        Więcej informacji na ten temat znajduje się w naszej
        <a
            :href="`${baseUrl}/regulaminy/pliki-cookies`"
            title="Polityka prywatności"
        >
            Polityce plików cookies
        </a>.
    </p>
</template>

<script>
export default {
    name: 'CookieBarText',
    data() {
        return {
            baseUrl: process.env.baseUrl,
        };
    },
};
</script>

<style lang="scss">
.cookie-bar-text {
    color: $color-slate100;
    vertical-align: middle;
    display: table-cell;
    font-size: 11px;
    padding: 0 40px 0 24px;
    line-height: 12px;
    margin: 0;

    a {
        text-decoration: none;
        font-weight: $font-weight-bold;
        color: $color-slate100;
    }
}
</style>
