<template>
    <input
        :id="inputId"
        v-model.lazy="inputValue"
        v-debounce="debounceTime"
        :placeholder="placeholder"
        type="text"
        class="base-number-input"
        @focus="$emit('base-input-focus')"
        @blur="$emit('base-input-blur')"
    >
</template>

<script>
import debounce from '~/assets/directives/debounce';
import { getNumberFromString } from '~/assets/utils/number';
import dotToComma from '~/src/utils/stringTransform/dotToComma';

export default {
    name: 'BaseNumberInput',
    directives: {
        debounce,
    },
    props: {
        currentValue: {
            type: Number,
            default: 1,
        },
        placeholder: {
            type: String,
            default: '',
        },
        debounceTime: {
            type: Number,
            default: 1000,
        },
        inputId: {
            type: String,
            default: '',
        },
        allowOnlyMultiple: {
            type: Boolean,
            default: false,
        },
        onlyGreaterThanZero: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            inputValue: this.currentValue,
            initialValue: this.currentValue,
        };
    },
    watch: {
        currentValue() {
            this.inputValue = this.currentValue;
        },
        inputValue(newVal, oldValue) {
            if (newVal !== oldValue) {
                if (newVal !== '') {
                    let finalValue = typeof newVal === 'number' ? newVal : (getNumberFromString(newVal) || this.initialValue);

                    if (this.allowOnlyMultiple) {
                        const multiplicationCeil = finalValue / this.initialValue;

                        if (!Number.isInteger(multiplicationCeil)) {
                            finalValue = parseFloat((this.initialValue * Math.ceil(multiplicationCeil)).toFixed(1));
                        }
                    }

                    if (this.onlyGreaterThanZero && finalValue <= 0) {
                        finalValue = this.initialValue;
                    }

                    this.inputValue = dotToComma(finalValue);

                    this.$emit('input-changed', finalValue);
                } else {
                    this.inputValue = this.initialValue;
                }
            }
        },
    },
};
</script>
