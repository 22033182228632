import config from '~/src/config/recentlyProducts';
import { validate as validateTimestamp } from '~/src/utils/timestamp';

const normalizeLabel = label => (label && label.length ? label[0] : null);

export default function () {
    if (!localStorage) {
        return [];
    }

    const recentlyProducts = JSON.parse(localStorage.getItem(config.STORAGE_KEY)) || [];

    if (recentlyProducts.length < config.MINIMUM_TO_DISPLAY) {
        return [];
    }

    return recentlyProducts
        .map(productData => ({ ...productData, productId: parseInt(productData.id, 10), labelId: normalizeLabel(productData.labels) }))
        .filter(({ timestamp }) => validateTimestamp(timestamp + config.NUMBER_OF_DAYS_VALID_CACHE * 24 * 60 * 60 * 1000))
        .slice(0, config.MAX_ITEMS_TO_DISPLAY);
}
