<template>
    <div class="footer-additional">
        <a
            href="https://www.castorama.pl/kontakt#contactScrollToForm"
            title="Twoja sugestia jest dla nas ważna!"
            class="pull-right"
        >
            Twoja sugestia jest dla nas ważna!
        </a>
        <address class="pull-left">
            © {{ currentYear }} Castorama Polska Sp. z o. o. Wszystkie prawa zastrzeżone.
        </address>
    </div>
</template>

<script>
export default {
    name: 'Copyright',
    data() {
        return {
            currentYear: new Date().getFullYear(),
        };
    },
};
</script>

<style lang="scss">
.footer-additional {
    padding: 22px 15px 0 15px;
    margin: 0;
    border: none;
    width: 100%;

    .pull-right {
        float: right;
        font:$font-weight-bold 14px/14px $goodHomeFontFamily;
        color: $color-slate10;
        margin-left: 18px;
        text-decoration: none;

        &:hover {
            text-decoration: underline;
        }
    }
    .pull-left {
        float: left;
        margin-bottom: 20px;
    }

    &::after {
        display: table;
        content: '';
        clear: both;
    }
}
@media (min-width: $breakpoint-tablet) and (max-width: $breakpoint-small-desktop) {
    .footer-additional {
        padding: 22px 15px 0;
    }
}

@media (max-width: $breakpoint-tablet) {
    .footer-additional {
        width: 100%;
        position: relative;
        line-height: 1.3;
        font-size: 3.6vw;
        color: $color-slate20-dark;

        .pull-right {
            float: none;
            width: 100%;
            text-align: center;
            margin: 0 0 50px 0;
            display: inline-block;
        }

        .pull-left {
            float: none;
            position: absolute;
            width: 200px;
            left: 110px;
            bottom: -58px;
            font-size: 12px;
        }
        .suggestion {
            float: none;
            text-align: center;
            width: 100%;
            margin-bottom: 50px;
        }
    }
}
</style>
