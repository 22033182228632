export default function (imageElement) {
    return new Promise((resolve, reject) => {
        imageElement.addEventListener('load', function imageLoaded() {
            imageElement.removeEventListener('load', imageLoaded);
            resolve(true);
        });
        imageElement.addEventListener('error', function imageError() {
            imageElement.removeEventListener('error', imageError);
            reject(new Error('Image not loaded'));
        });
    });
}
