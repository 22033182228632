import { getDefaultShopIds } from '~/assets/services/storages/cookies/shop';

export default function () {
    return {
        fetchStartStateDone: false,
        marketsAvailable: [],
        selectedShopIds: getDefaultShopIds(),
        storeInfo: null,
        storeSelected: null,
        nearestStoreData: null,
    };
}
