import { getParamArray } from '~/assets/services/url';
import { fetchAllMarkets } from '~/assets/services/market';
import { getDefaultShopIds } from '~/assets/services/storages/cookies/shop';

export default async function (fetchRequest) {
    try {
        const urlStoreIds = getParamArray('store');

        if (!urlStoreIds || urlStoreIds.length !== 1) {
            return null;
        }

        let [urlStoreId] = urlStoreIds;
        urlStoreId = parseInt(urlStoreId, 10);

        if (!Number.isInteger(urlStoreId)) {
            return null;
        }

        if (parseInt(urlStoreId, 10) === 0) {
            return getDefaultShopIds();
        }

        const fetchHandler = fetchRequest || fetchAllMarkets;
        const allMarketsData = await fetchHandler();
        const currentMarketData = allMarketsData.find(singleMarketData => singleMarketData.storeId === urlStoreId);

        if (currentMarketData) {
            const { shopId, storeId } = currentMarketData;

            return {
                shopId: parseInt(shopId, 10),
                storeId: parseInt(storeId, 10),
            };
        }

        return null;
    } catch {
        return null;
    }
}
