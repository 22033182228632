import getTransformedUnit from './getTransformedUnit';
import getFloatFromPriceString from '~/src/utils/helpers/getFloatFromPriceString';

export default function ({ value, oldValue, conversion, conversionUnit, isInPromo }) {
    const price = {
        value: getFloatFromPriceString(value / conversion),
        unit: getTransformedUnit(conversionUnit),
    };

    if (oldValue) {
        price.oldValue = (oldValue / conversion);
    }

    if (isInPromo) {
        price.isInPromo = isInPromo;
    }

    return price;
}
