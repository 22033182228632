import { GLOBAL_VARS } from '~/config/index';

const blocksLoadedClient = {
    storage: [],
    failed: [],
    add({ blockName }) {
        this.storage.push(blockName);
    },
    fail({ blockName }) {
        this.remove({ blockName });
        this.failed.push(blockName);
    },
    remove({ blockName }) {
        const indexToRemove = this.getIndexOfBlockInStorage(blockName);
        const blockExistInStorage = indexToRemove >= 0;
        if (blockExistInStorage) {
            this.storage.splice(indexToRemove, 1);
        }
        return blockExistInStorage;
    },
    getIndexOfBlockInStorage(blockName) {
        return this.storage.indexOf(blockName);
    },
    getStateByName(blockName) {
        if (this.storage.indexOf(blockName) < 0 && this.failed.indexOf(blockName) < 0) {
            return null;
        }
        return this.storage.indexOf(blockName) >= 0 && !(this.failed.indexOf(blockName) >= 0);
    },
};
// eslint-disable-next-line
(function(app) {
    app[GLOBAL_VARS.BLOCKS_LOADED_STATE] =
        app[GLOBAL_VARS.BLOCKS_LOADED_STATE] || blocksLoadedClient;
})(window);
