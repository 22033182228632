import 'url-search-params-polyfill';
import axios from 'axios';
import normalizeData from './normalizeData';

import { GET_FOOTER_LINKS_URL } from './api.config';

export default async function () {
    try {
        const { data } = await axios({
            method: 'get',
            url: GET_FOOTER_LINKS_URL,
            responseType: 'json',
        });

        return normalizeData(data);
    } catch {
        return null;
    }
}
