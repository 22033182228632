const types = {
    SIMPLE: 'SIMPLE',
    GROUPED: 'GROUPED',
};

const reviews = {
    bestRating: 5,
    worstRating: 1,
    limitPerRequest: 10,
    initialOffset: 0,
};

function showNearestMarketWithProductAvailableCondition({ productType, storeId, availability }) {
    return productType === types.SIMPLE && storeId !== 'default' && availability === 'out';
}

function showSquareMetersCalculatorCondition(convertedData = {}, qtyPerPackage) {
    const { unit } = convertedData;

    return unit === 'm2' && qtyPerPackage !== null;
}

function changedUnit(priceUnit) {
    const unit = priceUnit.toLowerCase();
    let newLabel = unit;
    if (unit) {
        switch (unit) {
            case 'm2':
                newLabel = 'm<sup>2</sup>';
                break;
            case 'm3':
                newLabel = 'm<sup>3</sup>';
                break;
            default:
                newLabel = newLabel.replace(/a$/, 'ę');
        }
        newLabel = `<span class='changed-unit'>${newLabel}</span>`;
    }
    return newLabel;
}
export {
    showNearestMarketWithProductAvailableCondition,
    showSquareMetersCalculatorCondition,
    changedUnit,
    types,
    reviews,
};
