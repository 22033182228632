import { indexedFiltersNames } from './page.config';

export default function (query = {}) {
    try {
        const filtersNames = Object.keys(query);
        return !filtersNames.find(filterName => indexedFiltersNames.indexOf(filterName) === -1);
    } catch (ignore) {
        return false;
    }
}
