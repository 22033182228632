const customerAccount = `${process.env.baseUrl}/customer/account`;

export default {
    customerAccount,
    customerAccountSettings: `${customerAccount}#settings`,
    customerForgotPassword: `${customerAccount}/forgotpassword/`,
    signUp: `${customerAccount}/`,
    accountLogin: `${customerAccount}/login`,
    logout: `${customerAccount}/logout`,
};
