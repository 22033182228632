export default [
    {
        name: 'Produkty',
        symbol: 'products',
    },
    {
        name: 'Serie',
        symbol: 'series',
        type: 'url',
    },
];
