import { types } from './mutations';

export default {
    setLinks({ commit }, links) {
        commit(types.SET_LINKS, links);
    },
    clearLinks({ commit }) {
        commit(types.SET_LINKS, []);
    },
};
