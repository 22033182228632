export default function () {
    return {
        mobileScrollOffsetLeft: 0,
        productsData: [],
        attributesCodes: [],
        storeId: null,
        urlIdTable: [],
        localIdTable: [],
        compareUrl: '',
        globalItems: [],
    };
}
