<template>
    <section class="footer__block-single">
        <BaseButton
            class="block-single__title"
            :name="name"
            @click.native="toogleExpand(symbol)"
        >
            {{ name }}
        </BaseButton>
        <template
            v-for="child in listToDisplay"
        >
            <BaseLink
                v-if="child.url"
                :key="child.symbol"
                :href="link(child.url)"
                :title="child.name"
                :text="child.name"
                :target="child.target"
                :rel="child.url.rel"
            />
            <span
                v-else
                :key="child.symbol"
                class="disabled-list"
            >
                {{ child.name }}
                <client-only>
                    <span v-if="symbol === 'dane_osobowe'">io<!-- abc@def -->d@castor<!-- abc@def -->ama.pl</span>
                </client-only>
            </span>
        </template>
    </section>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';
import getProperlyRelAttributeByUrl from '~/assets/services/DOMElement/getProperlyRelAttributeByUrl';
import BaseLink from '~/components/common/Link/BaseLink';
import BaseButton from '~/components/common/Button/BaseButton';

const { mapActions: mapActionsFooter } = createNamespacedHelpers('footer');

export default {
    name: 'SingleBlockMenu',
    components: {
        BaseButton,
        BaseLink,
    },
    props: {
        name: {
            type: String,
            required: true,
        },
        symbol: {
            type: String,
            required: true,
        },
        list: {
            type: Array,
            required: true,
        },
    },
    data() {
        return {
            baseUrl: process.env.baseUrl,
            productionDomain: process.env.productionDomain,
        };
    },
    computed: {
        listToDisplay() {
            return this.list.map(link => ({ ...link, rel: getProperlyRelAttributeByUrl(link.url) }));
        },
    },
    methods: {
        ...mapActionsFooter(['toogleExpand']),
        link(url) {
            if (url.includes(this.productionDomain) || url.includes('http')) {
                return url;
            }
            return this.baseUrl + url;
        },
    },
};
</script>

<style lang="scss">
    .footer__block-single {
        text-align: left;
        font: $font-weight-regular 12px/19px $goodHomeFontFamily;
        -webkit-tap-highlight-color: transparent;
        text-transform: none;

        .block-single__title {
            width: 100%;
            text-align: left;
            display: block;
            cursor: default;
            color: $color-castoWhite;
            font: $font-weight-bold 16px/19px $goodHomeFontFamily;
            margin: 0 0 15px;
            padding: 0;
        }

        .base-link, .disabled-list {
            display: inline-block;
            width: 100%;
            color: $color-slate20-dark;
            text-decoration: none;
            background: transparent;
            font: $font-weight-regular 13px/21px $goodHomeFontFamily;

            &:hover:not(.disabled-list) {
                color: $color-castoWhite;
                text-decoration: none;
            }
        }

        .disabled-list {
            color: $color-slate50-dark;
        }
    }

    @media screen and (max-width: $mf-breakpoint-small-desktop){
        .footer__block-single {
            padding-bottom: 10px;
        }
    }

    @media screen and (max-width: $mf-breakpoint-tablet){
        .footer__block-single {
            width: 100vw;
            display: table-cell;

            .disabled-list {
                display: block;
                line-height: 30px;
                margin: 0;
            }
        }
    }
</style>
