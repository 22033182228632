<template>
    <SearchBarWrapper
        :search-url="searchUrl"
        :block-id="currentId"
        class="search-bar__wrapper"
    >
        <search-bar-label :for="inputId" />
        <SearchBarInput
            :id="inputId"
            ref="searchBarInput"
            class="search-bar__input"
            @click="addSupportSearchNode"
        />
        <SearchBarButton class="search-bar__button" />
    </SearchBarWrapper>
</template>

<script>
import { GLOBAL_VARS } from '~/config';
import SearchBarWrapper from './components/SearchBarWrapper';
import SearchBarInput from './components/SearchBarInput';
import SearchBarButton from './components/SearchBarButton';
import SearchBarLabel from '~/components/common/SearchBar/components/SearchBarLabel';

const {
    wrapper: WRAPPER_ID,
} = process.env.domIdForExternalComponents.searchNode;
const searchUrl = `${process.env.baseUrl}/catalogsearch/result/`;
const MAIN_PLUGIN_KEY = 'searchnode';
const SEARCH_INPUT_SEARCHNODE_CLASS = 'sn-suggest-input';

export default {
    name: 'SearchBar',
    components: {
        SearchBarLabel,
        SearchBarWrapper,
        SearchBarInput,
        SearchBarButton,
    },
    props: {
        type: {
            type: String,
            required: true,
        },
    },
    data() {
        return {
            searchUrl,
            WRAPPER_ID,
            activated: false,
            maxCatchIteration: 20,
            currentCatchIteration: 0,
            catchInterval: null,
        };
    },
    computed: {
        currentId() {
            return this.activated ? this.WRAPPER_ID : `${this.WRAPPER_ID}${this.type}`;
        },
        inputId() {
            return `search-input-${this.type}`;
        },
    },
    mounted() {
        window[GLOBAL_VARS.BLOCKS_LOADED_STATE].add({
            blockName: MAIN_PLUGIN_KEY,
        });

        const externalCallbackForSearchBar = window[
            GLOBAL_VARS.EXTERNAL_CALLBACKS_WRAPPER_NAME
        ].getBySource(MAIN_PLUGIN_KEY);

        if (externalCallbackForSearchBar) {
            externalCallbackForSearchBar.callback();
        }
    },
    beforeDestroy() {
        window[GLOBAL_VARS.BLOCKS_LOADED_STATE].remove({
            blockName: MAIN_PLUGIN_KEY,
        });
        this.clearCatchInterval();
    },
    methods: {
        addSupportSearchNode() {
            this.activated = true;
            import('~/plugins/helpers/searchnode.client.js'/* webpackChunkName: "searchnodeSupport" */).then(() => {
                this.catchSearchNodeInitialized();
            });
        },
        catchSearchNodeInitialized() {
            this.clearCatchInterval();
            this.catchInterval = setInterval(() => {
                this.currentCatchIteration = this.currentCatchIteration + 1;

                const searchNodeInitialized = this.$refs.searchBarInput.$el.className.indexOf(SEARCH_INPUT_SEARCHNODE_CLASS) > -1;
                if (this.currentCatchIteration > this.maxCatchIteration || searchNodeInitialized) {
                    this.$refs.searchBarInput.$el.focus();
                    this.clearCatchInterval();
                }
            }, 100);
        },
        clearCatchInterval() {
            if (this.catchInterval) {
                clearInterval(this.catchInterval);
            }
        },
    },
};
</script>

<style lang="scss">
    @import '~/assets/css/sprites/header-desktop.scss';

    .search-bar {
        &__wrapper {
            position: relative;
        }

        &__button {
            outline: none;
            position: absolute;
            @include spriteHeaderDesktop('search-blue', false, true);
            background-size: 23px auto;
            width: 26px;
            height: 26px;
            margin: 5px 9px 6px 0;
            padding: 0;
            right: 0;
            top: 4px;
            line-height: 400px;
            overflow: hidden;
            font: 14px/1.25 400 $baseFontFamily;
            display: block;
            border: none;
            cursor: pointer;
        }

        &__input {
            width: 100%;
            box-sizing: border-box;
            color: $color-slate50;
            border: 1px solid $color-slate20;
            padding: 13px 49px 11px 13px;
            background: $color-castoWhite;
            font: $font-weight-light 15px/1.25 $goodHomeFontFamily;

            &:focus {
                outline: none;
            }
        }
    }
</style>
