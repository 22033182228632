<template>
    <section
        :class="['login-header', { active: shouldDisplayBox }]"
        @mouseenter="showBox"
        @mouseleave="hideBox"
        @click="showBox"
    >
        <p class="login-header__upper-link">
            <ConditionallyNuxtLink
                v-if="isLogged"
                :use-nuxt-link="isModeTest"
                class="login-header__upper-link"
                :href="customerUrl.customerAccount"
                title="Moje konto"
                text="Moje konto"
            />
            <BaseLink
                v-else
                class="login-header__upper-link"
                :href="customerUrl.signUp"
                text="Logowanie i rejestracja"
            />
        </p>
        <UserAccountBox
            v-if="isLogged && shouldDisplayBox"
            class="login-header__pop-up"
        />
        <LoginBox
            v-if="!isLogged && shouldDisplayBox"
            class="login-header__pop-up"
            bem-modifiers="login-header"
            @close="hideBoxImmediately"
            @focus="loginBoxActive = true"
            @blur="loginBoxActive = false"
        />
    </section>
</template>

<script>
import { createNamespacedHelpers, mapGetters } from 'vuex';
import LoginBox from '~/components/LoginBox';
import ConditionallyNuxtLink from '~/components/common/Wrappers/ConditionallyNuxtLink';
import UserAccountBox from '~/components/UserAccountBox';
import BaseLink from '~/components/common/Link/BaseLink';
import { customerUrl } from '~/src/config/pagesUrl';

const { mapState: mapStateCustomer } = createNamespacedHelpers('customer');
const hideLoginBoxDelay = 100;

export default {
    name: 'LoginHeader',
    components: { BaseLink, UserAccountBox, LoginBox, ConditionallyNuxtLink },
    data() {
        return {
            customerUrl,
            boxShowed: false,
            hideBlockTimeOut: null,
            loginBoxActive: false,
        };
    },
    computed: {
        ...mapGetters(['isModeTest']),
        ...mapStateCustomer({
            isLogged: state => state.isCustomerLogged,
        }),
        shouldDisplayBox() {
            return this.loginBoxActive || this.boxShowed;
        },
    },
    methods: {
        showBox() {
            this.$nextTick(() => {
                this.clearDelay();
                this.boxShowed = true;
            });
        },
        hideBoxImmediately() {
            this.clearDelay();
            this.boxShowed = false;
        },
        hideBox() {
            this.clearDelay();
            this.hideBlockTimeOut = window.setTimeout(() => {
                this.boxShowed = false;
            }, hideLoginBoxDelay);
        },
        clearDelay() {
            if (this.hideBlockTimeOut) {
                window.clearTimeout(this.hideBlockTimeOut);
            }
        },
    },
};
</script>

<style lang="scss">
.login-header {
    padding: 0;
    background-color: $color-castoWhite;
    font-family: $goodHomeFontFamily;
    width: 100%;
    position: relative;
    text-align: left;
    top: -2px;

    &:hover, &.active {
        background-color: $color-castoBlue;

        .login-header__upper-link {
            color: $color-castoWhite;
            cursor: pointer;
        }
    }

    &:hover &__upper-link {
        color: $color-castoWhite;
    }

    &__upper-link {
        padding: 11px 11px 10px 11px;
        font: $font-weight-light 12px/28px $goodHomeFontFamily;
        margin: 0 1px 0 0;
        color: $color-slate70;
        text-decoration: none;
    }

    &__pop-up {
        border: solid 2px $color-castoBlue;
        color: $color-slate100;
        background-color: $color-castoWhite;
        position: absolute;
        top: 46px;
        height: auto;
        right: 0;
        z-index: 500;
        text-align: center;

        &:hover {
            display: block;
        }
    }
}

@media screen and (max-width: $breakpoint-big-desktop) {
    .login-header__upper-link {
        font-size: 10px;
    }
}

@media screen and (max-width: $breakpoint-small-desktop) {
    .login-header__upper-link {
        font-size: 12px;
    }
}
</style>
