import Vue from 'vue';
import { ValidationProvider, ValidationObserver, extend } from 'vee-validate';
import { required, min, email } from 'vee-validate/dist/rules';

extend('email', {
    ...email,
    message: 'Proszę wprowadzić poprawny adres email np. jan.kowalski@domena.pl.',
});

extend('required', {
    ...required,
    message: 'To pole jest wymagane.',
});

extend('password', {
    validate: value => [/.{8}/, /[A-Z]/, /[a-z]/, /[!@#$%^&*)(+=._-]+|[0-9]+/, /^\S+$/].every(test => new RegExp(test).test(value)),
    message: 'Hasło powinno składać się co najmniej z 8 znaków: dużej litery, małej litery, cyfry lub znaku specjalnego (!\'^£$%&*()}{@#~?><>,|=_+¬-]).',
    computesRequired: true,
});

extend('min', {
    ...min,
    message: 'Minimalna ilość znaków to {length}.',
});

extend('bigLetter', {
    validate: value => new RegExp(/[A-Z]/).test(value),
    message: 'Nie podano dużej litery.',
});

extend('smallLetter', {
    validate: value => new RegExp(/[a-z]/).test(value),
    message: 'Nie podano małej litery.',
});

extend('specialOrDigit', {
    validate: value => new RegExp(/[!@#$%^&*)(+=._-]+|[0-9]+/).test(value),
    message: 'Nie podano cyfry lub znaku specjalnego.',
});

extend('validate-phone-number', {
    validate: value => new RegExp(/^\d{9}$/).test(value),
    message: 'Wprowadź numer w formacie 9-cio cyfrowym.',
});

extend('validate-postcode', {
    validate: value => new RegExp(/^\d{2}-\d{3}/).test(value),
    message: 'Proszę podać kod pocztowy w prawidłowym formacie, np. 12-345.',
});

extend('validate-digits', {
    validate: value => !new RegExp(/[^\d]/).test(value),
    message: 'Proszę używać tylko liczb w danym polu. Należy unikać także spacji oraz znaków specjalnych, jak kropki i przecinki.',
});

Vue.component('ValidationProvider', ValidationProvider);
Vue.component('ValidationObserver', ValidationObserver);
