export const types = {
    PUT_FETCHED_WISHLIST: 'PUT_FETCHED_WISHLIST',
    SET_WISHLIST_TOTAL: 'SET_WISHLIST_TOTAL',
    SET_WISHLIST_ID: 'SET_WISHLIST_ID',
    FLUSH_WISHLIST: 'FLUSH_WISHLIST',
    SET_NEED_FETCH: 'SET_NEED_FETCH',
    SET_ITEMS_COUNT: 'SET_ITEMS_COUNT',
    SET_MONIT_CLOSED: 'SET_MONIT_CLOSED',
    SET_ADDED_ITEM: 'SET_ADDED_ITEM',
    UNSET_ADDED_ITEM: 'UNSET_ADDED_ITEM',
};

const mutations = {
    [types.PUT_FETCHED_WISHLIST](state, items) {
        state.items = items;
    },
    [types.SET_WISHLIST_TOTAL](state, totalPrice) {
        state.totalPrice = totalPrice;
    },
    [types.FLUSH_WISHLIST](state) {
        state.items = [];
    },
    [types.SET_WISHLIST_ID](state, wishlistId) {
        state.wishlistId = wishlistId;
    },
    [types.SET_MONIT_CLOSED](state) {
        state.showMonit = false;
    },
    [types.SET_NEED_FETCH](state, needFetch) {
        state.needFetch = needFetch;
    },
    [types.SET_ITEMS_COUNT](state, count) {
        state.itemsCount = count;
    },
    [types.SET_ADDED_ITEM](state, addedItems) {
        state.addedItems = addedItems;
    },
    [types.UNSET_ADDED_ITEM](state) {
        state.addedItems = null;
    },
};

export default mutations;
