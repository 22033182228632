function productsForDataLayer(sourceProducts) {
    const products = { ...sourceProducts };
    const dataLayerProducts = [];
    if (products) {
        Object.keys(products).forEach((key) => {
            let brandWithDataAttr = '';
            let categoryWithDataAttr = null;
            let price = null;
            const {
                productId,
                sku,
                url,
                name,
                brand,
            } = products[key];
            if (products[key].attrData) {
                brandWithDataAttr = products[key].attrData.brand;
                categoryWithDataAttr = products[key].attrData.category;
            }
            if (products[key].price && products[key].price.value) {
                price = products[key].price.value;
            }
            if (price) {
                price = price.toString();
            }
            const dataLayerObject = {
                brand: brand || brandWithDataAttr,
                url,
                name,
                id: productId || key,
                sku,
                price,
            };
            if (categoryWithDataAttr) {
                dataLayerObject.categoryWithDataAttr = categoryWithDataAttr;
            }
            dataLayerProducts.push(dataLayerObject);
        });
    }
    return dataLayerProducts;
}

function getDataLayerByParameters(dataLayerProducts, foundedParameters) {
    const parametersKeys = Object.getOwnPropertyNames(foundedParameters);
    let product = false;
    if (dataLayerProducts) {
        Object.keys(dataLayerProducts).forEach((typeProducts) => {
            Object.keys(dataLayerProducts[typeProducts]).forEach((key) => {
                if (!product) {
                    product = dataLayerProducts[typeProducts][key];
                    parametersKeys.forEach((parameterKey) => {
                        const currentProduct = dataLayerProducts[typeProducts][key];
                        if (currentProduct[parameterKey] !== foundedParameters[parameterKey]) {
                            product = false;
                        }
                    });
                }
            });
        });
    }
    return product;
}

function productsPriceForDataLayer(products, prices, replaceIdProductsOnSku = false) {
    const productsData = { ...products };
    if (productsData) {
        Object.keys(productsData).forEach((key) => {
            const { id } = productsData[key];
            if (prices && prices[id] && prices[id].price && prices[id].price.value) {
                productsData[key].price = prices[id].price.value.toString();
            }
            if (prices && prices[id] && prices[id].attrData && prices[id].attrData.category) {
                productsData[key].category = prices[id].attrData.category;
            }
            if (replaceIdProductsOnSku) {
                productsData[key].id = productsData[key].sku;
            }
        });
    }
    return productsData;
}

function additionalParametersForDataLayer(products, options, setPosition = false) {
    const productsData = { ...products };
    if (productsData) {
        Object.keys(productsData).forEach((key, index) => {
            Object.assign(productsData[key], options);
            if (setPosition) {
                productsData[key].position = index + 1;
            }
        });
    }
    return productsData;
}

export {
    productsForDataLayer,
    productsPriceForDataLayer,
    additionalParametersForDataLayer,
    getDataLayerByParameters,
};
