import getProducts from '~/assets/services/storages/local-storage/compare/getProducts';

export default function ({ id, sku, image, name, url }) {
    if (localStorage) {
        const currentProducts = getProducts();
        currentProducts.map((item) => {
            item.lastAdded = false;
            return item;
        });
        currentProducts.push({
            id,
            sku,
            name,
            image,
            url,
            lastAdded: true,
        });
        localStorage.removeItem('compare');
        localStorage.setItem('compare', JSON.stringify(currentProducts));
    }
}
