// eslint-disable-next-line
const apiPath = process.env.apiPath;

export const GET_MARKET_DATA_URL = `${
    process.env.baseUrl
}/bold_all/data/getMarketData/`;
export const FETCH_MARKET_INFO = `${
    process.env.baseUrl
}${apiPath}market`;
export const FETCH_ALL_MARKETS = `${
    process.env.baseUrl
}${apiPath}markets`;
export const FETCH_ALL_MARKETS_INFO = `${
    process.env.baseUrl
}/cataloginventory/index/checkAvailabilityInStores`;
export const NEAREST_MARKET_WITH_PRODUCT_AVAILABLE = `${
    process.env.baseUrl
}/bold_all/data/getMarketData/marketWithNearestStockAvailability.php`;
export const SET_AVAILABILITY_REMINDER = `${
    process.env.baseUrl
}/productalert/add/stock`;
