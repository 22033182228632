import CookieUniversal from 'cookie-universal';
import cookieConfig from '~/assets/services/storages/cookies/cookie.config';

const cookies = new CookieUniversal();
const cookieName = 'popup_close_info';
const cookieValue = 1;
const cookieMaxDays = 30;

function getCookieMonitWishlistClosed() {
    return cookies.get(cookieName);
}

export function setCookieMonitWishlistClosed() {
    cookies.set(cookieName, cookieValue, {
        ...cookieConfig.defaultOptions,
        maxAge: cookieMaxDays * 24 * 60 * 60,
    });
}

export function isCookieMonitWishlistClosed() {
    return getCookieMonitWishlistClosed() === cookieValue;
}
