import { types } from './mutations';

export default {
    showPopup({ commit }, { instance, optionalProps = {}, optionalListeners = {} }) {
        commit(types.SET_OPTIONAL_PROPS, optionalProps);
        commit(types.SET_OPTIONAL_LISTENERS, optionalListeners);
        commit(types.SET_INSTANCE, instance);
    },
    hidePopup({ commit }) {
        commit(types.SET_INSTANCE, null);
        commit(types.SET_OPTIONAL_PROPS, {});
        commit(types.SET_OPTIONAL_LISTENERS, {});
    },
};
