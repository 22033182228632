<template>
    <div class="cart-mobile-wrapper">
        <Transition name="slide">
            <PopupCartMobile
                v-if="displayPopup"
                :display-state="displayPopup"
                @back="displayPopup=false"
            />
        </Transition>
        <BaseButton
            class="cart-mobile-wrapper__button"
            name="Pokaż koszyk"
            @click.native="openCart"
        >
            <p class="cart-mobile-wrapper__title">
                {{ cartItemsCount | transformQuantityWithoutQty('Product') }}
            </p>
            <span class="cart-mobile-wrapper__quantity">
                {{ cartItemsCount }}
            </span>
        </BaseButton>
    </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';
import BaseButton from '~/components/common/Button/BaseButton';

const {
    mapActions: mapActionsCart,
    mapState: mapStateCart,
} = createNamespacedHelpers('cart');
const { mapActions: mapActionsLoader } = createNamespacedHelpers('loader');
const { mapActions: mapActionsPopup } = createNamespacedHelpers('popup');

export default {
    name: 'CartMobile',
    components: {
        BaseButton,
        PopupCartMobile: () => import('./components/PopupCartMobile' /* webpackChunkName: "popup/cart-mobile" */),
    },
    data() {
        return {
            displayPopup: false,
        };
    },
    computed: {
        ...mapStateCart({
            cartItemsCount: state => state.itemsCount,
            needFetch: state => state.needFetch,
        }),
    },
    methods: {
        ...mapActionsCart(['fetchData']),
        ...mapActionsLoader(['showLoader', 'hideLoader']),
        ...mapActionsPopup(['showPopup', 'hidePopup']),
        openCart() {
            this.displayPopup = true;
            if (this.needFetch) {
                this.showLoader({
                    type: 'BlueDotsPreloader',
                });
                this.fetchData()
                    .finally(() => {
                        this.hideLoader();
                    });
            }
        },
    },
};
</script>

<style lang="scss">
@import '~assets/css/transitions/slide.scss';

.cart-mobile-wrapper {
    text-align: center;
    cursor: pointer;

    &__quantity {
        border-radius: 100%;
        line-height: 17px;
        color: $color-castoWhite;
        font-family: $goodHomeFontFamily;
        text-align: center;
        background-color: $color-deepCanynon-dark;
        display: inline-block;
        font-weight: $font-weight-bold;
        width: 18px;
        height: 18px;
        text-indent: 0;
        font-size: 11px;
        position: absolute;
        top: 38%;
        left: 58%;
    }

    &__button {
        text-transform: capitalize;
        color: $color-slate100;
        height: 54px;
        width: 100%;
        background: $color-castoWhite url('~static/images/icons/nh-mini-cart.svg') no-repeat center 12px;
    }

    &__title {
        text-align: center;
        position: absolute;
        bottom: 9px;
        margin: 0 auto;
        left: 0;
        right: 0;
        font: $font-weight-regular 12px/12px $goodHomeFontFamily;
        color: $color-slate70-dark;
    }
}
</style>
