<template>
    <!--eslint-disable-next-line-->
    <client-only>
        <carousel
            ref="customCarousel"
            v-bind="$attrs"
            :touch-drag="isTouchedDown"
            :mouse-drag="isTouchedDown"
            @pageChange="pageChange"
        >
            <slot />
            <component
                :is="currentNavigation.componentReference"
                slot="navigation"
                v-bind="currentNavigation.dataForComponent"
            />
            <CustomPagination
                :is="currentPagination"
                slot="pagination"
            />
        </carousel>
    </client-only>
</template>

<script>
import CustomNavigation from '~/components/common/Carousel/components/CustomNavigation';
import CustomPagination from '~/components/common/Carousel/components/CustomPagination';
import MiniNavigation from '~/components/common/Carousel/components/MiniNavigation';
import ProductNavigation from './components/ProductNavigation';
import ProductPagination from './components/ProductPagination';

export default {
    name: 'CustomCarousel',
    components: {
        CustomPagination,
        ProductPagination,
    },
    inheritAttrs: false,
    props: {
        typeNavigation: {
            type: String,
            default: 'isCustomNavigation',
        },
        isProductPagination: {
            type: Boolean,
            default: false,
        },
        isCustomPagination: {
            type: Boolean,
            default: false,
        },
        isTouchedDown: {
            type: Boolean,
            default: true,
        },
    },
    data() {
        return {
            navigation: {
                isMiniNavigation: {
                    componentReference: MiniNavigation,
                },
                isCustomNavigation: {
                    componentReference: CustomNavigation,
                },
                isProductNavigation: {
                    componentReference: ProductNavigation,
                },
                isProductListNavigation: {
                    componentReference: ProductNavigation,
                    dataForComponent: {
                        productListNavigation: true,
                    },
                },
            },
        };
    },
    provide() {
        return {
            navigateForward: this.navigateForward,
            navigateBackward: this.navigateBackward,
        };
    },
    computed: {
        currentPagination() {
            return this.isProductPagination ? ProductPagination : CustomPagination;
        },
        currentNavigation() {
            return this.navigation[this.typeNavigation];
        },
    },
    methods: {
        navigateForward() {
            this.$refs.customCarousel.goToPage(this.$refs.customCarousel.getNextPage());
        },
        navigateBackward() {
            this.$refs.customCarousel.goToPage(this.$refs.customCarousel.getPreviousPage());
        },
        pageChange(numberPage) {
            this.$emit('page-change', numberPage);
        },
    },
};
</script>
