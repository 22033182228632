import PAGE_CACHE_TAGS from './cache';
import PREFETCH_DNS_LIST from './prefetchDnsList';

const GLOBAL_VARS = {
    ORGANIZATION_NAME: 'Castorama Polska Sp. z o.o',
    QUERY_MODE_PROPERTY_NAME: 'mode',
    MODE_DEFAULT_VALUE: 'default',
    MODE_TEST_VALUE: 'test',
    MODE_HOMEPAGE_VALUE: 'homepage',
    BASE_LINK: {
        text: 'Strona główna',
        relativePath: '/',
    },
    DEPLOY_BLOCK_FILE_NAME: 'deploy.lock',
    PAGE_AUTHOR: 'Bold now Strix',
    BLOCKS_LOADED_STATE: 'castoramaBlocksLoaded',
    SEARCH_NODE_CALLBACK_WRAPPER_NAME: 'onContentReadyforSearchNode',
    EXTERNAL_CALLBACKS_WRAPPER_NAME: 'EXTERNAL_CALLBACKS',
    BREAK_POINTS: {
        SMART_PHONES: 480,
        SMALL: 576,
        TABLET: 768,
        SMALL_DESKTOP: 992,
        DESKTOP: 1200,
    },
    GOOGLE_TAG_MANAGER_ID: 'GTM-PCMFXL',
    GOOGLE_ANALYTICS_ID: 'UA-27193958-1',
    GOOGLE_PUBLISHER_TAG_ID: 27263118,
    THEME: {
        TABLET_STICKY_HEADER_HEIGHT: 92,
        DESKTOP_STICKY_HEADER_HEIGHT: 69,
    },
};

const ERORR_MESSAGES_BY_CODE = {
    404: 'Błąd połączenia',
};

export { GLOBAL_VARS, ERORR_MESSAGES_BY_CODE, PAGE_CACHE_TAGS, PREFETCH_DNS_LIST };
