import { types } from './mutations';
import { GLOBAL_VARS } from '~/config/index';

export default {
    onWindowResize({ commit }, { width }) {
        commit(types.RESIZE_WINDOW, width);
        commit(types.SET_MOBILE_STATE, width < GLOBAL_VARS.BREAK_POINTS.TABLET);
        commit(types.SET_TABLET_STATE, width >= GLOBAL_VARS.BREAK_POINTS.TABLET && width < GLOBAL_VARS.BREAK_POINTS.SMALL_DESKTOP);
        commit(types.SET_DESKTOP_STATE, width >= GLOBAL_VARS.BREAK_POINTS.SMALL_DESKTOP);
    },
    setMobileState({ commit }, currentState) {
        commit(types.SET_MOBILE_STATE, currentState);
    },
    setTabletState({ commit }, currentState) {
        commit(types.SET_TABLET_STATE, currentState);
    },
    setDesktopState({ commit }, currentState) {
        commit(types.SET_DESKTOP_STATE, currentState);
    },
};
