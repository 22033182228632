export default function () {
    return {
        width: 500,
        isMobile: null,
        isTablet: null,
        isDesktop: null,
        isMobileDevice: null,
        levelPageUrl: null,
    };
}
