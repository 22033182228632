import { types } from './mutations';
import { getProductReviews } from '~/assets/services/product';

export default {
    async setReviewsToProduct({ commit }, { productId, reviewsData }) {
        commit(types.SET_PRODUCT_REVIEWS_DATA, {
            productId,
            reviewsData,
        });
    },
    async addReviewsToProduct({ commit }, { productId, limit, offset }) {
        const newReviews = await getProductReviews({ productId, limit, offset });
        if (newReviews) {
            commit(types.ADD_PRODUCT_REVIEWS, {
                productId,
                newReviews,
            });
        }
    },
};
