import { CLIENT_TYPES } from '~/config/apollo';
import createClient from '~/src/apollo/createClient';

/**
 * This function create apollo clients.
 *
 * @param {Object} context - special object from nuxt
 *
 * @return {Object}
 */
export function createClients({ context }) {
    const { app: { $logger: logger } } = context;
    const clients = [
        createClient({ logger }, CLIENT_TYPES.GRAPHQL),
        createClient({ logger }, CLIENT_TYPES.REST),
    ];

    const [graphQLClient, restClient] = clients;

    return { graphQLClient, restClient };
}

/**
 * This function load apollo service from file.
 *
 * @param {String} name - service fileName
 *
 * @return {Object}
 */
async function loadService(name) {
    const { default: action } = await import(`~/src/apollo/services/${name}`);
    return { action, name };
}

/**
 * This function recursively loads service if not loaded yet and return it
 *
 * @param {Object} app - nuxt app from context
 * @param {Array} servicesNames - array of services names
 * @param {Object} clients - apollo clients
 * @returns {Object}
 */
async function loadServices({ app }, servicesNames, clients) {
    const name = servicesNames.pop();

    if (name) {
        const action = app.$apollo.services[name];

        if (action) {
            return { [name]: action, ...await loadServices({ app }, servicesNames, clients) };
        }

        const { action: serviceAction } = await loadService(name);
        const services = serviceAction.call(app, { clients });

        app.$apollo.services[name] = services;

        return { [name]: services, ...await loadServices({ app }, servicesNames, clients) };
    }

    return null;
}

/**
 * This function returns API functionality by service name
 *
 * @param {Object} context - special object from nuxt
 * @param {Array} servicesNames - array of services names
 * @param {String} source - name of loader invoker
 *
 * @return {Object}
 */
export default async (context, { services: servicesNames, source = 'apolloLoadServices' }) => {
    const { app } = context;
    const { $logger } = app;
    if (servicesNames) {
        try {
            const clients = createClients({ context });

            return await loadServices(context, servicesNames, clients);
        } catch (errorMessage) {
            $logger({
                message: `Cannot load apollo services: ${servicesNames.join(',')}, error: ${errorMessage}`,
                source,
            });
        }
    }
};
