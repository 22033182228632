import dataLayerAddToCart from '~/assets/services/dataLayer/dataLayerAddToCart';
import { getDataLayerByParameters } from '~/assets/services/dataLayer/dataLayerProducts';
import { prepareDataProductRemoveWithCart, dataLayerRemoveWithCart } from '~/assets/services/dataLayer/dataLayerRemoveFromCart';
import { recommendedProduct as normalizeRecommendedProduct } from '~/assets/services/category/normalizeData';
import {
    addProduct as addProductToCart,
    addProductsSet as addProductsSetToCart,
    deleteProduct,
    fetchItems,
    changeProductQty,
} from '~/assets/services/cart';

import { types } from './mutations';

export default {
    async addToCart({ dispatch, rootState }, { id, groupedData, source, qty = 1, withFetch = 1 }) {
        if (!Number.isNaN(qty) && qty > 0) {
            const {
                items,
                qtyAdded,
                success,
                error,
                recommended,
            } = await addProductToCart({ id, qty, source, groupedData });

            if (withFetch && success) {
                const products = getDataLayerByParameters(rootState.googleServices.products, { id });

                await dispatch('fetchData', true);

                if (products) {
                    const dataLayerProducts = { type: 'recommendedProducts', products: recommended.map(product => normalizeRecommendedProduct(product)) };

                    dispatch('googleServices/setDataLayerProducts', dataLayerProducts, { root: true });
                    products.quantity = qtyAdded;
                    dataLayerAddToCart({ recommended, product: products });
                }
                return ({ items, recommended, qtyAdded, success, error });
            }
        }
        return { error: true };
    },
    async addSetToCart({ dispatch }, { setId, productsSet }) {
        const result = await addProductsSetToCart({ setId, productsSet });
        await dispatch('fetchData', true);
        return result;
    },
    async deleteItem({ dispatch, state }, { itemId, withFetch = true }) {
        const prepareDataProduct = prepareDataProductRemoveWithCart(state.items, itemId);
        await dispatch('registerRequest', {
            requestHandler: deleteProduct.bind(this, { id: itemId }),
            successHandler: () => {
                if (withFetch) {
                    dispatch('fetchData', { isLoader: true });
                    dataLayerRemoveWithCart(prepareDataProduct);
                }
            },
        }, { root: true });
    },
    async fetchData({ commit, dispatch }, payload = {}) {
        const { isLoader: addLoader = true } = payload;
        dispatch('setNeedFetch', false);
        dispatch('registerRequest', {
            requestHandler: fetchItems,
            successHandler: (data) => {
                const { items, subtotal, formKey, payment } = data;
                commit(types.SET_CART_SUBTOTAL, subtotal);
                commit(types.ADD_PAYMENT_INFO, payment);
                commit(types.SET_FORM_KEY, formKey);
                commit(types.PUT_FETCHED_CART, items);
                dispatch('setItemsCount');
            },
            errorHandler: (errorMessages) => {
                dispatch('setNeedFetch', true);
                if (this.app && this.app.$logger) {
                    this.app.$logger({
                        message: `Can not fetch cart error: ${errorMessages}`,
                        source: 'store/cart/actions.js',
                    });
                }
            },
            addLoader,
        }, { root: true });
    },
    changeQty({ state, dispatch }, { qty, itemId }) {
        if (state.formKey !== '') {
            dispatch('registerRequest', {
                requestHandler: changeProductQty.bind(this, { formKey: state.formKey, itemId, qty }),
                successHandler: () => {
                    dispatch('fetchData', { isLoader: true });
                },
            },
            { root: true });
        }
    },
    setItemsCount({ commit, state }, count) {
        if (count || count === 0) {
            commit(types.SET_ITEMS_COUNT, count);
        } else {
            const cartCount = state.items.length
                ? state.items.map(item => Math.ceil(item.qty)).reduce((summary, qty) => summary + qty)
                : 0;
            commit(types.SET_ITEMS_COUNT, cartCount);
        }
    },
    setNeedFetch({ commit }, needFetch) {
        commit(types.SET_NEED_FETCH, needFetch);
    },
    flushCart({ commit }) {
        commit(types.FLUSH_CART);
    },
    fetchDataIfNeed({ state, dispatch }, payload) {
        if (state.needFetch) {
            return dispatch('fetchData', payload);
        }
    },
};
