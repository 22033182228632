export const types = {
    CREATE_NEW_PROVINCE: 'CREATE_NEW_PROVINCE',
    CREATE_NEW_SKU: 'CREATE_NEW_SKU',
    FILL_AVAILABILITY: 'FILL_AVAILABILITY',
    FLUSH_AVAILABILITY: 'FLUSH_AVAILABILITY',
};

const mutations = {
    [types.CREATE_NEW_PROVINCE](state, { province }) {
        state.availability[province] = {};
    },
    [types.CREATE_NEW_SKU](state, { province, sku }) {
        state.availability[province][sku] = [];
    },
    [types.FILL_AVAILABILITY](state, { province, sku, response }) {
        state.availability[province][sku] = response;
    },
    [types.FLUSH_AVAILABILITY](state) {
        state.availability = {};
    },
};

export default mutations;
