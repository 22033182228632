export default function (shopId) {
    return new Promise((resolve, reject) => {
        if (window.dataLayer) {
            window.dataLayer.push({ store: String(shopId) });
            resolve();
        } else {
            reject();
        }
    });
}
