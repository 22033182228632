<template>
    <div
        class="product-image-name-block-no-forward"
    >
        <BaseImage
            class="product-image-name-block-no-forward__image"
            :data="{src: img, alt: title}"
        />
        <h3
            class="product-image-name-block-no-forward__name"
        >
            {{ title }}
        </h3>
    </div>
</template>

<script>
import BaseImage from '~/components/common/Image/BaseImage';

export default {
    name: 'ProductImageNameBlockNoForward',
    components: {
        BaseImage,
    },
    props: {
        img: {
            required: true,
            type: String,
        },
        title: {
            required: true,
            type: String,
        },
    },
};
</script>

<style lang="scss">
    .product-image-name-block-no-forward {
        background-color: $color-castoWhite;
        &__image {
            width: 100%;
            margin-bottom: 24px;
            .image-base {
                width: 100%;
            }
        }
        &__name {
            color: $color-slate100;
            font: $font-weight-bold 13px/16px $goodHomeFontFamily;
            height: 64px;
            margin: 0 auto 10px auto;
            overflow: hidden;
            text-align: center;
        }
    }
    @media screen and (max-width: $breakpoint-tablet) {
        .product-image-name-block-no-forward {
            width: auto;
            &__image {
                height: auto;
            }
            &__name {
                height: initial;
            }
        }
    }
</style>
