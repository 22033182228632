import { templateLevelLimit } from './config';
import getLevelByPath from './getLevelByPath';
import { isCategorySeasonPath } from '~/assets/services/category';


export default function ({ path }) {
    let levelByPath = getLevelByPath(path);

    if (isCategorySeasonPath(path)) {
        levelByPath += 1;
    }

    if (levelByPath >= templateLevelLimit) {
        return 'CategoryLevel3';
    }
    return `CategoryLevel${levelByPath}`;
}
