const DEFAULT_CODE = 'default';

const CODES = {
    in: 'in',
    out: 'out',
    backorder: 'backorder',
    default: 'default',
};

const LABELS_BY_CODES = {
    [CODES.in]: 'Dostępny',
    [CODES.out]: 'Niedostępny',
    [CODES.backorder]: 'Na zamówienie',
    [CODES.default]: 'Cena sugerowana',
};

const CODES_DISABLED_IN_SALE = [CODES.out];

export { CODES, LABELS_BY_CODES, DEFAULT_CODE, CODES_DISABLED_IN_SALE };
