<template>
    <form
        v-bind="$attrs"
        class="base-form-with-validation"
        @submit="submitForm"
    >
        <slot />
    </form>
</template>

<script>
import { validate, isValidationPassed } from '~/assets/services/validator';
import FieldWarning from '~/components/common/FormValidationPrompt/FieldWarning';
import { appendToComponent, removeFromComponent } from '~/assets/services/DOMManipulate';

export default {
    name: 'BaseFormWithValidation',
    props: {
        submitByNative: {
            type: Boolean,
            default: true,
        },
    },
    data() {
        return {
            currentValidations: [],
            currentFormDataToSubmit: {},
        };
    },
    methods: {
        flushDataBeforeValidate() {
            this.currentValidations.forEach(({ DOMElement, instance }) => {
                removeFromComponent(DOMElement, instance);
            });
            this.currentValidations = [];
            this.currentFormDataToSubmit = {};
        },
        submitForm(event) {
            event.preventDefault();
            const allTestHandlers = [];
            const elementsToValidateCollection = [
                ...event.srcElement.getElementsByTagName('input'),
                ...event.srcElement.getElementsByTagName('textarea'),
            ];
            this.flushDataBeforeValidate();
            elementsToValidateCollection.forEach((singleElementToValidate) => {
                const { attributes, checked: isChecked, value: valueOfElement } = singleElementToValidate;
                const { 'data-validate': dataValidate, type: elementType } = attributes;
                singleElementToValidate.classList.remove('not-valid');
                const validationArray = dataValidate ? dataValidate.value.split(',') : [];
                const valueToValidate = elementType.value === 'checkbox' ? isChecked || '' : valueOfElement;
                allTestHandlers.push(validate(valueToValidate, validationArray, 'every').then((validationResult) => {
                    if (typeof validationResult === 'object') {
                        const getWarning = validationResult.find(singleValidationResult => !singleValidationResult.testValue).instructions;
                        const virtualInstanceRef = appendToComponent(singleElementToValidate.parentElement, FieldWarning, {
                            text: getWarning,
                            bemModifiers: this.bemModifiers,
                        });
                        this.currentValidations.push({ DOMElement: singleElementToValidate.parentElement, instance: virtualInstanceRef });
                        singleElementToValidate.classList.add('not-valid');
                    } else {
                        this.currentFormDataToSubmit = {
                            ...this.currentFormDataToSubmit,
                            [attributes.name.value]: singleElementToValidate.value,
                        };
                    }
                    return validationResult;
                }));
                return singleElementToValidate;
            });
            Promise.all(allTestHandlers).then((validationCollection) => {
                if (this.submitByNative) {
                    event.target.submit();
                } else if (isValidationPassed(validationCollection)) {
                    this.$emit('send-form', this.currentFormDataToSubmit);
                }
            });
        },
    },
};
</script>
