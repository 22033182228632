import Vue from 'vue';
import isInViewport from '~/assets/services/DOMElement/isInViewport';
import { getPossibleImageSources } from '~/assets/services/helpers';
import imageLoading from '~/assets/services/DOMElement/imageLoading';

const emit = (vnode, name, data) => {
    const handlers = (vnode.data && vnode.data.on) ||
        (vnode.componentOptions && vnode.componentOptions.listeners);

    if (handlers && handlers[name]) {
        handlers[name].fns(data);
    }
};

const errorImageSource = require('~/static/images/placeholder-error.png');

function cacheObserver(element, observer) {
    element.progressiveSrcObserver = observer;
}

function removeCacheObserver(element) {
    if (element.progressiveSrcObserver) {
        element.progressiveSrcObserver.disconnect();
        element.progressiveSrcObserver = null;
    }
}

Vue.directive('progressiveBackground', {
    intersectionObserver: null,
    async bind(element, { value }, vnode) {
        const { src, handlePixelRatio = false } = value;
        const possibleImageSources = await getPossibleImageSources({ src });
        const optimalExtensionSrc = possibleImageSources[0];
        const img = new Image();

        if (handlePixelRatio && window.devicePixelRatio > 1) {
            optimalExtensionSrc.replace('.', '_x2.');
        }

        emit(vnode, 'progressiveBackgroundBefore');
        const { checkingIsInViewport, intersectionObserver } = isInViewport({ element });
        checkingIsInViewport.then(() => {
            img.src = optimalExtensionSrc;
            return imageLoading(img);
        }).then(() => {
            element.style.backgroundImage = `url(${optimalExtensionSrc})`;
            emit(vnode, 'progressiveBackgroundSuccess');
        }, () => {
            element.style.backgroundImage = `url(${errorImageSource})`;
            emit(vnode, 'progressiveBackgroundError');
        }).finally(() => {
            emit(vnode, 'progressiveBackgroundChanged');
        });
        cacheObserver(element, intersectionObserver);
    },
    unbind(element) {
        removeCacheObserver(element);
    },
});
