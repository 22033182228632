<template>
    <LinkDataLayer
        :class="['product-image__wrapper', { 'product-image__wrapper--loaded': loaded }]"
        :href="url"
        :title="name"
    >
        <ProgressiveImage
            :data="{ src: image, alt: name }"
            class="product-image"
            @success="loaded = true"
        />
    </LinkDataLayer>
</template>

<script>
import LinkDataLayer from '~/components/common/Link/LinkDataLayer';
import ProgressiveImage from '~/components/common/Image/ProgressiveImage';

export default {
    name: 'ImageProductTail',
    components: { ProgressiveImage, LinkDataLayer },
    props: {
        name: {
            type: String,
            required: true,
        },
        image: {
            type: String,
            required: true,
        },
        url: {
            type: String,
            default: '',
        },
    },
    data() {
        return {
            loaded: false,
        };
    },
};
</script>

<style lang="scss">
    .product-image {
        max-width: 100%;

        &__wrapper {
            display: block;
            min-height: 266px;
            background: $color-castoWhite;


            &--loaded {
                background: none;

                .product-image {
                    opacity: 1;
                }
            }
        }
    }

    @media only screen and (max-width: $breakpoint-big-desktop) {
        .product-image__wrapper {
            min-height: 216px;
        }
    }

    @media (min-width: $breakpoint-tablet) and (max-width: $breakpoint-small-desktop) {
        .product-image__wrapper {
            min-height: 224px;
        }
    }

    @media only screen and (max-width: $breakpoint-tablet) {
        .product-image__wrapper {
            min-height: 138px;
        }
    }

    @media only screen and (max-width: $breakpoint-smart-phones) {

    }
</style>
