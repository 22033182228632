export default {
    storeAddress: ({ storeInfo }) => (storeInfo ? (storeInfo.address || {}) : {}),
    hasCourierSaturdayDelivery({ storeInfo }) {
        const { courierSaturdayDelivery } = storeInfo || {};
        return !!courierSaturdayDelivery;
    },
    phoneToDisplay({ storeInfo }) {
        const { phoneNumber, phoneNumberOrders } = storeInfo || {};
        return phoneNumberOrders || phoneNumber;
    },
};
