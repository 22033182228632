import axios from 'axios';
import 'url-search-params-polyfill';

import { CHECK_CUSTOMER_URL } from './api.config';

export default function () {
    return axios({
        method: 'get',
        url: CHECK_CUSTOMER_URL,
    });
}
