import { GLOBAL_MESSAGE_TYPES } from '~/src/config/dictionary/message/index';

function normalizeData(msgType) {
    const normalizeMsgType = msgType.toLowerCase().trim();
    const defaultMessageType = GLOBAL_MESSAGE_TYPES.warning;

    return Object.keys(GLOBAL_MESSAGE_TYPES).some(el => (el === normalizeMsgType))
        ? normalizeMsgType
        : defaultMessageType;
}

function groupMessagesByType(messages) {
    const messagesGroupedByType = [];
    messages.forEach((message) => {
        const messageType = normalizeData(message.type);
        let indexOfType = messagesGroupedByType.findIndex(({ type }) => type === messageType);
        if (indexOfType <= -1) {
            messagesGroupedByType.push({
                type: messageType,
                texts: [],
            });
            indexOfType = messagesGroupedByType.length - 1;
        }
        messagesGroupedByType[indexOfType].texts.push(message.text);
    });
    return messagesGroupedByType;
}

export default function (messages = []) {
    return groupMessagesByType(messages);
}
