<template>
    <div class="wrapper col-sm-12 col-md-12 col-lg-5">
        <div class="row">
            <div
                v-for="(itemMenu, indexMenu) in menuSections"
                :key="indexMenu"
                :class="['col-sm-12 col-md-6 col-lg-6 footer-bottom__section', {'expanded': itemMenu.isExpanded}]"
            >
                <SingleBlockMenu v-bind="itemMenu" />
            </div>
        </div>
    </div>
</template>

<script>
import SingleBlockMenu from '~/components/common/Footer/components/SingleBlockMenu';

export default {
    name: 'FirstMenuBottom',
    components: {
        SingleBlockMenu,
    },
    props: {
        menuSections: {
            type: Array,
            required: true,
        },
    },
};
</script>

<style lang="scss">
    .wrapper {
        outline: none;
    }
</style>
