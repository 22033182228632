function getLazyElementFromIframe(iframeElement) {
    const iframeElementContent = iframeElement.contentDocument || iframeElement.contentWindow.document;
    const preloaderElement = iframeElementContent.getElementById('preloader');
    const backgroundElement = iframeElementContent.getElementsByClassName('bold-lazy-load bold-loading')[0];
    const imageElements = iframeElementContent.querySelectorAll('img[data-src]');
    const iframeWrapper = iframeElement.parentNode.parentNode;
    return {
        iframeElement,
        preloaderElement,
        backgroundElement,
        imageElements,
        iframeWrapper,
    };
}

export default async function () {
    try {
        const iframeElements = document.getElementsByTagName('iframe');
        const asyncIframes = [];

        const iframeElementsArray = [].slice.call(iframeElements);
        const unprocessedIframes = iframeElementsArray.filter(iframeElement => !iframeElement.hasAttribute('lazy-processed') && iframeElement.id && iframeElement.id.indexOf('google_ads_iframe') >= 0);

        if (unprocessedIframes.length) {
            unprocessedIframes.forEach((iframeElement) => {
                const asyncIframe = new Promise((resolve) => {
                    try {
                        const iframeElementContent = iframeElement.contentDocument || iframeElement.contentWindow.document;
                        if (iframeElementContent.readyState === 'complete') {
                            resolve(getLazyElementFromIframe(iframeElement));
                        } else {
                            iframeElement.onload = () => {
                                try {
                                    resolve(getLazyElementFromIframe(iframeElement));
                                } catch (e) {
                                    resolve(null);
                                }
                            };
                        }
                    } catch {
                        resolve(null);
                    }
                });

                asyncIframes.push(asyncIframe);
            });
            const lazyLoadElements = await Promise.all(asyncIframes);
            return lazyLoadElements.filter(lazyLoadElement => !!lazyLoadElement);
        }
        return [];
    } catch (e) {
        return [];
    }
}
