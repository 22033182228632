<template>
    <button
        :type="type"
        :name="name"
        class="base-button"
        :class="getBemModifiers()"
        v-on="$listeners"
    >
        <slot />
    </button>
</template>

<script>
export default {
    name: 'BaseButton',
    props: {
        type: {
            type: String,
            default: 'button',
        },
        name: {
            type: [String, Number],
            default: null,
        },
    },
};
</script>

<style lang="scss">
    .base-button {
        font: $font-weight-bold 17px/48px $goodHomeFontFamily;
        color: $color-castoWhite;
        box-sizing: border-box;
        border: none;
        background: none;
        outline: none;
        display: flex;
        text-decoration: none;
        cursor: pointer;

        &--horizontally-centered {
            justify-content: center;
            margin-left: auto;
            margin-right: auto;
        }

        &--vertically-centered {
            align-items: center;
        }

        &--full-width {
            width: 100%;
        }

        &--color-inherit {
            color: inherit;
        }

        &--color-slate100 {
            color: $color-slate100;
        }

        &--color-slate100-dark {
            color: $color-slate100-dark;
        }

        &--color-casto-blue {
            color: $color-castoBlue;
        }

        &--line-height-one {
            line-height: 1;
        }

        &--line-height-small {
            line-height: 36px;
        }

        &--line-height-big {
            line-height: 60px;
        }

        &--font-size-smallest {
            font-size: 13px;
        }

        &--font-size-small {
            font-size: 14px;
        }

        &--font-size-normal {
            font-size: 16px;
        }

        &--font-weight-regular {
            font-weight: $font-weight-regular;
        }

        &--hover-underlined:hover {
            text-decoration: underline;
        }

        &--background-casto-blue {
            background-color: $color-castoBlue;

            &:hover {
                background-color: $color-castoBlue-dark;
            }
        }

        &--background-deep-canyon {
            background-color: $color-deepCanynon;

            &:hover {
                background-color: $color-deepCanynon-dark;
            }
        }

        &--background-slate-medium {
            background: $color-slate70;

            &:hover {
                background-color: $color-slate70-dark;
            }
        }

        &--background-slate-dark {
            background: $color-slate100;

            &:hover {
                background-color: $color-slate100-dark;
            }
        }

        &--disabled {
            background-color: $color-slate10;
            cursor: default;

            &:hover {
                background: $color-slate10;
            }
        }

        &--justify-content-center {
            justify-content: center;
        }

        &--faq {
            background: none;
            line-height: 11px;
            font-size: 11px;
            font-weight: $font-weight-light;
            float: right;
        }
    }
    @media (min-width: $mf-breakpoint-tablet) {
        .base-button--faq {
            font: $font-weight-bold 16px/36px $goodHomeFontFamily;
            background-color: $color-castoYellow;

            &:hover {
                background-color: $color-castoYellow-dark;
            }
        }
    }
</style>
