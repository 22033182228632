import { types } from './mutations';
import {
    addProduct,
    updateItemQty,
    fetchItems,
    removeItem,
    addAllToCart as addAllItemsToCart,
    removeAllItems,
} from '~/assets/services/wishlist';
import {
    isCookieMonitWishlistClosed,
    setCookieMonitWishlistClosed,
} from '~/assets/services/storages/cookies/cookieWishlist';

export default {
    async addItemToWishlist({ commit, dispatch }, { groupedData, productId, source, name, price, qty = 1 }) {
        const wishlistTotalPrice = (price * qty).toFixed(2);

        dispatch('registerRequest', {
            requestHandler: addProduct.bind(this, { productId, source, qty, groupedData }),
            successHandler: async () => {
                await dispatch('fetchData');
                commit(types.SET_ADDED_ITEM, [{ name, price: wishlistTotalPrice, qty }]);
            },

        }, { root: true });
    },
    addAllToCart({ rootState, state, dispatch }) {
        return new Promise((resolve, reject) => {
            const { loginFormKey: formKey } = rootState.customer;
            const { wishlistId } = state;
            addAllItemsToCart({ formKey, wishlistId })
                .then(({ successMessage, errors }) => {
                    if (successMessage) {
                        dispatch('fetchData', true);
                        dispatch('cart/fetchData', true, { root: true });
                    }
                    resolve({ successMessage, errors });
                })
                .catch(() => {
                    reject(new Error('Can not add all items to cart'));
                });
        });
    },
    async deleteItem({ dispatch }, { itemId }) {
        await dispatch('registerRequest', {
            requestHandler: removeItem.bind(this, { itemId }),
            successHandler: await dispatch('fetchData'),
        }, { root: true });
    },
    async deleteAll({ dispatch }) {
        await dispatch('registerRequest', {
            requestHandler: removeAllItems,
            successHandler: async () => {
                await dispatch('fetchData');
            },
        }, { root: true });
    },
    async fetchData({ commit, dispatch }) {
        const data = await fetchItems();
        const { items, subtotal, wishlistId } = data;
        if (items) {
            dispatch('setNeedFetch', false);
            dispatch('flushWishlist');
            commit(types.SET_WISHLIST_TOTAL, subtotal);
            commit(types.SET_WISHLIST_ID, wishlistId);
            commit(types.PUT_FETCHED_WISHLIST, items);
            dispatch('setItemsCount');
        }
    },
    async changeQty({ dispatch }, { qty, wishlistItemId }) {
        await dispatch('registerRequest', {
            requestHandler: updateItemQty.bind(this, { wishlistItemId, qty }),
            successHandler: async () => {
                await dispatch('fetchData', {});
            },
        }, { root: true });
    },
    setItemsCount({ commit, state }, count) {
        if (count || count === 0) {
            commit(types.SET_ITEMS_COUNT, count);
        } else {
            const itemsCount = state.items.length
                ? state.items.map(item => item.qty).reduce((summary, qty) => summary + qty)
                : 0;
            commit(types.SET_ITEMS_COUNT, Math.ceil(itemsCount));
        }
    },
    closeMonit({ commit }) {
        setCookieMonitWishlistClosed();
        commit(types.SET_MONIT_CLOSED);
    },
    checkStateCookieMonitWishlist({ commit }) {
        if (isCookieMonitWishlistClosed()) {
            commit(types.SET_MONIT_CLOSED);
        }
    },
    flushWishlist({ commit }) {
        commit(types.FLUSH_WISHLIST);
    },
    setNeedFetch({ commit }, needFetch) {
        commit(types.SET_NEED_FETCH, needFetch);
    },
    clearLastAdded({ commit }) {
        commit(types.UNSET_ADDED_ITEM);
    },
};
