import { imageAvailabilityChecking } from '~/assets/services/helpers';

const attributesNames = {
    image: 'data-src',
};

const errorImageSource = require('~/static/images/placeholder-error.png');

function getImageData(element, imageData) {
    const events = {
        before: new CustomEvent('ImageLazyLoadBefore'),
        success: new CustomEvent('ImageLazyLoadSuccess'),
        change: new CustomEvent('ImageLazyLoadChange'),
        error: new CustomEvent('ImageLazyLoadError'),
    };
    const data = {
        src: element.getAttribute(attributesNames.image),
        errorImage: errorImageSource,
        ...imageData,
    };

    return {
        ...data,
        onBefore() {
            if (data.onBefore && typeof data.onBefore === 'function') {
                data.onBefore();
            }
            element.dispatchEvent(events.before);
        },
        onSuccess() {
            if (data.onSuccess && typeof data.onSuccess === 'function') {
                data.onSuccess();
            }
            element.dispatchEvent(events.success);
        },
        onChange() {
            if (data.onSuccess && typeof data.onSuccess === 'function') {
                data.onChange();
            }
            element.dispatchEvent(events.change);
        },
        onError() {
            if (data.onError && typeof data.onError === 'function') {
                data.onError();
            }
            element.dispatchEvent(events.error);
        },
    };
}

export default async function (target, props = {}) {
    const data = getImageData(target, props);
    const { onBefore, onSuccess, onChange, onError, errorImage, src } = data;

    onBefore();
    return imageAvailabilityChecking(src, target, true).then((srcChanged) => {
        if (srcChanged) {
            target.removeAttribute(attributesNames.image);
            onSuccess();
        } else {
            target.src = errorImage;
            onError();
        }
        onChange();
    });
}
