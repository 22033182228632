function filtersInterface({ filterFieldName, filterFieldSymbol, filterType, filteredItem }) {
    const filtersInterfaces = {
        price: () => ({
            filterFieldName,
            filterFieldSymbol,
            filterType,
            filterItemName: `${filteredItem.value[0]} zł - ${filteredItem.value[1]} zł`,
            filterItemSymbol: `${filteredItem.value[0]}-${filteredItem.value[1]}`,
        }),
        default: () => ({
            filterFieldName,
            filterFieldSymbol,
            filterType,
            filterItemName: filteredItem.name.toString(),
            filterItemSymbol: filteredItem.symbol.toString(),
        }),
    };
    return filtersInterfaces[filterFieldSymbol] ? filtersInterfaces[filterFieldSymbol]() : filtersInterfaces.default();
}

function conversionToActiveFilters(filterFieldName, filterFieldSymbol, filterType, filteredItems) {
    const activeFiltersByFieldName = [];
    filteredItems.forEach((filteredItem) => {
        activeFiltersByFieldName.push(filtersInterface({
            filterFieldName,
            filterFieldSymbol,
            filterType,
            filteredItem,
        }));
    });
    return activeFiltersByFieldName;
}

export default function (filtersFromRequest) {
    let filtersToBeApplied = {};
    filtersFromRequest.forEach((filterFromRequest) => {
        const { name, symbol, type, items } = filterFromRequest;
        filtersToBeApplied = { ...filtersToBeApplied, [symbol]: conversionToActiveFilters(name, symbol, type, items) };
    });
    return filtersToBeApplied;
}
