import debounce from './debounce';

function directive(el, binding) {
    if (binding.value !== binding.oldValue) {
        // change debounce only if interval has changed
        el.oninput = directive.debounce(() => {
            el.dispatchEvent(new Event('change'));
        }, parseInt(binding.value, 10) || 500);
    }
}

directive.debounce = debounce;

export default directive;
