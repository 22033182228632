import axios from 'axios';

import { GET_MAGENTO_MESSAGE } from './api.config';

export default function () {
    return axios({
        method: 'get',
        url: GET_MAGENTO_MESSAGE,
    });
}
