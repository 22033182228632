<template>
    <label
        v-show="false"
    >
        Wyszukiwane hasło
    </label>
</template>

<script>
export default {
    name: 'SearchBarLabel',
};
</script>
