const all = {
    BL: ['blister', 'blistery', 'blisterów'],
    JED: ['jednostka', 'jednostki', 'jednostek', 'jednostkę'],
    KG: ['kilogram', 'kilogramy', 'kilogramów'],
    KOM: ['kompozycja', 'kompozycje', 'kompozycji', 'kompozycję'],
    KPL: ['komplet', 'komplety', 'kompletów'],
    KT: ['paczka', 'paczki', 'paczek', 'paczkę'],
    L: ['litr', 'litry', 'litrów'],
    M2: ['m2'],
    M3: ['m3'],
    MB: ['metr bieżący'],
    PAL: ['paleta', 'palety', 'palet', 'paletę'],
    PACZKA: ['paczka', 'paczki', 'paczek', 'paczkę'],
    PAR: ['para', 'pary', 'par', 'parę'],
    ROL: ['rolka', 'rolki', 'rolek', 'rolkę'],
    SZT: ['sztuka', 'sztuki', 'sztuk', 'sztukę'],
    TRB: ['torebka', 'torebki', 'torebek', 'torebkę'],
    ZW: ['zwój', 'zwoje', 'zwoji'],
    ZESTAW: ['zestaw', 'zestawy', 'zestawów'],
};

const withShortcut = [
    {
        base: all.KG[0],
        short: 'kg',
    },
    {
        base: all.MB[0],
        short: 'mb',
    },
];

const reverseWithShortcut = [
    {
        base: 'kt',
        short: all.KT[0],
    },
];

const withSuperscriptNumber = [
    all.M2[0],
    all.M3[0],
];

export default {
    default: all.SZT,
    groupedDefaultUnit: all.ZESTAW,
    withShortcut,
    reverseWithShortcut,
    withSuperscriptNumber,
    all,
};
