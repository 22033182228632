import { onError } from 'apollo-link-error';

/**
 * This function create apollo-link-error (onError) instance.
 *
 * @param {String} type - name of error handler
 * @param {Function} logger - handler for logging errors
 *
 * @return {Object} (ApolloLink)
 */
export default function ({ type, logger }) {
    return onError(({ graphQLErrors, networkError }) => {
        if (logger) {
            if (graphQLErrors) {
                graphQLErrors.forEach(({ message, locations: source, path }) => logger({
                    path,
                    source,
                    message: `[${type} error]: ${message}`,
                }));
            }
            if (networkError) {
                logger({
                    message: networkError,
                    source: `Apollo ${type} client network error`,
                });
            }
        }
    });
}
