import axios from 'axios';
import { getGroupedParamString } from '~/assets/utils/product';
import 'url-search-params-polyfill';

import { ADD_TO_WISHLIST_URL } from './api.config';

const extraParamsInterface = ({ productId, qty, groupedData }) => {
    if (groupedData && groupedData.length) {
        return {
            groupedData: getGroupedParamString({ productId, groupedData }),
            product: productId,
        };
    }

    return {
        qty,
        product: productId,
    };
};

export default function ({ groupedData, productId, source, qty = 1 }) {
    const search = new URLSearchParams({
        isAjax: true,
        source,
        ...extraParamsInterface({ groupedData, productId, qty }),
    });

    return axios({
        method: 'post',
        url: ADD_TO_WISHLIST_URL,
        data: search.toString(),
        config: {
            headers: {
                'Content-Type':
                    'application/x-www-form-urlencoded; charset=UTF-8',
            },
        },
    });
}
