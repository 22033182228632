<template lang="html">
    <CastoramaDefaultPreloader
        v-show="loading"
        class="application-loader"
    />
</template>

<script>
import CastoramaDefaultPreloader from '~/components/common/Loader/components/CastoramaDefaultPreloader';

export default {
    name: 'NuxtLoader',
    components: { CastoramaDefaultPreloader },
    data: () => ({
        loading: false,
    }),
    methods: {
        start() {
            this.loading = true;
        },
        finish() {
            this.loading = false;
        },
    },
};
</script>

<style>
    .application-loader {
        position: fixed;
    }
</style>
