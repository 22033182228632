<template>
    <li
        class="newspaper"
        :style="backgroundStyle"
        v-on="listeners"
    >
        <BaseLink
            :href="data.url"
            :title="data.title"
            class="link-wrapper"
        >
            <span class="title">
                {{ data.title }}
            </span>
            <span class="button-text">
                {{ linkLabel }} >
            </span>
        </BaseLink>
    </li>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';
import BaseLink from '~/components/common/Link/BaseLink';

const { mapState: mapStateMenu } = createNamespacedHelpers('menu');

export default {
    name: 'CatalogMenuNewspaper',
    components: { BaseLink },
    computed: {
        ...mapStateMenu({
            data: state => state.newspaperData,
        }),
        backgroundStyle() {
            return {
                'background-image': `url(${this.data.mainImage}`,
            };
        },
        linkLabel() {
            return this.data.isCatalog ? 'Zobacz ofertę' : 'Zobacz gazetkę';
        },
        listeners() {
            const { mouseout, mouseover } = this.$listeners;
            return {
                mouseout,
                mouseover,
            };
        },
    },
};
</script>

<style lang="scss">
.newspaper {
    .link-wrapper {
        float: right;
        width: 100%;
        position: relative;
        padding-top: 10px;
        cursor: pointer;
        padding-right: 10px;
        color: $color-castoWhite;
        text-decoration: none;

        &:hover, &:active {
            text-decoration: none;

            .button-text {
                color: $color-castoYellow;
            }
        }
    }

    .title, .button-text {
        position: relative;
        padding: 9px 0;
        text-align: left;
    }
    .title {
        width: 115px;
        display: block;
        font: $font-weight-regular 16px/22px $goodHomeFontFamily;
        color: $color-castoWhite;
        margin-bottom: 15px;
    }
    .button-text {
        font: $font-weight-regular 14px/22px $goodHomeFontFamily;
        cursor: pointer;
        display: block;
        color: $color-castoWhite;
    }
}
</style>
