<template>
    <h1 v-if="isHomePage">
        <BaseImage
            :data="{ src: logoImagePath }"
            class="logo-image"
            :bem-modifiers="bemModifiers"
            alt="Sklep budowlany Castorama"
        />
    </h1>
    <NuxtLink
        v-else
        to="/"
        title="Sklep budowlany Castorama"
    >
        <BaseImage
            :data="{ src: logoImagePath }"
            class="logo-image"
            :bem-modifiers="bemModifiers"
            alt="Sklep budowlany Castorama"
        />
    </NuxtLink>
</template>

<script>
import { GLOBAL_VARS } from '~/config';
import BaseImage from '~/components/common/Image/BaseImage';

const logoImagePath = require('~/static/images/logo.svg');

const { MODE_HOMEPAGE_VALUE } = GLOBAL_VARS;

export default {
    name: 'Logo',
    components: {
        BaseImage,
    },
    data() {
        return {
            logoImagePath,
            baseUrl: process.env.baseUrl,
            homePageModeName: MODE_HOMEPAGE_VALUE,
        };
    },
    computed: {
        isHomePage() {
            return this.$route.path === '/';
        },
    },
};
</script>

<style lang="scss">
.logo-image {
    width: 100%;

    .is-fixed & {
        top: 9px;
    }
}

@media (min-width: $mf-breakpoint-tablet) {
    .logo-image {
        left: 0;
        width: 245px;
        position: absolute;
        height: 29px;
        top: -2px;
    }
}

@media (min-width: $mf-breakpoint-small-desktop) {
    .logo-image {
        width: 196px;

        .is-fixed & {
            top: -4px;
            left: 7px;
        }
    }
}

@media (min-width: $mf-breakpoint-big-desktop) {
    .logo-image {
        width: 245px;
        height: 29px;
        top: -1px;
        left: -9px;

        .is-fixed & {
            left: -7px;
        }
    }
}
</style>
