import { GLOBAL_VARS } from '~/config';
import loadScript from '~/assets/services/loadScript';
import { googlePublisherTag as googlePublisherTagLibUrl } from '~/src/config/externalLibsUrl';
import { loaderWrapperForExternals } from '~/src/utils/helpers';

export default () => {
    // eslint-disable-next-line
    loaderWrapperForExternals( (app) => {
        const MAIN_PLUGIN_KEY = 'gpt';
        const url = googlePublisherTagLibUrl;

        const runExternalCallback = (state) => {
            const externalCallbackWrapper = window[
                GLOBAL_VARS.EXTERNAL_CALLBACKS_WRAPPER_NAME
            ].getBySource(MAIN_PLUGIN_KEY);

            if (externalCallbackWrapper) {
                externalCallbackWrapper.callback(state);
            }
        };

        loadScript({
            url,
            callback() {
                const googletag = app.googletag || {};
                googletag.cmd = googletag.cmd || [];

                googletag.cmd.push(() => {
                    if (window.googletag && googletag.apiReady) {
                        runExternalCallback(true);
                        app[GLOBAL_VARS.BLOCKS_LOADED_STATE].add({
                            blockName: MAIN_PLUGIN_KEY,
                        });
                    }
                });
            },
            fail() {
                runExternalCallback(false);
                app[GLOBAL_VARS.BLOCKS_LOADED_STATE].fail({ blockName: MAIN_PLUGIN_KEY });
            },
        });
    });
};
