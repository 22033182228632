import Vue from 'vue';
import { progressiveImageLazyLoad } from '~/assets/services/lazyLoadLauncher';

const emit = (vnode, name, data) => {
    const handlers = (vnode.data && vnode.data.on) ||
        (vnode.componentOptions && vnode.componentOptions.listeners);

    if (handlers && handlers[name]) {
        handlers[name].fns(data);
    }
};
const errorImageSource = require('~/static/images/placeholder-error.png');

function cacheObserver(element, observer) {
    element.progressiveSrcObserver = observer;
}

function removeCacheObserver(element) {
    if (element.progressiveSrcObserver) {
        element.progressiveSrcObserver.disconnect();
        element.progressiveSrcObserver = null;
    }
}

Vue.directive('progressiveSrc', {
    intersectionObserver: null,
    async bind(element, binding, vnode) {
        const { value } = binding;
        const { src, loading, error: errorImage = errorImageSource, webp: supportWebp } = value;
        const { intersectionObserver } = progressiveImageLazyLoad({
            element,
            supportWebp,
            src,
            loading,
            errorImage,
            onBefore() {
                emit(vnode, 'progressiveSrcBeforeChange');
            },
            onSuccess() {
                emit(vnode, 'progressiveSrcSuccess');
            },
            onChange() {
                emit(vnode, 'progressiveSrcChanged');
            },
            onError() {
                emit(vnode, 'progressiveSrcChanged');
                emit(vnode, 'progressiveSrcError');
            },
        });
        cacheObserver(element, intersectionObserver);
    },
    unbind(element) {
        removeCacheObserver(element);
    },
});
