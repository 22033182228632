<template>
    <section
        :class="['col-xs-6 col-sm-12 mobile-app-links download-app footer-bottom__section desktop-exclude', {'expanded': isExpand}]"
    >
        <BaseButton
            class="block-single__title mobile-app-links__title"
            name="Pobierz aplikację"
            @click.native="showDownloadAppBlock"
        >
            Pobierz aplikację
        </BaseButton>
        <BaseLink
            href="https://click.google-analytics.com/redirect?tid=UA-77897594-2&url=https%3A%2F%2Fitunes.apple.com%2Fapp%2Fid1090445849&aid=pl.castorama.app&idfa=%{idfa}&cs=web-desktop&cm=footer-link"
            target="_blank"
            title="AppStore"
            class="mobile-app-links__link mobile-app-links__link--appstore"
        >
            AppStore
        </BaseLink>
        <BaseLink
            href="https://play.google.com/store/apps/details?id=pl.looksoft.castorama&referrer=utm_source%3Dweb-desktop%26utm_medium%3Dfooter-link"
            title="Google Play"
            target="_blank"
            class="mobile-app-links__link mobile-app-links__link--googleplay"
        >
            Google Play
        </BaseLink>
    </section>
</template>

<script>
import { mapState } from 'vuex';
import BaseLink from '~/components/common/Link/BaseLink';
import BaseButton from '~/components/common/Button/BaseButton';

export default {
    name: 'DownloadApp',
    components: { BaseButton, BaseLink },
    data() {
        return {
            isExpand: false,
        };
    },
    computed: {
        ...mapState({
            isMobile: state => state.windowOptions.isMobile,
        }),
    },
    methods: {
        showDownloadAppBlock() {
            if (this.isMobile) {
                this.isExpand = !this.isExpand;
            }
        },
    },
};
</script>

<style lang="scss">
.mobile-app-links {
    margin-top: 0;
    width: 100%;
    text-align: left;

    &__title {
        display: block;
        text-align: left;
    }

    &__link {
        text-align: left;
        padding-left: 0;
        display: inline-block;
        width: 110px;
        height: 33px;
        background-repeat: no-repeat;
        background-position: -165px top;
        background-size: auto;
        line-height: 400px;
        overflow: hidden;
        font-size: 0;
        margin-bottom: 15px;

        &:hover {
            background-position: -165px -33px;
        }

        &--googleplay {
            background-image: url('~static/images/sprites/googleApp.png');
        }

        &--appstore {
            background-image: url('~static/images/sprites/appleApp.png');
        }
    }
}

@media (min-width: $breakpoint-tablet) and (max-width: $breakpoint-small-desktop) {
    .mobile-app-links {
        padding-left: 15px;
        margin-top: 22px;

        &__link {
            float: left;
            margin-right: 8px;

            &:last-child {
                margin-right: 0;
            }
        }
    }
}
@media screen and (max-width: $breakpoint-tablet) {

    .mobile-app-links {
        max-height: 39px;
        transition-property: max-height;
        transition-duration: 1s;
        transition-timing-function: ease-out;

        &.expaned {
            max-height: 600px;
        }

        p {
            margin-left: 0;
        }

        &__title {
            width: 100vw;
            display: table-cell;
        }

        &__link {
            display: block;
            overflow: hidden;
            margin: 0 0 15px 30px;

            &::after {
                content: none;
            }
        }
    }
}
</style>
