const dnsList = [
    'ajax.googleapis.com',
    'www.googletagmanager.com',
    'www.googletagservices.com',
    'www.googleadservices.com',
    'tpc.googlesyndication.com',
    'pagead2.googlesyndication.com',
    'www.google-analytics.com',
    'fonts.googleapis.com',
    'connect.facebook.net',
    'apis.google.com',
    'bam.nr-data.net',
    'm.exactag.com',
    'secure.adnxs.com',
    'cdn.exactag.com',
    'securepubads.g.doubleclick.net',
    'api.survicate.com',
    'www.fullstory.com',
    't.goadservices.com',
    'www.dwin1.com',
    'cdn.trackjs.com',
];

export default dnsList;
