<template>
    <span class="diagonally-crossed-out-label">
        <!-- @slot Use this slot for crossed out content -->
        <slot />
    </span>
</template>

<script>
export default {
    name: 'LabelDiagonallyCrossedOut',
};
</script>

<style lang="scss">
.diagonally-crossed-out-label {
    position: relative;
    display: inline-block;

    &::after {
        position: absolute;
        left: 5%;
        bottom: 45%;
        content: '';
        width: 100%;
        height: 2px;
        background: $color-slate100-dark;
        transform: rotate(-32deg);
        display: block;
    }
}
</style>
