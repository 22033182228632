<template>
    <img
        :key="imgData.src"
        v-progressive-src="imgData"
        :class="{'visible': visible, 'error': error }"
        :src="imgData.loading"
        :alt="data.alt || 'image'"
        class="progressive-image"
        @progressiveSrcSuccess="handleSuccess"
        @progressiveSrcError="handleError"
    >
</template>

<script>
const placeholderImagePath = require('~/static/images/placeholder.png');

export default {
    name: 'ProgressiveImage',
    props: {
        data: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            visible: false,
            error: false,
        };
    },
    computed: {
        imgData() {
            const { src, webp = true } = this.data;
            return {
                src,
                webp,
                loading: placeholderImagePath,
            };
        },
    },
    methods: {
        handleError() {
            this.progressiveSrcError = true;
            this.visible = true;
            this.$emit('error');
        },
        handleSuccess() {
            this.visible = true;
            this.$emit('success');
        },
    },
};
</script>
<style lang="scss">
    .progressive-image {
        display: block;
        opacity: 0;
        transition: opacity 0.3s;

        &:focus {
            outline: none;
            border: none;
        }

        &.visible {
            opacity: 1;
        }
    }
</style>
