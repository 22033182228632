<template>
    <BaseButton
        type="button"
        :name="text"
        class="check-in-other-market-button"
        :class="getBemModifiers()"
        @click.native="changeShop"
    >
        {{ text }}
    </BaseButton>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';
import BaseButton from '~/components/common/Button/BaseButton';

const { mapActions } = createNamespacedHelpers('popup');
export default {
    name: 'CheckInOtherMarketButton',
    components: { BaseButton },
    props: {
        type: {
            type: String,
            default: 'button',
        },
        text: {
            type: String,
            default: 'Sprawdź w innych sklepach',
        },
    },
    methods: {
        ...mapActions(['showPopup']),
        async changeShop() {
            const { default: instance } = await import('~/components/common/Popup/ChangeShopPopup' /* webpackChunkName: "popup/change-shop" */);
            this.showPopup({ instance });
        },
    },
};
</script>

<style lang="scss">
    .check-in-other-market-button {
        display: block;
        text-align: center;
        text-decoration: none;
        background: $color-slate10;
        color: $color-slate70-dark;
        min-width: 132px;
        min-height: 42px;
        line-height: 32px;
        margin: 0 auto;
        padding: 5px;
        font: $font-weight-bold 11px/21px $goodHomeFontFamily;

        &:hover {
            background: $color-slate50;
        }

        &--page-product-price-box {
            color: $color-castoBlue;
            background-color: $color-castoYellow;
            font-size: 14px;
            width: 100%;
            height: 100%;

            &:hover {
                background-color: $color-castoYellow-dark;
            }
        }
    }

    @media screen and (min-width: $mf-breakpoint-tablet) {
        .check-in-other-market-button--page-product-price-box {
            width: calc(100% + 24px);
            height: calc(100% + 20px);
            margin: -10px -12px;
        }
    }
</style>
