<template>
    <img
        ref="baseImageLazyLoad"
        :key="data.src"
        v-lazy-load-src="data"
        :src="placeholderImagePath"
        :alt="data.alt || 'Base lazy load image'"
        class="base-image-lazy-load"
        :class="[{'visible': visible, 'error': error }, ...getBemModifiers()]"
        @lazyLoadSrcChanged="visible = true"
        @lazyLoadSrcError="error = true"
    >
</template>

<script>
const placeholderImagePath = require('~/static/images/placeholder.png');

export default {
    name: 'BaseImageLazyLoad',
    props: {
        data: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            visible: false,
            error: false,
            placeholderImagePath,
        };
    },
};
</script>
<style lang="scss">
    .base-image-lazy-load {
        $self: &;

        display: block;
        opacity: 0;
        transition: opacity 0.3s;

        &--inline {
            display: inline;
        }

        &:focus {
            outline: none;
            border: none;
        }

        &.visible {
            opacity: 1;

            &#{$self}--border-on-visible {
                border: thin solid $color-slate20;
            }
        }
    }
</style>
