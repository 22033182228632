<template>
    <Transition
        name="expand"
        class="print-hide"
        @after-enter="setHeightByRef"
    >
        <div
            v-show="showCookieBar"
            ref="cookieBar"
            class="cookie-bar print-hide"
        >
            <div class="container">
                <div class="cookie-bar-box">
                    <CookieBarText />
                    <div class="cookie-bar-button-wrapper">
                        <CookieBarSettingsLink />
                        <CookieBarCloseButton />
                    </div>
                </div>
            </div>
        </div>
    </Transition>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';
import CookieBarText from './components/CookieBarText';
import CookieBarSettingsLink from './components/CookieBarSettingsLink';
import CookieBarCloseButton from './components/CookieBarCloseButton';

const { mapState, mapActions } = createNamespacedHelpers('cookieBar');

export default {
    name: 'CookieBar',
    components: {
        CookieBarText,
        CookieBarSettingsLink,
        CookieBarCloseButton,
    },
    data() {
        return {
            showCookieBar: false,
        };
    },
    computed: {
        ...mapState({ closed: state => state.closed }),
    },
    watch: {
        closed(currentValue) {
            this.showCookieBar = !currentValue;
        },
    },
    mounted() {
        this.showCookieBar = !this.closed;
    },
    updated() {
        if (this.$refs.cookieBar) {
            this.setHeightByRef();
        }
    },
    methods: {
        ...mapActions(['setHeight']),
        setHeightByRef() {
            this.setHeight(this.$refs.cookieBar.clientHeight);
        },
    },
};
</script>

<style lang="scss">
    .cookie-bar {
        background-color: $color-slate5-dark;
        top: 0;
        padding: 32px 0;
        max-height: 400px;
        text-align: left;
        width: 100%;
        z-index: 99;
        position: relative;

        .container {
            padding-left: 12px;
            padding-right: 12px;
        }
    }

    .cookie-bar-box {
        display: table;

        &::before {
            content: '';
            display: table-cell;
            vertical-align: middle;
            width: 40px;
            height: 40px;
            background: url('~static/images/icons/info-grey.svg') no-repeat 0 50%;
        }
    }

    .cookie-bar-button-wrapper {
        display: table-cell;
        vertical-align: middle;
        width: 223px;
    }

    @media screen and (min-width: $breakpoint-big-desktop) {
        .cookie-bar .container {
            width: 1176px;
        }
    }

    @media screen and (max-width: $breakpoint-smart-phones) {
        .cookie-bar {
            padding: 22px 0 15px 0;
        }
        .cookie-bar-box {
            display: block;
            width: 100%;

            &::before {
                display: none;
            }

            .cookie-bar-text {
                display: block;
                width: 100%;
                padding: 0 24px 15px 24px;
                margin: 0;
                color: $color-slate;
                font-size: 11px;
                line-height: 15px;
                text-align: justify;

                a {
                    font-weight: $font-weight-bold;
                    color: $color-castoBlue;
                }
            }
        }
        .cookie-bar-button-wrapper {
            text-align: center;
            display: block;
            width: 100%;

            a,
            button {
                display: inline-block;
                float: none;
            }

            a {
                padding: 2px 0;
                line-height: 16px;
            }
            button {
                top: -8px;
                position: relative;
            }
        }
    }
    @media screen and (max-width: $breakpoint-small-desktop) {
        .cookie-bar .container {
            padding-right: 8px;
        }
    }
    @import '~assets/css/transitions/expand.scss';
</style>
