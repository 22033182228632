export default function (value, callback) {
    this.value = value;

    return {
        setDistinct: (newValue) => {
            if (this.value !== newValue) {
                this.value = newValue;
                callback(this.value);
            }
        },
    };
}
