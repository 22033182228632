export const types = {
    ADD_MESSAGE: 'ADD_MESSAGE',
    ADD_MESSAGES: 'ADD_MESSAGES',
    REMOVE_MESSAGE: 'REMOVE_MESSAGE',
    SET_MESSAGES: 'SET_MESSAGES',
    FLUSH_MESSAGES: 'FLUSH_MESSAGES',
};

const mutations = {
    [types.FLUSH_MESSAGES](state) {
        state.messages = [];
    },
    [types.SET_MESSAGES](state, messages) {
        state.messages = messages;
    },
    [types.ADD_MESSAGE](state, message) {
        state.messages = [
            ...state.messages,
            message,
        ];
    },
    [types.REMOVE_MESSAGE](state, id) {
        state.messages.splice(id, 1);
    },
    [types.ADD_MESSAGES](state, messages) {
        state.messages = [
            ...state.messages,
            ...messages,
        ];
    },
};

export default mutations;
