<template>
    <div
        v-if="isLoading"
        class="loader__wrapper"
        :class="{globalLoader}"
    >
        <Component
            :is="preloaderType"
            v-bind="optionalProps"
        />
    </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';
import BlueDotsPreloader from './components/BlueDotsPreloader';
import CastoramaDefaultPreloader from './components/CastoramaDefaultPreloader';
import { appendToComponent, removeFromComponent } from '~/assets/services/DOMManipulate';

const { mapState: mapStateLoader } = createNamespacedHelpers('loader');

export default {
    name: 'Loader',
    components: {
        BlueDotsPreloader,
        CastoramaDefaultPreloader,
    },
    props: {
        globalLoader: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            loaderInstance: null,
            componentPreloaded: null,
        };
    },
    computed: {
        preloaderType() {
            if (!this.optionalProps) {
                return 'BlueDotsPreloader';
            }
            if (this.optionalProps && this.optionalProps.instance) {
                return '';
            }
            return this.optionalProps.type || 'BlueDotsPreloader';
        },
        preloaderInstance() {
            if (this.optionalProps && this.optionalProps.type) {
                const preloaderInstance = this.$options.components[this.optionalProps.type];
                if (preloaderInstance) {
                    return preloaderInstance;
                }
            }
            return BlueDotsPreloader;
        },
        ...mapStateLoader({
            isLoading: state => state.isLoading,
            optionalProps: state => state.optionalProps,
        }),
    },
    watch: {
        isLoading() {
            if (this.isLoading && this.optionalProps) {
                this.componentPreloaded = this.optionalProps.instance;
                this.loaderInstance = appendToComponent(this.componentPreloaded, this.preloaderInstance);
            } else if (this.componentPreloaded) {
                removeFromComponent(this.componentPreloaded, this.loaderInstance);
                this.componentPreloaded = null;
            }
        },
    },
    beforeDestroy() {
        delete this.loaderInstance;
    },
};
</script>

<style lang="scss">
    .loader__wrapper {
        &.global-loader {
            position: fixed;
        }
        .bold-preloader_blue-dots {
            position: fixed;
        }
    }
</style>
