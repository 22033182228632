import { types } from './mutations';
import fetchArticleGrid from '~/assets/services/articleGrid';

export default {
    async fetchData({ commit }, { categoryId, limit = 7 }) {
        const data = await fetchArticleGrid({ categoryId, limit });
        commit(types.SET_ARTICLE_GRID, data);
    },

    setArticleGrid({ commit }, data) {
        commit(types.SET_ARTICLE_GRID, data);
    },
};
