import fetch from 'isomorphic-fetch';
import { RestLink } from 'apollo-link-rest';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { ApolloLink } from 'apollo-link';
import { CLIENT_DEFAULT_CONFIG as defaultOptions } from '~/config/apollo';
import getErrorHandler from '~/src/apollo/getErrorHandler';

/**
 * This function create apollo rest client config.
 *
 * @param {Function} logger - handler for logging errors
 *
 * @return {Object}
 */
export default function ({ logger }) {
    const uri = process.env.API_URL;

    const restLink = new RestLink({
        uri,
        credentials: 'include',
        headers: {
            'Content-Type': 'application/json',
        },
        customFetch: fetch,
    });

    const cache = new InMemoryCache();

    const errorLink = getErrorHandler({ type: 'REST', logger });

    const link = ApolloLink.from([errorLink, restLink]);

    return {
        defaultOptions,
        link,
        cache,
        ssrMode: process.server,
        defaultHttpLink: false,
    };
}
