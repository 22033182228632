<template>
    <BaseButton
        :name="name || text"
        :bem-modifiers="bemModifiers"
        @click.native="click"
    >
        <BaseImage
            v-if="icon"
            :data="{ src: icon, alt: text || 'ikona' }"
            class="image-base"
        />
        <span
            v-if="text"
            class="image-base__text"
        >
            {{ text }}
        </span>
    </BaseButton>
</template>

<script>
import BaseImage from '~/components/common/Image/BaseImage';
import BaseButton from '~/components/common/Button/BaseButton';

export default {
    name: 'IconTextButton',
    components: {
        BaseImage,
        BaseButton,
    },
    props: {
        icon: {
            type: String,
            default: '',
        },
        text: {
            type: String,
            default: '',
        },
        name: {
            type: [String, Number],
            required: true,
        },
    },
    methods: {
        click() {
            this.$emit('click');
        },
    },
};
</script>

<style lang="scss">
.image-base {
    display: inline;
    outline: none;

    &__text {
        vertical-align: middle;
    }
}
</style>
