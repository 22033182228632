<template>
    <BaseLink
        class="forgot-password-link"
        v-bind="link"
    />
</template>

<script>
import { customerUrl } from '~/src/config/pagesUrl';
import BaseLink from '~/components/common/Link/BaseLink';

const link = {
    href: customerUrl.customerForgotPassword,
    text: 'Nie pamiętam hasła',
};
export default {
    name: 'ForgotPasswordLink',
    components: { BaseLink },
    data() {
        return { link };
    },
};
</script>

<style lang="scss">
    .forgot-password-link {
        display: inline-block;
        margin-top: 12px;
        padding: 0;
        color: $color-castoBlue;
        font: $font-weight-regular 12px/24px $baseFontFamily;
        text-decoration: none;

        &:hover {
             cursor: pointer;
             text-decoration: underline;
        }
    }
</style>
