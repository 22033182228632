import { types } from './mutations';
import {
    isMobileApplicationBarClosed,
    setMobileApplicationBarClosed,
} from '~/assets/services/storages/cookies/cookieMobileApplicationBar';

export default {
    closeMonit({ commit }) {
        setMobileApplicationBarClosed();
        commit(types.SET_MONIT_VISIBLE, false);
    },
    checkStateMobileApplicationBar({ commit }) {
        if (!isMobileApplicationBarClosed()) {
            commit(types.SET_MONIT_VISIBLE, true);
        }
    },
    setMonitHeight({ commit }, height) {
        commit(types.SET_MONIT_HEIGHT, height);
    },
};
