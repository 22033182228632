<template>
    <BasePopup
        class="product-to-compare"
        :show-default-close-button="false"
    >
        <h4
            v-if="maxElementReached"
            class="product-to-compare__warning"
        >
            Osiągnąłeś limit produktów do porównania. Usuń jeden i spróbuj ponownie.
        </h4>
        <h4 class="product-to-compare__header">
            Produkty do porównania:
            <span class="product-to-compare__header--qty">
                {{ globalItems.length }}
            </span>
            <BaseButton
                class="product-to-compare__header--close"
                name="Zamknij"
                @click.native="hidePopup"
            >
                x
            </BaseButton>
        </h4>
        <div class="product-to-compare__content">
            <div
                v-if="globalItems.length"
                class="container"
            >
                <div class="row">
                    <div
                        v-for="(item, index) in globalItems"
                        :key="index"
                        class="col-12 col-md-3 product-to-compare__content--item"
                    >
                        <ProductImageNameBlockNoForward
                            :img="item.image"
                            :title="item.name"
                        />
                        <span
                            v-if="item.lastAdded"
                            class="already-added-block"
                        >
                            Dodano
                        </span>
                        <BaseButton
                            class="delete-item"
                            name="Usuń"
                            @click.native="removeAndUpdate(item)"
                        >
                            Usuń
                        </BaseButton>
                    </div>
                    <BaseButton
                        v-if="!maxElementReached"
                        class="col-12 col-md-3 product-to-compare__content--next-item"
                        name="Dodaj kolejny produkt"
                        :bem-modifiers="['horizontally-centered']"
                        @click.native="hidePopup"
                    >
                        Dodaj kolejny produkt
                    </BaseButton>
                </div>
            </div>
            <p
                v-else
                class="product-to-compare__content--empty"
            >
                Dodaj produkty do porównania
            </p>
            <NuxtLink
                v-if="!isCompareListEmpty"
                class="button btn-blue product-to-compare__content--go-to-compare"
                title="Przejdź do porównania"
                :to="compareUrl"
                @click.native="hidePopup"
            >
                Przejdź do porównania
            </NuxtLink>
        </div>
    </BasePopup>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';
import ProductImageNameBlockNoForward from '~/components/common/Product/ProductImageNameBlockNoForward';
import BaseButton from '~/components/common/Button/BaseButton';
import BasePopup from '~/components/common/Popup/BasePopup';
import comparePageConfig from '~/assets/config/pages/compare';

const {
    mapState: mapStateCompare,
    mapActions: mapActionsCompare,
} = createNamespacedHelpers('compare');
const {
    mapActions: mapActionsPopup,
} = createNamespacedHelpers('popup');

export default {
    name: 'ProductToCompare',
    components: {
        BaseButton,
        BasePopup,
        ProductImageNameBlockNoForward,
    },
    computed: {
        ...mapStateCompare({
            globalItems: state => state.globalItems,
            compareUrl: state => state.compareUrl,
        }),
        maxElementReached() {
            return this.globalItems.length >= comparePageConfig.bundleConfig.numberOfElementsToDisplay;
        },
        isCompareListEmpty() {
            return this.globalItems.length === 0;
        },
    },
    methods: {
        ...mapActionsCompare(['generateCompareUrl', 'removeItem', 'fetchCompareProducts']),
        ...mapActionsPopup(['hidePopup']),
        removeAndUpdate(item) {
            this.removeItem({ ...item, isUrlModified: false });
            this.fetchCompareProducts();
        },
    },
};
</script>

<style lang="scss">
.product-to-compare {
    font: $font-weight-bold 14px/18px $goodHomeFontFamily;

    .modal__container {
        width: 700px;
        padding: 0 24px;
    }
    &__warning {
        color: $color-deepCanynon-dark;
        padding: 24px 0;
        border-bottom: 1px solid $color-slate10-dark;
    }
    &__header {
        color: $color-slate100;
        padding: 24px 0;
        border-bottom: 1px solid $color-slate10-dark;

        &--qty {
            color: $color-castoBlue;
        }
        &--close {
            font: $font-weight-bold 14px/1 $baseFontFamily;
            color: $color-slate;
            text-shadow: 0 1px 0 $color-castoWhite;
            opacity: 0.2;
            float: right;
        }
    }
    &__content {
        padding: 20px 0;
        text-align: center;
        &--item {
            display: inline-block;
            padding-bottom: 24px;
            position: relative;
            .hoverable-product-block {
                &__name {
                    font: $font-weight-bold 13px/16px $goodHomeFontFamily;
                    display: inline-block;
                    height: 64px;
                    width: 100%;
                    padding: 0;
                }
                &__image {
                    height: initial;
                }
            }
            .delete-item {
                color: $color-deepCanynon-dark;
                font: $font-weight-regular 15px/1 $goodHomeFontFamily;
                display: block;
                margin: 0 auto;
                text-transform: capitalize;
                padding-left: 14px;
                background: url("~static/images/icons/ajc-remove.png") 0 55% no-repeat;
            }
            .already-added-block {
                font: $font-weight-regular 13px/29px $goodHomeFontFamily;
                padding: 0 11px 0 37px;
                border: 1px solid $color-slate10-dark;
                background: $color-castoWhite url('~static/images/check_add_to_cart.svg') 11px 50% no-repeat;
                background-size: 22%;
                color: $color-accent2;
                position: absolute;
                top: 40%;
                width: fit-content;
                left: 0;
                right: 0;
                margin: 0 auto;
            }
        }
        &--empty {
            padding: 24px 0 4px;
            width: 100%;
            text-align: center;
            font: $font-weight-regular 12px/1.35 $baseFontFamily;
            color: $color-slate100;
            text-transform: initial;
        }
        &--next-item {
            margin: 48px 0 10px 0;
            color: $color-castoBlue;
            font: $font-weight-bold 13px/14px $goodHomeFontFamily;
            cursor: pointer;
            text-transform: initial;
        }
        &--go-to-compare {
            text-transform: initial;
            text-decoration: none;
            padding: 0 8px;
            margin: 0 auto;
        }
    }
}
@media screen and (max-width: $breakpoint-tablet) {
    .product-to-compare {
        .modal {
            max-height: 100%;
            overflow: scroll;
            height: initial;
            width: 100%;
            padding: 10px 10px 0 10px;
            &__container {
                text-align: center;
                width: 100%;
                height: auto;
            }
        }
        &__content {
            &--next-item {
                margin: 36px 0 36px 0;
            }
            &--go-to-compare {
                font: $font-weight-bold 12px/37px $goodHomeFontFamily;
                padding: 0 40px;
            }
        }
        &__header--close {
            padding-top: 3px;
        }
    }
}
</style>
