import { specialFiltersNames, isSpecialFilterValueValid } from './page.config';

export default function (name, value) {
    try {
        if (specialFiltersNames.indexOf(name) >= 0) {
            if (isSpecialFilterValueValid[name] && value) {
                return isSpecialFilterValueValid[name](value);
            }
            return true;
        }
        return false;
    } catch (ignore) {
        return false;
    }
}
