<template>
    <BaseLink
        v-if="href && text && className"
        class="product-label"
        :href="href"
        :text="text"
        :class="[className, ...getBemModifiers()]"
    />
</template>

<script>
import BaseLink from '~/components/common/Link/BaseLink';
import {
    SYMBOLS_BY_ID,
    CSS_CLASS_NAME,
    LABELS_BY_ID,
    LINKS_BY_ID,
} from '~/assets/services/productLabel/config';

export default {
    name: 'ProductLabel',
    components: { BaseLink },
    props: {
        labelId: {
            type: Number,
            required: true,
        },
    },
    computed: {
        href() {
            return LINKS_BY_ID[this.labelId];
        },
        text() {
            return LABELS_BY_ID[this.labelId];
        },
        className() {
            return `${CSS_CLASS_NAME}--${SYMBOLS_BY_ID[this.labelId]}`;
        },
    },
};
</script>

<style lang="scss">
    $DEFAULT_BACKGROUND: #e1eb00;
    $DEFAULT_COLOR: $color-slate100;
    $NEWNESS_BACKGROUND: #02996d;
    $NEWNESS_COLOR: $color-castoWhite;
    $LIMITED_OFFER_COLOR: #e44b21;
    $FREE_DELIVERY_BACKGROUND: #74b142;
    $FREE_DELIVERY_COLOR: $color-castoWhite;
    $ALWAYS_LOWEST_PRICE_BACKGROUND: $color-slate100;
    $ALWAYS_LOWEST_PRICE_COLOR: $color-castoWhite;
    $OUR_CLIENTS_RECOMMENDED_BACKGROUND: $color-slate100;
    $OUR_CLIENTS_RECOMMENDED_COLOR: #e1eb00;

    .product-label {
        background-color: $DEFAULT_BACKGROUND;
        display: inline-block;
        text-align: left;
        font: $font-weight-bold 11px/14px $goodHomeFontFamily;
        color: $DEFAULT_COLOR;
        height: 66px;
        width: 66px;
        padding: 7px 5px;

        &:hover {
            text-decoration: none;
        }

        &--newness {
            font-size: 14px;
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: $NEWNESS_BACKGROUND;
            color: $NEWNESS_COLOR;
        }

        &--limited-offer, &--lowest-price, &--special-offer {
            color: $LIMITED_OFFER_COLOR;
        }

        &--buy-three-for-two {
            &::after {
                content: 'w cenie 2';
                display: block;
            }
        }

        &--only-in-castorama {
            word-spacing: 66px;
        }

        &--our-clients-recommended {
            background: $OUR_CLIENTS_RECOMMENDED_BACKGROUND url('~static/images/stars.png') no-repeat left 5px bottom 4px;
            color: $OUR_CLIENTS_RECOMMENDED_COLOR;
            padding-right: 14px;

            &::after {
                content: 'polecają';
                display: block;
            }
        }

        &--always-lowest-price {
            background: $ALWAYS_LOWEST_PRICE_BACKGROUND;
            color: $ALWAYS_LOWEST_PRICE_COLOR;
            padding-right: 14px;
        }

        &--free-delivery {
            background: $FREE_DELIVERY_BACKGROUND;
            color: $FREE_DELIVERY_COLOR;
        }

        &--cheaper-in-set {
            padding: 7px 4px;
        }
    }

    @media (min-width: $breakpoint-tablet) {
        .product-label {
            padding: 7px 9px;

            &--desktop-bigger {
                height: 99px;
                width: 99px;
                font-size: 14px;
                line-height: 16px;
            }

            &--only-in-castorama {
                padding: 7px 6px;
            }

            &--our-clients-recommended {
                background-position: left 8px bottom 8px;
            }

            &--cheaper-in-set {
                padding: 7px 9px;
            }
        }
    }
</style>
