export default function () {
    return {
        items: [],
        totalPrice: 0,
        wishlistId: null,
        needFetch: true,
        itemsCount: 0,
        showMonit: true,
        addedItems: null,
    };
}
