export const types = {
    SET_PRINT_WINDOW: 'SET_PRINT_WINDOW',
};

const mutations = {
    [types.SET_PRINT_WINDOW](state, data) {
        state.isPrintWindow = data;
    },
};

export default mutations;
