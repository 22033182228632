import axios from 'axios';
import { GET_NEWSPAPER_DATA } from './api.config';

export default async function () {
    try {
        const { data } = await axios({
            method: 'get',
            url: GET_NEWSPAPER_DATA,
        });

        return data;
    } catch (ignore) {
        // @todo log errors
        return {};
    }
}
