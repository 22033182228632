export const types = {
    SET_ARTICLE_GRID: 'SET_ARTICLE_GRID',
};

const mutations = {
    [types.SET_ARTICLE_GRID](state, items) {
        state.articleGrid = items;
    },
};

export default mutations;
