<template>
    <p
        class="available-only-in-store"
        :class="getBemModifiers()"
    >
        Produkt dostępny wyłącznie w sprzedaży stacjonarnej
    </p>
</template>

<script>
export default {
    name: 'AvailableOnlyInStore',
};
</script>

<style lang="scss">
    .available-only-in-store {
        font: $font-weight-bold 12px/18px $goodHomeFontFamily;
        color: $color-castoBlue;
        text-align: center;
        padding: 4px 0;
        border: 2px solid $color-castoBlue;

        &--product-tail {
            height: 60px;
            margin: 0 $spacing-default;
            line-height: 16px;
        }
    }

    @media screen and (min-width: $mf-breakpoint-tablet) {
        .available-only-in-store {
            font-size: 14px;
            padding: 4px 5px;

            &--product-tail {
                max-width: 180px;
                margin: 0 auto 18px auto;
            }
        }
    }
</style>
