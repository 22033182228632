<template>
    <div class="castorama-preloader">
        <CastoC />
    </div>
</template>

<script>
import CastoC from '~/components/common/Animations/CastoC';

export default {
    name: 'CastoramaDefaultPreloader',
    components: { CastoC },
};
</script>

<style lang="scss">
    .castorama-preloader {
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        opacity: 1;
        width: 100%;
        height: 100%;
        background-color: rgba(255, 255, 255, 0.8);
        z-index: 100;
        text-align: center;
        position: absolute;
    }

    .casto-c-wrapper {
        top: calc(50% - 32px);
        left: calc(50% - 32px);
        position: absolute;
        overflow: hidden;
        width: 64px;
        height: 70px;
        text-align: center;
    }
</style>
