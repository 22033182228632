<script>
const codeComponentMap = new Map();

codeComponentMap.set(404, 'ErrorPage404');

export default {
    name: 'Error',
    components: {
        ErrorPage404: () => import('~/components/ErrorPage/ErrorPage404'),
        ErrorPageDefault: () => import('~/components/ErrorPage/ErrorPageDefault'),
    },
    props: {
        error: {
            type: Object,
            default: () => ({
                statusCode: 404,
            }),
        },
    },
    render(createElement) {
        const ErrorPage = codeComponentMap.get(this.error.statusCode) || 'ErrorPageDefault';
        return createElement(
            ErrorPage,
            {},
            [],
        );
    },
};
</script>
