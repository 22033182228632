import Vue from 'vue';

export const types = {
    SET_GAPI: 'SET_GAPI',
    SET_GA: 'SET_GA',
    SET_GTM: 'SET_GTM',
    SET_GPT: 'SET_GPT',
    SET_PRODUCTS_DATA_LAYER: 'SET_PRODUCTS_DATA_LAYER',
    SET_IS_PRODUCT_PRICE_LOADED: 'SET_IS_PRODUCT_PRICE_LOADED',
    ADD_DOUBLE_CLICK_SLOT: 'ADD_DOUBLE_CLICK_SLOT',
    REMOVE_DOUBLE_CLICK_SLOT_ON_INDEX: 'REMOVE_DOUBLE_CLICK_SLOT_ON_INDEX',
};

const mutations = {
    [types.SET_GAPI](state, data) {
        state.GAPI = data;
    },
    [types.SET_GA](state, data) {
        state.GA = data;
    },
    [types.SET_GTM](state, data) {
        state.GTM = data;
    },
    [types.SET_GPT](state, data) {
        state.GPT = data;
    },
    [types.SET_PRODUCTS_DATA_LAYER](state, { type, products }) {
        Vue.set(state.products, type || 'collection', products);
    },
    [types.SET_IS_PRODUCT_PRICE_LOADED](state, areLoaded) {
        state.isProductPriceLoaded = areLoaded;
    },
    [types.ADD_DOUBLE_CLICK_SLOT](state, doubleClickSlot) {
        state.doubleClickSlots.push(doubleClickSlot);
    },
    [types.REMOVE_DOUBLE_CLICK_SLOT_ON_INDEX](state, index) {
        state.doubleClickSlots.splice(index, 1);
    },
};

export default mutations;
