export default function (paramName, separator = '&') {
    const searchPageUrl = decodeURIComponent(window.location.search.substring(1));
    const paramValues = [];
    const searchPageUrlVariables = searchPageUrl.split(separator);

    if (searchPageUrlVariables.length) {
        searchPageUrlVariables.forEach((searchPageUrlVariable) => {
            const [searchPageUrlParamName, searchPageUrlValue] = searchPageUrlVariable.split('=');
            if (searchPageUrlParamName === paramName) {
                paramValues.push(searchPageUrlValue);
            }
        });
    }

    return paramValues;
}
