import { getServices } from '~/src/apollo';

export default function (context, inject) {
    const apollo = {
        services: {},
        getServices: getServices.bind(null, context),
    };

    inject('apollo', apollo);
}
