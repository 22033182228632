import { GLOBAL_VARS } from '~/config';
import loadScript from '~/assets/services/loadScript';
import { googleAnalytics as googleAnalyticsLibUrl } from '~/src/config/externalLibsUrl';
import { checkGA } from '~/assets/services/googleAnalytics';
import { loaderWrapperForExternals } from '~/src/utils/helpers';

export default () => {
    // eslint-disable-next-line
    loaderWrapperForExternals( (app) => {
        const MAIN_PLUGIN_KEY = 'ga';
        const { GOOGLE_ANALYTICS_ID } = GLOBAL_VARS;

        const gaIsLoaded = new Promise((resolve) => {
            if (checkGA()) {
                window.ga('create', GOOGLE_ANALYTICS_ID, 'auto');
                resolve();
            } else {
                // eslint-disable-next-line
                window.ga = window.ga || function () {
                    // eslint-disable-next-line
                    (window.ga.q = window.ga.q || []).push(arguments);
                };
                window.ga.l = 1 * new Date();
                window.ga('create', GOOGLE_ANALYTICS_ID, 'auto');
                loadScript({
                    url: googleAnalyticsLibUrl,
                    callback: resolve,
                });
            }
        });

        const runExternalCallback = (state) => {
            const externalCallbackWrapper = window[
                GLOBAL_VARS.EXTERNAL_CALLBACKS_WRAPPER_NAME
            ].getBySource(MAIN_PLUGIN_KEY);

            if (externalCallbackWrapper) {
                externalCallbackWrapper.callback(state);
            }
        };

        gaIsLoaded.then(() => {
            runExternalCallback(true);
            app[GLOBAL_VARS.BLOCKS_LOADED_STATE].add({ blockName: MAIN_PLUGIN_KEY });
        }, () => {
            runExternalCallback(false);
            app[GLOBAL_VARS.BLOCKS_LOADED_STATE].fail({ blockName: MAIN_PLUGIN_KEY });
        });
    });
};
