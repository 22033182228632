<template>
    <div
        v-if="catalogs && catalogs.length"
        class="catalog-list"
    >
        <p class="catalog-list__section-name">
            Katalogi
        </p>
        <!-- eslint-disable-next-line vue/component-name-in-template-casing -->
        <client-only>
            <PerfectScrollbar ref="scrollbar">
                <div
                    v-for="(catalog, index) in catalogs"
                    :key="`catalog-${index}`"
                    class="catalog-list__content"
                >
                    <a
                        :href="catalog.url"
                        :title="catalog.title"
                    >
                        <BaseImage
                            :data="{ src: catalog.image, alt: 'Katalog w sklepie Castorama' }"
                            class="catalog-image"
                        />
                        <span class="catalog-title">
                            {{ catalog.title }}
                        </span>
                        <span class="catalog-button">
                            Zobacz katalog
                        </span>
                    </a>
                </div>
            </PerfectScrollbar>
        </client-only>
    </div>
</template>

<script>
import { mapState } from 'vuex';
import BaseImage from '~/components/common/Image/BaseImage';

export default {
    name: 'CatalogList',
    components: {
        BaseImage,
    },
    props: {
        showCatalogsList: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        ...mapState({
            catalogs: state => state.promotional.catalog,
        }),
    },
    watch: {
        showCatalogsList() {
            this.$refs.scrollbar.update();
        },
    },
};
</script>

<style lang="scss">
.catalog-list {
    background: $color-castoWhite;
    padding: 0 24px 14px 8px;
    box-sizing: content-box;
    width: 381px;
    max-height: 370px;

    &__content {
        width: 50%;
        float: left;
        margin-bottom: 5px;

        .catalog-title, .catalog-button {
            font-family: $goodHomeFontFamily;
            font-weight: $font-weight-regular;
        }

        .catalog-title {
            display: block;
            margin: 0 10px 10px 0;
            font-size: 14px;
            line-height: 16px;
            color: $color-slate100;
        }

        .catalog-image {
            max-width: 80px;
            float: left;
            margin-right: 4px;
        }

        .catalog-button {
            font-size: 13px;
            line-height: 16px;
            color: $color-castoBlue;
        }

        a {
            text-decoration: none;

            &::after {
                clear: both;
                display: block;
                content: '';
            }
        }
    }

    &__section-name {
        clear: both;
        margin-top: 0;
        color: $color-slate100;
        font: $font-weight-bold 15px/20px $goodHomeFontFamily;
    }

    &::after {
        clear: both;
        display: block;
        content: '';
    }
}
@media (min-width: $breakpoint-tablet) and (max-width: $breakpoint-small-desktop) {
    .catalog-list {
        width: 330px;
        padding: 2px 12px 30px 8px;
        max-height: 200px;

        &--content {
            float: none;
            width: 100%;
        }

        .ps {
            height: 200px;
        }
    }
}
</style>
