import { types } from './mutations';
import compareVars from '~/assets/services/compare/vars';

const stateToSetByIndex = [
    {
        mutationType: types.SHOW_DIFFERENCE,
        toggleText: compareVars.text.HIDE_DIFFERENCE,
    },
    {
        mutationType: types.HIDE_DIFFERENCE,
        toggleText: compareVars.text.SHOW_DIFFERENCE,
    },
];

export default {
    toggleDifference({ commit, state }) {
        const stateToSet = stateToSetByIndex[+state.showDifferencesFlag];
        commit(stateToSet.mutationType);
        commit(types.SET_TOGGLE_DIFFERENCE_TEXT, stateToSet.toggleText);
    },
};
