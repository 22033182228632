import setXMagentoTags from '~/src/utils/httpHeaders/setXMagentoTags';

export default function ({ res, route }) {
    if (process.server) {
        const { name } = route;
        if (name) {
            const routeName = name === 'index' ? 'homepage' : name;
            setXMagentoTags({ res, tags: [routeName.replace(' ', '-')] });
        }
    }
}
