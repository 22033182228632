export const types = {
    SET_SEARCHBAR_MOUNTED: 'SET_SEARCHBAR_MOUNTED',
};

const mutations = {
    [types.SET_SEARCHBAR_MOUNTED](state, data) {
        state.mounted = data;
    },
};

export default mutations;
