export const searchNode = process.env.SEARCH_NODE_URL;

export const trackJs = 'https://cdn.trackjs.com/agent/v3/latest/t.js';

export const googleRecaptcha = 'https://www.google.com/recaptcha/api.js';

export const googlePublisherTag = 'https://www.googletagservices.com/tag/js/gpt.js';

export const googleApi = 'https://apis.google.com/js/api.js';

export const googleAnalytics = 'https://www.google-analytics.com/analytics.js';

export const googleTagManager = 'https://www.googletagmanager.com/gtm.js';

export const easypack = 'https://geowidget.easypack24.net/js/sdk-for-javascript.js';
