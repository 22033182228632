import { productData } from './normalizeData';
import { GET_PRODUCT_DATA as urlTemplate } from './api.config';
import restApiRequestFactory from '~/src/utils/helpers/restApiRequestFactory';

export default async function ({ productId: id }) {
    const { data } = await restApiRequestFactory({
        urlTemplate,
        urlTemplateProps: {
            id,
        },
        method: 'get',
    });

    const { data: productsData, attributes } = data;
    return {
        productData: productData(productsData),
        attributes,
    };
}
