export const types = {
    SET_HEIGHT: 'SET_HEIGHT',
};

const mutations = {
    [types.SET_HEIGHT](state, height) {
        state.height = height;
    },
};

export default mutations;
