import { FILTERS_DEFAULT } from '~/src/config/category';

export default function (activeFilters = []) {
    const copyOfActiveFilters = [...activeFilters];
    const defaultBasicFiltersReassigned = FILTERS_DEFAULT.map((defaultFilter) => {
        const filterProvidedIndex = copyOfActiveFilters.findIndex(activeFilter => activeFilter.filterFieldSymbol === defaultFilter.filterFieldSymbol);
        const copyOfDefaultFilter = { ...defaultFilter };
        if (filterProvidedIndex !== -1) {
            copyOfDefaultFilter.value = copyOfActiveFilters[filterProvidedIndex].filterItemSymbol;
            copyOfDefaultFilter.filterType = 'url';
        }
        return copyOfDefaultFilter;
    });

    FILTERS_DEFAULT.forEach((defaultBasicFilter) => {
        const basicFilterIndexToDelete = copyOfActiveFilters.findIndex(activeFilter => (
            activeFilter.filterFieldSymbol === defaultBasicFilter.filterFieldSymbol
        ));
        if (basicFilterIndexToDelete !== -1) {
            copyOfActiveFilters.splice(basicFilterIndexToDelete, 1);
        }
    });

    return { basicFilters: defaultBasicFiltersReassigned, extendedFilters: copyOfActiveFilters };
}
