import { GLOBAL_VARS } from '~/config/index';
import { types } from '~/store/mutations';

const { QUERY_MODE_PROPERTY_NAME: queryModePropName, MODE_DEFAULT_VALUE } = GLOBAL_VARS;
export default function ({ store, route }) {
    const { query } = route;
    const activeModeName = query[queryModePropName];

    if (activeModeName) {
        store.commit(types.SET_MODE, activeModeName);
    } else {
        store.commit(types.SET_MODE, MODE_DEFAULT_VALUE);
    }
}
