<template>
    <PopUp
        v-if="lastAdded"
        class="added-to-wishlist-popup"
    >
        <div class="added-to-wishlist-content">
            <template v-if="response.success && response.success === 1">
                <div class="wishlist-popup-content">
                    <div class="wishlist-popup-content__header">
                        <BaseImage
                            class="wishlist-popup-content__header--image"
                            :data="{ src: iconAddToCart, alt: 'check' }"
                        />
                        <span
                            class="wishlist-popup-content__header--message"
                        >
                            Produkt został dodany do listy
                        </span>
                    </div>
                    <div class="wishlist-popup-content__header__product">
                        <div
                            v-for="addedItem in lastAdded"
                            :key="addedItem.name"
                            class="mini-wishlist-product-wrapper"
                        >
                            <div
                                class="wishlist-popup-content__header__product--title"
                            >
                                {{ addedItem.qty }} x {{ addedItem.name }}
                            </div>
                            <div
                                v-if="addedItem.price && addedItem.price > 0"
                                class="wishlist-popup-content__header__product--price"
                            >
                                {{ addedItem.price.toString() | dotToComma }} zł
                            </div>
                        </div>
                        <div
                            class="wishlist-popup-content__header__product--totals"
                        >
                            <p class="wishlist-popup-content__header__product--total-products">
                                <span
                                    class="total-in-wishlist"
                                >
                                    Liczba produktów na liście:
                                </span>
                                {{ itemsAmount }}
                            </p>
                            <p class="wishlist-popup-content__header__product--total-price">
                                <span
                                    class="price-total"
                                >
                                    Razem:
                                </span>
                                <PriceValue :value="totalPriceWishlist" />
                                <span class="wishlist-popup-content__header__product--currency">{{ currentCurrency.default }}</span>
                            </p>
                        </div>
                        <div class="wishlist-popup-content__header__buttons">
                            <BaseButton
                                class="wishlist-popup-content__header__product--continue-shopping"
                                :bem-modifiers="['background-slate-medium', 'horizontally-centered']"
                                name="Kontynuuj zakupy"
                                @click.native="closePopUp"
                            >
                                Kontynuuj zakupy
                            </BaseButton>
                            <BaseLink
                                class="button btn-blue wishlist-popup-content__header__product--add-to-wishlist-link"
                                text="Przejdź do listy"
                                :href="`${ baseUrl }/wishlist`"
                            />
                        </div>
                    </div>
                </div>
            </template>
            <template v-else>
                Żądana ilość nie jest dostępna
            </template>
        </div>
    </PopUp>
</template>

<script>
import { createNamespacedHelpers, mapState } from 'vuex';
import PopUp from './BasePopup';
import BaseImage from '~/components/common/Image/BaseImage';
import BaseButton from '~/components/common/Button/BaseButton';
import BaseLink from '~/components/common/Link/BaseLink';
import dotToComma from '~/src/utils/stringTransform/dotToComma';
import PriceValue from '~/src/components/Price/PriceValue';

const iconAddToCart = require('~/static/images/check_add_to_cart.svg');

const { mapState: mapStateWishlist, mapActions: mapActionsWishlist } = createNamespacedHelpers('wishlist');
export default {
    name: 'AddedToWishlistPopup',
    components: {
        BaseButton,
        BaseImage,
        PopUp,
        BaseLink,
        PriceValue,
    },
    filters: {
        dotToComma,
    },
    data() {
        return {
            isShown: true,
            isAddedShown: false,
            response: {
                success: 1,
            },
            iconAddToCart,
        };
    },
    computed: {
        ...mapState({
            baseUrl: state => state.url.baseUrl,
            currentCurrency: state => state.customer.currentCurrency,
        }),
        ...mapStateWishlist({
            lastAdded: state => state.addedItems,
            itemsAmount(state) {
                const { items } = state;
                return items.map(item => item.qty).reduce((item, nextItem) => item + nextItem, 0);
            },
            totalPriceWishlist: state => state.totalPrice,
        }),
    },
    created() {
        this.$on('close-pop-up', this.closePopUp);
    },
    methods: {
        ...mapActionsWishlist(['clearLastAdded']),
        closePopUp() {
            this.clearLastAdded();
            this.$parent.$emit('close-wishlist-pop-up');
        },
    },
};
</script>


<style lang="scss">
    .added-to-wishlist-popup {
        /* @todo Usunąć po refaktorze popupów lub odpaleniu wishlist-popup w poprawny sposób */
        top: 0;
    }
    .added-to-wishlist-content {
        width: 576px;

        .wishlist-popup-content {
            text-align: left;

            &__header {
                padding: 25px;
                border-bottom: 1px solid $color-slate5-dark;

                &--image {
                    width: 36px;
                    vertical-align: middle;
                }

                &--message {
                    padding-left: 10px;
                    vertical-align: middle;
                    font-size: 20px;
                    font-weight: $font-weight-light;
                    color: $color-accent2;
                    line-height: 40px;
                }

                &__buttons {
                    padding: 25px;
                }

                &__product {
                    width: 100%;
                    display: table;

                    .mini-wishlist-product-wrapper {
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        color: $color-slate100;
                        background-color: $color-castoWhite-dark;
                        padding: 25px;
                        border-bottom: 1px solid $color-castoWhite;
                    }

                    &--totals {
                        color: $color-slate100;
                        font-size: 16px;
                        font-weight: $font-weight-bold;
                        text-transform: initial;
                        display: flex;
                        text-align: center;
                        width: 100%;
                        padding: 25px;
                        background-color: $color-castoWhite-dark;

                        .price-total,
                        .total-in-wishlist {
                            font-weight: $font-weight-light;
                        }
                    }

                    &--title {
                        font: $font-weight-light 18px/24px $goodHomeFontFamily;
                        display: flex;
                        flex-direction: column;
                        flex: 2;
                        padding-right: 10px;
                    }

                    &--total-price {
                        display: table-cell;
                        text-align: right;
                        width: 50%;

                        .price-unit {
                            font-size: 12px;
                        }
                    }

                    &--total-products {
                        display: table-cell;
                        width: 50%;
                        text-align: left;
                    }

                    &--price {
                        font-size: 18px;
                        font-weight: $font-weight-bold;
                        text-align: right;

                        .price-value__decimal {
                            font-size: 14px;
                        }
                    }

                    &--currency {
                        font-size: 12px;
                        font-weight: $font-weight-regular;
                    }

                    &--add-to-wishlist-link {
                        display: table-cell;
                        vertical-align: middle;
                        width: auto;
                        padding: 15px 13px;
                        font-size: 16px;
                        line-height: 1;
                        background: $color-castoBlue;
                        float: right;
                        text-decoration: none;

                        &:hover {
                            background-color: $color-castoBlue-dark;
                            text-decoration: none;
                        }
                    }

                    &--continue-shopping {
                        padding: 0 13px;
                        display: inline-block;
                    }
                }
            }
        }
    }

    .modal__close {
        top: 35px;
    }

    @media screen and (max-width: $breakpoint-tablet) {
        .added-to-wishlist-popup {
            width: 100%;
            top: 0;
            padding: 10px 0;
            font-size: 12px;

            .modal {
                overflow-y: scroll;
                height: 100%;
                width: 100%;

                &__container {
                    height: auto;
                    width: 100%;
                }

                &__close {
                    top: 14px;
                    right: 14px;
                }
            }
        }

        .added-to-wishlist-content {
            width: 100%;
            margin: 0;
            background: $color-castoWhite;
            top: 0;
            padding: 10px 0;
            font-size: 12px;

            .wishlist-popup-content {
                &__header {
                    padding: 0 10px 10px 10px;

                    &--message {
                        font-size: 12px;
                        line-height: 23px;
                        font-weight: $font-weight-bold;
                    }

                    &--image {
                        width: 20px;
                    }

                    &__buttons {
                        padding: 0;
                    }

                    &__product {
                        padding-top: 0;
                        display: block;

                        .mini-wishlist-product-wrapper {
                            position: relative;
                            display: table;
                            width: 100%;
                            background-color: $color-castoWhite-dark;
                            padding: 0;
                        }

                        &--title {
                            display: table-cell;
                            width: 75%;
                            font-size: 14px;
                            line-height: 18px;
                            font-weight: $font-weight-bold;
                            padding: 25px 10px;
                        }

                        &--price {
                            display: table-cell;
                            width: 25%;
                            font-size: 14px;
                            text-align: right;
                            padding-right: 10px;
                        }

                        &--totals {
                            text-align: center;
                            display: block;
                            width: 100%;
                            margin: 25px 0;
                            font-weight: $font-weight-bold;
                            font-size: 14px;
                            border-bottom: 1px solid $color-slate5-dark;
                            padding: 0 10px 20px;
                            background-color: $color-castoWhite;

                            p {
                                margin: 0;
                            }

                            .price-total,
                            .total-in-wishlist {
                                line-height: 20px;
                            }
                        }

                        &--total-products,
                        &--total-price {
                            display: block;
                            width: 100%;
                            text-align: center;
                        }

                        &--add-to-wishlist-link {
                            display: block;
                            width: calc(100% - 20px);
                            margin: 10px;
                            text-transform: initial;
                            font-size: 16px;
                            text-align: center;
                        }

                        &--continue-shopping {
                            display: flex;
                            font-size: 16px;
                            margin: auto;
                            width: calc(100% - 20px);
                        }
                    }
                }
            }
        }
    }
</style>
