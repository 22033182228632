<template>
    <section
        v-if="messages.length"
        class="global-messages"
    >
        <BasePopup
            v-for="(message, popupIndex) in messages"
            :key="`globalMessage-${message.type}-${popupIndex}`"
            :class="['message', message.type]"
            @close-pop-up="flushMessageByIndex(popupIndex)"
        >
            <p v-if="typeof message.texts === 'string'">
                {{ message.texts }}
            </p>
            <p
                v-for="(text, messageIndex) in message.texts"
                v-else
                :key="`${message.type}-text-${messageIndex}`"
            >
                {{ text }}
            </p>
            <BaseButton
                v-if="!message.hideButton"
                class="btn-blue message__button"
                name="Zamknij"
                @click.native="flushMessageByIndex(popupIndex)"
            >
                Zamknij
            </BaseButton>
        </BasePopup>
    </section>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import BasePopup from '~/components/common/Popup/BasePopup';
import BaseButton from '~/components/common/Button/BaseButton';

export default {
    name: 'GlobalMessages',
    components: {
        BaseButton,
        BasePopup,
    },
    computed: {
        ...mapState({
            messages: state => state.globalMessages.messages,
        }),
    },
    methods: {
        ...mapActions({
            flushMessageByIndex: 'globalMessages/flushMessageByIndex',
        }),
    },
};
</script>

<style lang="scss">
.global-messages {
    .message {
        display: none;

        &:first-child {
            display: flex;
        }

        .modal__container {
            padding: 15px;
            width: calc(100% + 50px);
            max-width: 100vw;
        }
        p {
            color: $color-slate100;
            font: $font-weight-bold 20px/24px $goodHomeFontFamily;
        }
        .message__button {
            float: left;
            padding: 0 12px;
            margin: 30px 0 10px 10px;
            height: 48px;
            cursor: pointer;

            &:hover {
                background-color: $color-castoBlue-dark;
            }
        }

        &.warning, &.notice, &.error, &.success {
            p {
                color: $color-slate100;
                font: $font-weight-bold 14px/1.35 $goodHomeFontFamily;
            }
            .modal {
                &__container {
                    padding: 48px 39px 48px 140px;
                    background-repeat: no-repeat;
                    background-position: 36px center;
                    background-color: $color-castoWhite;
                    background-size: 67px 67px;
                    border: 2px solid $color-slate20-dark;
                }
                &__close {
                    top: 13px;
                    right: 14px;
                }
            }
        }

        &.warning .modal__container {
            background-image: url('~static/images/svg/i-warning.svg');
        }

        &.error .modal__container {
            background-image: url('~static/images/svg/i-error.svg');
        }

        &.notice .modal__container {
            background-image: url('~static/images/svg/i-notice.svg');
        }

        &.success .modal__container {
            background-image: url('~static/images/svg/i-success.svg');
        }
    }
}

@media screen and (max-width: $breakpoint-tablet) {
    .global-messages {
        width: 100%;
        padding: 0 5%;
        height: 100%;
        position: fixed;
        top: 0;
        align-items: center;
        justify-content: center;
        z-index: 560;
        display: flex;
        flex-wrap: wrap;
        flex: 0 0 100%;
        align-content: center;

        .message {
            align-items: center;

            .modal__overlay.button {
                display: none;
            }

            &.warning, &.notice, &.error, &.success {
                width: 100%;
                height: auto;
                overflow: hidden;
                vertical-align: middle;
                position: relative;

                &:first-child .modal__overlay.button {
                    display: block;
                }
                .modal {
                    position: relative;
                    width: 100%;

                    .modal__container {
                        width: 100%;
                        height: auto;
                        background-size: 36px 36px;
                        padding: 30px 24px 30px 100px;
                        overflow: hidden;
                    }
                }
            }

            & + .message {
                margin-top: 10px;
            }
        }
    }
}
</style>
