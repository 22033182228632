export default function ({ url, callback, async = 'true', fail }) {
    const head = document.getElementsByTagName('head')[0];
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.async = async;
    script.src = url;
    if (callback) {
        script.addEventListener('load', function loadHandle() {
            callback({ element: script });
            script.removeEventListener('load', loadHandle);
        });
    }
    if (fail) {
        script.addEventListener('error', function errorHandle() {
            fail();
            script.removeEventListener('error', errorHandle);
        });
    }
    head.appendChild(script);
}
