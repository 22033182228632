const config = {
    pathPrefix: '/produkty',
    seasonPrefixes: [
        '/produkty/sezon',
        '/produkty/okazje-miesiaca',
    ],
};

const routes = [
    {
        path: `${config.pathPrefix}/*`,
        component: '~/pages/category',
    },
];

module.exports = { routes, config };
