<template>
    <BaseButton
        class="burger-menu-mobile"
        name="Menu"
        @click.native="revealBurgerMenu"
    >
        <span class="burger-menu-mobile__title">
            Menu
        </span>
    </BaseButton>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';
import BaseButton from '~/components/common/Button/BaseButton';

const BurgerMenuPopup = () => import('~/components/common/Header/mobile/Menu');

const { mapActions: mapActionsPopup } = createNamespacedHelpers('popup');

export default {
    name: 'BurgerMenuButton',
    components: {
        BaseButton,
    },
    props: {
        catalogTree: {
            type: Array,
            default: () => [],
        },
        inspirationsTree: {
            type: Array,
            default: () => [],
        },
        forumTree: {
            type: Array,
            default: () => [],
        },
    },
    methods: {
        ...mapActionsPopup(['showPopup']),
        revealBurgerMenu() {
            const { catalogTree, inspirationsTree, forumTree } = this;

            this.showPopup({
                instance: BurgerMenuPopup,
                optionalProps: {
                    catalogTree,
                    inspirationsTree,
                    forumTree,
                },
            });
        },
    },
};
</script>

<style lang="scss">
    .burger-menu-mobile {
        position: relative;
        height: 100%;
        width: 100%;
        background: $color-castoWhite url('~static/images/icons/nh-mini_menu.svg') no-repeat center 15px;
        cursor: pointer;

        &__title {
            text-align: center;
            position: absolute;
            bottom: 0;
            margin: 0 auto;
            left: 0;
            right: 0;
            font: $font-weight-regular 12px/30px $goodHomeFontFamily;
            color: $color-slate70-dark;
            text-transform: none;
        }
    }
</style>
