import axios from 'axios';
import { categoryMenuItem, categoryMenuHierarchy } from '~/assets/services/category/normalizeData';
import { FETCH_CATEGORY_DATA } from '~/assets/services/category/api.config';
import { getExtendedSearchCriteriaParams, getParamsFromSearchCriteriaObject } from '~/assets/services/searchCriteria';

export default async function ({ path, level = 5 }) {
    const mainCategoryDataCriteriaParams = getExtendedSearchCriteriaParams([
        {
            field: 'path',
            value: `${path}/%`,
            conditionType: 'like',
        },
        {
            field: 'level',
            value: level,
            conditionType: 'lteq',
        },
    ]);

    let response;
    try {
        response = await axios({
            method: 'get',
            url: FETCH_CATEGORY_DATA + getParamsFromSearchCriteriaObject(mainCategoryDataCriteriaParams),
        });
        let { data } = response;
        if (data.error) {
            throw data.error;
        }
        data = categoryMenuHierarchy(data);
        return data.map(menuItem => categoryMenuItem(menuItem));
    } catch (message) {
        // @todo log errors
        return [];
    }
}
