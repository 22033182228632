import normalizePriceData from '~/assets/services/price/normalizeData';
import getTransformedUnit from '~/assets/services/price/getTransformedUnit';

function wishlistItemNormalize({ productPrice: price, productPriceUnit, productType: type, qty, ...data }) {
    return {
        ...data,
        price,
        unit: getTransformedUnit(productPriceUnit),
        qty: parseInt(qty, 10) === qty ? parseInt(qty, 10) : parseFloat(qty),
    };
}

function prepareDataForNormalize(productData) {
    /* eslint-disable camelcase */
    const { productPriceUnit, unit, conversionUnit, price, conversion } = productData;
    const newProduct = { price, price_unit: productPriceUnit, ...productData };
    if (conversion) {
        newProduct.attrData = {
            conversion,
            sales_units_change: !!conversion,
            price_unit: unit,
            conversion_unit: conversionUnit.toLowerCase(),
        };
    }
    /* eslint-enable camelcase */
    return newProduct;
}

export default function ({ items, ...data }) {
    const normalizedItems = items.map((item) => {
        const normalizeItem = wishlistItemNormalize(item);
        const { groupedItems = [] } = normalizeItem;
        normalizeItem.groupedItems = groupedItems
            .map(groupedItem => ({ ...wishlistItemNormalize(groupedItem), ...normalizePriceData(groupedItem) }));
        return normalizePriceData(prepareDataForNormalize(normalizeItem));
    });

    return {
        items: normalizedItems,
        ...data,
    };
}
