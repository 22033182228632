import axios from 'axios';
import { FETCH_PROMO_BRANDS_DATA } from './api.config';

export default async function ({ categoryId: category }) {
    let response;
    try {
        response = await axios({
            method: 'get',
            params: {
                category,
            },
            url: FETCH_PROMO_BRANDS_DATA,
        });
        const { data } = response;
        return data;
    } catch (ignore) {
        // @todo log errors
        return [];
    }
}
