<template>
    <input
        type="text"
        placeholder="Szukaj w Castorama ..."
        name="q"
        value=""
        ceid="Wyszukiwarka - pole tekstowe"
        maxlength="128"
        autocomplete="off"
        tabindex="0"
        v-on="$listeners"
    >
</template>

<script>
export default {
    name: 'SearchBarInput',
};
</script>
