export default function () {
    return {
        currentCategoryId: null,
        priceSlider: {
            min: 0,
            max: 10,
            value: [],
        },
        name: null,
        alternativeName: null,
        filterOptions: [],
        activeFilters: [],
        filterHeights: null,
        categoryTree: null,
        consultantData: null,
        categoryMenu: [],
        componentHeights: {},
        seriesData: [],
        description: null,
        brandDescription: null,
        image: null,
        backgroundImage: null,
        path: null,
        meta: [],
        children: [],
        parentsIds: [],
        categoryPromoBrands: [],
        products: null,
        productsCount: 0,
        articleGrid: [],
        firstPageFlag: false,
        noSsr: false,
        containSpecialFilters: false,
        activeTabSymbol: 'products',
        activeSortSymbol: 'products',
        parentsTree: [],
        categoryIsSpecial: false,
        isSeasonCategory: true,
    };
}
