<template>
    <GlobalNotice
        v-if="appWithNotice"
        ref="appGlobalNoticeWrapper"
        class="demo-notice"
    >
        <template slot="baseText">
            This is a demo store. Any orders placed through this store will not be honored or fulfilled (APP: {{ appName }}).
        </template>
    </GlobalNotice>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';
import GlobalNotice from '~/components/common/Notice/GlobalNotice';

const { mapState, mapActions } = createNamespacedHelpers('appGlobalNotice');

export default {
    name: 'AppGlobalNotice',
    components: {
        GlobalNotice,
    },
    computed: {
        ...mapState({
            appName: state => state.appName,
            appWithNotice: state => state.displayed,
        }),
    },
    mounted() {
        if (this.$refs.appGlobalNoticeWrapper) {
            this.setHeight(this.$refs.appGlobalNoticeWrapper.$el.clientHeight);
        }
    },
    methods: {
        ...mapActions(['setHeight']),
    },
};
</script>

<style>
    .demo-notice {
        margin: 0;
        padding: 11px 10px;
        background: $color-deepCanynon;
        font-size: 12px;
        line-height: 1.15;
        text-align: center;
        color: $color-castoWhite;
        z-index: 1;
        position: relative;
    }
</style>
