<template>
    <span
        class="price-unit"
        :class="getBemModifiers()"
    >
        <template v-if="isDefaultUnit && displayCurrency">
            {{ currency }}
        </template>
        <template v-else-if="unitWithSuperscriptNumber">
            {{ unitToDisplay[0] }} <sup class="price-unit__superscript">{{ unitToDisplay[1] }}</sup>
        </template>
        <template v-else>
            {{ unitToDisplay }}
        </template>
    </span>
</template>

<script>

import saleUnitsConfig from '~/src/utils/saleUnits/config';

const {
    withShortcut: unitsShortcut,
    reverseWithShortcut: reverseUnitsShortcut,
    withSuperscriptNumber: unitsWithSuperscriptNumber,
    default: defaultUnits,
} = saleUnitsConfig;

export default {
    name: 'PriceUnit',
    props: {
        /**
         *  Product sale unit
         */
        unit: {
            type: String,
            default: defaultUnits[0],
        },
        /**
         *  Display currency status
         */
        displayCurrency: {
            type: Boolean,
            default: true,
        },
        /**
         *  Price currency
         */
        currency: {
            type: String,
            default: 'zł',
        },
    },
    computed: {
        /**
         * Returns whether the unit is the default unit on the system
         * @returns {boolean}
         */
        isDefaultUnit() {
            return defaultUnits.indexOf(`${this.unit}`) >= 0;
        },
        /**
         * Returns whether the unit contain superscript number (e.g unit: m2)
         * @returns {boolean}
         */
        unitWithSuperscriptNumber() {
            return unitsWithSuperscriptNumber.indexOf(this.unit) >= 0;
        },
        /**
         * Returns the unit in the correct format to display
         * @returns {string}
         */
        unitToDisplay() {
            const unitShortcut = unitsShortcut.find(({ base }) => base === this.unit) || reverseUnitsShortcut.find(({ base }) => base === this.unit);

            if (unitShortcut) {
                return unitShortcut.short;
            }

            if (this.unitWithSuperscriptNumber) {
                return this.unit.match(/[a-zA-Z]+|[0-9]+(?:\.[0-9]+|)/g);
            }

            return this.unit;
        },
    },
};
</script>

<style lang="scss">
    .price-unit {
        font-family: $goodHomeFontFamily;
        font-size: 11px;
        font-weight: $font-weight-regular;

        &__superscript {
            font-size: 9px;
            vertical-align: super;
            text-indent: -1px;
            display: inline-block;
        }

        &--product-page-price-box {
            font-weight: $font-weight-regular;
            font-size: 14px;
            line-height: 18px;
            padding-left: 0;
        }
    }
</style>
