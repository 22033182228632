import { processProgressiveImage } from '~/assets/services/DOMManipulate';
import isInViewport from '~/assets/services/DOMElement/isInViewport';

export default function ({ element, ...data }) {
    const { checkingIsInViewport, intersectionObserver } = isInViewport({ element });
    checkingIsInViewport.then(({ target }) => {
        processProgressiveImage(target, data);
    });
    return { intersectionObserver, checkingIsInViewport };
}
