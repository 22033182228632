export const types = {
    SET_PRODUCT_REVIEWS_DATA: 'SET_PRODUCT_REVIEWS_DATA',
    ADD_PRODUCT_REVIEWS: 'ADD_PRODUCT_REVIEWS',
};

const mutations = {
    [types.SET_PRODUCT_REVIEWS_DATA](state, { productId, reviewsData }) {
        state.reviewsData = {
            ...state.reviewsData,
            [productId]: reviewsData,
        };
    },
    [types.ADD_PRODUCT_REVIEWS](state, { productId, newReviews }) {
        const currentProductReviewsData = state.reviewsData[productId];

        state.reviewsData = {
            ...state.reviewsData,
            [productId]: {
                ...currentProductReviewsData,
                items: [...currentProductReviewsData.items, ...newReviews],
            },
        };
    },
};

export default mutations;
