import { createNamespacedHelpers } from 'vuex';

const {
    mapActions: mapActionsCart,
    mapState: mapStateCart,
} = createNamespacedHelpers('cart');

const {
    mapActions: mapActionsWishlist,
    mapState: mapStateWishlist,
} = createNamespacedHelpers('wishlist');

const { mapState: mapStateMarkets } = createNamespacedHelpers('markets');

export default {
    computed: {
        ...mapStateCart({
            cartItems: state => state.items,
        }),
        ...mapStateWishlist({
            wishlistItems: state => state.items,
        }),
        ...mapStateMarkets({
            selectedShopIds: state => state.selectedShopIds,
        }),
    },
    methods: {
        ...mapActionsCart({ setNeedFetchCart: 'setNeedFetch' }),
        ...mapActionsWishlist({ setNeedFetchWishlist: 'setNeedFetch' }),
    },
    watch: {
        selectedShopIds({ shopId: oldShopId }, { shopId: newShopId }) {
            if (oldShopId !== newShopId) {
                if (this.cartItems.length) {
                    this.setNeedFetchCart(true);
                }

                if (this.wishlistItems.length) {
                    this.setNeedFetchWishlist(true);
                }
            }
        },
    },
};
