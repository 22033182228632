import getSimpleFiltersCollection from './getSimpleFiltersCollection';

export default function (activeFilters) {
    const simpleFiltersCollection = getSimpleFiltersCollection(activeFilters);
    const filtersLikeInQuery = {};
    Object.keys(simpleFiltersCollection).forEach((filterName) => {
        filtersLikeInQuery[filterName] = simpleFiltersCollection[filterName];
        if (simpleFiltersCollection[filterName] instanceof Array) {
            filtersLikeInQuery[filterName] = simpleFiltersCollection[filterName].join('__');
        }
    });
    return filtersLikeInQuery;
}
