import axios from 'axios';
import { CHANGE_QTY_URL } from './api.config';
import 'url-search-params-polyfill';

export default function ({ formKey, itemId, qty }) {
    const search = new URLSearchParams({
        [`cart[${itemId}][qty]`]: qty,
        form_key: formKey,
        isAjax: true,
    });
    return axios({
        method: 'post',
        url: CHANGE_QTY_URL,
        data: search.toString(),
        config: { headers: { 'Content-Type': 'text/plain' } },
    });
}
