import CookieUniversal from 'cookie-universal';
import cookieConfig from '~/assets/services/storages/cookies/cookie.config';

const cookies = new CookieUniversal();
const cookieName = 'cookieBar';
const cookieValue = 1;
const cookieBarMaxDays = 30;

function getCookieBarClosed() {
    return cookies.get(cookieName);
}

export function setCookieBarClosed() {
    cookies.set(cookieName, cookieValue, {
        ...cookieConfig.defaultOptions,
        maxAge: cookieBarMaxDays * 24 * 60 * 60,
    });
}

export function isCookieBarClosed() {
    return getCookieBarClosed() === cookieValue;
}
