<template>
    <BaseButton
        class="close-button"
        name="Zamknij"
        @click.native.stop="$emit('click')"
        @click.stop="$emit('click')"
    >
        Zamknij
    </BaseButton>
</template>

<script>
import BaseButton from '~/components/common/Button/BaseButton';

export default {
    name: 'CloseButton',
    components: { BaseButton },
};
</script>

<style>
    .close-button {
        position: absolute;
        top: 37px;
        right: 24px;
        display: block;
        width: 11px;
        height: 11px;
        background: url('~static/images/icons/close-x.svg') top left no-repeat;
        line-height: 400px;
        overflow: hidden;
        cursor: pointer;
    }
</style>
