import Vue from 'vue'
import Meta from 'vue-meta'
import ClientOnly from 'vue-client-only'
import NoSsr from 'vue-no-ssr'
import { createRouter } from './router.js'
import NuxtChild from './components/nuxt-child.js'
import NuxtError from '../layouts/error.vue'
import Nuxt from './components/nuxt.js'
import App from './App.js'
import { setContext, getLocation, getRouteData, normalizeError } from './utils'
import { createStore } from './store.js'

/* Plugins */

import nuxt_plugin_cookieuniversalnuxt_5aeecf71 from 'nuxt_plugin_cookieuniversalnuxt_5aeecf71' // Source: ./cookie-universal-nuxt.js (mode: 'all')
import nuxt_plugin_logger_057b83b1 from 'nuxt_plugin_logger_057b83b1' // Source: ../plugins/logger.js (mode: 'all')
import nuxt_plugin_jsonld_2ff766e1 from 'nuxt_plugin_jsonld_2ff766e1' // Source: ../plugins/jsonld.js (mode: 'all')
import nuxt_plugin_apollo_82f16fdc from 'nuxt_plugin_apollo_82f16fdc' // Source: ../plugins/apollo.js (mode: 'all')
import nuxt_plugin_bemModifiers_250ab878 from 'nuxt_plugin_bemModifiers_250ab878' // Source: ../src/plugins/bemModifiers.js (mode: 'all')
import nuxt_plugin_sautedetectorclient_9d5b8222 from 'nuxt_plugin_sautedetectorclient_9d5b8222' // Source: ../plugins/saute-detector.client.js (mode: 'client')
import nuxt_plugin_intersectionobserverclient_9f6e9ca4 from 'nuxt_plugin_intersectionobserverclient_9f6e9ca4' // Source: ../plugins/polyfill/intersection-observer.client.js (mode: 'client')
import nuxt_plugin_customeventclient_38f40e26 from 'nuxt_plugin_customeventclient_38f40e26' // Source: ../plugins/polyfill/custom-event.client.js (mode: 'client')
import nuxt_plugin_nodelistforeachclient_b4989bcc from 'nuxt_plugin_nodelistforeachclient_b4989bcc' // Source: ../plugins/polyfill/node-list-foreach.client.js (mode: 'client')
import nuxt_plugin_trackclient_c683499a from 'nuxt_plugin_trackclient_c683499a' // Source: ../plugins/track.client.js (mode: 'client')
import nuxt_plugin_blocksLoadedclient_343b5509 from 'nuxt_plugin_blocksLoadedclient_343b5509' // Source: ../plugins/helpers/blocksLoaded.client.js (mode: 'client')
import nuxt_plugin_externalCallbacksclient_7a2bbba1 from 'nuxt_plugin_externalCallbacksclient_7a2bbba1' // Source: ../plugins/helpers/externalCallbacks.client.js (mode: 'client')
import nuxt_plugin_inpostclient_cf6baaba from 'nuxt_plugin_inpostclient_cf6baaba' // Source: ../plugins/helpers/inpost.client.js (mode: 'client')
import nuxt_plugin_googlerecaptchav3handlerclient_002373b2 from 'nuxt_plugin_googlerecaptchav3handlerclient_002373b2' // Source: ../plugins/google/google-recaptcha-v3-handler.client.js (mode: 'client')
import nuxt_plugin_googleapiclient_90b5f078 from 'nuxt_plugin_googleapiclient_90b5f078' // Source: ../plugins/google/google-api.client.js (mode: 'client')
import nuxt_plugin_googletagmanagerclient_ceecfbb8 from 'nuxt_plugin_googletagmanagerclient_ceecfbb8' // Source: ../plugins/google/google-tag-manager.client.js (mode: 'client')
import nuxt_plugin_googleanalyticsclient_02022da0 from 'nuxt_plugin_googleanalyticsclient_02022da0' // Source: ../plugins/google/google-analytics.client.js (mode: 'client')
import nuxt_plugin_googlepublishertagclient_37b56833 from 'nuxt_plugin_googlepublishertagclient_37b56833' // Source: ../plugins/google/google-publisher-tag.client.js (mode: 'client')
import nuxt_plugin_googleauth2client_5f277634 from 'nuxt_plugin_googleauth2client_5f277634' // Source: ../plugins/google/google-auth2.client.js (mode: 'client')
import nuxt_plugin_managingbrowsercookiesclient_7a45a713 from 'nuxt_plugin_managingbrowsercookiesclient_7a45a713' // Source: ../plugins/managing-browser-cookies.client.js (mode: 'client')
import nuxt_plugin_lazyLoadDoubleClickclient_5d38733b from 'nuxt_plugin_lazyLoadDoubleClickclient_5d38733b' // Source: ../plugins/helpers/lazyLoadDoubleClick.client.js (mode: 'client')
import nuxt_plugin_nuxtclientinitclient_a7415c48 from 'nuxt_plugin_nuxtclientinitclient_a7415c48' // Source: ../plugins/nuxt-client-init.client.js (mode: 'client')
import nuxt_plugin_windowresizeclient_035fc5a6 from 'nuxt_plugin_windowresizeclient_035fc5a6' // Source: ../plugins/window/window-resize.client.js (mode: 'client')
import nuxt_plugin_priceProviderclient_250ab3f8 from 'nuxt_plugin_priceProviderclient_250ab3f8' // Source: ../plugins/helpers/priceProvider.client.js (mode: 'client')
import nuxt_plugin_vuecarouselclient_083084f8 from 'nuxt_plugin_vuecarouselclient_083084f8' // Source: ../plugins/vue/vue-carousel.client.js (mode: 'client')
import nuxt_plugin_vuevideoplayerclient_66f44dd7 from 'nuxt_plugin_vuevideoplayerclient_66f44dd7' // Source: ../plugins/vue/vue-video-player.client.js (mode: 'client')
import nuxt_plugin_veevalidateclient_00d371aa from 'nuxt_plugin_veevalidateclient_00d371aa' // Source: ../plugins/vue/vee-validate.client.js (mode: 'client')
import nuxt_plugin_vuedragscrollclient_a1cc2b36 from 'nuxt_plugin_vuedragscrollclient_a1cc2b36' // Source: ../plugins/vue/vue-dragscroll.client.js (mode: 'client')
import nuxt_plugin_vue2perfectscrollbarclient_75d0abc2 from 'nuxt_plugin_vue2perfectscrollbarclient_75d0abc2' // Source: ../plugins/vue/vue2-perfect-scrollbar.client.js (mode: 'client')
import nuxt_plugin_samitodatalayerclient_6b754e04 from 'nuxt_plugin_samitodatalayerclient_6b754e04' // Source: ../plugins/samito-data-layer.client.js (mode: 'client')
import nuxt_plugin_progressiveSrcclient_5bf40b3d from 'nuxt_plugin_progressiveSrcclient_5bf40b3d' // Source: ../assets/directives/progressiveSrc.client.js (mode: 'client')
import nuxt_plugin_progressiveBackgroundclient_a7d9269e from 'nuxt_plugin_progressiveBackgroundclient_a7d9269e' // Source: ../assets/directives/progressiveBackground.client.js (mode: 'client')
import nuxt_plugin_isInViewportclient_95016dce from 'nuxt_plugin_isInViewportclient_95016dce' // Source: ../assets/directives/isInViewport.client.js (mode: 'client')
import nuxt_plugin_lazyLoadSrcclient_f077fc3c from 'nuxt_plugin_lazyLoadSrcclient_f077fc3c' // Source: ../assets/directives/lazyLoadSrc.client.js (mode: 'client')

// Component: <ClientOnly>
Vue.component(ClientOnly.name, ClientOnly)

// TODO: Remove in Nuxt 3: <NoSsr>
Vue.component(NoSsr.name, {
  ...NoSsr,
  render (h, ctx) {
    if (process.client && !NoSsr._warned) {
      NoSsr._warned = true

      console.warn('<no-ssr> has been deprecated and will be removed in Nuxt 3, please use <client-only> instead')
    }
    return NoSsr.render(h, ctx)
  }
})

// Component: <NuxtChild>
Vue.component(NuxtChild.name, NuxtChild)
Vue.component('NChild', NuxtChild)

// Component NuxtLink is imported in server.js or client.js

// Component: <Nuxt>
Vue.component(Nuxt.name, Nuxt)

Vue.use(Meta, {"keyName":"head","attribute":"data-n-head","ssrAttribute":"data-n-head-ssr","tagIDKeyName":"hid"})

const defaultTransition = {"name":"page","mode":"out-in","appear":false,"appearClass":"appear","appearActiveClass":"appear-active","appearToClass":"appear-to"}

async function createApp (ssrContext) {
  const router = await createRouter(ssrContext)

  const store = createStore(ssrContext)
  // Add this.$router into store actions/mutations
  store.$router = router

  // Fix SSR caveat https://github.com/nuxt/nuxt.js/issues/3757#issuecomment-414689141
  const registerModule = store.registerModule
  store.registerModule = (path, rawModule, options) => registerModule.call(store, path, rawModule, Object.assign({ preserveState: process.client }, options))

  // Create Root instance

  // here we inject the router and store to all child components,
  // making them available everywhere as `this.$router` and `this.$store`.
  const app = {
    head: function () {
    return {
        script: [
            {
                src: `${process.env.PUBLIC_PATH}/standalone-js/advertising.js`,
            },
        ],
        htmlAttrs: {
            lang: 'pl-PL',
        },
        title: 'Sklepy Castorama to sieć marketów z materiałami budowlanymi, remontowymi, dekoracyjnymi i ogrodniczymi.',
        meta: [
            { charset: 'utf-8' },
            {
                name: 'viewport',
                content: 'width=device-width, initial-scale=1',
            },
            {
                hid: 'description',
                name: 'description',
                content: 'Sieć sklepów budowlanych Castorama oferuje artykuły z kategorii: ogród, wystrój wnętrz, aranżacja, dekoracja,' +
                    ' majsterkowanie, budowa, technika.',
            },
        ],
    };
},

    store,
    router,
    nuxt: {
      defaultTransition,
      transitions: [defaultTransition],
      setTransitions (transitions) {
        if (!Array.isArray(transitions)) {
          transitions = [transitions]
        }
        transitions = transitions.map((transition) => {
          if (!transition) {
            transition = defaultTransition
          } else if (typeof transition === 'string') {
            transition = Object.assign({}, defaultTransition, { name: transition })
          } else {
            transition = Object.assign({}, defaultTransition, transition)
          }
          return transition
        })
        this.$options.nuxt.transitions = transitions
        return transitions
      },

      err: null,
      dateErr: null,
      error (err) {
        err = err || null
        app.context._errored = Boolean(err)
        err = err ? normalizeError(err) : null
        let nuxt = app.nuxt // to work with @vue/composition-api, see https://github.com/nuxt/nuxt.js/issues/6517#issuecomment-573280207
        if (this) {
          nuxt = this.nuxt || this.$options.nuxt
        }
        nuxt.dateErr = Date.now()
        nuxt.err = err
        // Used in src/server.js
        if (ssrContext) {
          ssrContext.nuxt.error = err
        }
        return err
      }
    },
    ...App
  }

  // Make app available into store via this.app
  store.app = app

  const next = ssrContext ? ssrContext.next : location => app.router.push(location)
  // Resolve route
  let route
  if (ssrContext) {
    route = router.resolve(ssrContext.url).route
  } else {
    const path = getLocation(router.options.base, router.options.mode)
    route = router.resolve(path).route
  }

  // Set context to app.context
  await setContext(app, {
    store,
    route,
    next,
    error: app.nuxt.error.bind(app),
    payload: ssrContext ? ssrContext.payload : undefined,
    req: ssrContext ? ssrContext.req : undefined,
    res: ssrContext ? ssrContext.res : undefined,
    beforeRenderFns: ssrContext ? ssrContext.beforeRenderFns : undefined,
    ssrContext
  })

  const inject = function (key, value) {
    if (!key) {
      throw new Error('inject(key, value) has no key provided')
    }
    if (value === undefined) {
      throw new Error(`inject('${key}', value) has no value provided`)
    }

    key = '$' + key
    // Add into app
    app[key] = value

    // Add into store
    store[key] = app[key]

    // Check if plugin not already installed
    const installKey = '__castoramabystrix_' + key + '_installed__'
    if (Vue[installKey]) {
      return
    }
    Vue[installKey] = true
    // Call Vue.use() to install the plugin into vm
    Vue.use(() => {
      if (!Object.prototype.hasOwnProperty.call(Vue, key)) {
        Object.defineProperty(Vue.prototype, key, {
          get () {
            return this.$root.$options[key]
          }
        })
      }
    })
  }

  if (process.client) {
    // Replace store state before plugins execution
    if (window.__CASTORAMABYSTRIX__ && window.__CASTORAMABYSTRIX__.state) {
      store.replaceState(window.__CASTORAMABYSTRIX__.state)
    }
  }

  // Plugin execution

  if (typeof nuxt_plugin_cookieuniversalnuxt_5aeecf71 === 'function') {
    await nuxt_plugin_cookieuniversalnuxt_5aeecf71(app.context, inject)
  }

  if (typeof nuxt_plugin_logger_057b83b1 === 'function') {
    await nuxt_plugin_logger_057b83b1(app.context, inject)
  }

  if (typeof nuxt_plugin_jsonld_2ff766e1 === 'function') {
    await nuxt_plugin_jsonld_2ff766e1(app.context, inject)
  }

  if (typeof nuxt_plugin_apollo_82f16fdc === 'function') {
    await nuxt_plugin_apollo_82f16fdc(app.context, inject)
  }

  if (typeof nuxt_plugin_bemModifiers_250ab878 === 'function') {
    await nuxt_plugin_bemModifiers_250ab878(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_sautedetectorclient_9d5b8222 === 'function') {
    await nuxt_plugin_sautedetectorclient_9d5b8222(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_intersectionobserverclient_9f6e9ca4 === 'function') {
    await nuxt_plugin_intersectionobserverclient_9f6e9ca4(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_customeventclient_38f40e26 === 'function') {
    await nuxt_plugin_customeventclient_38f40e26(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_nodelistforeachclient_b4989bcc === 'function') {
    await nuxt_plugin_nodelistforeachclient_b4989bcc(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_trackclient_c683499a === 'function') {
    await nuxt_plugin_trackclient_c683499a(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_blocksLoadedclient_343b5509 === 'function') {
    await nuxt_plugin_blocksLoadedclient_343b5509(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_externalCallbacksclient_7a2bbba1 === 'function') {
    await nuxt_plugin_externalCallbacksclient_7a2bbba1(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_inpostclient_cf6baaba === 'function') {
    await nuxt_plugin_inpostclient_cf6baaba(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_googlerecaptchav3handlerclient_002373b2 === 'function') {
    await nuxt_plugin_googlerecaptchav3handlerclient_002373b2(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_googleapiclient_90b5f078 === 'function') {
    await nuxt_plugin_googleapiclient_90b5f078(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_googletagmanagerclient_ceecfbb8 === 'function') {
    await nuxt_plugin_googletagmanagerclient_ceecfbb8(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_googleanalyticsclient_02022da0 === 'function') {
    await nuxt_plugin_googleanalyticsclient_02022da0(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_googlepublishertagclient_37b56833 === 'function') {
    await nuxt_plugin_googlepublishertagclient_37b56833(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_googleauth2client_5f277634 === 'function') {
    await nuxt_plugin_googleauth2client_5f277634(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_managingbrowsercookiesclient_7a45a713 === 'function') {
    await nuxt_plugin_managingbrowsercookiesclient_7a45a713(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_lazyLoadDoubleClickclient_5d38733b === 'function') {
    await nuxt_plugin_lazyLoadDoubleClickclient_5d38733b(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_nuxtclientinitclient_a7415c48 === 'function') {
    await nuxt_plugin_nuxtclientinitclient_a7415c48(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_windowresizeclient_035fc5a6 === 'function') {
    await nuxt_plugin_windowresizeclient_035fc5a6(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_priceProviderclient_250ab3f8 === 'function') {
    await nuxt_plugin_priceProviderclient_250ab3f8(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_vuecarouselclient_083084f8 === 'function') {
    await nuxt_plugin_vuecarouselclient_083084f8(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_vuevideoplayerclient_66f44dd7 === 'function') {
    await nuxt_plugin_vuevideoplayerclient_66f44dd7(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_veevalidateclient_00d371aa === 'function') {
    await nuxt_plugin_veevalidateclient_00d371aa(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_vuedragscrollclient_a1cc2b36 === 'function') {
    await nuxt_plugin_vuedragscrollclient_a1cc2b36(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_vue2perfectscrollbarclient_75d0abc2 === 'function') {
    await nuxt_plugin_vue2perfectscrollbarclient_75d0abc2(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_samitodatalayerclient_6b754e04 === 'function') {
    await nuxt_plugin_samitodatalayerclient_6b754e04(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_progressiveSrcclient_5bf40b3d === 'function') {
    await nuxt_plugin_progressiveSrcclient_5bf40b3d(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_progressiveBackgroundclient_a7d9269e === 'function') {
    await nuxt_plugin_progressiveBackgroundclient_a7d9269e(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_isInViewportclient_95016dce === 'function') {
    await nuxt_plugin_isInViewportclient_95016dce(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_lazyLoadSrcclient_f077fc3c === 'function') {
    await nuxt_plugin_lazyLoadSrcclient_f077fc3c(app.context, inject)
  }

  // If server-side, wait for async component to be resolved first
  if (process.server && ssrContext && ssrContext.url) {
    await new Promise((resolve, reject) => {
      router.push(ssrContext.url, resolve, () => {
        // navigated to a different route in router guard
        const unregister = router.afterEach(async (to, from, next) => {
          ssrContext.url = to.fullPath
          app.context.route = await getRouteData(to)
          app.context.params = to.params || {}
          app.context.query = to.query || {}
          unregister()
          resolve()
        })
      })
    })
  }

  return {
    store,
    app,
    router
  }
}

export { createApp, NuxtError }
