export default function () {
    return {
        Gapi: null,
        GA: null,
        GTM: null,
        GPT: null,
        products: {},
        isProductPriceLoaded: false,
        doubleClickSlots: [],
    };
}
