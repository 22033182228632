import validateVulgarity from '~/assets/services/censor';

const basicTests = [
    {
        name: 'email',
        instructions: 'Proszę wprowadzić poprawny adres email np. jan.kowalski@domena.pl.',
        // eslint-disable-next-line
        validateFunc: val => new RegExp(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/).test(val),
    },
    {
        name: 'not-castorama',
        instructions: 'Treść użyta jest niezgodna z regulaminem serwisu www akapit VI pkt 2',
        inverse: true,
        validateFunc: val => new RegExp(/castorama/gmi).test(val),
    },
    {
        name: '8charLength',
        validateFunc: val => new RegExp('.{8}').test(val),
    },
    {
        name: 'capital',
        validateFunc: val => new RegExp('[A-Z]').test(val),
    },
    {
        name: 'small',
        validateFunc: val => new RegExp('[a-z]').test(val),
    },
    {
        name: 'number',
        validateFunc: val => new RegExp('[0-9]').test(val),
    },
    {
        name: 'special',
        // eslint-disable-next-line
        validateFunc: val => new RegExp('[!@#$%\^&*)(+=._-]+|[0-9]+').test(val),
    },
    {
        name: 'whiteChar',
        inverse: true,
        validateFunc: val => new RegExp('[\\s]').test(val),
    },
    {
        name: 'dot',
        validateFunc: val => new RegExp('[.]').test(val),
    },
    {
        name: 'non-empty',
        instructions: 'To pole jest wymagane',
        validateFunc: val => new RegExp(/^(?!\\s*$).+/).test(val),
    },
    {
        name: 'censor',
        inverse: true,
        instructions: 'Proponowana treść zawiera słowa uważane za wulgarne lub obraźliwe',
        validateFunc: async (val) => {
            const { 'validation-vulgarity': validationVulgarity } = await validateVulgarity(val);
            return validationVulgarity;
        },
    },
];

const assembledTests = [
    {
        name: 'password',
        instructions: 'Hasło powinno składać się co najmniej z 8 znaków: dużej litery, małej litery, cyfry lub znaku specjalnego (!\\\'^£$%&*()}{@#~?><>,|=_+¬-]).',
        validateFunc: (val) => {
            const testsToPass = ['8charLength', 'capital', 'small', 'number', 'special', 'whiteChar'];
            return testsToPass.every((testToPass) => {
                const testResult = basicTests.find(basicTest => basicTest.name === testToPass);
                return testResult.inverse ? !testResult.validateFunc(val) : testResult.validateFunc(val);
            });
        },
    },
];

export default [
    ...basicTests,
    ...assembledTests,
];
