export const types = {
    SHOW_LOADER: 'SHOW_LOADER',
    HIDE_LOADER: 'HIDE_LOADER',
    SET_OPTIONAL_PROPS: 'SET_OPTIONAL_PROPS',
};

const mutations = {
    [types.SHOW_LOADER](state) {
        state.isLoading = true;
    },
    [types.HIDE_LOADER](state) {
        state.isLoading = false;
    },
    [types.SET_OPTIONAL_PROPS](state, optionalProps) {
        state.optionalProps = optionalProps;
    },
};

export default mutations;
