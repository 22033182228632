<template>
    <button
        type="submit"
        title="Szukaj"
        ceid="Wyszukiwarka - szukaj"
    >
        Szukaj w Castorama...
    </button>
</template>

<script>
export default {
    name: 'SearchBarButton',
};
</script>
