import Vue from 'vue';

export default function (DOMElement, appendedComponent, propsData) {
    const ComponentExtend = Vue.extend(appendedComponent);
    const componentInstance = new ComponentExtend({
        propsData,
    });
    componentInstance.$mount();
    if (DOMElement) {
        DOMElement.appendChild(componentInstance.$el);
    }
    return componentInstance;
}
