import { GLOBAL_VARS, PREFETCH_DNS_LIST } from '~/config/index';

export default function () {
    return {
        mode: GLOBAL_VARS.MODE_DEFAULT_VALUE,
        robotsFollow: true,
        adblock: false,
        url: {
            baseUrl: process.env.baseUrl,
        },
        requestsCount: 0,
        headLinks: [
            ...PREFETCH_DNS_LIST.map(dns => ({ hid: `${dns}-hid-con`, rel: 'preconnect', href: `//${dns}`, crossorigin: true })),
            ...PREFETCH_DNS_LIST.map(dns => ({ hid: `${dns}-hid-dns`, rel: 'dns-prefetch', href: `//${dns}` })),
        ],
        pageType: undefined,
        pageId: undefined,
        pageTemplate: undefined,
        saute: null,
    };
}
