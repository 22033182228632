<template>
    <div
        :class="{ 'current-market-wrapper--selected': storeSelected }"
        class="current-market-wrapper"
    >
        <MarketInfoBox v-if="storeInfo" />
        <SelectMarketButton
            v-else
            bem-modifiers="pick-shop"
        />
    </div>
</template>


<script>
import { createNamespacedHelpers } from 'vuex';
import SelectMarketButton from '~/components/common/Button/SelectMarketButton';
import MarketInfoBox from '~/components/common/MarketInfoBox';

const { mapState } = createNamespacedHelpers('markets');

export default {
    name: 'PickShop',
    components: {
        SelectMarketButton,
        MarketInfoBox,
    },
    computed: {
        ...mapState({
            storeSelected: state => state.storeSelected,
            storeInfo: state => state.storeInfo,
        }),
    },
};
</script>

<style lang="scss">
.current-market-wrapper {

    margin: 0;
    display: inline-block;
    bottom: 0;

    &--selected {
        background-color: $color-castoWhite;
        font-family: $goodHomeFontFamily;
        width: initial;
        bottom: 8px;
        position: relative;
        text-align: left;
        max-width: 180px;
        cursor: pointer;
    }

    p {
        font-size: 12px;
        font-family: $goodHomeFontFamily;
        margin: 0;
    }
}

@media (min-width: $mf-breakpoint-small-desktop) {
    .current-market-wrapper {
        cursor: pointer;
        margin-bottom: 4px;

        p {
            font-size: 14px;
        }
    }
}

@media (min-width: $mf-breakpoint-big-desktop) {
    .current-market-wrapper {
        &--selected {
            max-width: 225px;
        }
    }
}
</style>
