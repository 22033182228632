import axios from 'axios';
import priceNormalizeData from '~/assets/services/price/normalizeData';
import normalizeProduct from '~/assets/services/category/normalizeData/product';
import { ADD_TO_CART_URL } from './api.config';

export default async function ({ setId, productsSet }) {
    const { data } = await axios.get(ADD_TO_CART_URL, {
        params: {
            noHtml: 1,
            rule_index_id: setId,
            related_product: productsSet.map(({ productId, minQty }) => `${productId}_${minQty}`).join(','),
        },
    });

    if (data && data.items && data.items.length) {
        data.items = data.items.map(item => priceNormalizeData(item));
    }

    if (data && data.recommended && data.recommended.length) {
        data.recommended = data.recommended
            .map(singleProduct => normalizeProduct(priceNormalizeData(singleProduct)));
    }

    return data;
}
