export default function (searchCriteriaExtendedParams, compressBy) {
    const filtersCondidered = [];
    const compressedFilters = [];
    searchCriteriaExtendedParams.forEach((searchCriteriaExtendedParam) => {
        if (!filtersCondidered.includes(searchCriteriaExtendedParam[compressBy])) {
            const compressedValue = searchCriteriaExtendedParams
                .filter(searchCriteriaParam => searchCriteriaParam[compressBy] === searchCriteriaExtendedParam[compressBy])
                .reduce((prevValue, currValue) => ({ ...prevValue, value: `${prevValue.value}__${currValue.value}` }));
            compressedFilters.push(compressedValue);
            filtersCondidered.push(searchCriteriaExtendedParam[compressBy]);
        }
    });
    return compressedFilters;
}
