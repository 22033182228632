<template>
    <div class="product-image-name-block">
        <BaseLink
            :href="url"
            class="product-image-name-block__image-wrapper"
            :title="title"
        >
            <BaseImage
                class="product-image-name-block__image"
                :bem-modifiers="['fit-to-width']"
                :data="{src: img, alt: title}"
            />
        </BaseLink>
        <BaseLink
            class="product-image-name-block__name"
            :href="url"
            :title="title"
        >
            {{ title }}
        </BaseLink>
        <BaseLink
            class="button btn-blue product-image-name-block__check-it"
            :href="url"
            text="Sprawdź"
        />
    </div>
</template>

<script>
import BaseLink from '~/components/common/Link/BaseLink';
import BaseImage from '~/components/common/Image/BaseImage';

export default {
    name: 'RecentlyBlockCategories',
    components: {
        BaseLink,
        BaseImage,
    },
    props: {
        url: {
            type: String,
            required: true,
        },
        img: {
            type: String,
            required: true,
        },
        title: {
            type: String,
            required: true,
        },
    },
};
</script>

<style lang="scss">
    .product-image-name-block {
        background-color: $color-castoWhite;
        max-width: 175px;
        width: 100%;

        &__image-wrapper {
            display: flex;
            height: 175px;
            justify-content: center;
            align-content: center;
            overflow: hidden;
        }

        &__name {
            color: $color-slate100;
            font: $font-weight-bold 14px/20px $goodHomeFontFamily;
            height: 78px;
            margin: 0 auto;
            text-decoration: none;
            overflow: hidden;
            padding: 4px 10px 4px;
            display: flex;
            text-align: center;
            align-items: center;
            justify-content: center;

            &:hover {
                text-decoration: underline;
            }
        }

        &__check-it {
            width: 100%;
            font-size: 16px;
            text-align: center;
            text-decoration: none;
        }
    }

    @media screen and (min-width: $breakpoint-tablet) {
        .product-image-name-block {
            max-width: 190px;
        }
    }

    @media screen and (min-width: $breakpoint-small-desktop) {
        .product-image-name-block {
            max-width: 200px;

            &__image-wrapper {
                height: 200px;
            }
        }
    }
</style>
