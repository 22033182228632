<template>
    <Component
        :is="componentInstance"
        v-bind="optionalProps"
        v-on="optionalListeners"
    />
</template>

<script>
import { mapState } from 'vuex';

export default {
    name: 'GlobalPopups',
    computed: {
        ...mapState({
            componentInstance: state => state.popup.instance,
            optionalProps: state => state.popup.optionalProps,
            optionalListeners: state => state.popup.optionalListeners,
        }),
    },
};
</script>
