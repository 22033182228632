import { GLOBAL_VARS } from '~/config/index';

export const types = {
    SET_LINKS: 'SET_LINKS',
};

const mutations = {
    [types.SET_LINKS](state, links) {
        state.links = [
            GLOBAL_VARS.BASE_LINK,
            ...links,
        ];
    },
};

export default mutations;
