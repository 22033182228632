import comparePageConfig from '~/assets/config/pages/compare';
import { getParamArray } from '~/assets/services/url';
import pushState from '~/assets/services/windowHistory/';

export default function (sku) {
    const paramArray = getParamArray(comparePageConfig.queryKey.paramName);
    const itemArray = paramArray[0].split(comparePageConfig.queryKey.separator);

    const indexOfSku = itemArray.indexOf(sku);
    if (indexOfSku !== -1) {
        itemArray.splice(indexOfSku, 1);
    }
    const relativePath = window.location.pathname;
    const { paramName, separator } = comparePageConfig.queryKey;

    pushState(
        itemArray,
        comparePageConfig.siteTitle,
        `${relativePath}?${paramName}=${itemArray.join(separator)}`,
    );
}
