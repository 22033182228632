<template>
    <section>
        <template v-if="displayed">
            <WishlistMonit />
            <ProductsListWishlistHeader v-if="items.length" />
            <EmptyListWishlistHeader v-else-if="!needFetch" />
        </template>
    </section>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';

const {
    mapState: mapStateWishlist,
} = createNamespacedHelpers('wishlist');
export default {
    name: 'PopupWishlistHeader',
    components: {
        WishlistMonit: () => import('~/components/common/Wishlist/WishlistMonit' /* webpackChunkName: "wishlist/monit-wishlist" */),
        EmptyListWishlistHeader: () => import('./EmptyListWishlistHeader' /* webpackChunkName: "popup/empty-list-wishlist-header" */),
        ProductsListWishlistHeader: () => import('./ProductsListWishlistHeader' /* webpackChunkName: "popup/products-list-wishlist-header" */),
    },
    props: {
        displayed: {
            type: Boolean,
            required: true,
        },
    },
    computed: {
        ...mapStateWishlist({
            items: state => state.items,
            wishlistItemsCount: state => state.itemsCount,
            needFetch: state => state.needFetch,
        }),
    },
};
</script>
