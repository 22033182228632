export const testCodes = {
    enabled: 1,
    disabled: 0,
    notAvailable: -1,
};

const dataForTest = {
    key: 'localStorage',
    value: 'true',
};
let currentCode;
if (typeof localStorage !== 'undefined') {
    try {
        localStorage.setItem(dataForTest.key, dataForTest.value);
        if (localStorage.getItem(dataForTest.key) === dataForTest.value) {
            localStorage.removeItem(dataForTest.key);
            currentCode = testCodes.enabled;
        } else {
            throw new Error('disabled');
        }
    } catch (e) {
        currentCode = testCodes.disabled;
    }
}

const code = currentCode || testCodes.notAvailable;

export default code;
