import getFloatFromPriceString from '~/src/utils/helpers/getFloatFromPriceString';
import getConvertedParams from './getConvertedParams';
import getTransformedUnit from './getTransformedUnit';
import salesUnitsConfig from '~/src/utils/saleUnits/config';
import {
    productsInSet as normalizeProductsInSet,
    shippingMethods as normalizeShippingMethods,
    shippingMethodsCodes as normalizeShippingMethodsCodes,
} from '~/assets/services/product/normalizeData';
import shippingMethodCode from '~/src/config/dictionary/shippingMethods/shippingMethodsCodes';

const { PICKUP: shippingMethodCodePickup } = shippingMethodCode;

export default function (productData) {
    const { only_in_store: onlyInStore, productId, shippingMethods = {}, ...product } = { ...productData };
    const { price: sourcePrice } = product;
    const priceValue = getFloatFromPriceString(sourcePrice);

    const {
        conversion,
        conversion_unit: conversionUnit,
        sales_units_change: salesUnitsChange,
        is_special: isSpecial,
        special_price: oldPrice,
        price_unit: priceUnit = salesUnitsConfig.default[0],
    } = product.attrData || {};

    const price = {
        value: priceValue,
        unit: getTransformedUnit(priceUnit),
    };

    if (isSpecial) {
        price.isInPromo = true;
    }

    if (parseFloat(oldPrice) > parseFloat(price.value)) {
        price.oldValue = getFloatFromPriceString(oldPrice);
    }

    if (conversionUnit && conversion && priceValue) {
        price.conversionInfo = getConvertedParams({ ...price, conversion, conversionUnit });

        if (salesUnitsChange) {
            price.convertedData = getConvertedParams({ ...price, conversion, conversionUnit });
        }
    }

    // @todo this prop should be inside productData
    if (onlyInStore) {
        product.onlyInStore = onlyInStore;
    }

    // @todo this prop should not be in price/stock request
    const shippingMethodsWithPickupAsDefault = {
        [shippingMethodCodePickup]: true,
        ...shippingMethods,
    };
    product.shippingMethods = normalizeShippingMethods(normalizeShippingMethodsCodes(shippingMethodsWithPickupAsDefault));

    product.productId = parseInt(productId, 10);

    if (product.related_products) {
        product.productsInSet = normalizeProductsInSet(product.related_products);
        delete product.related_products;
    }

    if (product.type) {
        product.type = product.type.toUpperCase();
    }

    product.price = price;
    return product;
}
