import { normalizeReviewsData } from '~/assets/services/review/normalizeData';
import isDateBetween from '~/assets/services/helpers/date/isDateBetween';
import normalizeLabelData from '~/assets/services/productLabel/normalizeData';
import { getNumberFromString } from '~/assets/utils/number';
import dictionaryPriceCurrency from '~/src/config/dictionary/priceCurrency';

function getCategoryName(categoryPath) {
    return categoryPath && categoryPath.length > 0
        ? categoryPath[categoryPath.length - 1].name
        : '';
}

function normalizeVariants(variants) {
    const variantsArray = [];

    if (variants) {
        Object.keys(variants).forEach((variant) => {
            variantsArray.push(variants[variant]);
        });
    }

    return variantsArray;
}

function normalizeProducts(items) {
    try {
        return [...items].map((associatedItem) => {
            const { id, sku, name, title, url, image, unit, energy_value: energyClass } = associatedItem;
            return {
                sku,
                url,
                image,
                unit,
                energyClass,
                name: title || name,
                productId: parseInt(id, 10),
            };
        });
    } catch {
        return [];
    }
}

// @todo remove
function normalizePromotions(promotions) {
    if (promotions) {
        return promotions.map((promotion) => {
            let { stores } = promotion;
            if (Array.isArray(stores)) {
                stores = stores.map(storeId => `${storeId}`);
            }
            return {
                ...promotion,
                stores,
                sets: promotion.sets.map((set) => {
                    const products = set.products.map(({
                        id: productId,
                        category: categoryName,
                        minQty,
                        savings,
                        ...restProductData
                    }, itemIndex) => (
                        {
                            ...restProductData,
                            categoryName,
                            savingSubtotal: savings,
                            productId: parseInt(productId, 10),
                            minQty: parseInt(minQty, 10),
                            itemId: `${set.indexId}-${itemIndex}`,
                        }
                    ));
                    return {
                        ...set,
                        products,
                        indexId: parseInt(set.indexId, 10),
                    };
                }),
            };
        });
    }
    return [];
}

// @todo remove
function normalizeRegularProductsSet(sets) {
    if (sets) {
        return sets.map(({ products }) => ({
            products: products.map(({ id: productId, qty: minQty, brand_data: brandData, category, ...product }) => ({
                ...product,
                minQty,
                productId: parseInt(productId, 10),
                brandName: brandData.name,
                categoryName: category.name,
            })),
        }));
    }
    return [];
}

export default function ({
    sku,
    name,
    benefits,
    description,
    visibility,
    image,
    pictograms,
    variants,
    inspirations,
    thumbnail,
    consultant,
    attributes,
    energy_class: energyClass,
    promotions: promotionsProductsSet,
    buy_in_set: regularProductsSet,
    gallery: media,
    reviews: reviewsData,
    is_excise: hasExciseDuties,
    brand_data: brand,
    entity_id: productId,
    type_id: type,
    default_price: defaultPriceValue,
    price_unit: defaultPriceUnit,
    price_currency: priceCurrency = dictionaryPriceCurrency.pl,
    short_description: shortDescription,
    info_boxes: promotionInfo,
    attachments: files,
    free_delivery: freeDelivery,
    small_image: smallImage,
    url_path: urlPath,
    product_tree: parentsTree,
    associated_products: productsInSet,
    category_path: categoryPath,
    also_bought: otherAlsoBought,
    series_products: otherInSeries,
    label_product_catalog: catalogLabel,
    label_product_date_from: catalogLabelActiveFrom,
    label_product_date_to: catalogLabelActiveUntil,
    tax_rate: vatRate,
    related_products: relatedProducts,
    min_saleable_amount: minOrderQty,
    qty_per_package: qtyPerPackage,
}) {
    const productData = {
        type,
        sku,
        name,
        brand,
        visibility,
        files,
        categoryPath,
        smallImage,
        image,
        benefits,
        freeDelivery,
        pictograms,
        catalogLabel,
        catalogLabelActiveFrom,
        catalogLabelActiveUntil,
        promotionInfo,
        relatedProducts,
        media,
        hasExciseDuties,
        vatRate,
        inspirations,
        thumbnail,
        consultant,
        priceCurrency,
        qtyPerPackage,
        attributes,
        energyClass,
        regularProductsSet: normalizeRegularProductsSet(regularProductsSet),
        promotionsProductsSet: normalizePromotions(promotionsProductsSet),
        productId: parseInt(productId, 10),
        defaultPrice: {
            value: defaultPriceValue,
            unit: defaultPriceUnit,
        },
        minOrderQty: getNumberFromString(minOrderQty) || 0,
        shortDescription: shortDescription || '',
        description: description || '',
        product_tree: parentsTree,
        categoryName: getCategoryName(categoryPath),
        url: urlPath,
        productsInSet: normalizeProducts(productsInSet || []),
        reviewsData: normalizeReviewsData(reviewsData || {}),
        variants: normalizeVariants(variants),
    };

    if (otherAlsoBought) {
        productData.otherAlsoBought = normalizeProducts(otherAlsoBought);
    }

    if (relatedProducts) {
        productData.relatedProducts = relatedProducts.map(product => ({
            ...product,
            productId: parseInt(product.id, 10),
        }));
    }

    if (otherInSeries && otherInSeries.items) {
        productData.otherInSeries = normalizeProducts(otherInSeries.items);
        if (otherInSeries.url) {
            productData.seriesUrl = otherInSeries.url;
        }
    }

    if (catalogLabel && catalogLabelActiveFrom && catalogLabelActiveUntil) {
        if (isDateBetween({ from: catalogLabelActiveFrom, to: catalogLabelActiveUntil, check: new Date() })) {
            const [firstLabel] = normalizeLabelData(catalogLabel);
            productData.labelId = firstLabel;
        }
    }

    if (categoryPath) {
        const categoryTreeArray = categoryPath.map((category) => {
            const { id, name: text, url } = category;
            const relativePath = url.replace(process.env.baseUrl, '');

            return {
                id,
                text,
                relativePath,
                url,
            };
        });

        productData.parentsTree = categoryTreeArray.filter(parent => parent.id !== productId);
    }

    return productData;
}
