import { createNamespacedHelpers, mapActions } from 'vuex';

const AddedToCartPopup = () => import('~/components/common/Popup/AddedToCart/AddedToCartPopup');

const { mapActions: mapActionsPopup } = createNamespacedHelpers('popup');
const { mapActions: mapActionsGlobalMessages } = createNamespacedHelpers('globalMessages');
const { mapActions: mapActionsCart } = createNamespacedHelpers('cart');

export default {
    methods: {
        ...mapActions(['registerRequest']),
        ...mapActionsPopup(['showPopup']),
        ...mapActionsGlobalMessages(['addMessage']),
        ...mapActionsCart(['addToCart', 'addSetToCart']),
        handleAddToCart(payload = {}) {
            const {
                productId,
                sku,
                name,
                groupedData,
                setId,
                productsSet,
                source = 'productListView',
                qty = 1,
            } = payload;
            let requestHandler;

            if (productId) {
                requestHandler = this.addToCart.bind(this, {
                    qty,
                    groupedData,
                    source,
                    id: productId,
                });
            }

            if (productsSet && productsSet.length) {
                requestHandler = this.addSetToCart.bind(this, {
                    productsSet,
                    setId,
                });
            }

            if (requestHandler) {
                this.registerRequest({
                    requestHandler,
                    successHandler: (data) => {
                        const {
                            success,
                            error,
                            recommended,
                            items: addedArray,
                        } = data;
                        if (success && !error && addedArray) {
                            this.showPopup({
                                instance: AddedToCartPopup,
                                optionalProps: {
                                    recommended,
                                    addedArray,
                                    sku,
                                },
                            });

                            return true;
                        }
                        this.addMessage({
                            texts: `Żądana ilość ${name} nie jest dostępna`,
                            type: 'warning',
                            hideButton: true,
                        });
                    },
                });
            }
        },
    },
};
