import { types } from './mutations';
import { checkAvailability } from '~/assets/services/product';
import { fetchAllMarketsInfo } from '~/assets/services/market';

export default {
    getAvailabilityInOtherStores(
        { commit, state },
        { province, sku, qty = 1 },
    ) {
        let responseType = null;
        return new Promise((resolve, reject) => {
            if (typeof state.availability[province] !== 'object') {
                commit(types.CREATE_NEW_PROVINCE, { province });
            }
            if (typeof state.availability[province][sku] !== 'object') {
                commit(types.CREATE_NEW_SKU, { province, sku });
                if (province !== 'all') {
                    responseType = checkAvailability(province, qty, sku);
                } else {
                    responseType = fetchAllMarketsInfo({ qty, sku });
                }
                responseType
                    .then((response) => {
                        if (
                            response !== undefined &&
                            response !== false
                        ) {
                            commit(types.FILL_AVAILABILITY, {
                                province,
                                sku,
                                response,
                            });
                            resolve(state.availability[province][sku]);
                        } else {
                            reject();
                        }
                    })
                    .catch((error) => {
                        reject(error);
                    });
            } else if (state.availability[province][sku] && state.availability[province][sku].length === 0) {
                resolve([]);
            } else {
                resolve(state.availability[province][sku]);
            }
        });
    },
    flushAvailability({ commit }) {
        commit(types.FLUSH_AVAILABILITY);
    },
};
