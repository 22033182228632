<template>
    <div
        class="footer-top"
        tabindex="0"
    >
        <SingleBlockMenu
            v-for="itemMenu in menuSections"
            :key="`menu-top-${itemMenu.name}`"
            v-bind="itemMenu"
            class="footer-top__section"
            :class="[`footer-top__section--${itemMenu.symbol}`, { 'expanded': itemMenu.isExpanded }]"
        />
    </div>
</template>

<script>
import SingleBlockMenu from '~/components/common/Footer/components/SingleBlockMenu';

export default {
    name: 'MenuTop',
    components: {
        SingleBlockMenu,
    },
    props: {
        menuSections: {
            type: Array,
            required: true,
        },
    },
};
</script>

<style lang="scss">
    @import '~/assets/css/sprites/footer-desktop.scss';

    .footer-top {
        display: flex;
        width: 100%;
        outline: none;

        &__section {
            transition-property: max-height;
            transition-duration: 1s;
            transition-timing-function: linear;
            padding: 20px;
            min-height: 200px;
            margin: 0 12px;
            width: calc(25% - 24px);
            position: relative;

            &--sklepy {
                @include colored-icon('shops', $color-slate);
                background-position: right top;
                background-color: $color-slate100-dark;
                background-size: 95px 152px;
            }

            &--jak_kupowac {
                @include colored-icon('how-to-shop', $color-slate);
                background-position: right top;
                background-color: $color-slate100-dark;
                background-size: 102px 143px;
            }

            &--obsluga_klienta {
                @include colored-icon('client-service', $color-slate);
                background-position: right top;
                background-color: $color-slate100-dark;
                background-size: 81px 146px;
            }

            &--uslugi {
                @include colored-icon('services', $color-slate);
                background-position: right top;
                background-color: $color-slate100-dark;
                background-size: 100px 151px;
            }

            .disabled-list {
                width: 100%;
                line-height: 21px;
                color: $color-slate50-dark;
            }
        }
    }

    @media (max-width: $breakpoint-tablet) {
        .footer-top {
            margin: 0;
            outline: none;
            flex-direction: column;

            & &__section {
                background: $color-slate100-dark;
                width: 100%;
                max-height: 49px;
                overflow: hidden;
                padding: 0;
                margin: 0 0 5px 0;
                min-height: 0;
                cursor: pointer;

                &.expanded {
                    max-height: 350px;

                    .block-single__title {
                        background-color: $color-slate-dark;

                        &::after {
                            background-position: 99.31051344743274% 67.29066666666667%;
                            height: 2px;
                            margin-top: 8px;
                        }
                    }
                }

                .block-single__title {
                    background-color: $color-slate100-dark;
                    margin: 0;
                    padding: 15px 10px 15px 55px;

                    &::before {
                        content: '';
                        display: block;
                        position: absolute;
                        width: 30px;
                        height: 30px;
                        left: 20px;
                        top: 10px;
                    }

                    &::after {
                        content: '';
                        display: inline-block;
                        float: right;
                        background: url('~static/images/icons/icon-sprite-mobile-e8441b9b.svg') no-repeat;
                        background-position: 20.330073349633253% 55.176308539944905%;
                        width: 16px;
                        height: 14px;
                        margin-top: 2px;
                    }
                }

                &--sklepy {
                    .block-single__title::before {
                        background: url('~static/images/footer/m_shops.svg')
                        no-repeat;
                    }
                }

                &--jak_kupowac {
                    .block-single__title::before {
                        background: url('~static/images/footer/m_how-to-shop.svg')
                        no-repeat;
                    }
                }

                &--obsluga_klienta {
                    .block-single__title::before {
                        background: url('~static/images/footer/m_services.svg')
                        no-repeat;
                    }
                }

                &--uslugi {
                    .block-single__title::before {
                        background: url('~static/images/footer/m_client-service.svg')
                        no-repeat;
                    }
                }

                .base-link,
                .disabled-list {
                    background-color: $color-slate100-dark;
                    padding-left: 50px;
                    font: $font-weight-regular 13px/30px $goodHomeFontFamily;
                }
            }
        }
    }

    @media screen and (min-width: $mf-breakpoint-small-desktop) {
        .footer-top {
            .block-single__title {
                font: $font-weight-bold 20px/20px $goodHomeFontFamily;
            }
        }
    }
</style>
