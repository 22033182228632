const ID_BY_NAMES = {
    OUR_CLIENTS_RECOMMENDED: 529456,
    CHEAPER_IN_SET: 529455,
    LIMITED_OFFER: 529451,
    LOWEST_PRICE: 529458,
    NEWNESS: 530753,
    LAST_ITEMS: 529454,
    BUY_3_FOR_2: 529453,
    ONLY_IN_CASTO: 529452,
    FREE_DELIVERY: 529457,
    ALWAYS_LOWEST_PRICE: 529663,
    SPECIAL_OFFER: 531045,
};

const LABELS_BY_ID = {
    [ID_BY_NAMES.SPECIAL_OFFER]: 'oferta specjalna',
    [ID_BY_NAMES.LIMITED_OFFER]: 'oferta limitowana',
    [ID_BY_NAMES.LOWEST_PRICE]: 'najniższa cena',
    [ID_BY_NAMES.LAST_ITEMS]: 'ostatnie sztuki',
    [ID_BY_NAMES.BUY_3_FOR_2]: 'kup 3',
    [ID_BY_NAMES.ONLY_IN_CASTO]: 'tylko w castoramie',
    [ID_BY_NAMES.NEWNESS]: 'nowość',
    [ID_BY_NAMES.OUR_CLIENTS_RECOMMENDED]: 'nasi klienci',
    [ID_BY_NAMES.CHEAPER_IN_SET]: 'w zestawie taniej',
    [ID_BY_NAMES.FREE_DELIVERY]: 'darmowa dostawa',
    [ID_BY_NAMES.ALWAYS_LOWEST_PRICE]: 'zawsze niskie ceny',
};

const SYMBOLS_BY_ID = {
    [ID_BY_NAMES.SPECIAL_OFFER]: 'special-offer',
    [ID_BY_NAMES.LIMITED_OFFER]: 'limited-offer',
    [ID_BY_NAMES.LOWEST_PRICE]: 'lowest-price',
    [ID_BY_NAMES.LAST_ITEMS]: 'last-items',
    [ID_BY_NAMES.BUY_3_FOR_2]: 'buy-three-for-two',
    [ID_BY_NAMES.ONLY_IN_CASTO]: 'only-in-castorama',
    [ID_BY_NAMES.NEWNESS]: 'newness',
    [ID_BY_NAMES.OUR_CLIENTS_RECOMMENDED]: 'our-clients-recommended',
    [ID_BY_NAMES.CHEAPER_IN_SET]: 'cheaper-in-set',
    [ID_BY_NAMES.FREE_DELIVERY]: 'free-delivery',
    [ID_BY_NAMES.ALWAYS_LOWEST_PRICE]: 'always-lowest-price',
};

const LINKS_BY_ID = {
    [ID_BY_NAMES.SPECIAL_OFFER]: '/result/?promotion=oferta+specjalna',
    [ID_BY_NAMES.LIMITED_OFFER]: '/result/?promotion=oferta+limitowana',
    [ID_BY_NAMES.LOWEST_PRICE]: '/result/?promotion=najnizsza+cena',
    [ID_BY_NAMES.LAST_ITEMS]: '/result/?promotion=ostatnie+sztuki',
    [ID_BY_NAMES.BUY_3_FOR_2]: '/result/?promotion=kup+3+w+cenie+2',
    [ID_BY_NAMES.ONLY_IN_CASTO]: '/result/?promotion=tylko+w+castoramie',
    [ID_BY_NAMES.NEWNESS]: '/result/?promotion=nowosc',
    [ID_BY_NAMES.OUR_CLIENTS_RECOMMENDED]: '/result/?promotion=nasi+klienci+polecaja',
    [ID_BY_NAMES.CHEAPER_IN_SET]: '/result/?promotion=w+zestawie+taniej',
    [ID_BY_NAMES.FREE_DELIVERY]: '/result/?promotion=darmowa+dostawa',
    [ID_BY_NAMES.ALWAYS_LOWEST_PRICE]: '/result/?promotion=zawsze+niskie+ceny',
};

const ATTRIBUTE_FIELD_NAME_DATE_FROM = 'label_product_date_from';
const ATTRIBUTE_FIELD_NAME_DATE_TO = 'label_product_date_to';
const CSS_CLASS_NAME = 'product-label';
const NORMALIZE_DATA_CONFIG = {
    labelsIdsSeparator: ',',
};

export {
    ID_BY_NAMES,
    LABELS_BY_ID,
    LINKS_BY_ID,
    SYMBOLS_BY_ID,
    ATTRIBUTE_FIELD_NAME_DATE_FROM,
    ATTRIBUTE_FIELD_NAME_DATE_TO,
    CSS_CLASS_NAME,
    NORMALIZE_DATA_CONFIG,
};
