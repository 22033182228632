export const types = {
    SET_RECENTLY_CATEGORIES: 'SET_RECENTLY_CATEGORIES',
};

const mutations = {
    [types.SET_RECENTLY_CATEGORIES](state, categories) {
        state.categories = [...categories];
    },
};

export default mutations;
