<template>
    <label
        :key="`compare-${productId}`"
        class="add-to-compare-button"
        :class="getBemModifiers()"
        :for="`compare-${productId}`"
    >
        <input
            :id="`compare-${productId}`"
            v-model="comparedButtonChecked"
            type="checkbox"
            class="add-to-compare-button__checkbox"
            @click="compareActionInvoke(comparedButtonChecked)"
        >
        <span class="add-to-compare-button__text-wrapper add-to-compare-button__text-wrapper--static">
            Porównaj
        </span>
        <span class="add-to-compare-button__text-wrapper">
            {{ compareButtonLabel }}
        </span>
    </label>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';
import ProductToCompare from '~/components/common/Popup/ProductToCompare';

const { mapActions: mapActionsPopup } = createNamespacedHelpers('popup');
const {
    mapState: mapStateCompare,
    mapActions: mapActionsCompare,
} = createNamespacedHelpers('compare');

export default {
    name: 'AddToCompareButton',
    props: {
        sku: {
            type: [String, Number],
            required: true,
        },
        productId: {
            type: Number,
            required: true,
        },
        image: {
            type: String,
            required: true,
        },
        name: {
            type: String,
            required: true,
        },
        addText: {
            type: String,
            default: 'Dodaj do porównania',
        },
        removeText: {
            type: String,
            default: 'Usuń z porównania',
        },
    },
    data() {
        return {
            comparedButtonChecked: false,
        };
    },
    computed: {
        ...mapStateCompare(['globalItems']),
        comparedState() {
            return this.globalItems.some(item => item.id === this.productId);
        },
        compareButtonLabel() {
            return this.comparedState ? this.removeText : this.addText;
        },
    },
    watch: {
        comparedState() {
            this.comparedButtonChecked = this.comparedState;
        },
        comparedButtonChecked() {
            if (!this.comparedState) {
                this.$nextTick(() => {
                    this.comparedButtonChecked = false;
                });
            }
        },
    },
    mounted() {
        this.comparedButtonChecked = this.comparedState;
    },
    methods: {
        ...mapActionsPopup(['showPopup']),
        ...mapActionsCompare(['addItem', 'removeItem', 'fetchCompareProducts']),
        async compareActionInvoke(compareState) {
            const { sku, productId: id, image, name } = this;
            if (compareState) {
                this.removeItem({ sku, id, isUrlModified: false });
            } else {
                await this.addItem({ sku, id, image, name });
            }
            this.fetchCompareProducts();
            this.showPopup({
                instance: ProductToCompare,
            });
        },
    },
};
</script>

<style lang="scss">
    @import '~/assets/css/sprites/checkbox.scss';

    .add-to-compare-button {
        $self: &;

        float: none;
        width: 100%;
        display: block;
        text-align: center;
        line-height: 1;
        padding: 10px 0;
        position: relative;
        cursor: pointer;
        color: $color-slate70;
        font: $font-weight-regular 12px/18px $goodHomeFontFamily;
        overflow: hidden;

        &__checkbox {
            display: none;

            &:hover ~ #{$self}__text-wrapper {
                text-decoration: underline;

                &::before {
                    @include spriteCheckbox('checkbox-gray-active');
                    background-size: 16px 16px;
                }
            }

            &:checked ~ #{$self}__text-wrapper::before {
                @include spriteCheckbox('checkbox-gray-selected');
            }
        }

        &__text-wrapper {
            width: 100%;
            display: inline-block;
            float: right;

            &::before {
                width: 19px;
                height: 18px;
                position: absolute;
                content: '';
                display: inline-block;
                left: 0;
                @include spriteCheckbox('checkbox-gray');
                background-size: 16px 16px;
            }

            &--static {
                display: none;
            }
        }

        &--blue {
            #{$self}__checkbox {
                &:hover ~ #{$self}__text-wrapper::before {
                    @include spriteCheckbox('checkbox-blue-active');
                }

                &:checked ~ #{$self}__text-wrapper::before {
                    @include spriteCheckbox('checkbox-blue-selected');
                }
            }

            #{$self}__text-wrapper {
                color: $color-castoBlue;
                text-indent: 25px;

                &::before {
                    width: 16px;
                    height: 16px;
                    @include spriteCheckbox('checkbox-blue');
                    background-size: 16px 16px;
                }
            }
        }

        &--text, &--product-tail {
            #{$self}__text-wrapper {
                text-indent: 0;

                &::before {
                    display: none;
                }
            }
        }

        &--product-tail {
            #{$self}__text-wrapper {
                display: none;

                &--static {
                    display: block;
                }
            }
        }
    }

    @media only screen and (min-width: $mf-breakpoint-tablet) {
        .add-to-compare-button {
            $self: &;

            width: auto;
            display: inline-block;
            padding: 0;

            &--product-tail {
                float: right;

                #{$self}__text-wrapper {
                    display: none;

                    &--static {
                        text-indent: 25px;
                        display: block;

                        &::before {
                            display: block;
                        }
                    }
                }
            }

            &--text &__text-wrapper:not(&__text-wrapper--static) {
                display: inline-block;

                &::before {
                    display: none;
                }
            }
        }
    }
</style>
