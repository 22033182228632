<template>
    <BaseButton
        :name="text"
        :bem-modifiers="['horizontally-centered']"
        @click.native="logout"
    >
        {{ text }}
    </BaseButton>
</template>

<script>
import { createNamespacedHelpers, mapActions } from 'vuex';
import BaseButton from '~/components/common/Button/BaseButton';

const { mapActions: mapActionsCustomer } = createNamespacedHelpers('customer');

export default {
    name: 'LogoutButton',
    components: { BaseButton },
    props: {
        text: {
            type: String,
            default: 'Wyloguj',
        },
    },
    methods: {
        ...mapActionsCustomer(['logoutUser']),
        ...mapActions(['registerRequest']),
        logout() {
            this.registerRequest({
                forceGlobal: true,
                requestHandler: this.logoutUser,
            });
        },
    },
};
</script>
