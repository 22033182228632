var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{ref:"wrapper",staticClass:"catalog-menu",class:{ 'visible-menu': _vm.isHovering && _vm.sizesCalculated }},[_c('span',{staticClass:"catalog-menu--main-title",on:{"mouseout":_vm.hideMenu,"mouseenter":_vm.showMenu}},[_vm._v("\n        Produkty\n    ")]),_vm._v(" "),(_vm.wasInDOM)?_c('nav',{staticClass:"catalog-menu__content",style:({
            left: ("-" + _vm.marginLeft + "px")
        })},[_c('ul',{staticClass:"category-main",on:{"mouseout":_vm.hideMenu}},[_vm._l((_vm.catalogTree),function(category){
        var _obj;
return _c('li',{key:category.symbol,class:[
                    { 'category-main__level0': category.list },
                    ( _obj = {}, _obj[("category-main__level0--category--" + (category.symbol))] = category.list, _obj ),
                    { 'no-expand': !category.list },
                    { 'visible-level': category.symbol === _vm.hoveredItem.symbol } ],style:([{
                    width: _vm.marginLeft + 'px',
                    paddingLeft: _vm.linksPadding + 'px',
                }, !category.list ? { 'background-image': ("url(" + (category.thumbnail) + ")") } : {}]),on:{"mouseover":function($event){return _vm.setHoveredItem(category)}}},[_c('NuxtLink',{staticClass:"category-main__level0--title",class:{ 'extra-positions': !category.list || !category.list.length },attrs:{"to":category.url.replace(_vm.baseUrl, ''),"title":category.name},nativeOn:{"click":function($event){return _vm.hideMenu($event)}}},[_c('span',{class:("category-main__level0--title--" + (category.symbol))},[_vm._v("\n                        "+_vm._s(category.name)+"\n                    ")])]),_vm._v(" "),(!category.list)?_c('LinkByMode',{staticClass:"category-main__level0--link",attrs:{"url":category.url.replace(_vm.baseUrl, ''),"title":"Zobacz ofertę"}},[_vm._v("\n                    Zobacz ofertę >\n                ")]):_vm._e()],1)}),_vm._v(" "),(_vm.newspaperData)?_c('CatalogMenuNewspaper',{staticClass:"no-expand",style:({
                    width: _vm.marginLeft + 'px',
                    paddingLeft: _vm.linksPadding + 'px',
                }),on:{"mouseover":_vm.showMenu,"mouseout":_vm.hideMenu}}):_vm._e()],2),_vm._v(" "),_c('CatalogMenuList',{style:({
                left: (_vm.marginLeft + "px"),
                width: ("calc(100% - " + _vm.marginLeft + "px)"),
                paddingRight: (_vm.linksPadding + "px"),
            }),attrs:{"list":_vm.hoveredItem.list},on:{"mouseover":_vm.showMenu,"mouseout":_vm.hideMenu,"changeRoute":_vm.hideMenu}})],1):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }