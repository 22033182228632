import detectMobileBrowser from '~/assets/services/userAgent/detectMobileBrowser';

export default function ({ store, req }) {
    if (process.server) {
        const isMobile = process.static || !req.headers['user-agent'] ? false : detectMobileBrowser(req.headers['user-agent']);
        store.commit('windowOptions/SET_MOBILE_DEVICE', isMobile);
        store.dispatch('windowOptions/setMobileState', isMobile);
        store.dispatch('windowOptions/setTabletState', !isMobile);
        store.dispatch('windowOptions/setDesktopState', !isMobile);
    }
}
