<template>
    <div class="arrow-right-fishe">
        <!-- @slot Use this slot for content in arrow right fishe -->
        <slot />
    </div>
</template>

<script>
export default {
    name: 'FisheArrowRight',
};
</script>

<style lang="scss">
    $mobileSize: 16px;
    $desktopSize: 24px;

    .arrow-right-fishe {
        background: $color-slate20;
        position: relative;
        min-height: $mobileSize;
        margin-right: 16px;

        &::after {
            position: absolute;
            width: 0;
            height: 0;
            top: 0;
            right: -$mobileSize/2;
            content: '';
            display: block;
            border-top: $mobileSize/2 solid transparent;
            border-bottom: $mobileSize/2 solid transparent;
            border-left: $mobileSize/2 solid $color-slate20;
        }
    }

    @media (min-width: $mf-breakpoint-tablet) {
        .arrow-right-fishe {
            min-height: $desktopSize;
            margin-right: $desktopSize;

            &::after  {
                right: -$desktopSize/2;
                border-top-width: $desktopSize/2;
                border-bottom-width: $desktopSize/2;
                border-left-width: $desktopSize/2;
            }
        }
    }
</style>
