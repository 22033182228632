import axios from 'axios';

function isTimeExpired(time) {
    if (Number.isInteger(time)) {
        return new Date().getTime() > time;
    }

    return true;
}

class CacheWrapper {
    constructor(config = {}) {
        this.requestHandler = config.requestHandler || axios;
        this.config = config;
        this.storageKey = null;
        this.storageMethodName = 'localStorage';
        this.storageHandler = null;
        this.timestamp = 60;
        this.tillSpecifiedHourNextDay = false;
        this.storageKeysName = 'cache_keys';

        this.init();
    }

    init() {
        this.storageKey = this.config.url;

        if (this.config.storageMethodName) {
            this.storageMethodName = this.config.storageMethodName;
            delete this.config.storageMethodName;
        }

        if (this.config.storageKey) {
            this.storageKey = this.config.storageKey;
            delete this.config.storageKey;
        }

        if (this.config.timestamp) {
            this.timestamp = this.config.timestamp;
            delete this.config.timestamp;
        }

        if (typeof this.config.tillSpecifiedHourNextDay !== 'undefined') {
            if (this.config.tillSpecifiedHourNextDay >= 0 && this.config.tillSpecifiedHourNextDay <= 24) {
                this.tillSpecifiedHourNextDay = this.config.tillSpecifiedHourNextDay;
            }

            delete this.config.tillSpecifiedHourNextDay;
        }

        this.setStorageMethod();
    }

    getStorageHandler() {
        switch (this.storageMethodName) {
            case 'localStorage':
            case 'sessionStorage':
                return window[this.storageMethodName];
            default:
                return null;
        }
    }

    setStorageMethod() {
        this.storageHandler = this.getStorageHandler();
    }

    getValue(useCache = true) {
        return new Promise((resolve, reject) => {
            const storageValue = useCache ? this.getStorageValue(this.storageKey) : false;

            if (this.storageHandler && storageValue && useCache) resolve(storageValue);
            else {
                this.requestHandler(this.config)
                    .then(({ data }) => {
                        if (useCache && this.storageHandler) {
                            this.setStorageValue(data);
                            this.setStorageTimestamp();
                        }

                        resolve(data);
                    })
                    .catch(error => reject(error));
            }
        });
    }

    setStorageTimestamp() {
        const storageKeys = this.getStorageKeys(this.storageKeysName) || {};

        const expireDateTillSpecifiedHourNextDay = new Date();
        const expireDateTimestamp = new Date();
        let timestampTillSpecifiedHourNextDay = 0;
        let timestamp = 0;

        if (this.tillSpecifiedHourNextDay || this.tillSpecifiedHourNextDay === 0) {
            expireDateTillSpecifiedHourNextDay.setTime(expireDateTillSpecifiedHourNextDay.getTime() + 24 * 60 * 60 * 1000);
            expireDateTillSpecifiedHourNextDay.setHours(this.tillSpecifiedHourNextDay, 0, 0, 0);
            timestampTillSpecifiedHourNextDay = expireDateTillSpecifiedHourNextDay.getTime();
        }

        if (this.timestamp) {
            timestamp = expireDateTimestamp.getTime() + this.timestamp * 60 * 1000;
        }

        storageKeys[this.storageKey] = this.tillSpecifiedHourNextDay
            ? Math.min(timestampTillSpecifiedHourNextDay, timestamp) : timestamp;

        this.storageHandler.setItem(this.storageKeysName, JSON.stringify(storageKeys));
    }

    getStorageKeys() {
        if (!this.storageHandler) {
            return false;
        }

        const storageKeys = this.storageHandler.getItem(this.storageKeysName);

        return storageKeys ? JSON.parse(this.storageHandler.getItem(this.storageKeysName)) : false;
    }

    getStorageKey(value) {
        return this.getStorageKeys()[value];
    }

    setStorageValue(value) {
        this.storageHandler.setItem(this.storageKey, JSON.stringify(value));
    }

    getStorageValue(name) {
        if (!this.storageHandler) {
            return false;
        }

        const value = this.storageHandler.getItem(name);
        const ifExpired = isTimeExpired(
            this.getStorageKey(this.storageKey),
        );

        if (value && !ifExpired) {
            return JSON.parse(value);
        }

        return false;
    }

    clearExpiredKeys() {
        if (!this.storageHandler) {
            return false;
        }

        let storageKeys = this.storageHandler.getItem(this.storageKeysName);

        if (storageKeys) {
            storageKeys = JSON.parse(storageKeys);
            Object.keys(storageKeys).forEach((key) => {
                if (isTimeExpired(storageKeys[key])) {
                    delete storageKeys[key];
                    this.storageHandler.removeItem(key);
                }
            });

            if (Object.keys(storageKeys).length === 0 && storageKeys.constructor === Object) {
                this.storageHandler.removeItem(this.storageKeysName);
            } else {
                this.storageHandler.setItem(this.storageKeysName, JSON.stringify(storageKeys));
            }
        }

        return false;
    }
}

export default CacheWrapper;
