<template>
    <span
        class="price-value"
        :class="getBemModifiers()"
    >
        <template v-if="Number.isInteger(value)">
            {{ value }}
        </template>
        <template v-else>
            {{ integerPart }},<sub class="price-value__decimal">{{ decimalPart }}</sub>
        </template>
    </span>
</template>

<script>

export default {
    name: 'PriceValue',
    props: {
        /**
         * Product price value
         */
        value: {
            type: Number,
            required: true,
        },
    },
    computed: {
        /**
         * Returns integer part of product price
         * @returns {number}
         */
        integerPart() {
            return Math.floor(this.value);
        },
        /**
         * Returns decimal part of product price
         * @returns {string}
         */
        decimalPart() {
            let decimalValue = `${this.value}`.split('.')[1] || '00';
            if (decimalValue.length === 1) {
                decimalValue = `${decimalValue * 10}`;
            }
            return decimalValue.slice(0, 2);
        },
    },
};
</script>

<style lang="scss">
    .price-value {
        $self: &;

        font-family: $goodHomeFontFamily;

        &__decimal {
            vertical-align: baseline;
            padding-left: 2px;
            font-size: 11px;
            line-height: 15px;
            font-weight: $font-weight-bold;
        }

        &--product-page-price-box {
            font-size: 30px;
            line-height: 36px;

            #{$self}__decimal {
                font-size: 14px;
                line-height: 18px;
                padding-left: 0;
            }
        }

        &--product-page-price-box#{$self}--price-old-value {
            font-size: 18px;
            line-height: 18px;

            #{$self}__decimal {
                font-size: 12px;
            }
        }

        &--flat {
            font-size: inherit;
            font-weight: $font-weight-regular;

            #{$self}__decimal {
                font-size: inherit;
                font-weight: $font-weight-regular;
                padding-left: 0;
            }
        }

        &--bold {
            font-weight: $font-weight-bold;

             #{$self}__decimal {
                 font-weight: $font-weight-bold;
             }
         }

        &--grid-order {
            font-weight: $font-weight-bold;
            color: $color-slate;
            font-size: 18px;
            line-height: 18px;

            #{$self}__decimal {
                font-weight: $font-weight-bold;
                color: $color-slate;
                font-size: 18px;
                line-height: 18px;
            }
        }
    }

    @media (min-width: $mf-breakpoint-tablet) {
        .price-value__decimal {
            line-height: inherit;
            font-size: 12px;
        }
    }
</style>
