import categoryData from './categoryData';
import { deletePolishChar } from '~/assets/services/helper';

function sortByLevel(categoryA, categoryB) {
    if (categoryA.level === categoryB.level) {
        return 0;
    }

    return categoryA.level > categoryB.level ? 1 : -1;
}

function item(data) {
    const itemData = categoryData(data);
    const { items, url } = data;
    const relativePath = url.replace(process.env.baseUrl, '');
    const symbol = (deletePolishChar(itemData.name).replace(' ', '_')).replace(', ', '__');
    return {
        ...itemData,
        symbol,
        items,
        relativePath,
    };
}

function hierarchy(data) {
    const sortedByLevel = data.sort(sortByLevel);
    const menuData = sortedByLevel.map(dataItem => ({ ...item(dataItem), items: [] }));
    const sortedMenuData = [];
    const lowestLevel = menuData[0].level;
    const rootItems = menuData.filter(dataItem => dataItem.level === lowestLevel);

    rootItems.forEach((menuItem) => {
        const items = menuData.filter(childItem => childItem.path.indexOf(`${menuItem.path}/`) >= 0);
        sortedMenuData.push({
            ...menuItem,
            items,
        });
    });

    return sortedMenuData;
}

export { item, hierarchy };
