<template>
    <div class="mobile-header-wrapper">
        <div class="mobile-header-wrapper__container container">
            <div class="row">
                <div class="col-7 logo-container">
                    <Logo class="logo" />
                </div>
                <div class="col-5">
                    <div class="row">
                        <div class="col-6 cart-header-mobile">
                            <CartMobile />
                        </div>
                        <div class="col-6 menu-mobile">
                            <BurgerMenuButton
                                :catalog-tree="catalogTree"
                                :inspirations-tree="inspirationsTree"
                                :forum-tree="forumTree"
                            />
                        </div>
                    </div>
                </div>
                <div class="col-12 search-node">
                    <SearchBar type="mobile" />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import BurgerMenuButton from '~/components/common/Button/BurgerMenuButton';
import CartMobile from './CartMobile';
import Logo from '~/components/common/Header/Logo';
import SearchBar from '~/components/common/SearchBar';

export default {
    name: 'MobileHeader',
    components: {
        Logo,
        SearchBar,
        BurgerMenuButton,
        CartMobile,
    },
    props: {
        catalogTree: {
            type: Array,
            default: () => [],
        },
        inspirationsTree: {
            type: Array,
            default: () => [],
        },
        forumTree: {
            type: Array,
            default: () => [],
        },
    },
};
</script>

<style lang="scss">
.mobile-header-wrapper {
    border-top: 1px solid $color-slate20;

    &__container.container {
        width: initial;
        min-width: 0;
        padding: 0 15px;
    }

    .header-wrapper {
        &__form {
            position: relative;

            .button {
                position: absolute;
                top: -17px;
                right: 0;
                background: url('~static/images/icons/search-blue.svg') 50% 50% no-repeat;
                width: 49px;
                height: 45px;
                padding: 0;
                display: block;
            }
        }
    }

    .logo-container {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .logo {
        height: auto;
        max-width: calc(100% + 6px);
    }

    .cart-header-mobile {
        border-left: 1px solid $color-slate20;
        text-align: center;
        padding: 0;
    }

    .menu-mobile {
        padding: 0;
        text-align: center;
        border-left: 1px solid $color-slate20;
        position: relative;
        top: 2px;
    }

    .search-node {
        padding: 12px 24px;
        background: $color-slate10;
    }
}
@media screen and (max-width: $breakpoint-tablet) {
    .mobile-header-wrapper {
        .logo {
            height: auto;
            max-width: calc(100% + 6px);
            margin: 2px -3px 0 -3px;
            position: relative;
            display: block;

            .logo-image {
                position: relative;
            }
        }
    }
}
</style>
