export default {
    computed: {
        /**
         * Returns price object to use
         * @returns {Object} priceObject
         * @returns {number} priceObject.value
         * @returns {string} priceObject.unit
         * @returns {number} priceObject.oldPrice
         * @returns {boolean} priceObject.isInPromo
         * @returns {Object} priceObject.convertedData
         */
        priceToDisplay() {
            const { price: fullPriceData = {} } = this;
            const { convertedData, ...price } = fullPriceData;
            return {
                ...(convertedData || price),
            };
        },
    },
};
