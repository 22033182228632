import { getSpecialFiltersNames } from '~/assets/services/category';

export default function ({ parentsTree, name, meta, containSpecialFilters, activeFilters }) {
    if (meta && meta.title) {
        return meta.title;
    }

    const titleParts = [name];
    const specialFiltersNames = getSpecialFiltersNames(activeFilters);

    if (containSpecialFilters && specialFiltersNames) {
        titleParts[0] = `${titleParts[0]} ${getSpecialFiltersNames(activeFilters)}`;
        if (parentsTree && parentsTree.length) {
            titleParts.push(parentsTree[parentsTree.length - 1].text);
        }
        titleParts.push('Castorama');
    } else if (parentsTree && parentsTree.length) {
        titleParts.push([...parentsTree].reverse().map(parent => parent.text));
    }

    return titleParts.join(' - ');
}
