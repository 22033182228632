const middleware = {}

middleware['canonicalCategory'] = require('../middleware/canonicalCategory.js')
middleware['canonicalCategory'] = middleware['canonicalCategory'].default || middleware['canonicalCategory']

middleware['categoryContainSpecialFilters'] = require('../middleware/categoryContainSpecialFilters.js')
middleware['categoryContainSpecialFilters'] = middleware['categoryContainSpecialFilters'].default || middleware['categoryContainSpecialFilters']

middleware['categoryShouldSsr'] = require('../middleware/categoryShouldSsr.js')
middleware['categoryShouldSsr'] = middleware['categoryShouldSsr'].default || middleware['categoryShouldSsr']

middleware['levelPageUrl'] = require('../middleware/levelPageUrl.js')
middleware['levelPageUrl'] = middleware['levelPageUrl'].default || middleware['levelPageUrl']

middleware['setCacheHeader10min'] = require('../middleware/setCacheHeader10min.js')
middleware['setCacheHeader10min'] = middleware['setCacheHeader10min'].default || middleware['setCacheHeader10min']

middleware['setModeByUrl'] = require('../middleware/setModeByUrl.js')
middleware['setModeByUrl'] = middleware['setModeByUrl'].default || middleware['setModeByUrl']

middleware['setRouteData'] = require('../middleware/setRouteData.js')
middleware['setRouteData'] = middleware['setRouteData'].default || middleware['setRouteData']

middleware['userAgent'] = require('../middleware/userAgent.js')
middleware['userAgent'] = middleware['userAgent'].default || middleware['userAgent']

middleware['xMagentoTagsByPageState'] = require('../middleware/xMagentoTagsByPageState.js')
middleware['xMagentoTagsByPageState'] = middleware['xMagentoTagsByPageState'].default || middleware['xMagentoTagsByPageState']

middleware['xMagentoTagsNamedRoute'] = require('../middleware/xMagentoTagsNamedRoute.js')
middleware['xMagentoTagsNamedRoute'] = middleware['xMagentoTagsNamedRoute'].default || middleware['xMagentoTagsNamedRoute']

export default middleware
