<template>
    <section>
        <ProductListCartHeader v-if="items.length" />
        <EmptyListCartHeader v-else-if="!needFetch" />
        <CastoramaDefaultPreloader v-else />
    </section>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';
import CastoramaDefaultPreloader from '~/components/common/Loader/components/CastoramaDefaultPreloader';

const {
    mapState: mapStateCart,
} = createNamespacedHelpers('cart');
export default {
    name: 'PopupCartHeader',
    components: {
        CastoramaDefaultPreloader,
        ProductListCartHeader: () => import('./ProductListCartHeader' /* webpackChunkName: "popup/product-list-cart-header" */),
        EmptyListCartHeader: () => import('./EmptyListCartHeader' /* webpackChunkName: "popup/empty-list-cart-header" */),
    },
    computed: {
        ...mapStateCart({
            items: state => state.items,
            needFetch: state => state.needFetch,
        }),
    },
};
</script>
