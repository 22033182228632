<template>
    <div class="add-to-cart-or-wishlist-with-qty">
        <BaseNumberInput
            v-if="isInputShown && !isGrouped"
            :id="inputId"
            :current-value="qtyForAction"
            :allow-only-multiple="true"
            :only-greater-than-zero="true"
            class="add-to-cart-or-wishlist-with-qty__input"
            @input-changed="inputChanged"
        />
        <Component
            :is="buttonTypeInstance"
            :bem-modifiers="[...bemModifiers, 'horizontally-centered']"
            class="add-to-cart-or-wishlist-with-qty__button"
            v-bind="dataForButtonByInstance"
            :class="classByActionType"
        />
    </div>
</template>

<script>
import { types as PRODUCT_TYPES } from '~/assets/services/product/config';
import BaseNumberInput from '~/components/common/Input/BaseNumberInput';
import AddToWishlistButton from '~/components/common/Wishlist/AddToWishlistButton';
import AddToCartButton from '~/components/common/Cart/AddToCartButton';

export default {
    name: 'AddToCartOrWishlistWithQty',
    components: {
        BaseNumberInput,
        AddToWishlistButton,
        AddToCartButton,
    },
    props: {
        groupedData: {
            type: Array,
            default: () => [],
        },
        productType: {
            type: String,
            default: '',
        },
        productId: {
            type: Number,
            required: true,
        },
        name: {
            type: String,
            default: '',
        },
        isSalePossible: {
            type: Boolean,
            required: true,
        },
        qtyToAdd: {
            type: Number,
            default: 1,
        },
        addToCartLabel: {
            type: String,
            default: 'Do koszyka',
        },
        price: {
            type: Object,
            default: () => ({}),
        },
        isAddToCartQtyEnable: {
            type: Boolean,
            default: false,
        },
        isAddToWishlistQtyEnable: {
            type: Boolean,
            default: false,
        },
        inputId: {
            type: String,
            default: '',
        },
        sku: {
            type: [String, Number],
            default: undefined,
        },
    },
    data() {
        return {
            isGrouped: this.productType === PRODUCT_TYPES.GROUPED,
            basicLabels: {
                cart: 'Do koszyka',
                wishlist: 'Dodaj do listy',
            },
            qtyForAction: this.qtyToAdd,
        };
    },
    computed: {
        groupData() {
            return this.productType === PRODUCT_TYPES.GROUPED && this.groupedData && this.groupedData.length ? this.groupedData : null;
        },
        buttonTypeInstance() {
            return this.isSalePossible ? AddToCartButton : AddToWishlistButton;
        },
        isInputShown() {
            return this.isSalePossible ? this.isAddToCartQtyEnable : this.isAddToWishlistQtyEnable;
        },
        classByActionType() {
            return this.isSalePossible ? 'cart-type' : 'wishlist-type';
        },
        labelByActionType() {
            if (!this.isInputShown) {
                return this.isSalePossible ? this.addToCartLabel : this.basicLabels.wishlist;
            }
            return this.isSalePossible ? this.basicLabels.cart : this.basicLabels.wishlist;
        },
        dataForButtonByInstance() {
            const { qtyForAction: qty } = this;
            const { sku, productId, name } = this.$props;
            const label = this.labelByActionType;
            const groupedData = this.groupData;

            if (this.isSalePossible) {
                return {
                    sku,
                    productId,
                    name,
                    label,
                    groupedData,
                    qty,
                };
            }
            const { price } = this.$props;
            const { value: priceValue } = price;

            return {
                sku,
                productId,
                name,
                label,
                groupedData,
                qty,
                price: priceValue,
            };
        },
    },
    watch: {
        qtyToAdd() {
            this.qtyForAction = this.qtyToAdd;
        },
    },
    methods: {
        inputChanged(val) {
            const value = parseFloat(val);
            this.$emit('qty-changed', value);
            this.qtyForAction = value;
        },
    },
};
</script>

<style lang="scss">
    .add-to-cart-or-wishlist-with-qty {
        display: flex;
        flex-direction: row;

        &__input {
            background: $color-castoWhite;
            max-width: 48px;
            text-align: center;
            vertical-align: middle;
            border: 2px solid $color-slate20;
            font-weight: bold;
            color: $color-slate70-dark;
            width: 48px;
            line-height: 0;
            padding: 14px 0;
            margin-right: 5px;
        }

        &__button {
            flex: 1 1;
        }
    }

    @media screen and (max-width: $breakpoint-tablet) {
        .add-to-cart-or-wishlist-with-qty__input {
            margin-right: 13px;
        }
    }
</style>
