import { types } from './mutations';

export default {
    setData({ commit }, data = {}) {
        const {
            santanderEnabled,
            santanderMinPrice,
            santanderLandingPageUrl,
            santanderInstallmentsNumber,
            santanderShopNumber,
            santanderDateStart,
            santanderDateEnd,
        } = data;

        commit(types.SET_IS_ENABLED, santanderEnabled);
        commit(types.SET_MIN_PRICE, santanderMinPrice);
        commit(types.SET_URL, santanderLandingPageUrl);
        commit(types.SET_INSTALLMENTS_NUMBER, santanderInstallmentsNumber);
        commit(types.SET_SHOP_NUMBER, santanderShopNumber);
        commit(types.SET_DATE_START, santanderDateStart);
        commit(types.SET_DATE_END, santanderDateEnd);
    },
};
