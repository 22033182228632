import axios from 'axios';
import 'url-search-params-polyfill';

import { CUSTOMER_SIGN_IN_URL } from './api.config';

export default function ({ username, password, formKey }) {
    const search = new URLSearchParams({
        'login[username]': username,
        'login[password]': password,
        form_key: formKey,
    });
    return axios({
        method: 'post',
        url: CUSTOMER_SIGN_IN_URL,
        data: search.toString(),
    });
}
