<template>
    <div
        class="category-product-tail"
    >
        <p>
            Kategoria:
        </p>
        <BaseLink :href="categoryUrl">
            {{ categoryName }}
        </BaseLink>
    </div>
</template>

<script>
import BaseLink from '~/components/common/Link/BaseLink';

export default {
    name: 'CategoryProductTail',
    components: {
        BaseLink,
    },
    props: {
        categoryUrl: {
            required: true,
            type: String,
        },
        categoryName: {
            required: true,
            type: String,
        },
    },
};
</script>

<style lang="scss">
.category-product-tail {
    height: 64px;
    text-align: left;
    font: $font-weight-regular 12px/14px $goodHomeFontFamily;
    padding: 0 12px;
    margin: 10px 0;

    p {
        display: inline-block;
    }

    a {
        color: $color-castoBlue;
    }
}
</style>
