export default {
    catalogs: `${process.env.baseUrl}/gazetka-i-katalogi`,
    contact: `${process.env.baseUrl}/kontakt`,
    checkoutOnepage: `${process.env.baseUrl}/checkout/onepage`,
    checkoutCart: `${process.env.baseUrl}/checkout/cart`,
    wishlist: `${process.env.baseUrl}/wishlist`,
    printWishlist: `${process.env.baseUrl}/wishlist/index/print`,
    shareWishlist: `${process.env.baseUrl}/wishlist/index/share`,
    help: `${process.env.baseUrl}/pomoc`,
    subscribeNewsletter: `${process.env.baseUrl}/newsletter/subscriber/new/`,
    orderWithDelivery: `${process.env.baseUrl}/zamow-z-dostawa/`,
    castoramaTerms: `${process.env.baseUrl}/regulaminwww`,
    cookiesPolicy: `${process.env.baseUrl}/pliki-cookies`,
    privacyPolicy: `${process.env.baseUrl}/polityka-prywatnosci`,
    getMoreDetailsAboutProcessingOfPersonalData: `${process.env.baseUrl}/przetwarzanie-danych-osobowych`,
    printProductPage: `${process.env.baseUrl}/catalog/product/print/id/{id}/`,
    socialMedia: {
        fb: 'https://www.facebook.com/CastoramaPolska',
        instagram: 'https://www.instagram.com/castoramapolska/',
        yt: 'https://www.youtube.com/user/CastoramaPolska',
        linkedin: 'https://pl.linkedin.com/company/castorama-polska-sp-z-o-o-',
    },
};
