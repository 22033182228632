import CookieUniversal from 'cookie-universal';
import cookieConfig from '~/assets/services/storages/cookies/cookie.config';

const cookies = new CookieUniversal();
const maxAge = 60 * 60 * 24 * 30 * 12;
const cookieNames = {
    shop: 'selected_shop',
    store: 'selected_shop_store_view',
};

export function getSelectedShopIds() {
    const storeId = cookies.get(cookieNames.store);
    const shopId = cookies.get(cookieNames.shop);
    if (shopId && storeId) {
        return {
            shopId,
            storeId,
        };
    }
    return {};
}

export function setShopIds({ storeId: newStoreId, shopId: newShopId }) {
    const {
        storeId: currentStoreId,
        shopId: currentShopId,
    } = getSelectedShopIds();
    if (newStoreId !== currentStoreId || newShopId !== currentShopId) {
        const cookieOptions = { ...cookieConfig.defaultOptions, maxAge };
        cookies.setAll([
            { name: cookieNames.shop, value: newShopId, opts: cookieOptions },
            { name: cookieNames.store, value: newStoreId, opts: cookieOptions },
        ]);
    }
}

export function getDefaultShopIds() {
    return {
        shopId: null,
        storeId: 'default',
    };
}

export function setDefaultShopIds() {
    setShopIds(getDefaultShopIds());
}

export function isShopSelected({ storeId, shopId }) {
    const {
        storeId: defaultStoreId,
        shopId: defaultShopId,
    } = getDefaultShopIds();

    return (
        storeId &&
        shopId &&
        storeId !== defaultStoreId &&
        shopId !== defaultShopId
    );
}

export function isDefaultShopSelected() {
    const { storeId, shopId } = getSelectedShopIds();
    const {
        storeId: defaultStoreId,
        shopId: defaultShopId,
    } = getDefaultShopIds();

    return storeId === defaultStoreId && shopId === defaultShopId;
}
