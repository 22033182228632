<template>
    <div
        v-if="recentlyCategoriesToView.length"
        class="recently-viewed-categories"
    >
        <div class="container">
            <p class="recently-viewed-categories__title">
                Ostatnio oglądane kategorie
            </p>
            <div class="recently-viewed-categories__box">
                <CustomCarousel
                    :per-page-custom="[
                        [0, 2.2],
                        [768, 3],
                        [991, 4],
                        [1200, 5],
                    ]"
                    :pagination-enabled="false"
                    type-navigation="isCustomNavigation"
                    :navigation-enabled="false"
                >
                    <!--eslint-disable-next-line-->
                    <slide
                        v-for="(recentlyCategory, index) in recentlyCategoriesToView"
                        :key="index"
                        class="recently-viewed-categories__box-item-wrapper"
                    >
                        <RecentlyBlockCategories
                            class="recently-viewed-categories__box--item"
                            :img="recentlyCategory.image"
                            :title="recentlyCategory.title"
                            :url="recentlyCategory.url"
                        />
                    </slide>
                </CustomCarousel>
            </div>
        </div>
    </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';
import { getRecentlyViewedCategories } from '~/assets/services/storages/local-storage/category';
import RecentlyBlockCategories from '~/components/common/Category/components/RecentlyBlockCategories';
import CustomCarousel from '~/components/common/Carousel/CustomCarousel';

const { mapState: mapStateCategory } = createNamespacedHelpers('category');
const { mapState: mapStateRecentlyCategories } = createNamespacedHelpers('recentlyCategories');

export default {
    name: 'RecentlyViewedCategories',
    components: {
        RecentlyBlockCategories,
        CustomCarousel,
    },
    data() {
        return {
            recentlyInStorage: getRecentlyViewedCategories(),
        };
    },
    computed: {
        ...mapStateCategory({
            currentCategoryId: state => state.currentCategoryId,
            name: state => state.name,
        }),
        ...mapStateRecentlyCategories({
            recentlyCategories: state => state.categories,
        }),
        recentlyCategoriesToView() {
            let withoutCurrentCategory;
            if (this.currentCategoryId === null) {
                withoutCurrentCategory = this.recentlyCategories;
            } else {
                withoutCurrentCategory = this.recentlyCategories.filter(({ id }) => id !== this.currentCategoryId);
            }
            return withoutCurrentCategory.slice(0, 5);
        },
    },
    beforeCreate() {
        this.$store.dispatch('recentlyCategories/initializeCategories');
    },
};
</script>

<style lang="scss">
    .recently-viewed-categories {
        @include centralize();
        padding: 48px 18px;
        background: $color-slate5;

        .navigation__forward {
            display: block;
        }

        .VueCarousel-slide {
            padding: 0 12px 0 0;
        }

        &__title {
            font: $font-weight-bold 24px/24px $goodHomeFontFamily;
            color: $color-slate100;
            margin-top: 0;
            padding-top: 0;
            padding-bottom: 0;
            margin-bottom: 36px;
        }

        &__box {
            &--item {
                display: inline-block;
            }
        }
    }

    @media (min-width: $mf-breakpoint-tablet) and (max-width: $mf-breakpoint-small-desktop) {
        .recently-viewed-categories {
            &__box,
            &__title {
                width: 90%;
                margin: 0 auto;
            }

            &__title {
                margin-bottom: 36px;
            }
        }
    }

    @media screen and (max-width: $mf-breakpoint-tablet) {
        .recently-viewed-categories {
            padding: 19px #{$spacing-default};
            width: calc(100% + #{$spacing-default});

            &__title {
                font-size: 20px;
                margin-bottom: 19px;
            }

            .navigation__forward {
                display: none;
            }
        }
    }
</style>
