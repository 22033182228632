const articleLimit = 7;
const templateLevelLimit = 3;
const pageType = 'category';

const categoryLevel3Config = {
    articleLimit,
    maxFilterValuesShown: 3,
};

export {
    pageType,
    templateLevelLimit,
    categoryLevel3Config,
};
