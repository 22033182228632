import eventProductClick from './events/eventProductClick';

export default function (data) {
    return new Promise((resolve, reject) => {
        const { brand, category, id, name, position, price, sku, list } = data;
        eventProductClick.ecommerce.click.products.push({
            brand,
            category,
            id,
            name,
            position,
            price,
            sku,
            list,
        });
        if (window.dataLayer) {
            window.dataLayer.push(eventProductClick);
            eventProductClick.eventCallback = () => { resolve(); };
            resolve();
        } else {
            reject();
        }
    });
}
