<template>
    <p
        class="market-info-box__main-title"
        v-on="$listeners"
    >
        {{ storeInfo.name }}
    </p>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';

const { mapState } = createNamespacedHelpers('markets');
export default {
    name: 'MarketInfoBoxTitle',
    computed: {
        ...mapState({
            storeInfo: state => state.storeInfo,
        }),
    },
};
</script>

<style lang="scss">
    @import '~/assets/css/sprites/header-desktop.scss';

    .market-info-box__main-title {
        padding: 8px 24px;
        margin: 0;
        color: $color-castoBlue;
        font: $font-weight-regular 12px/24px $goodHomeFontFamily;
        position: relative;

        &::before {
            @include spriteHeaderDesktop('localization-black', false, true);
            background-size: 12px 16px;
            height: 18px;
            position: absolute;
            content: '';
            left: 4px;
            top: 12px;
            width: 13px;
         }
        &::after {
            @include spriteHeaderDesktop('arrow-black-bottom', false, true);
            background-size: 7px 4px;
             width: 9px;
             height: 6px;
             position: absolute;
             content: '';
             right: 8px;
             top: 18px;
         }
    }

    @media screen and (max-width: $breakpoint-big-desktop) {
        .market-info-box__main-title {
            font-size: 10px;
        }
    }
</style>
