export default {
    event: 'addToCart',
    GAaction: 'Dodanie produktu do koszyka',
    GAcategory: 'Koszyk',
    ecommerce: {
        add: {
            products: [],
        },
        currencyCode: 'PLN',
        impressions: [],
    },
};
