<template>
    <div
        class="cart-header"
        @mouseenter="getCartAndUpdate"
    >
        <BaseLink
            :href="pagesUrl.checkoutCart"
            :title="cartItemsCount | transformQuantityWithoutQty('Product')"
            :class="{ bluetify: cartItemsCount }"
            class="cart-header--bottom-link-cart"
        >
            <span class="counter">
                {{ cartItemsCount }}
            </span>
            {{ cartItemsCount | transformQuantityWithoutQty('Product') }}
        </BaseLink>
        <PopupCartHeader
            v-if="wasHovered"
            ref="cartHeader"
            :class="{ 'cart-header__popup--empty': !(items.length > 0), 'cart-header__popup--loading': needFetch}"
            class="cart-header__popup"
        />
    </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';
import '~/assets/filters/numeralsHelper';
import pagesUrl from '~/assets/services/cmsPagesUrl';
import PopupCartHeader from '~/components/common/Header/CartHeader/component/PopupCartHeader';
import BaseLink from '~/components/common/Link/BaseLink';

const { mapActions: mapActionsLoader } = createNamespacedHelpers('loader');
const {
    mapActions: mapActionsCart,
    mapState: mapStateCart,
} = createNamespacedHelpers('cart');

export default {
    name: 'CartHeader',
    components: {
        BaseLink,
        PopupCartHeader,
    },
    data() {
        return {
            pagesUrl,
            isFetchPending: false,
            wasHovered: false,
        };
    },
    computed: {
        ...mapStateCart({
            items: state => state.items,
            cartItemsCount: state => state.itemsCount,
            needFetch: state => state.needFetch,
        }),
    },
    methods: {
        ...mapActionsCart(['fetchData']),
        ...mapActionsLoader(['showLoader', 'hideLoader']),
        getCartAndUpdate() {
            this.wasHovered = true;
            if (this.needFetch && !this.isFetchPending) {
                this.$nextTick(() => {
                    this.showLoader({
                        instance: this.$refs.cartHeader.$el,
                        type: 'CastoramaDefaultPreloader',
                    });
                });
                this.isFetchPending = true;
                this.fetchData().finally(() => {
                    this.hideLoader();
                    this.isFetchPending = false;
                });
            }
        },
    },
};
</script>

<style lang="scss">
    @import '~/assets/css/sprites/header-desktop.scss';

    .cart-header {
        background-color: $color-castoWhite;
        font-family: $goodHomeFontFamily;
        height: 50px;
        width: 100%;
        position: relative;
        cursor: pointer;
        color: $color-castoBlue;

        .bluetify {
            color: $color-castoBlue;
        }

        &:hover {
            .cart-header--bottom-link-cart {
                color: $color-castoWhite;
                background-color: $color-castoBlue;
            }
        }

        &:hover .bluetify {
            color: $color-castoWhite;
        }

        &:hover .cart-header__popup {
            display: block;
        }

        &:after {
            content: '';
            @include spriteHeaderDesktop('cart-blue', false, true);
            background-size: 100% 100%;
            width: 22px;
            height: 19px;
            top: 9px;
            right: 14px;
            position: absolute;
        }

        &:hover:after {
            @include spriteHeaderDesktop('cart-white', false, true);
        }

        &--bottom-link-cart {
            width: 100%;
            display: inline-block;
            padding: 14px 31px 23px 16px;
            font-size: 15px;
            text-decoration: none;
            color: $color-castoBlue;
            font-weight: 300;
            text-transform: capitalize;

            &:hover {
                text-decoration: none;
            }
        }

        &__popup {
            border-color: $color-castoBlue;
            border-style: solid;
            border-width: 3px 3px 3px 3px;
            padding: 6px;
            color: $color-slate100;
            display: none;
            background-color: $color-castoWhite;
            position: absolute;
            min-width: 500px;
            right: 0;
            top: 50px;
            z-index: 500;

            &--loading {
                height: 200px;
            }

            &--empty {
                padding: 20px 0 50px 0;
                text-align: center;
            }
        }

        .bolder {
            font-weight: bold;
        }

        .counter {
            font-size: 15px;
        }
    }

    @media screen and (max-width: $breakpoint-big-desktop) {
        .cart-header {
            &--bottom-link-cart {
                font-size: 12px;
            }
        }
    }

    @media screen and (min-width: $breakpoint-small-desktop) and (max-width: $breakpoint-big-desktop) {
        .cart-header--bottom-link-cart .counter {
            font-size: 13px;
        }
    }

    @media screen and (max-width: $breakpoint-small-desktop) {
        .cart-header {
            &--bottom-link-cart {
                font-size: 15px;
            }
        }
    }
</style>
