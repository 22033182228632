export default function ({
    id,
    url,
    name,
    description,
    path,
    level,
    image,
    series,
    alternative_name: alternativeName,
    brand_description: brandDescription,
    meta_title: title,
    meta_description: metaDescription,
    background_image: backgroundImage,
    children_count: childrenCount,
    category_tree: parentsTree,
    product_count: productCount,
}) {
    const categoryData = {
        id,
        name,
        alternativeName,
        url,
        image,
        backgroundImage,
        description,
        level,
        childrenCount,
        path,
        productCount,
        series,
        brandDescription,
        meta: {
            title,
            description: metaDescription,
        },
    };

    let parentsIds = [];

    if (path) {
        parentsIds = path.split('/');
        const currentIdIndex = parentsIds.indexOf(id);
        if (currentIdIndex !== -1) {
            parentsIds.splice(currentIdIndex, 1);
        }
    }

    if (parentsIds.length) {
        categoryData.parentsIds = parentsIds;
    }

    if (parentsTree) {
        const parentsTreeArray = [];
        Object.keys(parentsTree).forEach((key) => {
            const { link, label: text } = parentsTree[key];
            const relativePath = link.replace(process.env.baseUrl, '');
            parentsTreeArray.push({
                url: link,
                relativePath,
                text,
                id: key.replace('category', ''),
            });
        });

        categoryData.parentsTree = parentsTreeArray.filter(parent => parent.id !== id);
    }

    return categoryData;
}
