export default function ({ products: productsToNormalize, ...restData }) {
    const products = {};
    Object.keys(productsToNormalize).forEach((productId) => {
        const {
            energy_level: ignore,
            energy_value: energyClass,
            ...restCurrentProductData
        } = productsToNormalize[productId];

        products[productId] = restCurrentProductData;

        if (energyClass) {
            products[productId].energyClass = energyClass;
        }
    });

    return {
        products,
        ...restData,
    };
}
