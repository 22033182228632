<template>
    <section class="click-to-action-product">
        <AvailableOnlyInStore
            v-if="onlyInStore"
            bem-modifiers="product-tail"
        />
        <ChooseShopButton
            v-else-if="chooseShopButtonShown"
            bem-modifiers="product-tail"
        />
        <AddToCartOrWishlistWithQty
            v-else
            v-bind="dataForAddToCartOrWishlist"
            :is-sale-possible="isSalePossible"
            bem-modifiers="line-height-big"
            add-to-cart-label="Do koszyka"
        />
        <div class="click-to-action-product__row">
            <AddToWishlistText
                v-if="!showedAddToWishlistButton"
                :product-id="productId"
                :name="name"
                :price="price.value"
            />
            <AddToCompareButton
                :class="[
                    { 'not-visible': type === typesConfig.GROUPED },
                    { 'only-compare': showedAddToWishlistButton }
                ]"
                bem-modifiers="product-tail"
                v-bind="dataForAddToCompare"
            />
        </div>
    </section>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';
import AddToCartOrWishlistWithQty from '~/components/common/Wrappers/AddToCartOrWishlistWithQty';
import AddToWishlistText from '~/components/common/Wishlist/AddToWishlistText';
import AddToCompareButton from '~/components/common/Button/AddToCompareButton';
import AvailableOnlyInStore from '~/components/AvailableOnlyInStore';
import { types as typesConfig } from '~/assets/services/product/config';
import ChooseShopButton from '~/components/common/Button/ChooseShopButton';
import isProductSalePossible from '~/assets/services/product/isProductSalePossible';
import requestStatusesDictionary from '~/src/config/dictionary/requestStatuses';

const { mapState: mapStateMarkets } = createNamespacedHelpers('markets');

export default {
    name: 'ClickToActionProductTail',
    components: {
        ChooseShopButton,
        AddToCartOrWishlistWithQty,
        AvailableOnlyInStore,
        AddToCompareButton,
        AddToWishlistText,
    },
    props: {
        productId: {
            type: Number,
            required: true,
        },
        isSalePossible: {
            type: Boolean,
            required: true,
        },
        name: {
            type: String,
            required: true,
        },
        sku: {
            type: [String, Number],
            required: true,
        },
        statuses: {
            type: Object,
            default: () => ({}),
        },
        image: {
            type: String,
            required: true,
        },
        type: {
            type: String,
            required: true,
        },
        onlyInStore: {
            type: Boolean,
            default: false,
        },
        availability: {
            type: String,
            default: 'default',
        },
        price: {
            type: Object,
            default: () => ({}),
        },
    },
    data() {
        return {
            qty: 1,
            typesConfig,
        };
    },
    computed: {
        ...mapStateMarkets({
            storeSelected: state => state.storeSelected,
        }),
        chooseShopButtonShown() {
            return (!this.statuses[requestStatusesDictionary.isResolved] || this.price.value) && !this.storeSelected && !this.isSalePossible;
        },
        showedAddToWishlistButton() {
            const { $props, storeSelected, onlyInStore } = this;
            return storeSelected && !onlyInStore && !isProductSalePossible({
                productData: $props,
                storeSelected,
            });
        },
        dataForAddToCartOrWishlist() {
            const { statuses, ...props } = this.$props;
            const { qty: qtyToAdd } = this;

            return { ...props, qtyToAdd };
        },
        dataForAddToCompare() {
            const { sku, productId, image, name } = this.$props;
            return { sku, productId, image, name };
        },
    },
};
</script>

<style lang="scss">
    .click-to-action-product {
        &__row {
            padding: 0 18px;
            overflow: hidden;

            .add-to-wishlist__wrapper {
                width: 100%;
            }

            .add-to-wishlist-text__button {
                font: 12px/18px $goodHomeFontFamily;
            }

            .not-visible {
                visibility: hidden;
            }

            .only-compare {
                margin-top: 41px;
            }
        }

        .add-to-wishlist-text__button {
            width: 100%;
            padding: 10px 0;
            margin: 10px 0 0 0;
            text-align: center;
            line-height: 1;
            color: $color-slate70;

            .image-base {
                display: none;
            }

            .add-to-wishlist-button {
                display: none;
            }
        }

        .button.add-to-wishlist-button {
            font-size: 17px;
            width: 100%;
        }
    }


    @media (min-width: $mf-breakpoint-tablet) {
        .click-to-action-product {
            &__row {
                .add-to-wishlist__wrapper {
                    width: auto;
                }

                .add-to-wishlist-text__button {
                    width: auto;
                    float: left;
                    margin: 0;
                    padding: 2px 0;
                }

                .add-to-compare {
                    float: right;
                }

                .only-compare {
                    margin-top: 0;
                }
            }

            .button.add-to-wishlist-button {
                font-size: 17px;
                margin: 0 18px 18px 18px;
            }
        }
    }
</style>
