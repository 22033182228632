export default function ({ element }) {
    let intersectionObserver = null;
    const checkingIsInViewport = new Promise((resolve) => {
        intersectionObserver = new IntersectionObserver((entries, observer) => {
            entries.forEach(async (entry) => {
                if (entry.intersectionRatio > 0) {
                    const { target } = entry;
                    resolve({ target });
                    observer.unobserve(element);
                }
            });
        });

        intersectionObserver.observe(element);
    });

    return { checkingIsInViewport, intersectionObserver };
}
