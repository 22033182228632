import { types } from './mutations';
import {
    addRecentlyViewedCategories,
    getRecentlyViewedCategories,
} from '~/assets/services/storages/local-storage/category';


export default {
    addCategory({ commit }, { id, url, title, image }) {
        addRecentlyViewedCategories({
            id,
            url,
            image,
            title,
        });
        commit(types.SET_RECENTLY_CATEGORIES, getRecentlyViewedCategories());
    },
    initializeCategories({ commit }) {
        commit(types.SET_RECENTLY_CATEGORIES, getRecentlyViewedCategories());
    },
};
