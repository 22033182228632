<template>
    <BaseButton
        class="add-to-wishlist-button"
        :bem-modifiers="[
            'background-casto-blue',
            'full-width',
            'font-size-small',
            'horizontally-centered',
            ...bemModifiers,
        ]"
        :name="label"
        @click.native="addToWishlist"
    >
        {{ label }}
    </BaseButton>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';
import BaseButton from '~/components/common/Button/BaseButton';

const { mapActions: mapActionsWishlist } = createNamespacedHelpers('wishlist');

export default {
    name: 'AddToWishlistButton',
    components: {
        BaseButton,
    },
    props: {
        label: {
            type: String,
            default: 'Dodaj do listy',
        },
        groupedData: {
            type: Array,
            default: null,
        },
        productId: {
            type: Number,
            required: true,
        },
        name: {
            type: String,
            default: '',
        },
        price: {
            type: [Number, String],
            default: 0,
        },
        qty: {
            type: Number,
            default: 1,
        },
    },
    methods: {
        ...mapActionsWishlist(['addItemToWishlist']),
        addToWishlist() {
            const { productId, name, price, qty, groupedData } = this;
            this.addItemToWishlist({
                productId,
                name,
                price,
                groupedData,
                qty,
                source: 'productListView',
            });
        },
    },
};
</script>

<style lang="scss">
    .add-to-wishlist-button {
        font: $font-weight-bold 16px/48px $goodHomeFontFamily;
        color: $color-castoWhite;
        box-sizing: border-box;
        border: none;
        outline: none;
        display: inline-block;
        text-decoration: none;
        cursor: pointer;
        background-color: $color-castoBlue;

        &:hover {
            background-color: $color-castoBlue;
        }
    }
</style>
