import priceCurrency from '~/src/config/dictionary/priceCurrency';
import commaToDot from '~/src/utils/stringTransform/commaToDot';

/**
 * Clear price string from currency, white space and return number (price value)
 *
 * @param {(string|number|undefined)} value, {Object} currency
 * @returns {number}
 */
export default function (value, currency = priceCurrency.pl) {
    const currencyPattern = new RegExp(`[${currency.default}|${currency.iso4217}]|\\s`, 'g');
    const integerPattern = /^-{0,1}\d+$/;
    const floatPattern = /^\d+\.\d+$/;

    if (value) {
        if (typeof value === 'string' && value.length) {
            let normalizedValue = value;
            normalizedValue = commaToDot(normalizedValue).replace(currencyPattern, '');

            if (normalizedValue.match(integerPattern) || normalizedValue.match(floatPattern)) {
                return Math.round(parseFloat(normalizedValue) * 100) / 100;
            }
        }

        if (String(value).match(integerPattern) || String(value).match(floatPattern)) {
            return value;
        }
    }

    return 0;
}
