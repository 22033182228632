import { normalizeReview } from '~/assets/services/review/normalizeData';
import restApiRequestFactory from '~/src/utils/helpers/restApiRequestFactory';
import { GET_REVIEWS_FOR_PRODUCT_URL as urlTemplate } from './api.config';

export default async function ({ productId, limit, offset }) {
    try {
        const { data: reviewItems = [] } = await restApiRequestFactory({
            urlTemplate,
            urlTemplateProps: {
                id: productId,
            },
            method: 'get',
            params: {
                limit,
                offset,
            },
        });
        return reviewItems.map(normalizeReview);
    } catch (message) {
        // @todo log errors
        return null;
    }
}
