import { observe } from 'rxjs-observe';
import _isEqual from 'lodash/isEqual';
import _uniqBy from 'lodash/uniqBy';
import requestStatusesDictionary from '~/src/config/dictionary/requestStatuses';

function PriceProviderPlugin(VueInstance, options = {}) {
    const {
        getPriceHandler,
        trigger,
        clearPriceBeforeRequest = false,
    } = options;

    let priceProviderIds = [];
    let watcherHandlers = null;
    let registeredInstances = [];
    let registeredRequestes = [];
    const registeredObservables = [];
    const {
        isPending: requestStatusPending,
        isRejected: requestStatusRejected,
        isResolved: requestStatusResolved,
    } = requestStatusesDictionary;
    const initialStatuses = {
        [requestStatusResolved]: false,
        [requestStatusRejected]: false,
        [requestStatusPending]: false,
    };

    if (!getPriceHandler) {
        throw new Error('You must implement getPriceHandler option');
    }

    /**
     * get collection by id
     *
     * @param productId {string|number}
     *
     * @returns {object}
     */
    const getCollectionById = productId => priceProviderIds
        .find(({ productId: _productId }) => _productId === parseInt(productId, 10));

    /**
     * get data collection by key
     *
     * @param productId {string|number}
     * @param key {string}
     *
     * @returns {object}
     */
    const getDataCollectionByKey = (productId, key) => (getCollectionById(productId) || {})[key];

    /**
     * set data collection by key
     *
     * @param productId {string|number}
     * @param key {string}
     * @param data {any}
     */
    const setDataCollectionByKey = (productId, key, data) => {
        const idCollection = getCollectionById(productId);

        if (idCollection) {
            idCollection[key] = data;
        }
    };

    /**
     * set all ids globally as not fetched
     */
    const clearAll = () => {
        priceProviderIds = priceProviderIds.map(idData => ({ ...idData, priceData: null }));
    };

    /**
     * get products ids which do not have price data
     *
     * @returns {array}
     */
    const getProductIdsNotFetched = () => priceProviderIds
        .filter(({ priceData }) => !priceData)
        .map(({ productId }) => productId);

    /**
     * set fetched product data globally
     *
     * @param productsData {object}
     */
    const setProductPriceData = (productsData = {}) => {
        Object.keys(productsData)
            .forEach(productId => setDataCollectionByKey(productId, 'priceData', productsData[productId]));
    };

    /**
     * get collection of sourceId
     *
     * @param sourceId {string|number}
     *
     * @return {array}
     */
    const getCollectionForSource = sourceId => priceProviderIds
        .filter(({ sourceIds }) => sourceIds.findIndex(sourceIdAdded => sourceIdAdded === sourceId) !== -1);

    /**
     * notify watchers about status by product ids
     *
     * @param productIds {array}
     */
    const notifyInstancesAboutStatus = (productIds) => {
        registeredObservables.forEach(({ proxy }) => {
            const newPriceData = proxy.data;

            productIds.forEach((productId) => {
                if (newPriceData[productId]) {
                    newPriceData[productId] = {
                        ...newPriceData[productId],
                        statuses: getDataCollectionByKey(productId, 'statuses'),
                    };
                }
            });

            proxy.data = newPriceData;
        });
    };

    /**
     * change product status
     *
     * @param productIds {array}
     * @param requestStatuses {array}
     */
    const changeIdsStatus = (productIds, requestStatuses) => {
        productIds.forEach((productId) => {
            const idCollection = getCollectionById(productId);
            const newStatuses = {};

            requestStatuses.forEach((requestStatus) => {
                const [statusName, flag] = requestStatus;

                newStatuses[statusName] = flag;
            });

            if (idCollection) {
                idCollection.statuses = newStatuses;
            }
        });

        notifyInstancesAboutStatus(productIds);
    };

    /**
     * notify watchers about price data
     */
    const notifyWatchersAboutPriceData = () => {
        registeredObservables.forEach(({ proxy }) => {
            const newData = {};

            Object.keys(proxy.data).forEach((productId) => {
                newData[productId] = {
                    statuses: proxy.data[productId].statuses,
                    ...getDataCollectionByKey(productId, 'priceData'),
                };
            });

            proxy.data = newData;
        });
    };

    /**
     * register observables
     *
     * @param productIds {array}
     *
     * @return {object}
     */
    const watch = (productIds) => {
        if (productIds) {
            const ids = typeof productIds === 'object' ? productIds : [productIds];
            const priceDataCollection = ids
                .map(id => parseInt(id, 10))
                .reduce((previousIds, productId) => {
                    const idCollection = getCollectionById(productId) || {};
                    const statuses = idCollection.statuses || initialStatuses;
                    const { priceData } = idCollection;

                    return ({
                        ...previousIds,
                        [productId]: {
                            statuses,
                            ...priceData,
                        },
                    });
                }, {});

            const {
                observables,
                proxy,
            } = observe({ data: priceDataCollection });

            registeredObservables.push({
                proxy,
                observables,
            });

            return observables;
        }
    };

    /**
     * unregister observables by id
     *
     * @param observablesObject {object}
     */
    const unwatch = (observablesObject) => {
        const observablesFound = registeredObservables.findIndex(({ observables }) => observables === observablesObject);

        if (observablesFound !== -1) {
            registeredObservables.splice(observablesFound, 1);
        }
    };

    /**
     * get all registered product ids
     *
     * @returns {array}
     */
    const getAllProductIds = () => priceProviderIds.map(({ productId }) => productId);

    /**
     * get product ids of source
     *
     * @param sourceId {string|number}
     *
     * @returns {array}
     */
    const getProductIdsOfSource = sourceId => getCollectionForSource(sourceId)
        .map(({ productId }) => productId);

    /**
     * register ids to global price provider
     *
     * @param sourceId {string|number}
     * @param productIds {array}
     */
    const registerToPriceProvider = (sourceId, productIds) => {
        let idsNotFound = [];

        productIds
            .filter(productId => productId)
            .forEach((productId) => {
                const idCollection = getCollectionById(productId);

                if (idCollection) {
                    idCollection.sourceIds.push(sourceId);
                } else {
                    idsNotFound.push(productId);
                }
            });

        idsNotFound = idsNotFound.map(productId => ({
            productId,
            priceData: null,
            sourceIds: [sourceId],
            statuses: initialStatuses,
        }));

        priceProviderIds = [...priceProviderIds, ...idsNotFound];
    };

    /**
     * unregister ids from global price provider
     *
     * @param sourceId {string|number}
     * @param productIds {array}
     */
    const unregisterFromPriceProvider = (sourceId, productIds = null) => {
        const ids = productIds || getProductIdsOfSource(sourceId);

        ids.forEach((productId) => {
            const idCollection = getCollectionById(productId);

            if (idCollection) {
                idCollection.sourceIds = idCollection.sourceIds.filter(sourceIdAdded => sourceIdAdded !== sourceId);
            }
        });

        priceProviderIds = priceProviderIds.filter(({ sourceIds }) => sourceIds.length > 0);
    };

    /**
     * fetch product data for all not fetched ids globally
     *
     * @param isPriceDataFlushed {boolean}
     */
    const fetch = async (isPriceDataFlushed) => {
        registeredRequestes.push(isPriceDataFlushed);

        if (registeredRequestes.length <= 1) {
            if (isPriceDataFlushed) {
                clearAll();

                if (clearPriceBeforeRequest) {
                    notifyWatchersAboutPriceData();
                }
            }

            const idsWithoutPrice = getProductIdsNotFetched();

            if (idsWithoutPrice.length) {
                try {
                    changeIdsStatus(idsWithoutPrice, [
                        [requestStatusPending, true],
                        [requestStatusResolved, false],
                        [requestStatusRejected, false],
                    ]);

                    const productsData = await getPriceHandler(idsWithoutPrice);
                    const productIdsWithPrice = Object.keys(productsData).map(productId => parseInt(productId, 10));
                    const productIdsNotFetched = idsWithoutPrice.filter(idNotFetched => !productIdsWithPrice.includes(idNotFetched));

                    setProductPriceData(productsData);
                    notifyWatchersAboutPriceData();
                    changeIdsStatus(productIdsNotFetched, [
                        [requestStatusPending, false],
                        [requestStatusResolved, false],
                        [requestStatusRejected, false],
                    ]);
                    changeIdsStatus(productIdsWithPrice, [
                        [requestStatusPending, false],
                        [requestStatusResolved, true],
                        [requestStatusRejected, false],
                    ]);
                } catch (e) {
                    changeIdsStatus(idsWithoutPrice, [
                        [requestStatusPending, false],
                        [requestStatusResolved, false],
                        [requestStatusRejected, true],
                    ]);
                }
            } else {
                changeIdsStatus(idsWithoutPrice, [
                    [requestStatusPending, false],
                    [requestStatusResolved, true],
                    [requestStatusRejected, false],
                ]);
                notifyWatchersAboutPriceData();
            }

            const needDataFlush = registeredRequestes.some(needFlush => needFlush);
            registeredRequestes.pop();

            if (registeredRequestes.length > 0) {
                registeredRequestes = [];
                fetch(needDataFlush);
            }
        }
    };

    /**
     * actions when trigger is triggered
     */
    const onObservableChange = () => {
        changeIdsStatus(getAllProductIds(), [
            [requestStatusPending, false],
            [requestStatusResolved, false],
            [requestStatusRejected, false],
        ]);

        fetch(true);
    };

    /**
     * set single watcher for store id changes
     */
    const setWatchersSingleton = () => {
        if (!watcherHandlers && typeof trigger === 'function') {
            trigger(onObservableChange);
            watcherHandlers = true;
        }
    };

    /**
     * add provided sourceId
     *
     * @param sourceId {string|number}
     */
    const addProvidedSourceId = sourceId => registeredInstances.push(sourceId);

    /**
     * remove provided sourceId
     *
     * @param sourceId {string|number}
     */
    const removeProvidedSourceId = (sourceId) => {
        registeredInstances = registeredInstances.filter(_sourceId => _sourceId !== sourceId);
    };

    /**
     * remove provided product ids by sourceId
     *
     * @param sourceId {string|number}
     */
    const remove = (sourceId) => {
        unregisterFromPriceProvider(sourceId);
        removeProvidedSourceId(sourceId);
    };

    /**
     * handle product ids change
     *
     * @param sourceId {string|number}
     * @param observables {object}
     */
    const watchProductIdsChange = (sourceId, observables) => {
        let oldProductsIds = [];

        observables.ids.subscribe((newProductIds) => {
            const newParsedInt = (newProductIds || []).map(newProductId => parseInt(newProductId, 10));
            const oldParsedInt = (oldProductsIds || []).map(oldProductsId => parseInt(oldProductsId, 10));
            const idsToRegister = _uniqBy(newParsedInt.filter(newId => !oldParsedInt.includes(newId)));
            const idsToUnregister = _uniqBy(oldParsedInt.filter(oldId => !newParsedInt.includes(oldId)));
            oldProductsIds = newProductIds;

            unregisterFromPriceProvider(sourceId, idsToUnregister);
            registerToPriceProvider(sourceId, idsToRegister);

            if (newParsedInt.length && !_isEqual(newParsedInt, oldParsedInt)) {
                fetch();
            }
        });
    };

    /**
     * add new provided product ids related to sourceId
     *
     * @param observables
     * @param sourceId
     */
    const add = (observables, sourceId) => {
        if (!observables) {
            throw new Error('You must provide observables');
        }
        if (!observables.ids) {
            throw new Error('No ids property');
        }
        if (!observables.ids.subscribe) {
            throw new Error('Ids object must implement subscribe method');
        }
        if (registeredInstances.includes(sourceId)) {
            throw new Error(`You declared already PriceProvider for this sourceId - ${sourceId}`);
        }
        addProvidedSourceId(sourceId);
        setWatchersSingleton();
        watchProductIdsChange(sourceId, observables);
    };

    VueInstance.prototype.$PriceProvider = {
        add,
        remove,
        watch,
        unwatch,
        fetch,
        clearAll,
    };
}

export default PriceProviderPlugin;
