import { types } from './mutations';
import {
    checkCustomer,
    getMagentoMessage,
    customerLogout,
    customerSignIn,
} from '~/assets/services/customer';
import { setCustomerLogged } from '~/assets/services/storages/local-storage/customer';
import { config as sessionMessagesConfig } from '~/assets/services/sessionMessages';

const { cookieName: sessionNewMessageCookieName } = sessionMessagesConfig;
export default {
    loginUser({ commit, state, dispatch }, { username, password }) {
        return new Promise((resolve, reject) => {
            customerSignIn({ username, password, formKey: state.loginFormKey })
                .then(() => {
                    commit(types.SET_CUSTOMER_LOGGED, true);
                    setCustomerLogged(true);
                    dispatch('refreshUserInfo');
                    resolve(true);
                })
                .catch(() => {
                    commit(types.SET_CUSTOMER_LOGGED, null);
                    setCustomerLogged(null);
                    getMagentoMessage()
                        .then(({ data }) => {
                            const { error: texts = 'Logowanie nie udało się spróbuj ponownie' } = data;
                            dispatch(
                                'globalMessages/addMessage',
                                {
                                    texts,
                                    type: 'warning',
                                    hideButton: true,
                                },
                                {
                                    root: true,
                                },
                            );
                            reject(new Error('User not logged in'));
                        })
                        .catch(() => {
                            reject(new Error('User not logged in'));
                        })
                        .finally(() => {
                            dispatch('refreshUserInfo');
                        });
                });
        });
    },
    logoutUser({ commit, dispatch }) {
        return new Promise((resolve, reject) => {
            customerLogout()
                .then(() => {
                    commit(types.SET_CUSTOMER_LOGGED, false);
                    setCustomerLogged(false);
                    dispatch('refreshUserInfo');
                    resolve(true);
                })
                .catch(() => {
                    getMagentoMessage()
                        .then(({ data }) => {
                            const { error: text = 'Wylogowano' } = data;
                            const texts = [text];
                            dispatch(
                                'globalMessages/addMessage',
                                {
                                    texts,
                                    type: 'warning',
                                    hideButton: true,
                                },
                                {
                                    root: true,
                                },
                            );
                            reject(new Error('User not logged out'));
                        })
                        .catch(() => {
                            reject(new Error('User not logged out'));
                        })
                        .finally(() => {
                            dispatch('refreshUserInfo');
                        });
                });
        });
    },
    getCurrentUserInfo({ commit }) {
        return new Promise((resolve) => {
            checkCustomer()
                .then(({ data }) => {
                    const { isLogged, formKey, wishlistItemsCount: wishlistCount, cartItemsCount: cartCount, email, firstName } = data;
                    commit(types.SET_CUSTOMER_LOGGED, isLogged);
                    commit(types.SET_LOGIN_FORM_KEY, formKey);
                    commit(types.SET_CUSTOMER_EMAIL, email);
                    commit(types.SET_CUSTOMER_NAME, firstName);
                    setCustomerLogged(isLogged);
                    resolve({
                        cartCount,
                        wishlistCount,
                    });
                })
                .catch(() => {
                    resolve({
                        cartCount: null,
                        wishlistCount: null,
                    });
                });
        });
    },
    async refreshUserInfo({ dispatch }) {
        const fetchDataActions = [dispatch('getCurrentUserInfo')];

        if (this.$cookies.get(sessionNewMessageCookieName) !== undefined) {
            fetchDataActions.push(dispatch('globalMessages/fetchSessionMessages', null, { root: true }));
        }

        const itemsCountCartAndWishlist = await Promise.all(fetchDataActions);
        const { cartCount, wishlistCount } = itemsCountCartAndWishlist[0];

        dispatch('cart/setItemsCount', cartCount, { root: true });
        dispatch('wishlist/setItemsCount', wishlistCount, { root: true });

        if (!cartCount) {
            dispatch('cart/setNeedFetch', false, { root: true });
            dispatch('cart/flushCart', null, { root: true });
        }

        if (wishlistCount) {
            dispatch('wishlist/setNeedFetch', true, { root: true });
        } else {
            dispatch('wishlist/setNeedFetch', false, { root: true });
            dispatch('wishlist/flushWishlist', null, { root: true });
        }
    },
};
