import CookieUniversal from 'cookie-universal';
import fetch from 'isomorphic-fetch';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { ApolloLink } from 'apollo-link';
import { createHttpLink } from 'apollo-link-http';
import { CLIENT_DEFAULT_CONFIG as defaultOptions } from '~/config/apollo';
import getErrorHandler from '~/src/apollo/getErrorHandler';

/**
 * This function create apollo graphQL client config.
 *
 * @param {Function} logger - handler for logging errors
 *
 * @return {Object}
 */
export default function ({ logger }) {
    const cache = new InMemoryCache();
    let authToken = null;

    if (process.browser) {
        const cookies = new CookieUniversal();

        authToken = cookies.get('gqlAuthToken');
    }

    const httpLink = createHttpLink({
        uri: process.env.API_URL_GRAPHQL,
        credentials: 'include',
        customFetch: fetch,
        // @todo do pozniejszego usuniecia ten headers, kiedy autoryzacja po BE bedzie juz ograna (tymczasowo jest to brane z cookies)
        headers: {
            Authorization: `Bearer ${authToken}`,
        },
    });

    const errorLink = getErrorHandler({ type: 'GraphQL', logger });

    const link = ApolloLink.from([errorLink, httpLink]);

    return {
        defaultOptions,
        link,
        cache,
        ssrMode: process.server,
        defaultHttpLink: false,
    };
}
