import comparePageConfig from '~/assets/config/pages/compare';
import pushState from '~/assets/services/windowHistory/';

export default function () {
    const { paramName: sParam } = comparePageConfig.queryKey;
    let url = `${window.location.href.split('?')[0]}?`;
    const sPageURL = decodeURIComponent(window.location.search.substring(1));

    const sURLVariables = sPageURL.split('&');

    let sParameterName;

    let i = 0;

    for (; i < sURLVariables.length; i += 1) {
        sParameterName = sURLVariables[i].split('=');
        if (sParameterName[0] !== sParam) {
            url = `${url + sParameterName[0]}=${sParameterName[1]}&`;
        }
    }
    pushState(window.history.state, document.title, url.substring(0, url.length - 1));
}
