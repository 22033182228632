<template>
    <div
        v-if="pageCount > 1"
        class="product-pagination"
    >
        <BaseButton
            v-for="index in pageCount"
            :key="index"
            class="product-pagination__button"
            :name="index"
            :class="{ active: isPageActive(index)}"
            @click.native="goToPage(index)"
        />
    </div>
</template>

<script>
import BaseButton from '~/components/common/Button/BaseButton';

export default {
    name: 'ProductPagination',
    components: {
        BaseButton,
    },
    inject: ['carousel'],
    data() {
        return {
            pageActive: 1,
        };
    },
    computed: {
        pageCount() {
            return this.carousel ? this.carousel.pageCount : 0;
        },
    },
    mounted() {
        this.$parent.$on('pageChange', (index) => {
            this.pageActive = index + 1;
        });
    },
    methods: {
        goToPage(index) {
            this.carousel.goToPage(index - 1);
        },
        isPageActive(index) {
            return this.pageActive === index;
        },
    },
};
</script>

<style lang="scss">
    .product-pagination {
        position: absolute;
        bottom: -30px;
        top: unset;
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: center;

        .product-pagination__button {
            width: 12px;
            height: 12px;
            margin: 5px 7px;
            border: none;
            background-color: $color-slate20;
            display: inline-block;
            transition: opacity .2s ease;
            border-radius: 30px;
            line-height: 24px;

            &::before, &::after {
                box-sizing: border-box;
            }

            &.active {
                background: $color-castoBlue;
                border: none;
            }

            @media screen and (max-width: $breakpoint-tablet) {
                margin: 8px 5px;
                display: inline-block;
                background-color: $color-slate10-dark;
                border-radius: 20px;
            }
        }
    }
</style>
