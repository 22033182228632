import { types } from './mutations';
import getFooterAjax from '~/assets/services/footer/getFooterAjax';

export default {
    async fetchBaseFooter({ commit }) {
        const footerData = await getFooterAjax();
        commit(types.SET_FOOTER, footerData);
    },
    toogleExpand({ commit, state }, symbol) {
        const { footerSections } = state;
        const footerSectionsCopy = [...footerSections];
        const footerSectionIndex = footerSectionsCopy.findIndex(footerSection => footerSection.symbol === symbol);
        const footerSectionItem = footerSectionsCopy[footerSectionIndex];
        if (footerSectionItem) {
            const { isExpanded = false } = footerSectionItem;
            const newItemToReplace = {
                ...footerSectionItem,
                isExpanded: !isExpanded,
            };
            footerSectionsCopy.splice(footerSectionIndex, 1, newItemToReplace);

            commit(types.SET_FOOTER, footerSectionsCopy);
        }
    },
};
