<template>
    <section class="user-account-box">
        <ConditionallyNuxtLink
            v-for="(link, index) in links"
            :key="index"
            v-bind="link"
            class="user-account-box__link"
        />
        <LogoutButton class="user-account-box__button-logout" />
    </section>
</template>

<script>
import { mapGetters } from 'vuex';
import LogoutButton from '~/components/common/Button/LogoutButton';
import ConditionallyNuxtLink from '~/components/common/Wrappers/ConditionallyNuxtLink';
import { customerUrl } from '~/src/config/pagesUrl';

export default {
    name: 'UserAccountBox',
    components: {
        LogoutButton,
        ConditionallyNuxtLink,
    },
    computed: {
        ...mapGetters(['isModeTest']),
        links() {
            return [
                { href: customerUrl.customerAccountSettings, title: 'Newsletter', text: 'Newsletter', useNuxtLink: this.isModeTest },
                { href: customerUrl.customerAccountSettings, title: 'Dane konta', text: 'Dane konta', useNuxtLink: this.isModeTest },
                { href: '/sales/order/history/', title: 'Moje zamówienia', text: 'Moje zamówienia', useNuxtLink: false },
            ];
        },
    },
};
</script>

<style lang="scss">
    .user-account-box {
        width: 250px;
        padding: 18px 0 24px 0;

        &__link {
            display: inline-block;
            margin: 0;
            padding: 0;
            color: $color-slate70-dark;
            width: 80%;
            font: $font-weight-regular 14px/51px $goodHomeFontFamily;
            border-bottom: 1px solid $color-slate5-dark;
            text-align: left;
            text-decoration: none;
            cursor: pointer;

            &:hover {
                color: $color-castoBlue;
            }
            &:last-of-type {
                border-bottom: none;
            }
        }

        &__button-logout {
            width: 80%;
            border: none;
            outline: none;
            padding: 10px;
            margin-top: 20px;
            margin-bottom: 20px;
            font: $font-weight-bold 14px/16px $goodHomeFontFamily;
            background-color: $color-slate70-dark;
            color: $color-castoWhite;
            cursor: pointer;
        }
    }
</style>
