import { types } from './mutations';
import { getProductsToCompare } from '~/assets/services/compare';
import { removeProduct, getProducts, addProduct } from '~/assets/services/storages/local-storage/compare';
import { deleteItemFromUrl, deleteParamFromUrl } from '~/assets/services/history/compare';
import comparePageConfig from '~/assets/config/pages/compare';

export default {
    async getProductsData({ commit }, { productsSku }) {
        const { products, attributesCodes } = await getProductsToCompare({ productsSku });
        commit(types.SET_PRODUCTS_DATA, products);
        commit(types.SET_ATTRIBUTES_DATA, attributesCodes);
    },
    addItem({ commit, dispatch }, { id, name, image, sku, url }) {
        let currentProductList = getProducts();
        const { length: currentQtyOfProduct } = currentProductList;
        if (currentQtyOfProduct < comparePageConfig.bundleConfig.numberOfElementsToDisplay) {
            addProduct({ id, name, image, sku, url });
            currentProductList = getProducts();
        }
        commit(types.PUT_ITEM_TO_COMPARE_GLOBAL, currentProductList);
        dispatch('generateCompareUrl');
        return {
            success: currentQtyOfProduct < comparePageConfig.bundleConfig.numberOfElementsToDisplay,
            items: currentProductList,
        };
    },
    fetchCompareProducts({ commit }) {
        commit(types.PUT_ITEM_TO_COMPARE_GLOBAL, getProducts());
    },
    removeItem({ commit, dispatch }, { sku, id, isUrlModified = true }) {
        removeProduct(id);
        commit(types.PUT_ITEM_TO_COMPARE_GLOBAL, getProducts());
        if (isUrlModified) {
            deleteItemFromUrl(sku);
        }
        commit(types.REMOVE_ITEM_BY_SKU, { sku });
        dispatch('generateCompareUrl');
    },
    removeAllItems({ state, commit }) {
        state.productsData.forEach((item) => {
            removeProduct(item.id);
        });
        deleteParamFromUrl();
        commit(types.REMOVE_ALL_ITEMS);
    },
    generateCompareUrl({ commit }) {
        const skusFromStorage = getProducts().map(item => item.sku);
        const { paramName, separator } = comparePageConfig.queryKey;
        const params = skusFromStorage.length > 0 ? `?${paramName}=${skusFromStorage.join(separator)}` : '';
        const newUrl = `${comparePageConfig.pageUrl}${params}`;
        commit(types.SET_COMPARE_URL, newUrl);
    },
    shiftProductsArrayLeft({ commit }) {
        commit(types.SHIFT_PRODUCTS_ARRAY_LEFT);
    },
    shiftProductsArrayRight({ commit }) {
        commit(types.SHIFT_PRODUCTS_ARRAY_RIGHT);
    },
    setMobileScrollOffsetLeft({ commit }, offset) {
        commit(types.SET_MOBILE_LEFT_SCROLL_OFFSET, offset);
    },
};
