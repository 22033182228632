/* eslint-disable no-template-curly-in-string */
export const GET_PRODUCT_DATA = 'product/${id}';

export const GET_PRODUCT_SHIPPING_DATA = 'product/${id}/shippingMethods/${storeId}';

export const GET_PROVINCES_LIST_WITH_AVAILABLE_PRODUCT = 'product/${id}/provinces';

export const GET_REVIEWS_FOR_PRODUCT_URL = 'product/${id}/reviews';

export const GET_PRICE_OF_PRODUCT_URL = `${
    process.env.baseUrl
}/bold_all/data/getProductPriceStockByStore/`;

export const CHECK_AVAILABILITY_IN_STORES_URL = `${
    process.env.baseUrl
}/cataloginventory/index/checkAvailabilityInStores`;
