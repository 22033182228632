<template>
    <div>
        <client-only>
            <AppGlobalNotice />
        </client-only>
        <MobileApplicationBar />
        <CookieBar v-show="showCookieBar" />
        <GlobalPopups />
        <GlobalMessages />
        <Loader :global-loader="true" />
        <BaseHeader
            :catalog-tree="catalogTree"
            :inspirations-tree="inspirationsTree"
            :forum-tree="forumTree"
            :newspaper-data="newspaperData"
        />
        <nuxt />
        <slot name="beforeFooter" />
        <BaseFooter class="print-hide" />
        <InteliwiseClient />
        <AddedToWishlistPopup />
    </div>
</template>

<script>
import { createNamespacedHelpers, mapState, mapActions } from 'vuex';
import dataLayerStoreId from '~/assets/services/dataLayer/dataLayerStore';
import mixinMarketChangeActions from '~/mixins/marketChangeActions';
import watchOnce from '~/mixins/watchOnce';
import BaseHeader from '~/components/common/Header/BaseHeader';
import BaseFooter from '~/components/common/Footer';
import Loader from '~/components/common/Loader';
import GlobalMessages from '~/components/common/Popup/GlobalMessages';
import GlobalPopups from '~/components/common/Popup/GlobalPopus';
import CookieBar from '~/components/CookieBar';
import AppGlobalNotice from '~/components/AppGlobalNotice';
import InteliwiseClient from '~/components/InteliwiseClient';
import MobileApplicationBar from '~/components/MobileApplicationBar';
import AddedToWishlistPopup from '~/components/common/Popup/AddedToWishlistPopup';

const { mapState: mapStateGoogleServices } = createNamespacedHelpers('googleServices');
const { mapActions: mapActionsCustomer } = createNamespacedHelpers('customer');
const { mapActions: mapActionsCookieBar } = createNamespacedHelpers('cookieBar');
const { mapActions: mapActionsCompare } = createNamespacedHelpers('compare');
const { mapState: mapStateMenu } = createNamespacedHelpers('menu');
const { mapState: mapStateMarkets } = createNamespacedHelpers('markets');

export default {
    name: 'LayoutsSimple',
    components: {
        AddedToWishlistPopup,
        InteliwiseClient,
        MobileApplicationBar,
        AppGlobalNotice,
        BaseHeader,
        BaseFooter,
        GlobalPopups,
        GlobalMessages,
        Loader,
        CookieBar,
    },
    mixins: [mixinMarketChangeActions, watchOnce],
    data() {
        return {
            showCookieBar: false,
        };
    },
    computed: {
        ...mapStateMenu({
            catalogTree: state => state.catalogTree,
            inspirationsTree: state => state.inspirationsTree,
            forumTree: state => state.forumTree,
            newspaperData: state => state.newspaperData,
        }),
        ...mapStateMarkets({
            fetchStartStateDone: state => state.fetchStartStateDone,
            shopId: state => state.selectedShopIds.shopId,
        }),
        ...mapState({
            robotsFollow: state => state.robotsFollow,
            headLinks: state => state.headLinks,
        }),
        ...mapStateGoogleServices({
            isGTM: state => state.GTM,
        }),
    },
    watch: {
        shopId(currentShopId) {
            if (currentShopId && this.isGTM) {
                dataLayerStoreId(this.shopId);
            }
        },
    },
    mounted() {
        if (!this.fetchStartStateDone) {
            this.setStoreIds();
        }
        this.refreshUserInfo();
        this.checkStateCookieBar();
        this.fetchCompareProducts();

        if (this.isGTM) {
            dataLayerStoreId(this.shopId);
        } else {
            this.watchOnce({
                key: 'isGTM',
                callback: (isGTMLoaded, ignore, unwatch) => {
                    if (isGTMLoaded) {
                        dataLayerStoreId(this.shopId);
                        unwatch();
                    }
                },
            });
        }
    },
    methods: {
        ...mapActions(['setStoreIds']),
        ...mapActionsCompare(['fetchCompareProducts']),
        ...mapActionsCustomer(['refreshUserInfo']),
        ...mapActionsCookieBar(['checkStateCookieBar']),
    },
    head() {
        return {
            meta: [
                { name: 'robots', content: this.robotsFollow ? 'INDEX, FOLLOW' : 'NOINDEX, NOFOLLOW' },
            ],
            link: this.headLinks,
        };
    },
};
</script>
