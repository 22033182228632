import eventRemoveToCart from './events/eventRemoveFromCart';

function prepareDataProductRemoveWithCart(products, idRemoveProduct) {
    let removeProduct = {};
    Object.keys(products).forEach((key) => {
        if (products[key].item_id === idRemoveProduct) {
            removeProduct = products[key];
        }
    });

    return removeProduct;
}

function dataLayerRemoveWithCart(product) {
    /* eslint-disable camelcase */
    const { brand, category, item_id, name, price, sku, qty } = product;
    eventRemoveToCart.ecommerce.remove.products.push({
        brand,
        category,
        id: item_id,
        name,
        price,
        sku,
        quantity: qty,
    });
    if (window.dataLayer) {
        window.dataLayer.push(eventRemoveToCart);
    }
}

export { prepareDataProductRemoveWithCart, dataLayerRemoveWithCart };
