<template>
    <a
        :href="href"
        class="base-link"
        :class="[{ disabled: !href }, ...getBemModifiers() ]"
        :title="titleOrText"
        :target="target"
    >
        <slot v-if="hasSlot && text === ''" />
        <template v-else>
            {{ text }}
        </template>
    </a>
</template>

<script>

export default {
    name: 'BaseLink',
    props: {
        href: {
            type: String,
            default: '#',
        },
        text: {
            type: String,
            default: '',
        },
        target: {
            type: String,
            default: '_self',
        },
        title: {
            type: String,
            default: '',
        },
    },
    computed: {
        titleOrText() {
            return this.title || this.text;
        },
    },
    methods: {
        hasSlot(name = 'default') {
            return !!(this.$slots && this.$slots[name]) || !!(this.$scopedSlots && this.$scopedSlots[name]);
        },
    },
};
</script>

<style lang="scss">
    .base-link {
        &--default {
            color: $color-castoBlue;

            &:hover {
                text-decoration: none;
            }
        }

        &--button-like {
            font: $font-weight-bold 17px/48px $goodHomeFontFamily;
            color: $color-castoWhite;
            box-sizing: border-box;
            border: none;
            background: none;
            outline: none;
            display: inline-block;
            text-decoration: none;
            cursor: pointer;
        }

        &--background-casto-yellow {
            background: $color-castoYellow;

            &:hover {
                background-color: $color-castoYellow-dark;
            }
        }

        &--hover-underlined:hover {
            text-decoration: underline;
        }
    }
</style>
