import { config } from '~/config/router/categories';
import getSeasonPrefixFromPath from '~/assets/services/category/getSeasonPrefixFromPath';

const { pathPrefix } = config;

export default function (path) {
    let categoryPath = path;
    const seasonPrefixInPath = getSeasonPrefixFromPath(path);

    if (seasonPrefixInPath) {
        categoryPath = categoryPath.substring(seasonPrefixInPath.length);
    }

    if (categoryPath.includes(pathPrefix)) {
        categoryPath = categoryPath.substring(pathPrefix.length);
    }
    return (categoryPath.match(/\//g) || []).length;
}
