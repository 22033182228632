import Vue from 'vue';
import isInViewport from '~/assets/services/DOMElement/isInViewport';

const emit = (vnode, name, data) => {
    const handlers = (vnode.data && vnode.data.on) ||
        (vnode.componentOptions && vnode.componentOptions.listeners);

    if (handlers && handlers[name]) {
        handlers[name].fns(data);
    }
};

function cacheObserver(element, observer) {
    element.inViewportObserver = observer;
}

function removeCacheObserver(element) {
    if (element.inViewportObserver) {
        element.inViewportObserver.disconnect();
        element.inViewportObserver = null;
    }
}

Vue.directive('isInViewport', {
    intersectionObserver: null,
    bind(element, binding, vnode) {
        const { checkingIsInViewport, intersectionObserver } = isInViewport({ element });
        checkingIsInViewport.then(() => {
            emit(vnode, 'isInViewportSuccess');
        }, () => {
            emit(vnode, 'isInViewportError');
        });
        cacheObserver(element, intersectionObserver);
    },
    unbind(element) {
        removeCacheObserver(element);
    },
});
