<template>
    <span class="energy-classes__separator">{{ separator }}</span>
</template>

<script>
export default {
    name: 'EnergyClassesSeparator',
    props: {
        /**
         * Energy class separator string
         */
        separator: {
            type: String,
            default: '/',
        },
    },
};
</script>
