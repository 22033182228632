export default [
    {
        filterFieldName: 'pageSize',
        filterFieldSymbol: 'limit',
        value: '47',
        filterType: 'basic',
    },
    {
        filterFieldName: 'currentPage',
        filterFieldSymbol: 'p',
        value: 1,
        filterType: 'basic',
    },
    {
        filterFieldName: 'dir',
        filterFieldSymbol: 'dir',
        value: 'desc',
        filterType: 'basic',
    },
    {
        filterFieldName: 'order',
        filterFieldSymbol: 'order',
        value: 'promoted',
        filterType: 'basic',
    },
];
