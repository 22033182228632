export const types = {
    FETCH_PROMOTIONAL: 'FETCH_PROMOTIONAL',
};

const mutations = {
    [types.FETCH_PROMOTIONAL](state, data) {
        state.newspaper = data.newspaper;
        state.bargain = data.bargain;
        state.catalog = data.catalog;
    },
};

export default mutations;
