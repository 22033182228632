<template>
    <BlueDotsPreloader class="price-loader" />
</template>

<script>

import BlueDotsPreloader from '~/components/common/Loader/components/BlueDotsPreloader';

export default {
    name: 'PriceLoaderProductTail',
    components: { BlueDotsPreloader },
};
</script>

<style lang="scss">
    .price-loader {
        height: 100%;
        width: 100%;
        &.bold-preloader_blue-dots {
            background: rgba(255, 255, 255, 0.35);
        }
    }
</style>
