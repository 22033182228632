import { types } from './mutations';
import { GLOBAL_VARS } from '~/config/index';

const MAIN_PLUGIN_KEY = 'searchnode';

export default {
    setMounted({ commit }) {
        commit(types.SET_SEARCHBAR_MOUNTED, true);

        window[GLOBAL_VARS.BLOCKS_LOADED_STATE].add({
            blockName: MAIN_PLUGIN_KEY,
        });

        const externalCallbackForSearchBar = window[
            GLOBAL_VARS.EXTERNAL_CALLBACKS_WRAPPER_NAME
        ].getBySource(MAIN_PLUGIN_KEY);

        if (externalCallbackForSearchBar) {
            externalCallbackForSearchBar.callback();
        }
    },
    unsetMounted({ commit }) {
        commit(types.SET_SEARCHBAR_MOUNTED, false);
        window[GLOBAL_VARS.BLOCKS_LOADED_STATE].remove({
            blockName: MAIN_PLUGIN_KEY,
        });
    },
};
