import { getLevel } from '~/assets/services/url';
import { config as categoriesRouteConfig } from '~/config/router/categories';
import { types as typesCategory } from '~/store/category/mutations';
import { types } from '~/store/mutations';
import { areOnlyIndexedFiltersInQuery, areSpecialFilters } from '~/assets/services/category';

export default function ({ store, route, query }) {
    const { path } = route;
    const { pathPrefix } = categoriesRouteConfig;
    const couldBeNotSsrByLevel = getLevel(path.replace(pathPrefix, '')) >= 3;
    const hasQuery = Object.keys(query).length > 0;
    const onlyIndexedFilters = areOnlyIndexedFiltersInQuery(query);
    const allIndexedValues = areSpecialFilters(query);
    const noSsrState = couldBeNotSsrByLevel && hasQuery && (!onlyIndexedFilters || !allIndexedValues);

    store.commit(`category/${typesCategory.SET_NO_SSR}`, noSsrState);
    store.commit(`${types.SET_ROBOTS_FOLLOW}`, !noSsrState);
}
