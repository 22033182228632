import axios from 'axios';
import getUrlWithParams from '~/src/utils/helpers/getUrlWithParams';

const apiHost = process.browser ? process.env.apiHostBrowser : process.env.apiHostServer;
const apiPath = `${apiHost}${process.env.apiPath}`;

export default function (params = {}) {
    const { urlTemplate, urlTemplateProps, url: sourceUrl, ...config } = params;
    const url = `${apiPath}${urlTemplate ? getUrlWithParams(urlTemplate, urlTemplateProps) : sourceUrl}`;
    return axios({
        ...config,
        url,
    });
}
