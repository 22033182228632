<template>
    <div
        id="intelwise-page"
        ref="intelwisePage"
        :class="{'not-loaded': !visible}"
    />
</template>

<script>
import { mapState } from 'vuex';

const EXTERNAL_JS_LINK = 'https://s3-eu-west-1.amazonaws.com/inteliwise-client/saas/embed/v_1.5/b7fa482022534822a681d53b6104990c320b7d9a4fe0345148a0814dbcf82dbf/11b24f7f431d7bad44d429e00e4a8f4e';
export default {
    name: 'InteliwiseClient',
    data() {
        return {
            visible: false,
        };
    },
    computed: {
        ...mapState({
            saute: state => state.saute,
        }),
    },
    mounted() {
        if (!this.saute) {
            const script = document.createElement('script');
            script.onload = () => {
                this.visible = true;
            };
            script.async = true;
            script.src = EXTERNAL_JS_LINK;
            this.$refs.intelwisePage.appendChild(script);
        }
    },
};
</script>

<style>
    .not-loaded {
        opacity: 0;
    }
</style>
