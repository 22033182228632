<template>
    <input
        :id="inputId"
        v-model="inputValue"
        :placeholder="placeholder"
        :type="type"
        class="base-input"
        @focus="$emit('base-input-focus')"
        @blur="$emit('base-input-blur')"
    >
</template>

<script>
export default {
    name: 'BaseInput',
    props: {
        value: {
            type: String,
            default: '',
        },
        placeholder: {
            type: String,
            default: '',
        },
        inputId: {
            type: String,
            default: '',
        },
        type: {
            type: String,
            default: 'text',
        },
    },
    data() {
        return {
            inputValue: this.value,
        };
    },
    watch: {
        value() {
            this.inputValue = this.value;
            this.$emit('input', this.value);
        },
    },
};
</script>

<style lang="scss">
.base-input {
    font-family: $goodHomeFontFamily;
    cursor: pointer;
    width: 100%;
    padding: 14px 10px;
    outline: none;
    color: $color-slate70;

    &.not-valid {
        border: 2px solid $color-deepCanynon-dark;
    }

    &:focus {
        outline: none;
    }
}
</style>
