import Vue from 'vue';
import { imageLazyLoad } from '~/assets/services/lazyLoadLauncher';

const emit = (vnode, name, data) => {
    const handlers = (vnode.data && vnode.data.on) ||
        (vnode.componentOptions && vnode.componentOptions.listeners);

    if (handlers && handlers[name]) {
        handlers[name].fns(data);
    }
};
const errorImageSource = require('~/static/images/placeholder-error.png');

function cacheObserver(element, observer) {
    element.progressiveSrcObserver = observer;
}

function removeCacheObserver(element) {
    if (element.progressiveSrcObserver) {
        element.progressiveSrcObserver.disconnect();
        element.progressiveSrcObserver = null;
    }
}

Vue.directive('lazyLoadSrc', {
    intersectionObserver: null,
    async bind(element, binding, vnode) {
        const { value } = binding;
        const { src, error: errorImage = errorImageSource } = value;
        const { intersectionObserver } = imageLazyLoad({
            element,
            src,
            errorImage,
            onBefore() {
                emit(vnode, 'lazyLoadSrcBeforeChange');
            },
            onSuccess() {
                emit(vnode, 'lazyLoadSrcSuccess');
            },
            onChange() {
                emit(vnode, 'lazyLoadSrcChanged');
            },
            onError() {
                emit(vnode, 'lazyLoadSrcError');
            },
        });
        cacheObserver(element, intersectionObserver);
    },
    unbind(element) {
        removeCacheObserver(element);
    },
});
