import { types } from './mutations';
import { getSessionMessages } from '~/assets/services/sessionMessages';
import GLOBAL_MESSAGE_TYPES from '~/src/config/dictionary/message/messageTypes';

export default {
    flushMessages({ commit }) {
        commit(types.FLUSH_MESSAGES);
    },
    flushMessageByIndex({ commit }, index) {
        commit(types.REMOVE_MESSAGE, index);
    },
    addMessage({ commit }, message) {
        if (message) {
            commit(types.ADD_MESSAGE, { type: GLOBAL_MESSAGE_TYPES.success, ...message });
        }
    },
    async fetchSessionMessages({ commit }) {
        const messages = await getSessionMessages();
        if (messages && messages.length) {
            commit(types.ADD_MESSAGES, messages.map(message => ({
                ...message,
                hideButton: true,
            })));
        }
    },
};
