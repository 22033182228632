<template>
    <section
        class="price-box"
        :class="[{ 'price-box--special': isSpecial, 'price-box--with-energy-class': hasEnergyClass }, ...getBemModifiers()]"
    >
        <EnergyClasses
            v-if="hasEnergyClass"
            class="price-box__energy-class"
            :value="energyClass"
        />
        <PriceValue
            :value="value"
            class="price-box__value"
        />
        <PriceUnit
            :unit="unit"
            class="price-box__unit"
        />
        <PriceOldValue
            v-if="oldValue && oldValue > value"
            :value="oldValue"
            class="price-box__old-value"
        />
    </section>
</template>

<script>
import PriceValue from '~/src/components/Price/PriceValue';
import PriceUnit from '~/src/components/Price/PriceUnit';
import PriceOldValue from '~/src/components/Price/PriceOldValue';
import EnergyClasses from '~/src/components/EnergyClasses';


export default {
    name: 'PriceBox',
    components: {
        PriceOldValue,
        PriceUnit,
        PriceValue,
        EnergyClasses,
    },
    props: {
        /**
         * Product price value
         */
        value: {
            type: Number,
            required: true,
        },
        /**
         *  Product sale unit
         */
        unit: {
            type: String,
            required: true,
        },
        /**
         * Product price before discount
         */
        oldValue: {
            type: Number,
            default: 0,
        },
        /**
         * Product in promotion
         */
        isInPromo: {
            type: Boolean,
            default: false,
        },
        /**
         * Product energy class label
         */
        energyClass: {
            type: String,
            default: '',
        },
    },
    computed: {
        /**
         * Returns whether product have energy class
         * @returns {boolean}
         */
        hasEnergyClass() {
            return this.energyClass && this.energyClass.length;
        },
        /**
         * Returns whether product is in promotion or has discount on price
         * @returns {boolean}
         */
        isSpecial() {
            return this.isInPromo || (this.oldValue && this.oldValue > this.value);
        },
    },
};
</script>

<style lang="scss">
    $horizontalPaddingDesktop: 10px;
    $horizontalPaddingMobile: 6px;

    .price-box {
        $self: &;

        position: relative;
        background: $color-castoWhite;
        width: 100%;
        height: 60px;
        padding: 18px $horizontalPaddingDesktop;
        color: $color-slate100;
        box-sizing: border-box;
        text-align: center;
        border-top: 1px solid $color-slate10;

        &--special {
            background: $color-castoYellow;
        }

        &--with-energy-class {
           padding-left: 24px;
        }

        #{&} {
            &__value {
                font: $font-weight-bold 24px/24px $goodHomeFontFamily;
            }

            &__old-value,
            &__energy-class {
                position: absolute;
            }

            &__old-value {
                top: 50%;
                right: $horizontalPaddingDesktop;
                transform: translateY(-50%);
            }

            &__energy-class {
                left: 0;
            }
        }
    }

    @media (max-width: $breakpoint-tablet) {
        .price-box {
            height: 43px;
            padding: 11px $horizontalPaddingMobile 0 $horizontalPaddingMobile;

            &--with-energy-class {
                padding-left: 40px;
            }

            #{&} {
                &__value {
                    font-size: 21px;
                    line-height: 21px;
                }

                &__unit {
                    font-size: 11px;
                }

                &__old-value {
                    display: inline-block;
                    height: 11px;
                    right: $horizontalPaddingMobile;
                    top: auto;
                    bottom: -2px;
                    font-size: 11px;
                }

                &__energy-class {
                    top: 10px;
                }
            }
        }
    }
</style>
