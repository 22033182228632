import axios from 'axios';
import { getGroupedParamString } from '~/assets/utils/product';
import normalizeProduct from '~/assets/services/category/normalizeData/product';
import priceNormalizeData from '~/assets/services/price/normalizeData';
import { ADD_TO_CART_URL } from './api.config';

const getGroupedTotalPrice = groupedData => groupedData
    .reduce((priceTotal, { price, currentQty }) => priceTotal + (price * currentQty), 0);

const extraParamsInterface = ({ productId, qty, groupedData }) => {
    if (groupedData && groupedData.length) {
        return {
            product: productId,
            groupedData: getGroupedParamString({ productId, groupedData }),
            groupedTotal: getGroupedTotalPrice(groupedData).toFixed(2),
        };
    }

    return {
        qty,
        product: productId,
    };
};

export default async function ({ id, qty, source, groupedData }) {
    try {
        const { data } = await axios.get(ADD_TO_CART_URL, {
            params: {
                noHtml: 1,
                isAjax: true,
                source,
                ...extraParamsInterface({ groupedData, productId: id, qty }),
            },
        });

        if (data && data.items && data.items.length) {
            data.items = data.items.map(item => priceNormalizeData(item));
        }

        if (data && data.recommended && data.recommended.length) {
            data.recommended = data.recommended
                .map(singleProduct => normalizeProduct(priceNormalizeData(singleProduct)));
        }

        return data;
    } catch {
        return {};
    }
}
