import { observe } from 'rxjs-observe';
import { createNamespacedHelpers } from 'vuex';

const { mapState: mapStateMarkets } = createNamespacedHelpers('markets');

export default function (sourceId) {
    const instance = { ids: [] };
    const {
        observables,
        proxy,
    } = observe(instance);

    return {
        data() {
            return {
                unwatchMarketsFetchStartStateDone: null,
            };
        },
        watch: {
            productsIdsForPriceProvider: {
                handler(ids) {
                    proxy.ids = ids;
                },
                immediate: process.browser,
            },
        },
        beforeDestroy() {
            this.removePriceObserver();

            if (this.unwatchMarketsFetchStartStateDone) {
                this.unwatchMarketsFetchStartStateDone();
            }
        },
        computed: {
            ...mapStateMarkets({
                marketsFetchStartStateDone: state => state.fetchStartStateDone,
            }),
        },
        mounted() {
            this.unwatchMarketsFetchStartStateDone = this.$watch('marketsFetchStartStateDone', () => {
                this.addPriceObserver();
            }, {
                immediate: this.marketsFetchStartStateDone,
            });
        },
        methods: {
            removePriceObserver() {
                this.$PriceProvider.remove(sourceId);
            },
            addPriceObserver() {
                this.$PriceProvider.add(
                    observables,
                    sourceId,
                );
            },
            forceUpdatePrices() {
                this.removePriceObserver();
                this.addPriceObserver();
            },
        },
    };
}
