import numerals from '~/assets/json/unitTypes/unitVariant.json';
import declesionableWords from '~/assets/json/declensionableWords.json';
import { getExplication } from '~/assets/services/helpers';

export default function (currentQty, currentNumeralName, withQty = false) {
    const qty = Math.floor(parseInt(currentQty, 10));
    const dictionarySets = [numerals, declesionableWords];
    let funcName = getExplication(currentNumeralName);
    const choosenDictionarySet = dictionarySets.find(dictionarySet => dictionarySet[funcName]);

    if (funcName === '') {
        funcName = 'sztuka';
    }

    if (!choosenDictionarySet) {
        return currentNumeralName;
    }

    if (!choosenDictionarySet[funcName]) {
        return withQty ? `${qty} ${funcName}` : funcName;
    }

    if (qty === 1) {
        return withQty
            ? `${qty} ${choosenDictionarySet[funcName][0].slice(-1) === 'a' ? 'ostatnia' : 'ostatni'} ${choosenDictionarySet[funcName][0]}`
            : choosenDictionarySet[funcName][0];
    }

    if ((qty < 5 && qty !== 0) || (qty > 20 && ((qty % 10 === 2) || (qty % 10 === 3) || (qty % 10 === 4)))) {
        return withQty ? `${qty} ${choosenDictionarySet[funcName][1]}` : choosenDictionarySet[funcName][1];
    }

    return withQty ? `${qty} ${choosenDictionarySet[funcName][2]}` : choosenDictionarySet[funcName][2];
}
