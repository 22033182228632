<template>
    <div
        class="login-box"
        :bem-modifiers="bemModifiers"
    >
        <div class="login-box__content">
            <CloseButton @click="$emit('close')" />
            <p class="login-box__title">
                Logowanie
            </p>
            <LoginForm
                bem-modifiers="login-box"
                @focus="$emit('focus')"
                @blur="$emit('blur')"
            />
            <ForgotPasswordLink />
            <SocialMediaLinks />
        </div>
        <CreateAccountLink />
    </div>
</template>

<script>
import SocialMediaLinks from './components/SocialMediaLinks';

import ForgotPasswordLink from '~/components/LoginBox/components/ForgotPasswordLink';
import CreateAccountLink from '~/components/LoginBox/components/CreateAccountLink';
import LoginForm from '~/components/LoginBox/components/LoginForm';
import CloseButton from '~/components/LoginBox/components/CloseButton';

export default {
    name: 'LoginBox',
    components: {
        CloseButton,
        LoginForm,
        CreateAccountLink,
        ForgotPasswordLink,
        SocialMediaLinks,
    },
};
</script>

<style lang="scss">
    .login-box {
        width: 324px;

        &__content {
            position: relative;
            padding: 34px 24px 24px;
            text-align: left;
        }

        &__title {
            color: $color-slate100;
            font: $font-weight-bold 17px/1 $goodHomeFontFamily;
            text-align: left;
            padding: 0;
            margin: 0 0 24px 0;
            line-height: 18px;
        }
    }
</style>
