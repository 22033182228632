import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

const VUEX_PROPERTIES = ['state', 'getters', 'actions', 'mutations']

let store = {};

(function updateModules () {
  store = normalizeRoot(require('../store/index.js'), 'store/index.js')

  // If store is an exported method = classic mode (deprecated)

  // Enforce store modules
  store.modules = store.modules || {}

  resolveStoreModules(require('../store/actions.js'), 'actions.js')
  resolveStoreModules(require('../store/getters.js'), 'getters.js')
  resolveStoreModules(require('../store/mutations.js'), 'mutations.js')
  resolveStoreModules(require('../store/state.js'), 'state.js')
  resolveStoreModules(require('../store/appGlobalNotice/actions.js'), 'appGlobalNotice/actions.js')
  resolveStoreModules(require('../store/appGlobalNotice/mutations.js'), 'appGlobalNotice/mutations.js')
  resolveStoreModules(require('../store/appGlobalNotice/state.js'), 'appGlobalNotice/state.js')
  resolveStoreModules(require('../store/articleGrid/actions.js'), 'articleGrid/actions.js')
  resolveStoreModules(require('../store/articleGrid/mutations.js'), 'articleGrid/mutations.js')
  resolveStoreModules(require('../store/articleGrid/state.js'), 'articleGrid/state.js')
  resolveStoreModules(require('../store/availabilityInOtherStores/actions.js'), 'availabilityInOtherStores/actions.js')
  resolveStoreModules(require('../store/availabilityInOtherStores/mutations.js'), 'availabilityInOtherStores/mutations.js')
  resolveStoreModules(require('../store/availabilityInOtherStores/state.js'), 'availabilityInOtherStores/state.js')
  resolveStoreModules(require('../store/breadcrumbs/actions.js'), 'breadcrumbs/actions.js')
  resolveStoreModules(require('../store/breadcrumbs/mutations.js'), 'breadcrumbs/mutations.js')
  resolveStoreModules(require('../store/breadcrumbs/state.js'), 'breadcrumbs/state.js')
  resolveStoreModules(require('../store/cart/actions.js'), 'cart/actions.js')
  resolveStoreModules(require('../store/cart/mutations.js'), 'cart/mutations.js')
  resolveStoreModules(require('../store/cart/state.js'), 'cart/state.js')
  resolveStoreModules(require('../store/category/actions.js'), 'category/actions.js')
  resolveStoreModules(require('../store/category/getters.js'), 'category/getters.js')
  resolveStoreModules(require('../store/category/mutations.js'), 'category/mutations.js')
  resolveStoreModules(require('../store/category/state.js'), 'category/state.js')
  resolveStoreModules(require('../store/compare/actions.js'), 'compare/actions.js')
  resolveStoreModules(require('../store/compare/mutations.js'), 'compare/mutations.js')
  resolveStoreModules(require('../store/compare/state.js'), 'compare/state.js')
  resolveStoreModules(require('../store/cookieBar/actions.js'), 'cookieBar/actions.js')
  resolveStoreModules(require('../store/cookieBar/mutations.js'), 'cookieBar/mutations.js')
  resolveStoreModules(require('../store/cookieBar/state.js'), 'cookieBar/state.js')
  resolveStoreModules(require('../store/customer/actions.js'), 'customer/actions.js')
  resolveStoreModules(require('../store/customer/mutations.js'), 'customer/mutations.js')
  resolveStoreModules(require('../store/customer/state.js'), 'customer/state.js')
  resolveStoreModules(require('../store/footer/actions.js'), 'footer/actions.js')
  resolveStoreModules(require('../store/footer/mutations.js'), 'footer/mutations.js')
  resolveStoreModules(require('../store/footer/state.js'), 'footer/state.js')
  resolveStoreModules(require('../store/globalMessages/actions.js'), 'globalMessages/actions.js')
  resolveStoreModules(require('../store/globalMessages/mutations.js'), 'globalMessages/mutations.js')
  resolveStoreModules(require('../store/globalMessages/state.js'), 'globalMessages/state.js')
  resolveStoreModules(require('../store/googleServices/actions.js'), 'googleServices/actions.js')
  resolveStoreModules(require('../store/googleServices/mutations.js'), 'googleServices/mutations.js')
  resolveStoreModules(require('../store/googleServices/state.js'), 'googleServices/state.js')
  resolveStoreModules(require('../store/loader/actions.js'), 'loader/actions.js')
  resolveStoreModules(require('../store/loader/mutations.js'), 'loader/mutations.js')
  resolveStoreModules(require('../store/loader/state.js'), 'loader/state.js')
  resolveStoreModules(require('../store/managePanel/actions.js'), 'managePanel/actions.js')
  resolveStoreModules(require('../store/managePanel/mutations.js'), 'managePanel/mutations.js')
  resolveStoreModules(require('../store/managePanel/state.js'), 'managePanel/state.js')
  resolveStoreModules(require('../store/markets/actions.js'), 'markets/actions.js')
  resolveStoreModules(require('../store/markets/getters.js'), 'markets/getters.js')
  resolveStoreModules(require('../store/markets/mutations.js'), 'markets/mutations.js')
  resolveStoreModules(require('../store/markets/state.js'), 'markets/state.js')
  resolveStoreModules(require('../store/menu/actions.js'), 'menu/actions.js')
  resolveStoreModules(require('../store/menu/mutations.js'), 'menu/mutations.js')
  resolveStoreModules(require('../store/menu/state.js'), 'menu/state.js')
  resolveStoreModules(require('../store/mobileApplicationBar/actions.js'), 'mobileApplicationBar/actions.js')
  resolveStoreModules(require('../store/mobileApplicationBar/mutations.js'), 'mobileApplicationBar/mutations.js')
  resolveStoreModules(require('../store/mobileApplicationBar/state.js'), 'mobileApplicationBar/state.js')
  resolveStoreModules(require('../store/popup/actions.js'), 'popup/actions.js')
  resolveStoreModules(require('../store/popup/mutations.js'), 'popup/mutations.js')
  resolveStoreModules(require('../store/popup/state.js'), 'popup/state.js')
  resolveStoreModules(require('../store/print/actions.js'), 'print/actions.js')
  resolveStoreModules(require('../store/print/mutations.js'), 'print/mutations.js')
  resolveStoreModules(require('../store/print/state.js'), 'print/state.js')
  resolveStoreModules(require('../store/product/actions.js'), 'product/actions.js')
  resolveStoreModules(require('../store/product/getters.js'), 'product/getters.js')
  resolveStoreModules(require('../store/product/mutations.js'), 'product/mutations.js')
  resolveStoreModules(require('../store/product/state.js'), 'product/state.js')
  resolveStoreModules(require('../store/promotional/actions.js'), 'promotional/actions.js')
  resolveStoreModules(require('../store/promotional/mutations.js'), 'promotional/mutations.js')
  resolveStoreModules(require('../store/promotional/state.js'), 'promotional/state.js')
  resolveStoreModules(require('../store/recentlyCategories/actions.js'), 'recentlyCategories/actions.js')
  resolveStoreModules(require('../store/recentlyCategories/mutations.js'), 'recentlyCategories/mutations.js')
  resolveStoreModules(require('../store/recentlyCategories/state.js'), 'recentlyCategories/state.js')
  resolveStoreModules(require('../store/reviews/actions.js'), 'reviews/actions.js')
  resolveStoreModules(require('../store/reviews/mutations.js'), 'reviews/mutations.js')
  resolveStoreModules(require('../store/reviews/state.js'), 'reviews/state.js')
  resolveStoreModules(require('../store/santanderInstallments/actions.js'), 'santanderInstallments/actions.js')
  resolveStoreModules(require('../store/santanderInstallments/mutations.js'), 'santanderInstallments/mutations.js')
  resolveStoreModules(require('../store/santanderInstallments/state.js'), 'santanderInstallments/state.js')
  resolveStoreModules(require('../store/searchBar/actions.js'), 'searchBar/actions.js')
  resolveStoreModules(require('../store/searchBar/mutations.js'), 'searchBar/mutations.js')
  resolveStoreModules(require('../store/searchBar/state.js'), 'searchBar/state.js')
  resolveStoreModules(require('../store/windowOptions/actions.js'), 'windowOptions/actions.js')
  resolveStoreModules(require('../store/windowOptions/mutations.js'), 'windowOptions/mutations.js')
  resolveStoreModules(require('../store/windowOptions/state.js'), 'windowOptions/state.js')
  resolveStoreModules(require('../store/wishlist/actions.js'), 'wishlist/actions.js')
  resolveStoreModules(require('../store/wishlist/mutations.js'), 'wishlist/mutations.js')
  resolveStoreModules(require('../store/wishlist/state.js'), 'wishlist/state.js')

  // If the environment supports hot reloading...
})()

// createStore
export const createStore = store instanceof Function ? store : () => {
  return new Vuex.Store(Object.assign({
    strict: (process.env.NODE_ENV !== 'production')
  }, store))
}

function normalizeRoot (moduleData, filePath) {
  moduleData = moduleData.default || moduleData

  if (moduleData.commit) {
    throw new Error(`[nuxt] ${filePath} should export a method that returns a Vuex instance.`)
  }

  if (typeof moduleData !== 'function') {
    // Avoid TypeError: setting a property that has only a getter when overwriting top level keys
    moduleData = Object.assign({}, moduleData)
  }
  return normalizeModule(moduleData, filePath)
}

function normalizeModule (moduleData, filePath) {
  if (moduleData.state && typeof moduleData.state !== 'function') {
    console.warn(`'state' should be a method that returns an object in ${filePath}`)

    const state = Object.assign({}, moduleData.state)
    // Avoid TypeError: setting a property that has only a getter when overwriting top level keys
    moduleData = Object.assign({}, moduleData, { state: () => state })
  }
  return moduleData
}

function resolveStoreModules (moduleData, filename) {
  moduleData = moduleData.default || moduleData
  // Remove store src + extension (./foo/index.js -> foo/index)
  const namespace = filename.replace(/\.(js|mjs)$/, '')
  const namespaces = namespace.split('/')
  let moduleName = namespaces[namespaces.length - 1]
  const filePath = `store/${filename}`

  moduleData = moduleName === 'state'
    ? normalizeState(moduleData, filePath)
    : normalizeModule(moduleData, filePath)

  // If src is a known Vuex property
  if (VUEX_PROPERTIES.includes(moduleName)) {
    const property = moduleName
    const storeModule = getStoreModule(store, namespaces, { isProperty: true })

    // Replace state since it's a function
    mergeProperty(storeModule, moduleData, property)
    return
  }

  // If file is foo/index.js, it should be saved as foo
  const isIndexModule = (moduleName === 'index')
  if (isIndexModule) {
    namespaces.pop()
    moduleName = namespaces[namespaces.length - 1]
  }

  const storeModule = getStoreModule(store, namespaces)

  for (const property of VUEX_PROPERTIES) {
    mergeProperty(storeModule, moduleData[property], property)
  }

  if (moduleData.namespaced === false) {
    delete storeModule.namespaced
  }
}

function normalizeState (moduleData, filePath) {
  if (typeof moduleData !== 'function') {
    console.warn(`${filePath} should export a method that returns an object`)
    const state = Object.assign({}, moduleData)
    return () => state
  }
  return normalizeModule(moduleData, filePath)
}

function getStoreModule (storeModule, namespaces, { isProperty = false } = {}) {
  // If ./mutations.js
  if (!namespaces.length || (isProperty && namespaces.length === 1)) {
    return storeModule
  }

  const namespace = namespaces.shift()

  storeModule.modules[namespace] = storeModule.modules[namespace] || {}
  storeModule.modules[namespace].namespaced = true
  storeModule.modules[namespace].modules = storeModule.modules[namespace].modules || {}

  return getStoreModule(storeModule.modules[namespace], namespaces, { isProperty })
}

function mergeProperty (storeModule, moduleData, property) {
  if (!moduleData) {
    return
  }

  if (property === 'state') {
    storeModule.state = moduleData || storeModule.state
  } else {
    storeModule[property] = Object.assign({}, storeModule[property], moduleData)
  }
}
