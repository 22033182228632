import { types } from './mutations';

import {
    setCookieBarClosed,
    isCookieBarClosed,
} from '~/assets/services/storages/cookies/cookiebar';

export default {
    closeCookieBar({ commit }) {
        setCookieBarClosed();
        commit(types.SET_CLOSED);
    },
    checkStateCookieBar({ commit }) {
        if (isCookieBarClosed()) {
            commit(types.SET_CLOSED);
        }
    },
    setHeight({ commit }, height) {
        commit(types.SET_HEIGHT, height);
    },
};
