const APPS_NAMES = require('~/config/apps/names');

const { production } = APPS_NAMES;

/**
 * This plugin inject logger functionality for ssr and csr app.
 *
 * @param {Object} store - store from nuxt context - vuex
 * @param {Object} route - route from nuxt context - vue router
 * @param {Function} inject - inject from nuxt which allow add functionality for whole app
 *
 * @return {undefined}
 */
export default function ({ store, route }, inject) {
    const { fullPath: currentPath } = route;
    const { markets: { selectedShopIds } } = store.state;
    const loggerLabel = '[Strix log]';

    /**
     * This function generate message from object, based on key/value pair.
     *
     * @param {Object} messageObject
     *
     * @return {String}
     */
    function getFullMessage(messageObject) {
        return Object.keys(messageObject).reduce((fullMessage, currentKey) => {
            if (messageObject[currentKey] === undefined) {
                return fullMessage;
            }
            return `${fullMessage}${currentKey}: ${messageObject[currentKey]} - `;
        }, '');
    }

    /**
     * This function return logger handler from console or trackJs or newRelic.
     *
     * @param {Boolean} useTrackJs
     * @param {Boolean} useNewRelic
     *
     * @return {Function}
     */
    function getLoggerHandler({ useTrackJs, useNewRelic }) {
        if (useNewRelic) {
            return newrelic.noticeError;
        }
        if (useTrackJs) {
            return TrackJS.track;
        }
        return console.log;
    }

    /**
     * This function generate log message and run logger handler.
     *
     * @param {String} message
     * @param {String} source
     * @param {String} path
     * @param {String} label
     *
     * @return {undefined}
     */
    const loggerPlugin = ({ message, source, path, label = loggerLabel }) => {
        const useTrackJs = process.browser && process.env.TRACKER_JS_TOKEN && window.TrackJS;
        const useNewRelic = process.server && process.env.APP_NAME === production && newrelic;
        const logger = getLoggerHandler({ useTrackJs, useNewRelic });

        const fullMessage = getFullMessage({
            message,
            source,
            path,
            url: `${process.env.URL}${currentPath}`,
            [`[${Object.keys(selectedShopIds).join(', ')}]`]: `[${Object.values(selectedShopIds).join(', ')}]`,
        });

        logger(`${label} ${fullMessage}`);
    };

    inject('logger', loggerPlugin);
}
