export const types = {
    SET_FETCH_START_STATE_DONE: 'SET_FETCH_START_STATE_DONE',
    FETCH_ALL_MARKETS: 'FETCH_ALL_MARKETS',
    SET_SHOP: 'SET_SHOP',
    SET_STORE_INFO: 'SET_STORE_INFO',
    SET_SHOP_SELECTED: 'SET_STORE_SELECTED',
    SET_NEAREST_STORE_DATA_WITH_PRODUCT_AVAILABLE: 'SET_NEAREST_STORE_DATA_WITH_PRODUCT_AVAILABLE',
    FLUSH_NEAREST_STORE_DATA_WITH_PRODUCT_AVAILABLE: 'FLUSH_NEAREST_STORE_DATA_WITH_PRODUCT_AVAILABLE',
};

const mutations = {
    [types.SET_FETCH_START_STATE_DONE](state) {
        state.fetchStartStateDone = true;
    },
    [types.FETCH_ALL_MARKETS](state, marketsAvailable) {
        state.marketsAvailable = marketsAvailable;
    },
    [types.SET_SHOP](state, shopIds) {
        state.selectedShopIds = shopIds;
    },
    [types.SET_STORE_INFO](state, { storeInfo }) {
        state.storeInfo = { ...storeInfo };
    },
    [types.SET_SHOP_SELECTED](state, currentState) {
        state.storeSelected = currentState;
    },
    [types.SET_NEAREST_STORE_DATA_WITH_PRODUCT_AVAILABLE](state, nearestStoreData) {
        state.nearestStoreData = nearestStoreData;
    },
    [types.FLUSH_NEAREST_STORE_DATA_WITH_PRODUCT_AVAILABLE](state) {
        state.nearestStoreData = null;
    },
};

export default mutations;
