import { TABS as CATEGORY_TABS, ORDERED_FILTERS_BEGIN_BY_SYMBOL } from '~/src/config/category';
import polishArraySort from '~/assets/services/array/sort';
import { separateItemsFromStructure } from '~/src/utils/helpers';
import { getSpecialFiltersNames } from '~/assets/services/category';
import categorySortOptions from '~/assets/json/categorySortOptions.json';

export default {
    categoryTitle({ name, alternativeName, containSpecialFilters, activeFilters }) {
        const titleToDisplay = alternativeName || name;
        if (!containSpecialFilters) {
            return titleToDisplay;
        }
        try {
            const specialFiltersNames = getSpecialFiltersNames(activeFilters);
            return [titleToDisplay, ...specialFiltersNames].join(' ');
        } catch (ignore) {
            return titleToDisplay;
        }
    },
    getSortedFilterOptions({ filterOptions }) {
        const separatedArray = separateItemsFromStructure(filterOptions, ORDERED_FILTERS_BEGIN_BY_SYMBOL, 'symbol', false);

        return [
            ...separatedArray.separatedItems,
            ...separatedArray.itemsLeft,
        ];
    },
    getNavigationData({ activeFilters, productsCount }) {
        const currentPageFilter = activeFilters.find(activeFilter => activeFilter.filterFieldName === 'currentPage');
        const currentMaxPageFilter = activeFilters.find(activeFilter => activeFilter.filterFieldName === 'pageSize');
        return {
            currentPage: currentPageFilter
                ? parseInt(currentPageFilter.filterItemSymbol, 10)
                : 1,
            maxPage: currentMaxPageFilter && productsCount
                ? Math.ceil(productsCount / currentMaxPageFilter.filterItemSymbol)
                : 1,
        };
    },
    getSortProductsFilter({ activeFilters }) {
        try {
            const dir = activeFilters.find(activeFilter => activeFilter.filterFieldSymbol === 'dir').filterItemSymbol;
            const type = activeFilters.find(activeFilter => activeFilter.filterFieldSymbol === 'order').filterItemSymbol;
            return {
                dir,
                type,
            };
        } catch {
            return {
                dir: false,
                type: false,
            };
        }
    },
    sortCollection({ activeTabSymbol }, { getSortProductsFilter }) {
        const sortOptions = { ...categorySortOptions };
        const collectionByActiveSymbol = sortOptions[activeTabSymbol];
        const { dir, type } = getSortProductsFilter;

        if (activeTabSymbol === 'products') {
            let foundActiveSelected = false;
            const sortOptionsWithSelected = collectionByActiveSymbol.map((sortItem) => {
                const isSelected = sortItem.dir === dir && sortItem.type === type;
                if (isSelected) {
                    foundActiveSelected = isSelected;
                }

                return {
                    ...sortItem,
                    selected: isSelected,
                };
            });
            if (foundActiveSelected) {
                return sortOptionsWithSelected;
            }
        }
        return collectionByActiveSymbol;
    },
    getPageLimit({ activeFilters }) {
        try {
            return activeFilters.find(activeFilter => activeFilter.filterFieldSymbol === 'limit').filterItemSymbol;
        } catch {
            return false;
        }
    },
    visibleCurrentFilters({ activeFilters }) {
        const filterTypesNotVisible = ['invisible', 'url', 'basic'];
        return polishArraySort(polishArraySort(activeFilters.filter(filterItem => filterItem.filterFieldName &&
            filterItem.filterFieldSymbol &&
            filterItem.filterItemName &&
            filterItem.filterItemSymbol &&
            filterItem.filterType &&
            !filterTypesNotVisible.includes(filterItem.filterType)), 'asc', 'filterItemName'), 'asc', 'filterFieldName');
    },
    maxFiltersHeight({ componentHeights }) {
        const { productsGrid, filtersOptions, marginBottom, price = 0, categories = 0, activeFilters = 0 } = componentHeights;
        if (productsGrid && typeof categories === 'number' && filtersOptions && marginBottom) {
            const maxHeight = productsGrid - categories - price - marginBottom - activeFilters;
            if (maxHeight < 0) {
                return 0;
            }
            return maxHeight;
        }
        return 0;
    },
    filtersOptionsWithHeight({ filterOptions, filterHeights }) {
        if (!filterHeights) {
            return filterOptions;
        }
        return filterOptions.map((filter) => {
            if (!filterHeights[filter.symbol]) {
                return filter;
            }
            const { height = 0 } = filterHeights[filter.symbol];

            return {
                ...filter,
                height,
            };
        });
    },
    tabsCategory({ seriesData, productsCount, activeTabSymbol }) {
        if (seriesData && seriesData.length) {
            const countBySymbol = {
                series: seriesData.length,
                products: productsCount,
            };

            return CATEGORY_TABS.map(item => ({
                ...item,
                count: countBySymbol[item.symbol],
                isActive: activeTabSymbol === item.symbol,
            }));
        }
        return [];
    },
    representativeCategoryImage({ image, products }) {
        if (image) {
            return image;
        }
        if (!products || !products.length) {
            return '';
        }
        const firstProductWithImage = products.find(product => product.image);
        return firstProductWithImage ? firstProductWithImage.image : false;
    },
};
