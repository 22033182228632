import config from '~/src/config/recentlyProducts';
import getRecentlyProducts from './getRecentlyProducts';
import arrayHelper from '~/src/utils/array/helper';

export default function ({ brand = {}, categoryName: category, productId, smallImage: image, name, sku, url, labelId }) {
    let recentlyProducts = getRecentlyProducts();

    if (category && productId && image && sku && name && url) {
        const timestamp = new Date().getTime();
        const productToAdd = {
            category,
            image,
            name,
            sku,
            url,
            timestamp,
            brand: brand.name,
            id: parseInt(productId, 10),
        };

        if (labelId) {
            productToAdd.labels = [labelId];
        }

        recentlyProducts = arrayHelper(recentlyProducts)
            .findIndexByKey('id', productToAdd.id)
            .deleteIndexFound()
            .unshift(productToAdd)
            .slice(0, config.MAX_ELEMENTS_IN_STORAGE)
            .getArray();

        localStorage.setItem(config.STORAGE_KEY, JSON.stringify(recentlyProducts));
    }
}
