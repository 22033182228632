<template>
    <section
        v-if="newspapers && newspapers.length"
        class="newspapper-list"
    >
        <p class="newspapper-list__section-name">
            Gazetki
        </p>
        <div
            v-for="(newspaper, index) in newspapers"
            :key="`newspaper-${index}`"
            class="newspapper-list__content"
        >
            <a
                :href="newspaper.url"
                :title="newspaper.title"
            >
                <BaseImage
                    :data="{ src: newspaper.image, alt: 'Gazetka w sklepie Castorama' }"
                    class="newspaper-image"
                />
                <span class="newspaper-name">
                    {{ newspaper.title }}
                </span>
                <span class="newspaper-description">
                    {{ newspaper.subtitle }}
                </span>
                <span class="newspaper-button">
                    Zobacz gazetkę
                </span>
            </a>
        </div>
    </section>
</template>

<script>
import { mapState } from 'vuex';
import BaseImage from '~/components/common/Image/BaseImage';

export default {
    name: 'NewspaperList',
    components: {
        BaseImage,
    },
    computed: {
        ...mapState({
            newspapers: state => state.promotional.newspaper,
        }),
    },
};
</script>

<style lang="scss">
.newspapper-list {
    background: $color-castoWhite;
    padding: 0 24px 8px 8px;
    box-sizing: content-box;
    width: 381px;
    max-height: 370px;

    .section-name, .newspaper-title, .newspaper-name, .newspaper-description {
        font-family: $goodHomeFontFamily;
    }

    .section-name, .newspaper-title {
        font-weight: $font-weight-bold;
        font-size: 15px;
        line-height: 20px;
        color: $color-slate100;
    }

    .newspaper-name, .newspaper-button {
        font-weight: $font-weight-regular;
        line-height: 16px;
    }

    .section-name {
        clear: both;
        margin-top: 0;
    }

    .newspaper-title {
        margin-bottom: 10px;
    }

    .newspaper-name {
        display: block;
        margin-top: 0;
        margin-bottom: 15px;
        font-size: 15px;
        color: $color-slate100;
    }

    .newspaper-description {
        display: block;
        margin-top: 0;
        margin-bottom: 15px;
        font-weight: $font-weight-regular;
        font-size: 13px;
        line-height: 18px;
        color: $color-slate70-dark;
    }

    .newspaper-image {
        width: 77px;
        height: 102px;
        display: block;
        float: left;
        margin-right: 4px;
    }

    .newspaper-button {
        font-size: 13px;
        color: $color-castoBlue;
    }

    a {
        text-decoration: none;

        &::after {
            clear: both;
            display: block;
            content: '';
        }
    }
}
@media (min-width: $breakpoint-tablet) and (max-width: $breakpoint-small-desktop) {
    .newspapper-list {
        width: 330px;
        padding: 35px 12px 10px 8px;
    }
}
</style>
