import axios from 'axios';
import FETCH_ROUTE_DATA from './api.config';

export default async function ({ path }) {
    try {
        const url = path.slice(1);
        const response = await axios({
            method: 'get',
            params: {
                url,
            },
            url: FETCH_ROUTE_DATA,
        });
        const { data } = response;
        return data;
    } catch (ignore) {
        // @todo log errors
        throw new Error('Route not found');
    }
}
