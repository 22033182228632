<template>
    <BaseFormWithValidation
        method="post"
        :action="formLink"
        :submit-by-native="false"
        :bem-modifiers="['login-form', ...bemModifiers]"
        @send-form="login"
    >
        <fieldset>
            <label>
                <BaseInput
                    name="username"
                    input-id="username"
                    :data-validate="['email']"
                    type="email"
                    class="login-form-input"
                    placeholder="e-mail"
                    autocomplete="on"
                    @base-input-focus="$emit('focus')"
                    @base-input-blur="$emit('blur')"
                />
            </label>
            <label>
                <BaseInput
                    name="password"
                    input-id="password"
                    :data-validate="['password']"
                    type="password"
                    class="login-form-input"
                    placeholder="hasło"
                    autocomplete="on"
                    @base-input-focus="$emit('focus')"
                    @base-input-blur="$emit('blur')"
                />
            </label>
        </fieldset>
        <BaseButton
            type="submit"
            name="Zaloguj"
            :bem-modifiers="['background-casto-blue']"
            class="login-form-button"
        >
            Zaloguj
        </BaseButton>
    </BaseFormWithValidation>
</template>

<script>
import { createNamespacedHelpers, mapActions } from 'vuex';
import { CUSTOMER_SIGN_IN_URL } from '~/assets/services/customer/api.config';
import BaseFormWithValidation from '~/components/Form/BaseFormWithValidation';
import BaseInput from '~/components/common/Input/BaseInput';
import BaseButton from '~/components/common/Button/BaseButton';

const { mapActions: mapActionsCustomer, mapState: mapStateCustomer } = createNamespacedHelpers('customer');

const formLink = CUSTOMER_SIGN_IN_URL;

export default {
    name: 'LoginForm',
    components: {
        BaseFormWithValidation,
        BaseInput,
        BaseButton,
    },
    data() {
        return {
            formLink,
        };
    },
    computed: {
        ...mapStateCustomer({
            formKey: state => state.loginFormKey,
        }),
    },
    methods: {
        ...mapActions(['registerRequest']),
        ...mapActionsCustomer(['loginUser']),
        login(formData) {
            const { username, password } = formData;
            const { formKey } = this;
            this.registerRequest({
                forceGlobal: true,
                requestHandler: this.loginUser.bind(this, {
                    username,
                    password,
                    formKey,
                }),
            });
        },
    },
};
</script>

<style lang="scss">
    .login-form-input {
        display: block;
        width: 100%;
        padding: 10px 16px 10px;
        margin-bottom: 12px;
        border: 1px solid $color-slate20-dark;
        color: $color-slate20;
        font: $font-weight-regular 12px/1 $baseFontFamily;
        background: $color-castoWhite;

        &:focus {
            color: $color-slate100;
            outline: none;
        }
    }

    .login-form-button {
        display: block;
        padding: 0 12px;
        width: 100%;
    }

</style>
