<template>
    <!-- eslint-disable max-len -->
    <div class="casto-c-wrapper">
        <svg
            class="casto-c-gray"
            data-name="Castorama gray icon"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 141.73 153.94"
        >
            <title>Castorama gray icon</title>
            <path
                class="casto-c-gray-path"
                d="M448.5,310.47c-8.8,8.28-17.3,13.84-29.8,13.84-18.85,0-32.54-14.88-32.54-35.46v-.47c0-19.65,13.67-34.95,31-34.95,13.21,0,21.35,5.71,29.49,14.27l1.3,1.36,25.87-27.77-1.05-1.18C458.34,224,441.33,216.9,417.39,216.9c-20.24,0-38.8,7.41-52.21,21.18a71.53,71.53,0,0,0-20.47,50.77v.5a71,71,0,0,0,20.53,50.53c13.35,13.52,31.72,21,51.66,21,29.47,0,44.62-12.12,56.61-25.21l1.12-1.29-24.87-25.12Z"
                transform="translate(-339.71 -211.9)"
            />
        </svg>
        <svg
            class="casto-c-blue"
            data-name="Castorama blue icon"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 141.73 153.94"
        >
            <title>Castorama blue icon</title>
            <path
                id="casto-c-blue-path"
                class="casto-c-blue-path"
                d="M137.8,32.8l-4.6-4.7C118.6,12.1,101.6,5,77.7,5c-20.2,0-38.8,7.4-52.2,21.2 C12.2,39.7,4.8,58,5,76.9v0.5c-0.2,18.9,7.2,37.1,20.5,50.5c13.4,13.5,31.7,21,51.7,21c29.5,0,44.6-12.1,56.6-25.2l1.1-1.3L110,97.3 l-1.2,1.2c-8.8,8.3-17.3,13.8-29.8,13.8c-18.9,0-32.5-14.9-32.5-35.5v-0.5c0-19.6,13.7-34.9,31-34.9c13.2,0,21.4,5.7,29.5,14.3 l1.3,1.4l25.9-27.8"
            />
        </svg>
    </div>
    <!--eslint-enable-->
</template>

<script>

export default {
    name: 'CastoC',
};
</script>

<style lang="scss">
    .casto-c-blue-path{
        fill:none;
        stroke:$color-castoBlue;
        stroke-miterlimit:10;
        stroke-width:10px;
    }

    .casto-c-gray-path{
        fill:none;
        stroke:$color-slate5-dark;
        stroke-miterlimit:10;
        stroke-width:10px;
    }

    .casto-c-wrapper {
        position: relative;
    }

    .casto-c-gray {
        z-index: 1;
    }

    .casto-c-blue {
        position: absolute;
        top: 0;
        left: 0;
        z-index: 3;
    }

    #casto-c-blue-path {
        animation-name: casto-c;
        animation-duration: 5s;
        animation-timing-function: linear;
        animation-iteration-count: infinite;
        animation-fill-mode: backwards;
        opacity: 0;
    }

    @keyframes casto-c {
        from {
            stroke-dasharray: 800;
            stroke-dashoffset: 800;
            opacity: 0;
        }
        1% {
            opacity: 1;
        }
        25% {
            stroke-dashoffset: 0;
        }
        50% {
            stroke-dashoffset: 1000;
            opacity: 1;
        }
        51% {
            stroke-dashoffset: 800;
            stroke-dasharray: 800;
            opacity: 0;
        }
        52% {
            opacity: 1;
        }
        75% {
            stroke-dashoffset: 0;
        }
        99% {
            opacity: 1;
        }
        100% {
            opacity: 0;
            stroke-dashoffset: 800;
            stroke-dasharray: 800;
        }
    }
</style>
