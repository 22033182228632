<template>
    <LabelDiagonallyCrossedOut
        class="price-old-value"
    >
        <PriceValue
            :value="value"
            :bem-modifiers="['price-old-value', ...bemModifiers]"
        />
    </LabelDiagonallyCrossedOut>
</template>

<script>
import LabelDiagonallyCrossedOut from '~/src/components/ui/Label/LabelDiagonallyCrossedOut';
import PriceValue from '~/src/components/Price/PriceValue';

export default {
    name: 'PriceOldValue',
    components: {
        LabelDiagonallyCrossedOut,
        PriceValue,
    },
    props: {
        /**
         * Product price before discount
         */
        value: {
            type: Number,
            required: true,
        },
    },
};
</script>

<style lang="scss">
    .price-old-value {
        font: $font-weight-regular 14px/14px $goodHomeFontFamily;

        .price-value__decimal {
            font-size: 9px;
            vertical-align: baseline;
        }

        &--product-page-price-box {
            transform: none;
        }
    }

    @media (max-width: $breakpoint-tablet) {
        .price-old-value {
            font-size: 15px;
            line-height: 15px;

            &::after {
                position: absolute;
                bottom: 50%;
                width: 100%;
                height: 1px;
                transform: rotate(-20deg);
                display: block;
            }
        }
    }
</style>
