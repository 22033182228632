<template>
    <IconTextButton
        class="add-to-wishlist-text__button"
        :icon="iconBtnWishlist"
        :bem-modifiers="['color-casto-blue', 'horizontally-centered', 'vertically-centered']"
        text="Dodaj do listy"
        name="Dodaj do listy"
        @click="addToWishlist"
    />
</template>

<script>
import { createNamespacedHelpers } from 'vuex';
import IconTextButton from '~/components/common/Button/IconTextButton';

const { mapActions: mapActionsWishlist } = createNamespacedHelpers('wishlist');
const iconBtnWishlist = require('~/static/images/i-btn-wishlist.png');

export default {
    name: 'AddToWishlistText',
    components: {
        IconTextButton,
    },
    props: {
        productId: {
            type: Number,
            required: true,
        },
        name: {
            type: String,
            required: true,
        },
        groupedData: {
            type: Array,
            default: null,
        },
        price: {
            type: [Number, String],
            default: 0,
        },
        qtyToAdd: {
            type: [Number],
            default: 1,
        },
    },
    data() {
        return {
            iconBtnWishlist,
        };
    },
    methods: {
        ...mapActionsWishlist(['addItemToWishlist']),
        addToWishlist() {
            const { productId, qtyToAdd: qty, name, price, groupedData } = this;
            this.addItemToWishlist({
                productId,
                qty,
                name,
                price,
                groupedData,
                source: 'productListView',
            });
        },
    },
};
</script>

<style lang="scss">
    .add-to-wishlist-text__button {
        cursor: pointer;
        outline: none;

        .image-base {
            vertical-align: middle;
        }
    }
</style>
