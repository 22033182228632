<template>
    <BaseButton
        class="cookie-bar-close-button"
        :bem-modifiers="['background-casto-blue', 'line-height-small', 'horizontally-centered']"
        name="Zaakceptuj"
        type="button"
        @click.native="closeCookieBar"
    >
        Zaakceptuj
    </BaseButton>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';
import BaseButton from '~/components/common/Button/BaseButton';

const { mapActions } = createNamespacedHelpers('cookieBar');

export default {
    name: 'CookieBarCloseButton',
    components: { BaseButton },
    methods: {
        ...mapActions(['closeCookieBar']),
    },
};
</script>

<style lang="scss">
    .cookie-bar-close-button {
        width: 106px;
        height: 36px;
        font-weight: 300;
    }
</style>
