<template>
    <BaseButton
        id="signin-button"
        class="google-sign-in"
        name="Zaloguj przez google"
        @click.native="signIn"
    >
        Zaloguj przez google
    </BaseButton>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';
import BaseButton from '~/components/common/Button/BaseButton';
import googleSignInServices from '~/assets/services/googleSignIn';
import { GLOBAL_MESSAGE_TYPES } from '~/src/config/dictionary/message';

const { mapActions: mapActionsGlobalMessages } = createNamespacedHelpers('globalMessages');
const { mapActions: mapActionsCustomer } = createNamespacedHelpers('customer');

const GOOGLE_API_ERRORS = {
    popup_blocked_by_browser: 'Popup zablokowany przez przeglądarke',
    popup_closed_by_user: 'Popup zamknięty przez użytkownika',
};

export default {
    name: 'GoogleSignIn',
    components: { BaseButton },
    data() {
        return {
            successLogout: 'Użytkownik wylogowany',
            errorLogout: 'Nie udało się wylogować, spróbuj ponownie',
            errorConnected: 'Błąd połączenia, nie udało się zalogować. Spróbuj ponownie.',
        };
    },
    methods: {
        ...mapActionsGlobalMessages(['addMessage']),
        ...mapActionsCustomer(['refreshUserInfo']),
        showErrorMessage(texts) {
            this.addMessage({
                texts,
                type: GLOBAL_MESSAGE_TYPES.error,
                hideButton: true,
            });
        },
        async signIn() {
            try {
                const authInstance = await this.$googleAuth2();
                const googleUser = await authInstance.signIn();
                this.onSignInSuccess(googleUser);
            } catch ({ error }) {
                this.onSignInError(error);
            }
        },
        async onSignInSuccess(googleUser) {
            try {
                const authResponse = googleUser.getAuthResponse();
                const { id_token: idToken } = authResponse;
                const { data } = await googleSignInServices({ idToken });
                const { new_user: newUser, message, error, redirect_url: url } = data;
                if (newUser && url) {
                    window.location = url;
                } else if (!error) {
                    if (url && url !== window.location.href) {
                        window.location = url;
                    } else {
                        this.refreshUserInfo();
                    }
                } else if (error) {
                    this.showErrorMessage(message);
                }
            } catch {
                this.onSignInError();
            }
        },
        onSignInError(errorCode) {
            let errorMessage = this.errorConnected;
            if (GOOGLE_API_ERRORS[errorCode]) {
                errorMessage = GOOGLE_API_ERRORS[errorCode];
            }
            this.showErrorMessage(errorMessage);
        },
    },
};
</script>

<style lang="scss">
    .google-sign-in {
        display: inline-block;
        height: 35px;
        line-height: 400px;
        overflow: hidden;
        cursor: pointer;
        float: left;
        width: calc(50% - 5px);
        background: url('~static/images/social-buttons/google.png') center 5px no-repeat;
        border: 1px solid $color-slate20-dark;
        margin: 0;
    }
</style>
