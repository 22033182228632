import axios from 'axios';
import 'url-search-params-polyfill';
import { SET_AVAILABILITY_REMINDER } from './api.config';

export default async function ({ storeId, productId, email }) {
    const search = {
        storeId,
        productId,
        email,
    };
    try {
        const { data } = await axios({
            method: 'post',
            params: search,
            url: SET_AVAILABILITY_REMINDER,
            headers: {
                'Content-Type':
                    'application/x-www-form-urlencoded; charset=UTF-8',
            },
        });
        return data;
    } catch {
        return {};
    }
}
