<template>
    <section
        class="price-product-tail"
        :class="getBemModifiers()"
    >
        <PlaceholderNoPriceFound
            v-if="statuses[requestStatusesDictionary.isRejected]"
            class="price-product-tail__warn"
            :bem-modifiers="bemModifiers"
        />
        <PriceBox
            v-else-if="statuses[requestStatusesDictionary.isResolved]"
            :value="value"
            :unit="unit"
            :old-value="oldValue"
            :is-in-promo="isInPromo"
            :energy-class="energyClass"
        />
        <PriceLoaderProductTail v-else />
    </section>
</template>

<script>

import PriceLoaderProductTail from '~/components/ProductTail/components/PriceLoaderProductTail';
import PlaceholderNoPriceFound from '~/src/components/ui/Placeholder/PlaceholderNoPriceFound';
import PriceBox from '~/src/components/PriceBox';
import requestStatusesDictionary from '~/src/config/dictionary/requestStatuses';

export default {
    name: 'PriceProductTail',
    components: { PriceBox, PriceLoaderProductTail, PlaceholderNoPriceFound },
    props: {
        statuses: {
            type: Object,
            required: true,
        },
        value: {
            type: Number,
            default: 0,
        },
        oldValue: {
            type: Number,
            default: 0,
        },
        unit: {
            type: String,
            default: '',
        },
        isInPromo: {
            type: Boolean,
            default: false,
        },
        energyClass: {
            type: String,
            default: '',
        },
    },
    data() {
        return {
            requestStatusesDictionary,
        };
    },
};
</script>

<style lang="scss">
    .price-product-tail {
        $self: &;
        position: relative;
        display: inline-block;
        width: 100%;
        height: 45px;
    }

    @media screen and (min-width: $mf-breakpoint-tablet) {
        .price-product-tail {
            height: 60px;
        }
    }
</style>
