<template>
    <div
        v-show="pageCount > 1"
        :class="['product-navigation', {'product-navigation__list': productListNavigation}]"
    >
        <BaseButton
            class="navigation navigation__forward"
            name="Następny"
            @click.native="navigateForward"
        >
            next
        </BaseButton>
        <BaseButton
            class="navigation navigation__backward"
            name="Poprzedni"
            @click.native="navigateBackward"
        >
            prev
        </BaseButton>
    </div>
</template>

<script>
import BaseButton from '~/components/common/Button/BaseButton';

export default {
    name: 'ProductNavigation',
    inject: [
        'carousel',
        'navigateForward',
        'navigateBackward',
    ],
    components: {
        BaseButton,
    },
    props: {
        productListNavigation: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            pageActive: 1,
        };
    },
    computed: {
        pageCount() {
            return this.carousel ? this.carousel.pageCount : 0;
        },
    },
    mounted() {
        this.$parent.$on('pageChange', (index) => {
            this.pageActive = index + 1;
        });
    },
};
</script>

<style lang="scss">
    .product-navigation {
        .navigation {
            cursor: pointer;

            &__backward,
            &__forward {
                width: 45px;
                height: 45px;
                background-size: 15px;
                display: inline-block;
                background: center center no-repeat;
                color: transparent;
                font-size: 0;
            }

            &__forward {
                background-image: url('~static/images/svg/rect-white-arrow-right.svg');
                right: -60px;
                transform: none;
            }

            &__backward {
                background-image: url('~static/images/svg/rect-white-arrow-left.svg');
                left: -60px;
            }
        }


        &__list {
            .navigation {
                &__forward,
                &__backward {
                    top: 50%;
                    transform: translateY(-50%);
                    border: none;
                }

                &__backward {
                    background: url("~static/images/svg/rect-gray-arrow-left.svg") center no-repeat;
                    left: 0;
                }

                &__forward {
                    background: url("~static/images/svg/rect-gray-arrow-right.svg") center no-repeat;
                    right: 0;
                }
            }
        }
    }
</style>
