import { getFiltersLikeInQuery, isSpecialFilter } from '~/assets/services/category/index';

export default function (activeFilters) {
    const filtersLikeInQuery = getFiltersLikeInQuery(activeFilters);
    const filtersSymbols = Object.keys(filtersLikeInQuery);
    const specialFiltersSymbols = filtersSymbols.filter((filterName) => {
        const filterValue = filtersLikeInQuery[filterName];
        return isSpecialFilter(filterName, filterValue);
    });
    const activeSpecialFilters = activeFilters.filter((activeFilter) => {
        const { filterFieldSymbol } = activeFilter;
        return specialFiltersSymbols.indexOf(filterFieldSymbol) >= 0;
    });
    return activeSpecialFilters.map(specialFilter => specialFilter.filterItemName);
}
