export const CLIENT_TYPES = {
    REST: 'REST',
    GRAPHQL: 'GRAPHQL',
};

export const CLIENT_DEFAULT_CONFIG = {
    watchQuery: {
        fetchPolicy: 'no-cache',
        errorPolicy: 'ignore',
    },
    query: {
        fetchPolicy: 'no-cache',
        errorPolicy: 'all',
    },
};
