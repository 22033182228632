<template>
    <span
        class="sign-warn"
        :class="getBemModifiers()"
    >
        !
    </span>
</template>

<script>
export default {
    name: 'SignWarn',
};
</script>

<style lang="scss">
    .sign-warn {
        font-weight: $font-weight-bold;
        display: flex;
        justify-content: center;
        font-size: 10px;
        line-height: 24px;
        width: 0;
        height: 0;
        color: $color-slate100;
        border-left: 12px solid transparent;
        border-right: 12px solid transparent;
        border-bottom: 19px solid $color-castoYellow;

        &--big-warn {
            border-width: 0 18px 25px 18px;
            font-size: 14px;
            line-height: 30px;
        }
    }

    @media screen and (min-width: $mf-breakpoint-small-desktop) {
        .sign-warn {
            border-width: 0 18px 25px 18px;
            font-size: 14px;
            line-height: 30px;
        }
    }
</style>
