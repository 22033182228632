<template>
    <div
        v-if="showMonit"
        ref="mobileApplicationBar"
        :class="`mobile-application-bar mobile-application-bar__${positionElement} print-hide`"
    >
        <IconTextButton
            :icon="iconCloseLightBlue"
            class="mobile-application-bar__close--icon"
            name="Zamknij"
            @click="closeMonit"
        />
        <Transition
            name="expand"
            class="print-hide"
            @after-enter="setHeightByRef"
        >
            <MobileApplicationBarTop
                v-if="visibleElement === 1"
                @click="closeMonit"
            />
            <MobileApplicationBarBottom
                v-else
                :visible-element="visibleElement"
            />
        </Transition>
        <div
            :class="`mobile-application-bar__wrapper mobile-application-bar__wrapper--${positionElement}`"
        >
            <BaseLink
                :href="mobileApplication.href"
                :class="`mobile-application-bar__wrapper-link mobile-application-bar__wrapper-link--${mobileApplication.type}`"
                @click.native="closeMonit"
            >
                Pobierz aplikacje
            </BaseLink>
        </div>
    </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';
import IconTextButton from '~/components/common/Button/IconTextButton';
import MobileApplicationBarTop from '~/components/MobileApplicationBar/components/MobileApplicationBarTop';
import MobileApplicationBarBottom from '~/components/MobileApplicationBar/components/MobileApplicationBarBottom';
import getNavigatorIOS from '~/assets/services/userAgent/getNavigatorIOS';
import BaseLink from '~/components/common/Link/BaseLink';

const iconCloseLightBlue = require('~/static/images/icons/x-blue.svg');

const {
    mapState: mapStateMobileApplicationBar,
    mapActions: mapActionsMobileApplicationBar,
} = createNamespacedHelpers('mobileApplicationBar');

export default {
    name: 'MobileApplicationBar',
    components: {
        IconTextButton,
        MobileApplicationBarTop,
        MobileApplicationBarBottom,
        BaseLink,
    },
    data() {
        return {
            iconCloseLightBlue,
        };
    },
    computed: {
        ...mapStateMobileApplicationBar({
            showMonit: state => state.isVisible,
        }),
        visibleElement() {
            const numberElementsApplicationBar = 3;
            return Math.floor(Math.random() * numberElementsApplicationBar) + 1;
        },
        positionElement() {
            return this.visibleElement !== 1 ? 'bottom' : 'top';
        },
        mobileApplication() {
            if (getNavigatorIOS()) {
                if (this.visibleElement === 1) {
                    return { href: 'https://click.google-analytics.com/redirect?tid=UA-77897594-2&url=https%3A%2F%2Fitunes.apple.com%2Fapp%2Fid1090445849&aid=pl.castorama.app&idfa=%{idfa}&cs=web-mobile&cm=banner-big', type: 'apple' };
                }
                return { href: 'https://click.google-analytics.com/redirect?tid=UA-77897594-2&url=https%3A%2F%2Fitunes.apple.com%2Fapp%2Fid1090445849&aid=pl.castorama.app&idfa=%{idfa}&cs=web-mobile&cm=banner-small', type: 'apple' };
            }
            if (this.visibleElement === 1) {
                return { href: 'https://play.google.com/store/apps/details?id=pl.looksoft.castorama&referrer=utm_source%3Dweb-mobile%26utm_medium%3Dbanner-big', type: 'google' };
            }
            return { href: 'https://play.google.com/store/apps/details?id=pl.looksoft.castorama&referrer=utm_source%3Dweb-mobile%26utm_medium%3Dbanner-small', type: 'google' };
        },
    },
    mounted() {
        this.checkStateMobileApplicationBar();
    },
    updated() {
        if (this.showMonit && this.$refs.mobileApplicationBar) {
            this.setHeightByRef();
        }
    },
    methods: {
        ...mapActionsMobileApplicationBar(['checkStateMobileApplicationBar', 'setMonitHeight', 'closeMonit']),
        setHeightByRef() {
            this.setMonitHeight(this.$refs.mobileApplicationBar.clientHeight);
        },
    },
};

</script>

<style lang="scss">
    @import '~/assets/css/transitions/expand.scss';
    @media screen and (min-width: $breakpoint-tablet) {
        .mobile-application-bar {
            display: none;
        }
    }
    @media screen and (max-width: $breakpoint-tablet) {
        .mobile-application-bar {
            position: relative;
            padding: 25px;
            background: $color-castoBlue-dark url('~static/images/phone-app-bg.jpg') no-repeat bottom left;
            background-size: auto 100%;

            &__box {
                text-align: center;
            }
            &__bottom {
                left: 0;
                text-align: center;
                bottom: 0;
                position: fixed;
                width: 100%;
                z-index: 100;
            }
            &__title {
                color: $color-castoWhite;
                font: $font-weight-regular 20px/20px $goodHomeFontFamily;
                padding: 0;
                margin: 0;
            }
            &__logo {
                width: 113px;
                height: 15px;
                text-indent: -600px;
                overflow: hidden;
                display: inline-block;
                background: url('~static/images/logo-white-small.svg') no-repeat top left;
                background-size: 100% 100%;
                margin: 12px 0;
                padding: 0;
                position: relative;
                top: 5px;
            }
            &__list {
                width: 100%;
                text-align: center;
                color: $color-castoWhite;
                list-style: none;
                font: $font-weight-regular 14px/14px $goodHomeFontFamily;
            }
            &__item {
                width: 100%;
                display: block;
                margin: 12px 0;
            }
            &__wrapper {
                width: 131px;

                &--top {
                    width: 100%;
                    margin: 16px auto 0;
                    text-align: center;
                }
                &--bottom {
                    width: 50%;
                    float: left;
                }
            }
            &__wrapper-link {
                display: inline-block;
                height: 38px;
                text-indent: -99000px;
                background-size: 100% auto;
                background-position: top center;
                background-repeat: no-repeat;

                &--google {
                    width: 131px;
                    background-image: url('~static/images/social-buttons/application-button-google.png');
                }
                &--apple {
                    width: 129px;
                    background-image: url('~static/images/social-buttons/application-button-apple.png');
                }
            }
            &__close {
                &--text {
                    padding: 20px 0 10px 0;
                    width: 100%;
                    color: $color-castoBlue-light;
                    text-transform: none;
                    font: $font-weight-regular 13px/14px $goodHomeFontFamily;
                }
                &--icon {
                    top: 10px;
                    right: 10px;
                    line-height: 0;
                    position: absolute;

                    .image-base {
                        width: 10px;
                        height: 10px;
                    }
                }
            }
        }
    }
</style>
