import isDateBetween from '~/assets/services/helpers/date/isDateBetween';
import normalizeLabelData from '~/assets/services/productLabel/normalizeData';

export default function (sourceData = {}) {
    const {
        entity_id: id,
        image,
        small_image: smallImage,
        name,
        sku,
        type,
        url,
        free_delivery: freeDelivery,
        energy_value: energyClass,
        only_in_store: onlyInStore,
        brand,
        label_product_catalog: catalogLabel,
        label_product_date_from: catalogLabelActiveFrom,
        label_product_date_to: catalogLabelActiveUntil,
    } = sourceData;

    const product = {
        ...sourceData,
        sku,
        image,
        smallImage,
        name,
        type,
        url,
        freeDelivery,
        energyClass,
        onlyInStore,
        brand,
        productId: parseInt(id, 10),
    };

    if (catalogLabel && isDateBetween({ from: catalogLabelActiveFrom, to: catalogLabelActiveUntil, check: new Date() })) {
        const [firstLabel] = normalizeLabelData(catalogLabel);
        product.labelId = firstLabel;
    }

    return product;
}
