export const types = {
    SET_MONIT_VISIBLE: 'SET_MONIT_VISIBLE',
    SET_MONIT_HEIGHT: 'SET_MONIT_HEIGHT',
};

const mutations = {
    [types.SET_MONIT_VISIBLE](state, isVisible) {
        state.isVisible = isVisible;
    },
    [types.SET_MONIT_HEIGHT](state, height) {
        state.heightMonit = height;
    },
};

export default mutations;
