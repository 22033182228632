<template>
    <div class="special-header container">
        <div class="row">
            <div class="col-md-4 special-header__logo">
                <Logo :bem-modifiers="['position-static', 'small-image-mobile']" />
            </div>
            <div class="col-md-8 special-header__faq">
                <div class="special-header__right-block">
                    <BaseText
                        class="special-header__text mobile-hide"
                        text="Potrzebujesz pomocy przy zamówieniu ?"
                    />
                    <BaseButton
                        class="special-header__help"
                        name="Pomoc online"
                        :bem-modifiers="['color-slate100',
                                         'justify-content-center',
                                         'faq']"
                        @click.native="displayInteliwiseChat"
                    >
                        Pomoc online
                    </BaseButton>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Logo from '~/components/common/Header/Logo';
import BaseText from '~/components/common/Text/BaseText';
import BaseButton from '~/components/common/Button/BaseButton';

export default {
    name: 'SpecialHeader',
    components: {
        Logo,
        BaseText,
        BaseButton,
    },
    methods: {
        /**
         * display inteliwise chat after clicked in faq button
         */
        displayInteliwiseChat() {
            /* eslint-disable camelcase */
            if (typeof IWVA_expand === 'function') {
                /* eslint-disable no-undef */
                IWVA_expand();
            }
        },
    },
};
</script>

<style lang="scss">
    .special-header {
        padding-right: 66px;
        height: 54px;
        background: url('~static/images/faq-guy-small.png') no-repeat;
        background-position: bottom -18px right 12px;
        background-size: 54px;

        &__logo {
            margin: 18px 0;
            float: left;
            width: 156px;
        }

        &__faq {
            float: right;
            width: calc(100% - 156px);
        }

        &__right-block{
            margin-top: 20px;
        }
    }

    @media (min-width: $mf-breakpoint-tablet) {
        .special-header {
            $self: &;

            height: 90px;
            background-size: auto;
            background-position: bottom right;

            &__logo {
                margin-top: 31px;
            }

            &__right-block {
                margin-top: 27px;
                float: right;

                #{$self}__text {
                    color: $color-slate70;
                    font: $font-weight-light 16px/21px $goodHomeFontFamily;
                    text-align: center;
                    vertical-align: middle;
                    max-width: 160px;
                    display: block;
                    float: left;
                }

                #{$self}__help {
                    float: left;
                    width: 136px;
                    margin: 0 48px;
                }

                &::after {
                    display: table;
                    clear: both;
                    content: '';
                }
            }
        }
    }
</style>
