import restApiRequestFactory from '~/src/utils/helpers/restApiRequestFactory';
import { GET_PRODUCT_SHIPPING_DATA as urlTemplate } from './api.config';
import { shippingMethods as normalizeData } from './normalizeData';

export default async function ({ productId: id, storeId }) {
    try {
        const { data } = await restApiRequestFactory({
            urlTemplate,
            urlTemplateProps: {
                id,
                storeId,
            },
            method: 'get',
        });
        const { items } = data;
        return normalizeData(items);
    } catch {
        // @todo log errors
        return [];
    }
}
