import { types } from './mutations';
import {
    getCatalogTree,
    getInspirationsTree,
    getForumTree,
    getNewspaperData,
} from '~/assets/services/menu';

export default {
    async fetchCatalogTree({ commit }) {
        const catalogTree = await getCatalogTree();
        commit(types.SET_CATALOG_TREE, catalogTree);
    },
    async fetchInspirationsTree({ commit }) {
        const inspirationTree = await getInspirationsTree();
        commit(types.SET_INSPIRATIONS_TREE, inspirationTree);
    },
    async fetchForumTree({ commit }) {
        const forumTree = await getForumTree();
        commit(types.SET_FORUM_TREE, forumTree);
    },
    async fetchNewspaperData({ commit }) {
        const newspaperData = await getNewspaperData();
        const { image, title, url } = newspaperData;
        if (image && title && url) {
            commit(types.SET_NEWSPAPER_DATA, newspaperData);
        }
    },
};
