export default function (activeFilters) {
    let simpleFiltersCollection = {};
    if (activeFilters) {
        activeFilters.forEach((item) => {
            if (item.filterType !== 'basic') {
                if (!simpleFiltersCollection[item.filterFieldSymbol]) {
                    simpleFiltersCollection = { ...simpleFiltersCollection, [item.filterFieldSymbol]: [] };
                }
                simpleFiltersCollection[item.filterFieldSymbol].push(item.filterItemSymbol);
            }
        });
    }
    return simpleFiltersCollection;
}
