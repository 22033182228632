export default function (AvailabilityInOtherStores) {
    let normalizedAvailabilityInOtherStores = [];

    AvailabilityInOtherStores.forEach((AvailabilityInOtherStoreData) => {
        const { availability,
            backorder_from: backorderFrom,
            backorder_to: backorderTo,
            id,
            nazwa: name,
            qty,
            store_code: storeId,
            telefon: phone,
            ulica: street,
        } = AvailabilityInOtherStoreData;

        normalizedAvailabilityInOtherStores = [...normalizedAvailabilityInOtherStores, {
            availability,
            backorderFrom,
            backorderTo,
            name,
            phone,
            street,
            qty: Number(qty),
            storeId: parseInt(storeId, 10),
            shopId: parseInt(id, 10),
        }];
    });

    return normalizedAvailabilityInOtherStores;
}
