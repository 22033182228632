import axios from 'axios';
import { categoryData } from '~/assets/services/category/normalizeData';
import { FETCH_CATEGORY_DATA } from '~/assets/services/category/api.config';
import { getExtendedSearchCriteriaParams, getParamsFromSearchCriteriaObject } from '~/assets/services/searchCriteria';

export default async function ({ id, brandName }) {
    const filters = [
        {
            field: 'entity_id',
            value: id,
            conditionType: 'eq',
        },
    ];

    if (brandName) {
        filters.push({
            field: 'brand_name',
            value: encodeURIComponent(brandName),
            conditionType: 'eq',
        });
    }
    const mainCategoryDataCriteriaParams = getExtendedSearchCriteriaParams(filters);

    let response;
    try {
        response = await axios({
            method: 'get',
            url: FETCH_CATEGORY_DATA + getParamsFromSearchCriteriaObject(mainCategoryDataCriteriaParams),
        });
        const { data } = response;
        return categoryData(data[0]);
    } catch (message) {
        // @todo log errors
        throw new Error(`We dont have data for category id: ${id} -- ${message}`);
    }
}
