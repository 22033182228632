import { getGoogleAdNotProcessed } from '~/assets/services/lazyLoadLauncher';
import { processProgressiveImage } from '~/assets/services/DOMManipulate';
import isInViewport from '~/assets/services/DOMElement/isInViewport';

window.addEventListener('message', async (event) => {
    if (event.origin !== process.env.baseUrl) {
        return false;
    }
    try {
        const { data } = event;
        const dataObject = JSON.parse(data);
        if (dataObject.googMsgType) {
            const lazyLoadElements = await getGoogleAdNotProcessed();
            if (lazyLoadElements.length) {
                lazyLoadElements.forEach((lazyLoadElement) => {
                    const { iframeElement, imageElements, preloaderElement, backgroundElement, iframeWrapper } = lazyLoadElement;
                    if (imageElements.length && !iframeWrapper.classList.contains('loaded')) {
                        const dataIsInViewPort = isInViewport({ element: iframeElement });
                        const { checkingIsInViewport } = dataIsInViewPort;

                        if (!iframeElement.locationChangeHandle) {
                            iframeElement.locationChangeHandle = true;
                            window.addEventListener('pushState', function locationChangeHandle() {
                                if (dataIsInViewPort && dataIsInViewPort.intersectionObserver) {
                                    dataIsInViewPort.intersectionObserver.disconnect();
                                    dataIsInViewPort.intersectionObserver = null;
                                }
                                window.removeEventListener('pushState', locationChangeHandle);
                            });
                        }

                        checkingIsInViewport.then(() => {
                            imageElements.forEach((image) => {
                                image.progressiveImageData = {
                                    onError() {
                                        image.classList.add('progressive-error');
                                    },
                                };
                                processProgressiveImage(image).then(() => {
                                    if (backgroundElement) {
                                        backgroundElement.className = backgroundElement.className.replace('bold-loading', '');
                                    }
                                    if (preloaderElement) {
                                        preloaderElement.style.opacity = 0;
                                    }
                                    iframeWrapper.classList.add('loaded');
                                });
                            });
                        });
                    }
                });
            }
        }
    } catch {
        return false;
    }
}, false);
