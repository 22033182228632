<template>
    <BaseButton
        class="choose-shop-button"
        :class="getBemModifiers()"
        :bem-modifiers="['horizontally-centered']"
        name="Wybierz sklep"
        @click.native="changeShop"
    >
        Wybierz sklep
    </BaseButton>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';
import BaseButton from './BaseButton';

const { mapActions: mapActionsPopup } = createNamespacedHelpers('popup');

export default {
    name: 'ChooseShopButton',
    components: {
        BaseButton,
    },
    methods: {
        ...mapActionsPopup(['showPopup']),
        async changeShop() {
            const { default: instance } = await import('~/components/common/Popup/ChangeShopPopup' /* webpackChunkName: "popup/change-shop" */);
            this.showPopup({ instance });
        },
    },
};
</script>

<style lang="scss">
    .choose-shop-button {
        background: $color-castoYellow;
        color: $color-castoBlue;
        width: 100%;
        font: $font-weight-bold 17px/38px $goodHomeFontFamily;

        &:hover {
            background: $color-castoYellow;
        }

        &--product-tail {
            height: 60px;
            display: inline-block;
            width: 100%;
            margin: 0;
        }
    }

    @media screen and (min-width: $mf-breakpoint-tablet) {
        .choose-shop-button--product-tail {
            margin-top: 0;
        }
    }
</style>
