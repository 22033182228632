import loadScript from '~/assets/services/loadScript';
import { googleRecaptcha as googleRecaptchaLibUrl } from '~/src/config/externalLibsUrl';

export default function ({ app }, inject) {
    let scriptLoaded = false;
    let scriptElement = null;

    function executeAction({ callback, errorHandler, action }) {
        window.grecaptcha.ready(() => {
            window.grecaptcha.execute(process.env.GOOGLE_RECAPTCHA_TOKEN, { action })
                .then(callback, errorHandler);
        });
    }

    app.router.beforeEach((from, to, next) => {
        if (scriptElement) {
            scriptElement.remove();
        }
        scriptLoaded = false;
        next();
    });

    inject('googleRecaptchaV3Handler', ({ callback, errorHandler, action }) => {
        if (scriptLoaded) {
            executeAction({ callback, errorHandler, action });
        } else {
            loadScript({
                url: `${googleRecaptchaLibUrl}?render=${process.env.GOOGLE_RECAPTCHA_TOKEN}`,
                callback({ element }) {
                    executeAction({ callback, errorHandler, action });
                    scriptLoaded = true;
                    scriptElement = element;
                },
                fail: errorHandler,
            });
        }
    });
}
