export default function (objectToFetch, arrayOfFetchedItems = []) {
    const objectWithoutFetchedItems = { ...objectToFetch };
    let fetchedItems = {};
    arrayOfFetchedItems.forEach((itemToFetch) => {
        fetchedItems = { ...fetchedItems, [itemToFetch]: objectToFetch[itemToFetch] };
        delete objectWithoutFetchedItems[itemToFetch];
    });
    return {
        objectWithoutFetchedItems,
        fetchedItems,
    };
}
