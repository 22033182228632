export default function () {
    return {
        isEnabled: null,
        minPrice: null,
        url: null,
        installmentsNumber: null,
        shopNumber: null,
        dateStart: null,
        dateEnd: null,
    };
}
