import axios from 'axios';
import 'url-search-params-polyfill';
import { NEAREST_MARKET_WITH_PRODUCT_AVAILABLE } from './api.config';

export default function ({ storeId, productId }) {
    const search = {
        storeId,
        productId,
    };
    return axios({
        method: 'get',
        params: search,
        url: NEAREST_MARKET_WITH_PRODUCT_AVAILABLE,
        headers: {
            'Content-Type':
                'application/x-www-form-urlencoded; charset=UTF-8',
        },
    });
}
