<template>
    <div
        v-if="footerSections"
        class="footer-container"
    >
        <div class="container">
            <div class="footer-row row">
                <MenuTop
                    :menu-sections="topMenuFooterSections"
                />
                <MenuBottom
                    :first-menu-sections="bottomMenuFooterFirstSection"
                    :second-menu-sections="bottomMenuFooterSecondSection"
                />
                <Copyright />
                <NortonSecured />
            </div>
        </div>
    </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';
import MenuTop from '~/components/common/Footer/MenuTop';
import MenuBottom from '~/components/common/Footer/MenuBottom';
import Copyright from '~/components/common/Footer/Copyright';
import NortonSecured from '~/components/common/NortonSecured';
import { menuFooterSections } from '~/assets/config/footer';
import { separateItemsFromStructure } from '~/src/utils/helpers';

const { mapState: mapStateFooter } = createNamespacedHelpers('footer');

export default {
    name: 'BaseFooter',
    components: {
        MenuTop,
        MenuBottom,
        Copyright,
        NortonSecured,
    },
    computed: {
        ...mapStateFooter({
            footerSections: state => state.footerSections,
        }),
        topMenuFooterSections() {
            return separateItemsFromStructure(this.footerSections, menuFooterSections.top, 'symbol', false).separatedItems;
        },
        bottomMenuFooterFirstSection() {
            return separateItemsFromStructure(this.footerSections, menuFooterSections.bottom.first, 'symbol', false).separatedItems;
        },
        bottomMenuFooterSecondSection() {
            return separateItemsFromStructure(this.footerSections, menuFooterSections.bottom.second, 'symbol', false).separatedItems;
        },
    },
};
</script>

<style lang="scss">
.footer-container {
    color: $color-castoWhite;
    background-color: $color-slate100;
    font: $font-weight-regular 12px/16px $goodHomeFontFamily;
    padding-top: 43px;
    padding-bottom: 15px;
    overflow: hidden;

    .container {
        max-width: 1176px;
    }

    .show-only-mobile {
        display: none;
    }

    .no-padding {
        padding-left: 0;
        padding-right: 0;
    }
}

@media (max-width: $mf-breakpoint-tablet) {
    .footer-container {
        & > .container {
            padding: 0;
            max-width: 100%;
        }

        .footer-row {
            margin: 0;
        }
    }
}
</style>
