export default {
    event: 'productClick',
    ecommerce: {
        click: {
            actionField: {
                action: 'click',
            },
            products: [],
        },
    },
    eventCallback: null,
};
