import { TYPES as ROUTE_TYPES } from '~/src/config/dictionary/route';
import priceCurrency from '~/src/config/dictionary/priceCurrency';
import { CODES as AVAILABILITY_CODES } from '~/assets/services/availability/config';

const SAMITO_DATALAYER_KEY = 'SamitoDataLayer';
const SAMITO_CALLBACK_KEY = 'SamitoCallbacks';

const SAMITO_PAGE_VIEW_TYPES_BY_ROUTE_TYPES = {
    [ROUTE_TYPES.category]: 'category',
    [ROUTE_TYPES.product]: 'product',
};

const samitDataLayerResolversByType = {
    /**
     * This function return data on category page view for samito data layer.
     *
     * @param {String} name - name of category
     * @param {Array} parentsTree - array of category parents
     *
     * @return {Object}
     */
    [ROUTE_TYPES.category]({ name, allCategories }) {
        return {
            category: name,
            allCategories,
        };
    },
    /**
     * This function return data on product page view for samito data layer.
     *
     * @param {String} productId - product sku code.
     * @param {String} name.
     * @param {String} image.
     * @param {String} availabilityCode.
     * @param {String} url.
     * @param {Number} price.
     * @param {String} category - name of product category.
     * @param {String} metaDesc - description.
     * @param {String} currency.
     * @param {Number,Null} oldPrice - price before discount.
     * @param {Null} metaKeys = null.
     * @param {String,Null} color = null.
     * @param {String,Null} size = null.
     * @param {String,Null} brand - product brand name.
     *
     * @return {Object}
     */
    [ROUTE_TYPES.product]({
        sku: productId,
        name,
        image,
        availabilityCode,
        url,
        priceValue: price,
        category,
        description: metaDesc,
        currency = priceCurrency.pl.iso4217,
        priceOldValue: oldPrice = null,
        metaKeys = null,
        color = null,
        size = null,
        brandName: brand = null,
    }) {
        return {
            url,
            category,
            name,
            price,
            oldPrice,
            currency,
            image,
            brand,
            metaDesc,
            metaKeys,
            color,
            size,
            available: availabilityCode === AVAILABILITY_CODES.in ? 1 : 0,
            id: `${productId}`,
        };
    },
};

/**
 * This function select data resolver by page type and run it.
 *
 * @param {String} type - page view type
 * @param {*} data - its payload for pageView resolver.
 *
 * @return {Object}
 */
function getPageViewDataByType(type, data) {
    return samitDataLayerResolversByType[type] ? samitDataLayerResolversByType[type](data) : {};
}

/**
 * This function check if global namespace contain function and run it.
 *
 * @param {String} callbackName - name of function inside samito callback object
 * @param {*} data - its payload for samito callback.
 *
 * @return {undefined}
 */
function runSamitoCallback(callbackName, data) {
    if (window && window[SAMITO_CALLBACK_KEY] && window[SAMITO_CALLBACK_KEY][callbackName]) {
        window[SAMITO_CALLBACK_KEY][callbackName]({ data });
    }
}

/**
 * This function prepare data for miniBasket in samito data layer.
 *
 * @param {Array} items - items in cart.
 * @param {Number} total - basket price summary.
 * @param {String} currency
 *
 * @return {Object}
 */
function getCartData({ items, totalPrice: total, currency }) {
    return {
        products: items.map(({ price_per_unit: price, image, qty: quantity, name, url, sku: id }) => ({
            url,
            id,
            name,
            price,
            quantity,
            image,
        })),
        total,
        currency,
    };
}

/**
 * This function prepare data for user in samito data layer.
 *
 * @param {String, Null} email - user email.
 * @param {Boolean} isCustomerLogged - it contain true if user has been logged in.
 *
 * @return {Object}
 */
function getCustomerData({ isCustomerLogged, email }) {
    return {
        email,
        logged: isCustomerLogged ? 1 : 0,
    };
}

/**
 * This function prepare data for physicalShop in samito data layer.
 *
 * @param {Object,Null} storeInfo - selected store data like address, name etc.
 * @param {Object,Null} selectedShopIds - object of selected store id's (shopId and storeId).
 * @param {Boolean} storeSelected - it contain true if user has selected the store.
 *
 * @return {Object}
 */
function getMarketData({ storeInfo, selectedShopIds, storeSelected }) {
    if (!storeSelected || !storeInfo || !selectedShopIds) {
        return {
            id: null,
            name: null,
            address: null,
        };
    }

    const { name, address } = storeInfo;
    const { city, postal: zipCode, street } = address;
    return {
        id: selectedShopIds.storeId,
        name,
        address: {
            city,
            street,
            zipCode,
            number: null,
        },
    };
}

/**
 * This plugin inject functionality for samito data layer.
 *
 * @param {Object} store - store from nuxt context - vuex
 * @param {Object} route - route from nuxt context - vue router
 * @param {Function} inject - inject from nuxt which allow add functionality for whole app
 *
 * @return {undefined}
 */
export default async ({ store }, inject) => {
    const { state } = store;
    const {
        cart: cartState = {},
        customer: customerState = {},
        markets: marketsState = {},
    } = state;
    let samitoDataLayerObject = {};

    window[SAMITO_CALLBACK_KEY] = window[SAMITO_CALLBACK_KEY] || {};

    Object.defineProperty(window, SAMITO_DATALAYER_KEY, {
        get() {
            return {
                ...samitoDataLayerObject,
                miniBasket: getCartData({ ...cartState, currency: customerState.currentCurrency.iso4217 }),
                user: getCustomerData(customerState),
                physicalShop: getMarketData(marketsState),
            };
        },
        set(newValue) {
            samitoDataLayerObject = newValue;
        },
    });

    inject('samitoDataLayer', {
        set: ({ pageType, pageData }) => {
            store.dispatch('cart/fetchDataIfNeed');

            window[SAMITO_DATALAYER_KEY] = {
                pageView: {
                    type: SAMITO_PAGE_VIEW_TYPES_BY_ROUTE_TYPES[pageType],
                    data: {
                        ...getPageViewDataByType(pageType, pageData),
                    },
                },
            };
            runSamitoCallback('afterSet', window[SAMITO_DATALAYER_KEY]);
        },
    });
};
