import polishChar from '~/assets/services/array/sort/comparators/polishChar';

export default function (array, dir, key) {
    switch (dir) {
        case 'asc':
            return array.sort((a, b) => polishChar(a, b, true, key));
        case 'desc':
            return array.sort((a, b) => polishChar(a, b, false, key));
        default:
            return array;
    }
}
