import axios from 'axios';
import 'url-search-params-polyfill';

import GET_GOOGLE_SIGN_IN_URL from './api.config';

export default ({ idToken }) => {
    const idCustomer = new URLSearchParams({
        code: idToken,
    });
    return axios({
        method: 'post',
        url: GET_GOOGLE_SIGN_IN_URL,
        data: idCustomer.toString(),
        config: {
            headers: {
                'Content-Type':
                        'application/x-www-form-urlencoded; charset=UTF-8',
                'Access-Control-Allow-Credentials': true,
            },
        },
    });
};
