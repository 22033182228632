<template>
    <div
        v-if="productDataWithPrice.length"
        class="recently-viewed-products"
    >
        <div class="container">
            <p class="recently-viewed-products__title">
                Ostatnio oglądane produkty
            </p>
            <div class="recently-viewed-products__box">
                <CustomCarousel
                    :per-page-custom="[
                        [0, 2.2],
                        [768, 3],
                        [991, 4],
                        [1200, 5],
                    ]"
                    :navigation-enabled="true"
                    type-navigation="isCustomNavigation"
                    :pagination-enabled="paginationEnabled"
                    :is-custom-pagination="paginationEnabled"
                >
                    <!-- eslint-disable-next-line vue/component-name-in-template-casing -->
                    <slide
                        v-for="(recentlyProduct, index) in productDataWithPrice"
                        :key="index"
                        class="recently-viewed-products__box-item-wrapper"
                    >
                        <ProductTail
                            bem-modifiers="recently-viewed"
                            name-dom-tag="p"
                            class="recently-viewed-products__box--item"
                            v-bind="{
                                ...recentlyProduct,
                            }"
                        />
                    </slide>
                </CustomCarousel>
            </div>
        </div>
    </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';
import CustomCarousel from '~/components/common/Carousel/CustomCarousel';
import { getRecentlyProducts } from '~/assets/services/storages/local-storage/recentlyProducts';
import ProductTail from '~/components/ProductTail';
import { productsForDataLayer } from '~/assets/services/dataLayer/dataLayerProducts';
import priceProviderSubscriber from '~/src/mixins/priceProviderSubscriber';
import priceProviderObserver from '~/src/mixins/priceProviderObserver';

const { mapState: mapStateWindowOptions } = createNamespacedHelpers('windowOptions');
const { mapActions: mapActionGoogleServices } = createNamespacedHelpers('googleServices');

export default {
    name: 'RecentlyProducts',
    components: {
        CustomCarousel,
        ProductTail,
    },
    mixins: [
        priceProviderSubscriber(),
        priceProviderObserver('recentlyProducts'),
    ],
    props: {
        idsExcluded: {
            type: Array,
            default: () => [],
        },
    },
    data() {
        return {
            productsData: [],
        };
    },
    computed: {
        ...mapStateWindowOptions({
            isTablet: state => state.isTablet,
            isMobile: state => state.isMobile,
        }),
        productDataWithPrice() {
            return this.productsData
                .map(productData => ({ ...productData, ...this.priceData[productData.productId] }))
                .filter(({ productId }) => this.idsExcluded.indexOf(productId) === -1);
        },
        productsIdsForPriceProvider() {
            return this.productsData
                .filter(({ productId }) => !this.idsExcluded.includes(productId))
                .map(({ productId }) => productId);
        },
        paginationEnabled() {
            let itemsPerWindowSize = 5;
            if (this.isTablet) {
                itemsPerWindowSize = 3;
            }
            if (this.isMobile) {
                itemsPerWindowSize = 1.5;
            }
            return this.productDataWithPrice.length > itemsPerWindowSize;
        },
    },
    watch: {
        productDataWithPrice() {
            if (Object.keys(this.productDataWithPrice).length) {
                this.setDataLayerProducts({ type: 'recentlyProducts', products: productsForDataLayer(this.productDataWithPrice) });
            }
        },
        // eslint-disable-next-line func-names
        '$route.path': function () {
            this.forceUpdatePrices();
            this.getLocalStorageData();
        },
    },
    mounted() {
        this.getLocalStorageData();
    },
    methods: {
        ...mapActionGoogleServices(['setDataLayerProducts']),
        getLocalStorageData() {
            this.productsData = getRecentlyProducts();
        },
    },
};
</script>

<style lang="scss">
    .recently-viewed-products {
        @include centralize();
        padding: 48px 12px;
        background: $color-slate5;

        &__title {
            font: $font-weight-bold 24px/24px $goodHomeFontFamily;
            color: $color-slate100;
            margin-top: 0;
            padding-top: 0;
            padding-bottom: 0;
            margin-bottom: 36px;
        }

        .VueCarousel-slide {
            padding: 0 12px 0 0;
        }

        &__box {
            &--item {
                display: inline-block;
            }
        }

        &__box-item-wrapper:first-child {
            margin-left: 0;
        }

        .navigation__forward {
            z-index: 3;
            right: 6px;
        }

        .navigation__backward {
            left: -17px;
            z-index: 3;
        }

        .pagination {
            top: -36px;
            right: 24px;

            .pagination__button {
                margin: 0;
                padding: 0;
                text-align: center;
                width: 24px;
                font: $font-weight-regular 14px/20px $baseFontFamily;

                &--count {
                    margin: 0;
                    padding: 0;
                    width: 100%;
                    line-height: 24px;
                }
            }
        }

        .product-tail {
            max-width: 175px;
            width: 100%;

            .product-image__wrapper {
                min-height: 175px;
            }

            .energy-class {
                width: 40px;
                height: 20px;
                line-height: 20px;

                &::after {
                    border-top-width: 10px;
                    border-bottom-width: 10px;
                }

                &--level11 {
                    font-size: 9px;
                    line-height: 9px;
                    padding-top: 1px;

                    .energy-class__element {
                        width: 100%;
                        margin-left: -2px;
                    }
                }

                &--levelspecial.energy-class  {
                    font-size: 14px;
                    letter-spacing: -2px;
                    margin-right: 1px;
                    padding-left: 2px;

                    .energy-class__element--special{
                        font-size: 10px;
                    }
                }
            }
        }
    }

    @media (min-width: $mf-breakpoint-small-desktop) and (max-width: $mf-breakpoint-big-desktop) {
        .recently-viewed-products {
            .pagination {
                right: -6px;
            }
            .navigation__forward {
                right: -22px;
            }
            .navigation__backward {
                left: -22px;
            }

            .product-tail {
                max-width: 200px;

                .product-image__wrapper {
                    max-height: 200px;
                }
            }
        }
    }

    @media (min-width: $mf-breakpoint-tablet) and (max-width: $mf-breakpoint-small-desktop) {
        .recently-viewed-products {
            &__title,
            &__box {
                width: 90%;
                margin: 0 auto;
            }

            &__title {
                margin-bottom: 36px;
            }

            .product-tail {
                max-width: 190px;

                .product-image__wrapper {
                    max-height: 186px;
                }
            }
        }
    }

    @media (min-width: $mf-breakpoint-tablet) {
        .recently-viewed-products {
            .VueCarousel {
                height: 318px;

                &:hover {
                    .VueCarousel-wrapper {
                        height: 561px;
                        z-index: 2;
                    }
                }
            }
        }
    }

    @media (min-width: $mf-breakpoint-big-desktop) {
        .recently-viewed-products {
            .VueCarousel {
                height: 350px;
            }

            .product-tail {
                max-width: 200px;

                .product-image__wrapper {
                    min-height: 200px;
                }
            }
        }
    }

    @media screen and (max-width: $mf-breakpoint-tablet) {
        .recently-viewed-products {
            padding: 0 #{$spacing-default} 17px #{$spacing-default};
            width: calc(100% + #{$spacing-default});

            > .container {
                padding: 0;
            }

            .navigation__forward, .navigation__backward, .pagination, .click-to-action-product {
                display: none;
            }

            .product-tail:hover .product-tail__hover-section, .product-tail__hover-section {
                display: none;
            }

            &__title {
                font-size: 20px;
                line-height: 24px;
                padding: 19px 2px;
                margin-bottom: 0;
            }

            .product-tail {
                .energy-class {
                    width: 37px;
                    height: 18px;
                    line-height: 18px;

                    &::after {
                        border-top-width: 9px;
                        border-bottom-width: 9px;
                        border-left-width: 9px;
                        right: -9px;
                    }

                    .energy-class__element:not(.energy-class__element--special) {
                        font-size: 16px;
                        top: 0;
                    }

                    &--level11 {
                        font-size: 8px;
                        line-height: 7px;
                        padding-top: 1px;
                        padding-left: 7px;

                        .energy-class__element {
                            font-size: 8px;
                        }
                    }

                    &--levelspecial.energy-class  {
                        .energy-class__element {
                            font-size: 12px;
                            left: -3px;
                        }

                        .energy-class__element--special {
                            font-size: 8px;
                        }
                    }
                }
            }
        }
    }
</style>
