import normalizePriceData from '~/assets/services/price/normalizeData';
import energyClass from './energyClass';

export default function (productsInSet) {
    return Object.keys(energyClass({ products: productsInSet }).products).map(productInSetId => ({
        ...normalizePriceData({
            productId: productInSetId,
            ...productsInSet[productInSetId],
        }),
        defaultQty: productsInSet[productInSetId].default_qty,
    }));
}
