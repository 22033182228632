export default function (arrayOfItems) {
    let arrayToOperate = [...arrayOfItems];
    let itemIndex = -1;

    return {
        getIndex: () => itemIndex,
        getArray: () => arrayToOperate,
        findIndexByKey(key, val) {
            itemIndex = arrayToOperate.findIndex(singleItem => singleItem[key] === val);

            return this;
        },
        deleteIndexFound() {
            if (itemIndex !== -1) {
                arrayToOperate.splice(itemIndex, 1);
            }

            return this;
        },
        unshift(el) {
            arrayToOperate.unshift(el);

            return this;
        },
        clean() {
            arrayToOperate = arrayToOperate.filter(val => (val !== undefined && val !== null));

            return this;
        },
        slice(begin, end) {
            arrayToOperate = arrayToOperate.slice(begin, end);

            return this;
        },
    };
}
