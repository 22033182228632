import getBasicSearchCriteriaParams from '~/assets/services/searchCriteria/getBasicSearchCriteriaParams';
import getExtendedSearchCriteriaParams from '~/assets/services/searchCriteria/getExtendedSearchCriteriaParams';
import compressSearchCriteriaParamsBy from '~/assets/services/searchCriteria/compressSearchCriteriaParamsBy';
import getParamsFromSearchCriteriaObject from '~/assets/services/searchCriteria/getParamsFromSearchCriteriaObject';

export default function (filters = {}) {
    const searchParamsFromExtendedFilter = filters.extendedFilters.map(item => ({
        field: item.filterFieldSymbol,
        value: item.filterItemSymbol,
        conditionType: 'eq',
    }));
    const basicFilterParams = getBasicSearchCriteriaParams(filters.basicFilters);
    const extendedFilterParams = getExtendedSearchCriteriaParams([
        ...compressSearchCriteriaParamsBy(searchParamsFromExtendedFilter, 'field'),
    ]);

    const normalizedFilters = [...basicFilterParams, ...extendedFilterParams].map((item) => {
        let value = encodeURIComponent(item.value);
        if (item.value instanceof Array) {
            value = item.value.map(itemValue => encodeURIComponent(itemValue));
        }
        return {
            ...item,
            value,
        };
    });

    return getParamsFromSearchCriteriaObject(normalizedFilters);
}
