import axios from 'axios';
import FETCH_SESSION_MESSAGES from './api.config';
import normalizeData from './normalizeData';

export default async function () {
    try {
        const { data } = await axios({
            method: 'get',
            url: FETCH_SESSION_MESSAGES,
        });
        return normalizeData(data);
    } catch (message) {
        return [];
    }
}
