<template>
    <Component
        :is="LinkComponent"
        v-bind="dataToBind"
    >
        <slot />
    </Component>
</template>

<script>
import { mapState } from 'vuex';
import { GLOBAL_VARS } from '~/config';
import BaseLink from '~/components/common/Link/BaseLink';

const { MODE_TEST_VALUE } = GLOBAL_VARS;
export default {
    name: 'LinkByMode',
    components: {
        BaseLink,
    },
    props: {
        url: {
            type: String,
            required: true,
        },
        text: {
            type: String,
            default: '',
        },
        target: {
            type: String,
            default: '_self',
        },
        title: {
            type: String,
            default: '',
        },
        modeName: {
            type: String,
            default: MODE_TEST_VALUE,
        },
    },
    computed: {
        ...mapState({
            stateModeName: state => state.mode,
        }),
        isModeSet() {
            return this.modeName === this.stateModeName;
        },
        LinkComponent() {
            if (this.isModeSet) {
                return 'NuxtLink';
            }
            return BaseLink;
        },
        dataToBind() {
            const { url, ...data } = this.$props;

            if (this.LinkComponent === BaseLink) {
                data.href = this.$props.url;
            } else {
                data.to = this.$props.url;
            }

            return data;
        },
    },
};
</script>
