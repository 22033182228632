export default {
    breadcrumbLink: {
        url: 'porownanie-produktow',
        text: 'Porównanie produktów',
    },
    queryKey: {
        paramName: 'sku',
        separator: '-',
    },
    bundleConfig: {
        numberOfElementsToDisplay: 5,
    },
    siteTitle: 'Produkty do porównania - Castorama',
    pageUrl: '/porownanie-produktow',
};
