const doubled = ['A++/A+', 'A+/A++', 'A/A+'];
const withShadow = ['A', 'B'];
const withVerbalRange = ['od A++ do A', 'od A+++ do A+'];
const rangeWords = ['od', 'do'];
const defaultSeparator = '/';
const all = ['A+++', 'A++', 'A+', 'A', 'B', 'C', 'D', 'E', 'F', 'G', 'K', ...doubled, ...withVerbalRange];
const singleEnergyClasses = all.filter(energyClasses => energyClasses.indexOf([defaultSeparator, ...rangeWords]) < 0);
const letters = singleEnergyClasses.filter(energyClass => energyClass.indexOf('+') < 0);
const quantifiers = [
    '',
    ...singleEnergyClasses.filter(energyClass => energyClass.indexOf('+') >= 0).map(value => value.slice(1)),
];
const cssBase = 'energy-classes';
const specialClasses = {
    multiple: `${cssBase}--multiple`,
    verbalRange: `${cssBase}--verbal-range`,
};

export default {
    default: false,
    all,
    doubled,
    withShadow,
    withVerbalRange,
    rangeWords,
    defaultSeparator,
    letters,
    quantifiers,
    cssOptions: {
        base: cssBase,
        specialClasses,
    },
};
