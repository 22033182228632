<template>
    <nav
        class="catalog-menu-list__wrapper"
        v-on="listeners"
    >
        <ul class="catalog-menu-list">
            <li
                v-for="(category) in list"
                :key="category.url"
                class="catalog-menu-list__category-item"
            >
                <NuxtLink
                    :to="category.url.replace(baseUrl, '')"
                    :title="category.name"
                    class="category-item__title"
                    @click.native="emitChangeRoute"
                >
                    <BaseImage
                        :data="{
                            src: category.thumbnail,
                            alt: category.name,
                        }"
                        class="title__thumbanail"
                    />
                    <span
                        class="title__text"
                    >
                        {{ category.name }}
                    </span>
                </NuxtLink>
                <ul class="category-item__menu">
                    <li
                        v-for="(subcategory) in category.list"
                        :key="subcategory.url"
                        class="menu__item"
                    >
                        <NuxtLink
                            :to="subcategory.url.replace(baseUrl, '')"
                            :title="subcategory.name"
                            class="item__link"
                            @click.native="emitChangeRoute"
                        >
                            {{ subcategory.name }}
                        </NuxtLink>
                    </li>
                </ul>
            </li>
        </ul>
    </nav>
</template>

<script>
import BaseImage from '~/components/common/Image/BaseImage';

export default {
    name: 'CatalogMenuList',
    components: { BaseImage },
    props: {
        list: {
            type: Array,
            required: true,
        },
    },
    data() {
        return {
            baseUrl: process.env.baseUrl,
        };
    },
    computed: {
        listeners() {
            const { mouseout, mouseover } = this.$listeners;
            return {
                mouseout,
                mouseover,
            };
        },
    },
    methods: {
        emitChangeRoute() {
            this.$emit('changeRoute');
        },
    },
};
</script>

<style lang="scss">
    @import '~/assets/css/sprites/header-desktop.scss';

    .catalog-menu-list {
        box-sizing: content-box;
        background: $color-castoWhite;
        column-count: 3;

        &__wrapper {
            position: absolute;
            background-color: $color-castoWhite;
            padding: 19px 21px 0 24px;
            display: block;
            overflow-y: scroll;
            overflow-x: hidden;
            max-height: calc(100vh - 113px);
            min-height: 500px;
            width: calc(100vw - 570px - 65px);
            top: 0;
        }

        &__category-item {
            vertical-align: top;
            width: 100%;
            display: inline-block;
            padding: 0 0 24px 12px;
            break-inside: avoid;

            a {
                text-decoration: none;
            }

            .category-item {
                &__title {
                    display: flex;
                    align-items: center;
                    margin-bottom: 10px;
                    padding-right: 20px;
                    text-align: left;
                    color: $color-castoBlue;
                    font-size: 15px;

                    &:hover {
                        color: $color-slate100;
                    }

                    &:focus {
                        text-decoration: underline;
                    }

                    .title {
                        &__thumbanail {
                            display: inline;
                        }

                        &__text {
                            display: inline;
                            color: $color-castoBlue;
                            margin-left: 5px;
                            font: $font-weight-regular 15px/15px $goodHomeFontFamily;

                            &:hover {
                                color: $color-slate100;
                            }
                        }
                    }
                }

                &__menu {
                    width: 100%;
                    padding: 0;
                    margin: 0;
                    text-align: left;

                    .menu__item {
                        position: relative;
                        color: $color-slate100;
                        font-size: 12px;
                        line-height: 18px;
                        padding-left: 12px;
                        display: inline-block;
                        width: 100%;

                        .item__link {
                            color: $color-slate100;

                            &:hover {
                                color: $color-castoBlue;

                                &::before {
                                    position: absolute;
                                    content: '';
                                    display: block;
                                    @include spriteHeaderDesktop('arrow-right-blue', false, true);
                                    background-size: 7px 10px;
                                    width: 9px;
                                    height: 12px;
                                    left: 0;
                                    top: 2px;
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    @media (min-width: $breakpoint-small-desktop) {
        .catalog-menu-list {
            column-count: 4;
        }
    }
</style>
