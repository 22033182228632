import ApolloClient from 'apollo-client';
import { CLIENT_TYPES } from '~/config/apollo';
import getClientConfigGraphQL from '~/src/apollo/getClientConfigGraphQL';
import getClientConfigRest from '~/src/apollo/getClientConfigRest';

/**
 * Config create handlers based on client type
 */
const configCreateHandlers = {
    [CLIENT_TYPES.GRAPHQL]: getClientConfigGraphQL,
    [CLIENT_TYPES.REST]: getClientConfigRest,
};

/**
 * This function create apollo graphQL client config.
 *
 * @param {Object} options
 * @param {String} type - type of apollo client
 *
 * @return {Object}
 */
export default function createApolloClient(options = {}, type = CLIENT_TYPES.GRAPHQL) {
    const {
        logger,
    } = options;

    const clientConfigHandler = configCreateHandlers[type];

    const clientConfig = clientConfigHandler({ logger });

    return new ApolloClient(clientConfig);
}
