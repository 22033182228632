import getFloatFromPriceString from '~/src/utils/helpers/getFloatFromPriceString';

function isInt(n) {
    return Number(n) === n && n % 1 === 0;
}

export default function (currentValue) {
    const value = getFloatFromPriceString(currentValue);
    if (isInt(value) === 'true') {
        return value;
    }
    return value.toString().slice(-2) === '00' ? value : value.toFixed(2);
}
