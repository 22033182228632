<template>
    <span :class="['availability-text', `availability-text--${availabilityType}`, ...getBemModifiers()]">
        {{ availabilityText }}
        <link
            v-if="shouldAddSchemaAvailability"
            itemprop="availability"
            :href="schemaAvailabilityValue"
        >
    </span>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';
import dictionarySchema from '~/src/config/dictionary/schema';
import { getLabelByCode, DEFAULT_CODE, CODES } from '~/assets/services/availability';

const { mapState: mapStateMarkets } = createNamespacedHelpers('markets');
const { availability: dictionaryAvailability } = dictionarySchema;

export default {
    name: 'AvailabilityText',
    props: {
        /**
         *  Code of availability
         */
        availability: {
            type: String,
            default: DEFAULT_CODE,
        },
        /**
         *  Quantity of product
         */
        quantity: {
            type: Number,
            default: 0,
        },
        /**
         * is store ignored
         */
        ignoreStore: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        /**
         * Returns choice of store by user
         * @returns {boolean}
         */
        ...mapStateMarkets({
            storeSelected: state => state.storeSelected,
        }),
        /**
         * Returns availability code based on product attributes
         * @returns {string} (in|out|backorder|default)
         */
        availabilityCode() {
            const { storeSelected, availability, quantity, ignoreStore } = this;

            if (!storeSelected && !ignoreStore) {
                return DEFAULT_CODE;
            }
            if (availability === CODES.backorder && quantity > 0) {
                return CODES.in;
            }
            return availability;
        },
        /**
         * Returns availability label by code
         * @returns {string}
         */
        availabilityText() {
            return getLabelByCode(this.availabilityCode);
        },
        /**
         * Returns availability code based on product attributes and choice of store by user
         * @returns {string}
         */
        availabilityType() {
            return (this.storeSelected || this.ignoreStore) && this.availability ? this.availabilityCode : DEFAULT_CODE;
        },
        /**
         * Returns value for structural data
         * @returns {string}
         */
        schemaAvailabilityValue() {
            return dictionaryAvailability[this.availabilityType];
        },
        /**
         * Returns state of adding structural data
         * @returns {boolean}
         */
        shouldAddSchemaAvailability() {
            return (this.availabilityType === CODES.in || this.availabilityType === CODES.out) && this.schemaAvailabilityValue;
        },
    },
};
</script>

<style lang="scss">
    .availability-text {
        $self: &;

        display: inline-block;
        background-position: left center;
        background-repeat: no-repeat;
        font: $font-weight-bold 16px/18px $goodHomeFontFamily;

        &::before {
            width: 16px;
            height: 18px;
            display: inline-block;
            content: '';
            background-position: left top;
            background-repeat: no-repeat;
            background-size: 16px 16px;
            vertical-align: bottom;
        }

        &--default {
            color: $color-accent1;

            &::before {
                background-image: url('~static/images/sugested-icon.png');
            }
        }

        &--in {
            color: $color-accent2;

            &::before {
                background-image: url('~static/images/svg/availability-in.svg');
            }
        }

        &--out {
            color: $color-deepCanynon-dark;

            &::before {
                background: url('~static/images/sprint_availability.png') 0 -226px no-repeat;
            }
        }

        &--backorder {
            color: $color-accent1;

            &::before {
                background: url('~static/images/sprint_availability.png') no-repeat 0 -340px;
            }
        }

        &--default#{$self}--product-tail {
            display: none;
        }
    }

    @media screen and (min-width: $mf-breakpoint-tablet) {
        .availability-text {
            $self: &;

            font-size: 16px;
            line-height: 18px;

            &--out::before{
                background-position: 0 -227px;
            }

            &--default#{$self}--product-tail {
                font-size: 16px;
                display: inline-block;
            }

            &--product-tail {
                font-size: 16px;
            }
        }
    }
</style>
