<template>
    <section class="market-info-box">
        <MarketInfoBoxTitle @mouseover="displayPopup = true" />
        <MarketInfoBoxPopup v-if="displayPopup" />
    </section>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';
import MarketInfoBoxTitle from '~/components/common/MarketInfoBox/components/common/MarketInfoBoxTitle';

const { mapState } = createNamespacedHelpers('markets');

export default {
    name: 'MarketInfoBox',
    components: {
        MarketInfoBoxPopup: () => import('./components/MarketInfoBoxPopup' /* webpackChunkName: "popup/market-info-box" */),
        MarketInfoBoxTitle,
    },
    data() {
        return {
            displayPopup: false,
        };
    },
    computed: {
        ...mapState({
            storeInfo: state => state.storeInfo,
        }),
    },
};
</script>

<style lang="scss">
    @import '~/assets/css/sprites/header-desktop.scss';

    .market-info-box {
        &:hover {
            background-color: $color-castoBlue;
            color: $color-castoWhite;

            .market-info-box__main-title {
                color: $color-castoWhite;

                &::before {
                    @include spriteHeaderDesktop('localization-white', false, true);
                    background-size: 12px 16px;
                }

                &::after {
                    @include spriteHeaderDesktop('arrow-white-bottom', false, true);
                    background-size: 7px 4px;
                    width: 9px;
                    height: 6px;
                    transform: rotate(180deg);
                }
            }
        }
        &:hover &__popup {
            display: block;
        }
    }

    @media screen and (max-width: $breakpoint-big-desktop) {
        .market-info-box {
            padding-left: 6px;
        }
    }
</style>
