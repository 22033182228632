<template>
    <div class="footer-secure">
        <span class="norton-secured">norton secured</span>
    </div>
</template>

<script>

export default {
    name: 'SecureInfo',
};
</script>

<style lang="scss">
    @import '~/assets/css/sprites/footer-desktop.scss';

    .footer-secure {
        margin-left: 15px;

        .norton-secured {
            display: block;
            width: 86px;
            height: 49px;
            line-height: 400px;
            overflow: hidden;
            @include spriteFooterDesktop('norton-secured', false, false);
            background-size: 86px 49px;
        }
    }

    @media (max-width: $breakpoint-tablet) {
        .footer-secure {
            margin: 0 10px 40px;
        }
    }
</style>
