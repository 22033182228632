import eventAddToCart from './events/eventAddToCart';

export default function dataLayerAddToCart(data) {
    if (data && data.product) {
        const { brand, category, id, name, price, quantity, sku } = data.product;
        eventAddToCart.ecommerce.add.products.push({
            brand,
            category,
            id,
            name,
            price,
            quantity,
            sku,
        });
        if (data && data.recommended) {
            eventAddToCart.ecommerce.impressions = data.recommended;
        }
        if (window.dataLayer) {
            window.dataLayer.push(eventAddToCart);
        }
    }
}
