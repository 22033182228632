<template>
    <!-- eslint-disable-next-line vue/component-name-in-template-casing -->
    <nuxt />
</template>

<script>
export default {
    name: 'LayoutsEmpty',
};
</script>
