<template>
    <div class="global-site-notice">
        <div class="notice-inner">
            <slot name="baseText" />
        </div>
    </div>
</template>

<script>
export default {
    name: 'GlobalNotice',
};
</script>
