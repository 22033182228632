import { FETCH_MARKET_INFO } from './api.config';
import CacheWrapper from '~/assets/services/axiosWrapper/cacheWrapper';

function getQueryConfig({ storeId, shopId }) {
    return {
        method: 'get',
        url: `${FETCH_MARKET_INFO}?storeId=${storeId}`,
        config: {
            headers: {
                'Content-Type':
                    'application/x-www-form-urlencoded; charset=UTF-8',
            },
        },
        storageKey: `marketData${shopId}`,
        storageMethodName: 'sessionStorage',
        timestamp: 120,
        tillSpecifiedHourNextDay: 0,
    };
}

export default function ({ storeId, shopId }) {
    const currentQueryConfig = getQueryConfig({ storeId, shopId });

    return new CacheWrapper(currentQueryConfig).getValue(true);
}
