<template>
    <img
        :src="data.src"
        class="base-image"
        :class="getBemModifiers()"
        :alt="alt"
    >
</template>

<script>
export default {
    name: 'BaseImage',
    props: {
        data: {
            type: Object,
            required: true,
        },
    },
    computed: {
        alt() {
            return this.data.alt || 'Base image';
        },
    },
};
</script>

<style lang="scss">
    .base-image {
        $self: &;

        &--fit-to-width {
            width: 100%;
            height: auto;
            max-height: 100%;
        }

        &#{$self}--position-static {
            position: static;
        }

        &--small-image-mobile {
            max-width: 156px;
        }
    }

    @media (min-width: $mf-breakpoint-tablet) {
        .base-image--small-image-mobile  {
            max-width: 100%;
        }
    }
</style>
