import webpSupportChecking from '~/assets/services/browser';
import getFileNameWithoutExtension from './getFileNameWithoutExtension';
import getFileExtension from './getFileExtension';

export default async function ({ supportWebp = true, src }) {
    const webp = 'webp';
    const browserSupportWebp = await webpSupportChecking();
    const extensionsInOrder = [webp, getFileExtension(src)];
    const extensionSupported = {
        [webp]: browserSupportWebp && supportWebp,
    };
    const fileSources = [];
    extensionsInOrder.forEach((extension) => {
        if (extensionSupported[extension] === undefined || extensionSupported[extension] === true) {
            const newFileUrl = `${getFileNameWithoutExtension(src)}.${extension}`;
            fileSources.push(newFileUrl);
        }
    });

    return fileSources;
}
