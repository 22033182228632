export const types = {
    SHOW_DIFFERENCE: 'SHOW_DIFFERENCE',
    HIDE_DIFFERENCE: 'HIDE_DIFFERENCE',
    SET_TOGGLE_DIFFERENCE_TEXT: 'SET_TOGGLE_DIFFERENCE_TEXT',
};

const mutations = {
    [types.SHOW_DIFFERENCE](state) {
        state.showDifferencesFlag = true;
    },
    [types.HIDE_DIFFERENCE](state) {
        state.showDifferencesFlag = false;
    },
    [types.SET_TOGGLE_DIFFERENCE_TEXT](state, currentToggleText) {
        state.toggleDifferenceText = currentToggleText;
    },
};

export default mutations;
