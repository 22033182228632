<template>
    <div class="mobile-application-bar-top__container container">
        <h3 class="mobile-application-bar__title">
            Wygodne zakupy z aplikacją
        </h3>
        <h4 class="mobile-application-bar__logo">
            Castorama
        </h4>
        <ul class="mobile-application-bar__list">
            <li class="mobile-application-bar__item">
                - szybki dostęp do produktów
            </li>
            <li class="mobile-application-bar__item">
                - koszyk i lista zakupów dostępna offline
            </li>
            <li class="mobile-application-bar__item">
                - powiadomienia o statusie zamówienia
            </li>
        </ul>
        <BaseButton
            class="mobile-application-bar__close--text"
            name="Nie teraz"
            :bem-modifiers="['horizontally-centered']"
            @click.native="closeMonit"
        >
            Nie teraz
        </BaseButton>
    </div>
</template>

<script>
import BaseButton from '~/components/common/Button/BaseButton';

export default {
    name: 'MobileApplicationBarTop',
    components: {
        BaseButton,
    },
    methods: {
        closeMonit() {
            this.$emit('click', 'closeMonit');
        },
    },
};
</script>

<style lang="scss">
    .mobile-application-bar-top__container {
        text-align: center;
        width: 100%;
    }
</style>
