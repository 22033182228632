import axios from 'axios';
import 'url-search-params-polyfill';

import { ADD_ALL_TO_CART } from './api.config';

export default async function ({ formKey, wishlistId, qty = {} }) {
    try {
        const search = new URLSearchParams({
            qty,
            form_key: formKey,
            wishlist_id: wishlistId,
        });

        const { data } = await axios({
            method: 'post',
            url: ADD_ALL_TO_CART,
            data: search.toString(),
            config: {
                headers: {
                    'Content-Type':
                        'application/json; charset=UTF-8',
                },
            },
        });

        return data;
    } catch {
        return {};
    }
}
