<template>
    <BaseButton
        class="add-to-cart-button"
        :bem-modifiers="[...buttonModifiers, ...bemModifiers]"
        :name="label"
        @click.native="addToCartInit"
    >
        {{ label }}
    </BaseButton>
</template>

<script>
import BaseButton from '~/components/common/Button/BaseButton';
import handleAddToCart from '~/assets/mixins/cart/handleAddToCart';

export default {
    name: 'AddToCartButton',
    components: {
        BaseButton,
    },
    mixins: [handleAddToCart],
    props: {
        productId: {
            type: Number,
            default: undefined,
        },
        setId: {
            type: Number,
            default: undefined,
        },
        name: {
            type: String,
            default: '',
        },
        groupedData: {
            type: Array,
            default: () => [],
        },
        productsSet: {
            type: Array,
            default: () => [],
        },
        qty: {
            type: Number,
            default: 1,
        },
        label: {
            type: String,
            default: 'Do koszyka',
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        sku: {
            type: [String, Number],
            default: undefined,
        },
    },
    computed: {
        buttonModifiers() {
            const modifiers = ['background-deep-canyon', 'horizontally-centered', 'full-width', 'font-size-small'];

            if (this.disabled) {
                modifiers.push('disabled');
            }

            return modifiers;
        },
    },
    methods: {
        addToCartInit() {
            if (!this.disabled) {
                const {
                    productId,
                    setId,
                    sku,
                    qty,
                    groupedData,
                    productsSet,
                    name,
                } = this;

                this.handleAddToCart({
                    productId,
                    setId,
                    sku,
                    qty,
                    productsSet,
                    groupedData,
                    name,
                });
            }
        },
    },
};
</script>
