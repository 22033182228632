import shippingMethodsCodes from './shippingMethodsCodes';

const { PICKUP, CASTO, DPD, INPOST } = shippingMethodsCodes;

export default {
    [INPOST]: 'Paczkomat',
    [PICKUP]: 'Odbiór w sklepie',
    [DPD]: 'Dostawa kurierem',
    [CASTO]: 'Firma transportowa',
};
