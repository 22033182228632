<template>
    <div class="col-xs-12 col-md-6 col-lg-2 subscribe__wrapper">
        <div class="block-subscribe">
            <p class="block-single__title block-subscribe__paragraph">
                Gazetki i okazje na maila
            </p>
            <BaseImageLazyLoad
                class="block-subscribe__img block-subscribe__img--top"
                :data="{src: subscriberAvatar, alt: 'Newsletter kobieta'}"
            />
            <BaseImageLazyLoad
                class="block-subscribe__img block-subscribe__img--bottom"
                :data="{src: subscriberBackground, alt: 'Newsletter ikony'}"
            />
            <BaseLink
                href="/newsletter"
                @click.native="subscribe($event)"
            >
                <span class="block-subscribe__button button btn-blue desktop-exclude tablet-include mobile-include">
                    Zapisz
                </span>
            </BaseLink>
        </div>
    </div>
</template>

<script>
import { createNamespacedHelpers, mapState } from 'vuex';
import BaseImageLazyLoad from '~/components/common/Image/BaseImageLazyLoad';
import BaseLink from '~/components/common/Link/BaseLink';

const { mapActions: mapActionsPopup } = createNamespacedHelpers('popup');
const subscriberAvatar = require('~/static/images/footer/subscribe_top.png');
const subscriberBackground = require('~/static/images/footer/subscribe_bottom.png');


export default {
    name: 'Subscribes',
    components: {
        BaseLink,
        BaseImageLazyLoad,
    },
    data() {
        return {
            subscriberAvatar,
            subscriberBackground,
        };
    },
    computed: {
        ...mapState({
            isMobile: state => state.windowOptions.isMobile,
        }),
    },
    methods: {
        async subscribe(event) {
            if (event && !this.isMobile) {
                event.preventDefault();

                const { default: instance } = await import('~/components/common/Popup/NewsletterPopup' /* webpackChunkName: "popup/newsletter" */);
                this.showPopup({
                    instance,
                    optionalProps: {
                        action: 'Zapis - stopka',
                        styleButton: 'modal__close--white',
                    },
                });
            }
        },
        ...mapActionsPopup(['showPopup']),
    },
};
</script>

<style lang="scss">
    .subscribe__wrapper {
        float: left;

        .block-subscribe {
            right: 0;
            position: relative;
            margin-left: auto;
            opacity: 1;
            height: 180px;

            a {
                text-decoration: none;
            }

            &__img {
                position: absolute;

                &--top {
                    bottom: 10px;
                    height: 80%;
                    right: 0;
                    z-index: 2;
                }

                &--bottom {
                    bottom: 60px;
                    height: 50%;
                    right: 50px;
                    z-index: 1;
                }
            }

            &__button {
                position: absolute;
                bottom: 0;
                right: 0;
                background: $color-castoBlue;
                border: none;
                z-index: 3;
                text-align: center;
                width: 176px;
                font: $font-weight-bold 16px/32px $goodHomeFontFamily;
                color: $color-castoWhite;

                &:hover {
                    background: $color-castoBlue-dark;
                    cursor: pointer;
                }
            }
        }
    }

    @media screen and (min-width: $mf-breakpoint-tablet) {
        .subscribe__wrapper .block-subscribe__paragraph {
            width: 70%;
        }
    }

    @media (min-width: $breakpoint-tablet) and (max-width: $breakpoint-small-desktop) {
        .subscribe__wrapper {
            .block-subscribe {
                &__paragraph {
                    margin-left: 0;
                }

                &__img {
                    &--top {
                        right: 110px;
                    }

                    &--bottom {
                        right: 10px;
                    }
                }

                &__button {
                    right: 100px;
                    bottom: -10px;
                }
            }
        }
    }

    @media screen and (min-width: $mf-breakpoint-small-desktop) {
        .subscribe__wrapper .block-subscribe__paragraph {
            width: 78%;
            margin-left: -12px;
        }
    }

    @media (max-width: $breakpoint-tablet) {
        .subscribe__wrapper {
            .block-subscribe {
                height: 220px;

                &__paragraph {
                    margin: 10px 0 0 5px;
                    font-size: 12px;
                }

                &__img {
                    &--top {
                        right: 26%;
                        top: 30px;
                    }

                    &--bottom {
                        right: 56%;
                        top: 30px;
                    }
                }

                &__button {
                    width: 100%;
                }
            }
        }
    }
</style>
