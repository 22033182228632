import normalizePriceData from '~/assets/services/price/normalizeData';

function injectPackageDetailsAndCost(paymentMethods, packageDetails, cost) {
    return paymentMethods.map((paymentMethod) => {
        if (paymentMethod.component_name === 'DpdDetailsPopup') {
            paymentMethod.extra_info = {
                packageDetails,
                ...paymentMethod.extra_info,
                saturdayValue: paymentMethod.extra_info.saturday_value,
                baseCost: cost.value,
            };
        }
        return paymentMethod;
    });
}

function normalizePayment(paymentTypes) {
    return paymentTypes.map((paymentType) => {
        const { package_details: packageDetails, paymentMethods, cost } = paymentType;
        if (packageDetails) {
            paymentType.packageDetails = packageDetails;
            paymentType.paymentMethods = injectPackageDetailsAndCost(paymentMethods, packageDetails, cost);
            delete paymentType.package_details;
        }
        return paymentType;
    });
}

function normalizePrice(subtotal) {
    try {
        return parseFloat(subtotal.replace(/zł|\s/g, '').replace(',', '.'));
    } catch {
        return null;
    }
}

function prepareDataForNormalize(productData) {
    /* eslint-disable camelcase */
    const { unit, conversion_unit, conversion_price, item_price, price, item_conversion_qty: conversion } = productData;
    productData.price = normalizePrice(item_price);
    const newProduct = { price_per_unit: normalizePrice(price), price_unit: unit, ...productData };

    if (conversion_price) {
        newProduct.attrData = {
            conversion_unit,
            conversion_price: normalizePrice(conversion_price),
            sales_units_change: !!conversion_price,
            unit,
            price_per_unit: normalizePrice(price),
            conversion: conversion.replace(',', '.'),
        };
    }
    /* eslint-enable camelcase */
    return newProduct;
}

function normalizeItem(itemData) {
    const { product_id: productId, ...productData } = itemData;
    return {
        ...normalizePriceData(prepareDataForNormalize(productData)),
        productId,
    };
}

export default function (data) {
    const items = [];
    const { form_key: formKey, payment, subtotal, promo_products: promoProducts = {} } = data;
    const promoProductsMerged = [];

    Object.keys(data).forEach((key) => {
        if (!Number.isNaN(parseInt(key, 10))) {
            if (data[key].grouped_items.length) {
                const subitems = [];
                Object.keys(data[key].grouped_items).forEach((subkey) => {
                    if (!Number.isNaN(parseInt(subkey, 10))) {
                        subitems.push(normalizeItem(data[key].grouped_items[subkey]));
                    }
                });
                data[key].grouped_items = subitems;
            }

            items.push(normalizeItem(data[key]));
        }
    });

    Object.keys(promoProducts).forEach(promoProductId => promoProductsMerged.push(...promoProducts[promoProductId].map(item => normalizeItem(item))));

    items.push(...promoProductsMerged);

    return {
        items,
        formKey,
        payment: normalizePayment(payment),
        subtotal: normalizePrice(subtotal),
    };
}
