export default function (filterArray = []) {
    const newParams = [];
    filterArray.forEach((item, index) => {
        newParams.push(...[
            {
                param: `searchCriteria[filterGroups][${index}][filters][0][field]`,
                value: item.field,
            },
            {
                param: `searchCriteria[filterGroups][${index}][filters][0][value]`,
                value: item.value,
            },
            {
                param: `searchCriteria[filterGroups][${index}][filters][0][conditionType]`,
                value: item.conditionType,
            },
        ]);
    });
    return newParams;
}
