function filtersInterface(filter, symbol) {
    const filtersInterfaces = {
        order: () => ({
            param: 'searchCriteria[sortOrders][0][field]',
            value: filter.value,
        }),
        dir: () => ({
            param: 'searchCriteria[sortOrders][0][direction]',
            value: filter.value,
        }),
        default: () => ({
            param: `searchCriteria[${filter.filterFieldName}]`,
            value: filter.value,
        }),
    };
    return filtersInterfaces[symbol] ? filtersInterfaces[symbol]() : filtersInterfaces.default();
}

export default function (filterArray = []) {
    const newParams = [];
    filterArray.forEach((item) => {
        newParams.push(filtersInterface(item, item.filterFieldName));
    });
    return newParams;
}
