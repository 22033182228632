export const types = {
    ADD_ITEM_TO_CART: 'ADD_ITEM_TO_CART',
    PUT_FETCHED_CART: 'PUT_FETCHED_CART',
    SET_CART_SUBTOTAL: 'SET_CART_SUBTOTAL',
    FLUSH_CART: 'FLUSH_CART',
    ADD_PAYMENT_INFO: 'ADD_PAYMENT_INFO',
    SET_FORM_KEY: 'SET_FORM_KEY',
    SET_ITEMS_COUNT: 'SET_ITEMS_COUNT',
    SET_NEED_FETCH: 'SET_NEED_FETCH',
};

const mutations = {
    [types.ADD_ITEM_TO_CART](state, item) {
        state.items.push(item);
    },
    [types.PUT_FETCHED_CART](state, cart) {
        state.items = cart;
    },
    [types.SET_CART_SUBTOTAL](state, cartSubtotal) {
        state.totalPrice = cartSubtotal;
    },
    [types.FLUSH_CART](state) {
        state.items = [];
    },
    [types.ADD_PAYMENT_INFO](state, info) {
        state.paymentInfo = info;
    },
    [types.SET_FORM_KEY](state, formKey) {
        state.formKey = formKey;
    },
    [types.SET_ITEMS_COUNT](state, count) {
        state.itemsCount = count;
    },
    [types.SET_NEED_FETCH](state, needFetch) {
        state.needFetch = needFetch;
    },
};

export default mutations;
