import axios from 'axios';
import 'url-search-params-polyfill';

import { REMOVE_ITEM_URL } from './api.config';

export default function ({ itemId }) {
    const search = new URLSearchParams({
        item: itemId,
        isAjax: true,
    });
    return axios({
        method: 'post',
        url: REMOVE_ITEM_URL,
        data: search.toString(),
        config: {
            headers: {
                'Content-Type':
                    'application/x-www-form-urlencoded; charset=UTF-8',
            },
        },
    });
}
