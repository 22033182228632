import axios from 'axios';
import { GET_INSPIRATIONS_TREE } from './api.config';

export default async function () {
    try {
        const { data } = await axios({
            method: 'get',
            url: GET_INSPIRATIONS_TREE,
        });

        return data;
    } catch (ignore) {
        // @todo log errors
        return [];
    }
}
