<template>
    <div
        ref="wrapper"
        class="catalog-menu"
        :class="{ 'visible-menu': isHovering && sizesCalculated }"
    >
        <span
            class="catalog-menu--main-title"
            @mouseout="hideMenu"
            @mouseenter="showMenu"
        >
            Produkty
        </span>
        <nav
            v-if="wasInDOM"
            class="catalog-menu__content"
            :style="{
                left: `-${marginLeft}px`
            }"
        >
            <ul
                class="category-main"
                @mouseout="hideMenu"
            >
                <li
                    v-for="(category) in catalogTree"
                    :key="category.symbol"
                    :style="[{
                        width: marginLeft + 'px',
                        paddingLeft: linksPadding + 'px',
                    }, !category.list ? { 'background-image': `url(${category.thumbnail})` } : {}]"
                    :class="[
                        { 'category-main__level0': category.list },
                        {
                            [`category-main__level0--category--${category.symbol}`]: category.list,
                        },
                        { 'no-expand': !category.list },
                        { 'visible-level': category.symbol === hoveredItem.symbol },
                    ]"
                    @mouseover="setHoveredItem(category)"
                >
                    <NuxtLink
                        :to="category.url.replace(baseUrl, '')"
                        :title="category.name"
                        class="category-main__level0--title"
                        :class="{ 'extra-positions': !category.list || !category.list.length }"
                        @click.native="hideMenu"
                    >
                        <span :class="`category-main__level0--title--${category.symbol}`">
                            {{ category.name }}
                        </span>
                    </NuxtLink>
                    <LinkByMode
                        v-if="!category.list"
                        :url="category.url.replace(baseUrl, '')"
                        title="Zobacz ofertę"
                        class="category-main__level0--link"
                    >
                        Zobacz ofertę >
                    </LinkByMode>
                </li>
                <CatalogMenuNewspaper
                    v-if="newspaperData"
                    :style="{
                        width: marginLeft + 'px',
                        paddingLeft: linksPadding + 'px',
                    }"
                    class="no-expand"
                    @mouseover="showMenu"
                    @mouseout="hideMenu"
                />
            </ul>
            <CatalogMenuList
                :style="{
                    left: `${marginLeft}px`,
                    width: `calc(100% - ${marginLeft}px)`,
                    paddingRight: `${linksPadding}px`,
                }"
                :list="hoveredItem.list"
                @mouseover="showMenu"
                @mouseout="hideMenu"
                @changeRoute="hideMenu"
            />
        </nav>
    </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';
import LinkByMode from '~/components/common/Link/LinkByMode';
import CatalogMenuList from './components/CatalogMenuList';
import CatalogMenuNewspaper from './components/CatalogMenuNewspaper';

const { mapState: mapStateMenu } = createNamespacedHelpers('menu');

export default {
    name: 'CatalogMenu',
    components: {
        LinkByMode,
        CatalogMenuList,
        CatalogMenuNewspaper,
    },
    props: {
        catalogTree: {
            type: Array,
            default: () => [],
        },
    },
    data() {
        return {
            baseUrl: process.env.baseUrl,
            scrollOffsetY: null,
            showClass: '',
            marginLeft: null,
            linksPadding: null,
            indexShown: null,
            isMouseMoving: false,
            isHovering: null,
            execute: null,
            pointer: 0,
            hideMenuTimeout: null,
            calculateSizesTimeout: null,
            itemHoveredByUser: null,
            wasInDOM: false,
        };
    },
    computed: {
        ...mapStateMenu({
            newspaperData: state => state.newspaperData,
        }),
        firstItemInCatalogTree() {
            return this.catalogTree[0];
        },
        hoveredItem() {
            return this.itemHoveredByUser || this.firstItemInCatalogTree;
        },
        sizesCalculated() {
            return this.paddingLeft !== null && this.marginLeft !== null;
        },
    },
    created() {
        if (process.browser) {
            window.addEventListener('scroll', this.setScrollYOffset);
            window.addEventListener('resize', this.calculateSizes);
        }
    },
    mounted() {
        this.calculateSizes();
    },
    beforeDestroy() {
        if (process.browser) {
            window.removeEventListener('scroll', this.setScrollYOffset);
            window.removeEventListener('resize', this.calculateSizes);
        }
    },
    methods: {
        calculateSizes() {
            if (this.calculateSizesTimeout) {
                clearTimeout(this.calculateSizesTimeout);
            }

            this.calculateSizesTimeout = setTimeout(() => {
                const headerInfo = document
                    .getElementsByClassName('header-info')[0];
                const productWrapper = this.$refs.wrapper;

                if (productWrapper) {
                    this.marginLeft = productWrapper.getBoundingClientRect().left;
                }
                if (headerInfo) {
                    this.linksPadding = headerInfo.getBoundingClientRect().left;
                }
            }, 10);
        },
        showMenu() {
            this.wasInDOM = true;
            this.clearHideMenuTimeout();
            this.isHovering = true;
        },
        hideMenu() {
            this.clearHideMenuTimeout();
            this.hideMenuTimeout = setTimeout(() => {
                this.isHovering = false;
                this.unsetHoveredItem();
            }, 200);
        },
        setHoveredItem(item) {
            this.clearHideMenuTimeout();
            if (item.list) {
                this.itemHoveredByUser = item;
            }
        },
        clearHideMenuTimeout() {
            if (this.hideMenuTimeout) {
                clearTimeout(this.hideMenuTimeout);
            }
        },
        unsetHoveredItem() {
            this.itemHoveredByUser = null;
        },
        setScrollYOffset() {
            this.scrollOffsetY = window.scrollY;
        },
    },
};
</script>

<style lang="scss">
    @import '~/assets/css/sprites/header-desktop.scss';

    @mixin active-arrow () {
        content: '';
        border-top: 14px solid transparent;
        border-bottom: 14px solid transparent;
        width: 0;
        height: 28px;
        border-right: 14px solid $color-castoWhite;
        text-align: right;
        float: right;
        top: calc(50% - 14px);
        right: 0;
        position: absolute;
    }

    .catalog-menu {
        height: 100%;
        width: 148px;
        top: 0;
        background-color: $color-castoBlue;
        display: inline-block;
        position: relative;
        color: $color-castoWhite;
        cursor: pointer;
        padding-left: 0;
        z-index: 10;
        padding-right: 0;
        margin-right: 1px;

        @include centralize();

        &.visible-menu {
            background-color: $color-castoWhite;
            color: $color-slate70-dark;
            z-index: 100;

            &::before {
                display: block;
                left: 0;
                top: 0;
                position: fixed;
                width: 100%;
                height: 100%;
                background-color: rgba(0, 0, 0, 0.5);
                content: '';
            }
        }

        &.visible-menu {
            .catalog-menu--main-title {
                background: $color-castoWhite;
            }
            .catalog-menu__content {
                display: block;
            }
        }

        &--main-title {
            width: 100%;
            height: 100%;
            margin: 0;
            padding-top: 24px;
            font: $font-weight-regular 15px/20px $goodHomeFontFamily;
            left: 0;
            text-align: center;
            position: relative;
            text-indent: -15px;
            display: flex;
            justify-content: center;
            align-items: center;

            &::after {
                @include spriteHeaderDesktop('arrow-white-bottom', false, true);
                width: 9px;
                height: 6px;
                display: inline-block;
                content: '';
                position: absolute;
                right: 26px;
                top: 64px;
            }
        }

        &__content {
            display: none;
            position: absolute;
            width: 100vw;
            left: 0;
            height: 500px;

            .category-main {
                color: $color-castoWhite;

                &__level0 {
                    background: $color-castoBlue;
                    padding: 2px 0 0 80px;
                    text-align: left;

                    &.visible-level  .category-main__level0--title span::after {
                        @include active-arrow();
                    }

                    &:hover > a, &.visible-level > a {
                        color: $color-castoYellow;
                    }

                    .category-main__level0--title--budowa::before {
                        @include spriteHeaderDesktop('building-white', false, true);
                        width: 32px;
                        height: 29px;
                    }

                    .category-main__level0--title--instalacja::before {
                        @include spriteHeaderDesktop('installation-white', false, true);
                        width: 27px;
                        height: 29px;
                    }

                    .category-main__level0--title--wykonczenie::before {
                        @include spriteHeaderDesktop('indoor-renov-white', false, true);
                        background-size: 100% 100%;
                        width: 29px;
                        height: 30px;
                    }

                    .category-main__level0--title--urzadzanie::before {
                        @include spriteHeaderDesktop('furnishing-white', false, true);
                        width: 32px;
                        height: 29px;
                    }

                    .category-main__level0--title--ogrod::before {
                        @include spriteHeaderDesktop('garden-white', false, true);
                        width: 33px;
                        height: 29px;
                    }

                    .category-main__level0--title--narzedzia_i_artykuly::before {
                        @include spriteHeaderDesktop('tools-white', false, true);
                        width: 32px;
                        height: 30px;
                    }

                    &.visible-level {
                        .category-main__level0--title {
                            &--budowa:before {
                                @include spriteHeaderDesktop('building-yellow', false, true);
                            }

                            &--instalacja:before {
                                @include spriteHeaderDesktop('installation-yellow', false, true);
                            }

                            &--wykonczenie:before {
                                @include spriteHeaderDesktop('indoor-renov-yellow', false, true);
                                background-size: 100% 100%;
                            }

                            &--urzadzanie:before {
                                @include spriteHeaderDesktop('furnishing-yellow', false, true);
                            }

                            &--ogrod:before {
                                @include spriteHeaderDesktop('garden-yellow', false, true);
                            }

                            &--narzedzia_i_artykuly:before {
                                @include spriteHeaderDesktop('tools-yellow', false, true);
                            }
                        }
                    }

                    &--title {
                        margin: 0;
                        min-height: 18px;
                        font-size: 13px;
                        color: $color-castoWhite;
                        text-decoration: none;

                        &:hover {
                            text-decoration: none;
                        }

                        &:before {
                            border-top: 14px solid transparent;
                            border-bottom: 14px solid transparent;
                            display: none;
                            content: '';
                            width: 0;
                            height: 28px;
                            border-right: 14px solid $color-castoWhite;
                            text-align: right;
                            position: relative;
                            float: right;
                            top: 8px;
                        }

                        > span {
                            font-family: $goodHomeFontFamily;
                            position: relative;
                            width: 100%;
                            display: block;
                            text-align: left;
                            padding: 15px 25px 13px 40px;
                            line-height: 18px;
                            margin: 0;
                            font-size: 16px;

                            &:before {
                                content: '';
                                position: absolute;
                                display: inline-block;
                                left: -10px;
                                top: 8px;
                            }
                        }
                    }
                }

                .static-block-wrapper {
                    padding: 20px 0 10px 30px;
                    background-color: $color-castoBlue-dark;
                    font-size: 16px;

                    a {
                        font-size: 16px;

                        p {
                            padding-left: 0;
                        }
                    }

                    &:hover a {
                        color: $color-castoWhite;
                    }

                    &:hover .category-main__level0--link {
                        color: $color-castoYellow;
                    }

                    .category-main__level0 {
                        &--link {
                            color: $color-castoWhite;
                            font-size: 12px;
                        }

                        &--title::before {
                            display: none;
                        }
                    }
                }
            }
        }
    }

    .is-fixed .catalog-menu {
        &--main-title {
            padding-top: 0;
            margin-top: -1px;

            &::after {
                top: 32px;
                right: 25px;
            }
        }
    }

    @media screen and (max-width: $breakpoint-big-desktop) {
        .catalog-menu {
            width: 115px;
            margin-right: -1px;

            .category-main {
                &__level0 {
                    &__level1 {
                        width: calc(100vw - 281px - 65px);
                    }
                }
            }
            &--main-title {
                font-size: 12px;
                padding-top: 19px;
                text-indent: -18px;

                &::after {
                    right: 16px;
                }

                &.fixed {
                    top: 31px;
                }
            }
        }
        .is-fixed {
            .catalog-menu {
                margin-right: 0;

                &--main-title {
                    padding-top: 0;
                    margin-top: -3px;

                    &::after {
                        top: 32px;
                        right: 17px;
                    }
                }
            }
        }
    }

    @media screen and (max-width: $breakpoint-small-desktop) {
        .catalog-menu {
            position: absolute;
            left: 248px;

            .category-main {
                &__level0 {
                    &__level1 {
                        width: calc(100vw - 488px - 65px);
                    }
                }
            }

            &--main-title {
                font-size: 15px;
                padding-top: 23px;
                padding-left: 3px;

                &::after {
                    right: 14px;
                }
                &.fixed {
                    top: 25px;
                }
            }
        }
        .is-fixed {
            .catalog-menu--main-title {
                padding-top: 0;
                margin-top: -1px;

                &::after {
                    top: 44px;
                    right: 14px;
                }
            }
        }
    }


    .no-expand {
        &, &.category-main__level0 {
            position: relative;
            padding: 5px 0 35px 0;
            background-position: right;
            background-repeat: no-repeat;
            min-height: 118px;
            background-color: $color-castoBlue-dark;
            overflow: hidden;
        }

        &:hover .category-main__level0--link {
            color: $color-castoYellow;
        }

        .category-main__level0 {
            &--title, &--link {
                position: relative;
                text-align: left;
                text-decoration: none;
            }

            &--title {
                display: block;
                font: $font-weight-regular 16px/22px $goodHomeFontFamily;
                color: $color-castoWhite;
                margin-bottom: 15px;

                span {
                    width: 115px;
                    padding-left: 0;
                    display: inline-block;
                }
            }

            &--link {
                width: 100%;
                padding: 9px 0;
                position: absolute;
                bottom: 0;
                font: $font-weight-regular 14px/18px $goodHomeFontFamily;
                cursor: pointer;
                display: block;
                color: $color-castoWhite;
                min-height: 50px;

                &:hover, &:active {
                    text-decoration: none;
                    color: $color-castoYellow;
                }

            }
        }

        &:last-child {
            padding-bottom: 0;
            background-position: bottom right;
        }
    }

    @media (min-width: $breakpoint-tablet) {
        .catalog-menu--main-title::after {
            top: 66px;
        }
    }

    @media (min-width: $breakpoint-small-desktop) {
        .catalog-menu--main-title::after {
            top: 64px;
        }
    }

    @media (min-width: $breakpoint-big-desktop) {
        .catalog-menu--main-title::after {
            top: 67px;
        }
    }
</style>
