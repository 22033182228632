export const ADD_TO_CART_URL = `${process.env.baseUrl}/checkout/cart/addAjax/`;
export const DELETE_FROM_CART_URL = `${
    process.env.baseUrl
}/checkout/cart/deleteAjax/`;
export const FETCH_ITEMS_FROM_SERVER_URL = `${
    process.env.baseUrl
}/checkout/cart/json/`;
export const CHANGE_QTY_URL = `${
    process.env.baseUrl
}/checkout/cart/updatePost/`;
export const GET_AVAILABLE_STORES = `${
    process.env.baseUrl
}/checkout/cart/getAvailableStores/`;
