import { imageAvailabilityChecking } from '~/assets/services/helpers';

export default function (feature = 'lossy') {
    const kTestImages = {
        lossy: 'UklGRiIAAABXRUJQVlA4IBYAAAAwAQCdASoBAAEADsD+JaQAA3AAAAAA',
        lossless: 'UklGRhoAAABXRUJQVlA4TA0AAAAvAAAAEAcQERGIiP4HAA==',
        alpha: 'UklGRkoAAABXRUJQVlA4WAoAAAAQAAAAAAAAAAAAQUxQSAwAAAARBxAR/Q9ERP8DAABWUDggGAAAABQBAJ0BKgEAAQAAAP4AAA3AAP7mtQAAAA==',
        animation: 'UklGRlIAAABXRUJQVlA4WAoAAAASAAAAAAAAAAAAQU5JTQYAAAD/////AABBTk1GJgAAAAAAAAAAAAAAAAAAAGQAAABWUDhMDQAAAC8AAAAQBxAREYiI/gcA',
    };
    const src = `data:image/webp;base64,${kTestImages[feature]}`;
    if (typeof window.isWebpSupported !== 'undefined') {
        return Promise.resolve(window.isWebpSupported);
    }
    return imageAvailabilityChecking(src).then((result) => {
        if (result) {
            window.isWebpSupported = true;
        }
        return Promise.resolve(result);
    });
}
