function ArrayHandler(arrayToFetch) {
    this.itemsLeft = [...arrayToFetch];
    this.separatedItems = [];
    this.getItemByKey = (key, value) => this.getIterator().find(singleItem => singleItem[key] === value);
    this.getIterator = () => this.itemsLeft;
    this.deleteItem = (item) => {
        const itemIndex = this.itemsLeft.findIndex(singleItem => singleItem === item);
        this.itemsLeft.splice(itemIndex, 1);
    };
    this.addSeparatedItem = (item, key) => {
        this.separatedItems.push({ key, item });
    };
    this.getSeparatedItems = (isObjectReturned) => {
        if (isObjectReturned) {
            return this.separatedItems
                .reduce((prevValue, currValue) => ({ ...prevValue, [currValue.key]: currValue.item }), {});
        }
        return this.separatedItems.map(separatedItem => separatedItem.item);
    };
}

function ObjectHandler(objectToFetch) {
    this.itemsLeft = { ...objectToFetch };
    this.separatedItems = {};
    this.getItemByKey = (key, value) => {
        if (!key) {
            return { [value]: this.itemsLeft[value] };
        }

        const foundedObjKey = this.getIterator().find(singleItem => this.itemsLeft[singleItem][key] === value);

        return { key: foundedObjKey, value: this.itemsLeft[foundedObjKey] };
    };
    this.getIterator = () => Object.keys(this.itemsLeft);
    this.deleteItem = item => delete this.itemsLeft[typeof item === 'object' ? Object.keys(item)[0] : item];
    this.addSeparatedItem = (item, key) => {
        this.separatedItems[key] = item[key];
    };
    this.getSeparatedItems = isObjectReturned => (isObjectReturned
        ? this.separatedItems : Object.keys(this.separatedItems).map(separatedItemKey => this.separatedItems[separatedItemKey]));
}

function HandlerInterface(structureToFetch) {
    return (Object.prototype.toString.call(structureToFetch) === '[object Array]')
        ? new ArrayHandler(structureToFetch)
        : new ObjectHandler(structureToFetch);
}

export default function (structureToFetch, keyValuesToFetch, keyToSearchBy, isObjectReturned = true) {
    const structureHandler = new HandlerInterface(structureToFetch);
    const isArray = structureHandler instanceof ArrayHandler;

    if (isArray && !keyToSearchBy) {
        throw new Error('You must provide keyToSearchBy');
    }

    keyValuesToFetch.forEach((keyValueToFetch) => {
        const item = structureHandler.getItemByKey(keyToSearchBy, keyValueToFetch);

        if ((item && !item.key && !item.key) || (item && item.key && item.value)) {
            if (keyToSearchBy && !isArray) {
                const { key, value } = item;

                structureHandler.addSeparatedItem({ [key]: value }, key);
                structureHandler.deleteItem(key);
            } else {
                structureHandler.addSeparatedItem(item, keyValueToFetch);
                structureHandler.deleteItem(item);
            }
        }
    });

    return {
        separatedItems: structureHandler.getSeparatedItems(isObjectReturned),
        itemsLeft: structureHandler.itemsLeft,
    };
}
