import { types } from './mutations';
import {
    fetchCategoryData,
    getFiltersLikeInQuery,
    fetchPromoBrands,
    fetchConsultantData as fetchConsultant,
    fetchCategoryMenu as fetchMenu,
    fetchProductsData as fetchProducts,
    getFiltersToApplyObject,
    getActiveFiltersArrayFromSelectedRequest,
    areSpecialFilters,
    getSimpleFiltersCollection,
    getMagentoFiltersCollection,
} from '~/assets/services/category';
import { getDefaultShopIds } from '~/assets/services/storages/cookies/shop';
import { setHash, getHash, removeHash, getStringFromParams } from '~/assets/services/url';
import { pageLimiter } from '~/assets/services/category/page.config';
import { TABS as CATEGORY_TABS } from '~/src/config/category';
import { productsForDataLayer, additionalParametersForDataLayer } from '~/assets/services/dataLayer/dataLayerProducts';

export default {
    async fetchData({ dispatch, commit }, { categoryId, error: errorHandle, brandName }) {
        try {
            const categoryData = await fetchCategoryData({ id: categoryId, brandName });
            const {
                name,
                alternativeName,
                image,
                backgroundImage,
                meta,
                parentsIds,
                path,
                description,
                brandDescription,
                parentsTree,
                productCount,
                series,
            } = categoryData;

            commit(types.SET_CURRENT_CATEGORY_ID, categoryId);
            commit(types.SET_CATEGORY_PATH, path);
            commit(types.SET_CATEGORY_NAME, name);
            commit(types.SET_CATEGORY_ALTERNATIVE_NAME, alternativeName);
            commit(types.SET_CATEGORY_DESCRIPTION, description);
            commit(types.SET_CATEGORY_BRAND_DESCRIPTION, brandDescription);
            commit(types.SET_CATEGORY_META_DATA, meta);
            commit(types.SET_CATEGORY_IMAGE, image);
            commit(types.SET_CATEGORY_BACKGROUND_IMAGE, backgroundImage);
            commit(types.SET_CATEGORY_PARENTS_IDS, parentsIds);
            commit(types.SET_CATEGORY_PARENTS_TREE, parentsTree);
            commit(types.SET_PRODUCTS_COUNT, productCount);
            commit(types.SET_SERIES_DATA, series);

            dispatch('setPaginationMetaLinks');
            dispatch('breadcrumbs/setLinks', [
                ...parentsTree,
                {
                    text: name,
                    relativePath: this.app.context.route.path,
                    id: categoryId,
                    current: true,
                },
            ], { root: true });
        } catch (error) {
            errorHandle({ statusCode: 404, message: error.message });
        }
    },
    setCategoryAsSeasonal({ commit }, payload) {
        commit(types.SET_IS_SEASON_CATEGORY, payload);
    },
    setRange({ commit }, value) {
        commit(types.SET_RANGE_PRICE_FILTER, value);
    },
    replaceActiveFiltersByTheseRequested({ commit, state }, filtersFromRequest) {
        const filtersToBeApplied = getActiveFiltersArrayFromSelectedRequest(filtersFromRequest);
        Object.keys(filtersToBeApplied).forEach((filterToBeApplied) => {
            const filterAlreadyAppliedIndex = state.activeFilters.findIndex(activeFilter => (
                activeFilter.filterFieldSymbol.toString() === filterToBeApplied.toString()
            ));
            if (filterAlreadyAppliedIndex !== -1) {
                commit(types.REMOVE_ACTIVE_FILTER, filterAlreadyAppliedIndex);
            }
            filtersToBeApplied[filterToBeApplied].forEach((filterToAply) => {
                const ifFilterAlreadyApplied = state.activeFilters.findIndex(activeFilter => (
                    activeFilter.filterFieldSymbol === filterToAply.filterFieldSymbol &&
                    activeFilter.filterItemName === filterToAply.filterItemName &&
                    activeFilter.filterItemSymbol === filterToAply.filterItemSymbol
                )) !== -1;
                if (!ifFilterAlreadyApplied) {
                    commit(types.ADD_FILTER_TO_ACTIVE, filterToAply);
                }
            });
        });
    },
    applyMinMaxRangeFromRequest({ commit }, priceFromRequest) {
        let normalizedValues = [0, 0, 0, 0];
        try {
            const { min: minRange, max: maxRange, current: currentRange } = priceFromRequest.items[0];
            const { min: currentMin, max: currentMax } = currentRange;
            normalizedValues = [
                currentMin,
                currentMax,
                minRange,
                maxRange,
            ].map(value => parseInt(`${value}`.replace(',', '.'), 10));
        } catch (e) {
            throw new Error('Can not fetch price');
        }
        const rangeMinPriceFiltered = normalizedValues[2] >= normalizedValues[0]
            ? normalizedValues[2]
            : normalizedValues[0];
        const rangeMaxPriceFiltered = normalizedValues[3] <= normalizedValues[1]
            ? normalizedValues[3]
            : normalizedValues[1];
        const rangePriceFiltered = [rangeMinPriceFiltered, rangeMaxPriceFiltered];
        commit(types.SET_RANGE_PRICE_FILTER, rangePriceFiltered);
        commit(types.SET_MIN_PRICE_FILTER, normalizedValues[2]);
        commit(types.SET_MAX_PRICE_FILTER, normalizedValues[3]);
    },
    fetchFilters({ commit, dispatch }, { filtersFromRequest = {} }) {
        if (Object.keys(filtersFromRequest).length > 0) {
            const { available, selected, price } = filtersFromRequest;
            commit(types.SET_FILTER_OPTIONS, available);
            dispatch('replaceActiveFiltersByTheseRequested', selected);
            if (price) {
                dispatch('applyMinMaxRangeFromRequest', price);
            }
        }
    },
    async fetchCategoryMenu({ commit }, { path, level }) {
        const categoryMenu = await fetchMenu({ path, level });
        commit(types.SET_CATEGORY_MENU, categoryMenu);
    },
    setActiveFilter({ state, commit }, { filterToActivate, withFetch = true }) {
        const filterActiveIndex = state.activeFilters.findIndex(filterItem => (
            filterItem.filterFieldSymbol === filterToActivate.filterFieldSymbol &&
            filterItem.filterItemSymbol === filterToActivate.filterItemSymbol));
        const isFilterAlreadyActive = filterActiveIndex !== -1;
        if (isFilterAlreadyActive && filterToActivate.filterType !== 'url' && filterToActivate.filterType !== 'basic') {
            commit(types.REMOVE_ACTIVE_FILTER, filterActiveIndex);
        } else {
            if (filterToActivate.filterType !== 'multiple') {
                const filterIndex = state.activeFilters
                    .findIndex(filterItem => filterItem.filterFieldSymbol === filterToActivate.filterFieldSymbol);
                const isFilterActive = filterIndex !== -1;
                if (isFilterActive) {
                    commit(types.REMOVE_ACTIVE_FILTER, filterIndex);
                }
            }
            commit(types.ADD_FILTER_TO_ACTIVE, filterToActivate);
        }
        if (withFetch) {
            const simpleFiltersCollection = getSimpleFiltersCollection(state.activeFilters);
            $nuxt.$router.push({
                query: getMagentoFiltersCollection(simpleFiltersCollection),
            });
        }
    },
    clearVisibleFilters({ state, commit, getters }) {
        const { visibleCurrentFilters } = getters;
        const { activeFilters } = state;
        const activeFiltersWithoutVisible = activeFilters.filter(activeFilter => !visibleCurrentFilters.find(
            visibleCurrentFilter => (
                activeFilter.filterFieldSymbol === visibleCurrentFilter.filterFieldSymbol &&
                activeFilter.filterItemSymbol === visibleCurrentFilter.filterItemSymbol
            ),
        ));
        commit(types.SET_FIRST_PAGE_FLAG, true);
        commit(types.REPLACE_ACTIVE_FILTERS, activeFiltersWithoutVisible);
        const simpleFiltersCollection = getSimpleFiltersCollection(state.activeFilters);
        $nuxt.$router.push({
            query: getMagentoFiltersCollection(simpleFiltersCollection),
        });
    },
    setCurrentPriceRange({ dispatch }, selectedPriceRange) {
        dispatch('setActiveFilter', {
            filterToActivate: {
                filterFieldName: 'Cena',
                filterFieldSymbol: 'price',
                filterItemName: `${selectedPriceRange[0]} zł - ${selectedPriceRange[1]} zł`,
                filterItemSymbol: `${selectedPriceRange[0]}-${selectedPriceRange[1]}`,
                filterType: 'default',
            },
        });
    },
    setElementHeight({ commit }, { blockId, height }) {
        commit(types.SET_ELEMENT_HEIGHT, { blockId, height });
    },
    setFilterHeight({ commit }, { symbol, id, height }) {
        commit(types.SET_FILTER_HEIGHT, { symbol, id, height });
    },
    async fetchPromoBrands({ commit }, { categoryId }) {
        const promoBrands = await fetchPromoBrands({ categoryId });
        commit(types.SET_PROMO_BRANDS, promoBrands);
    },
    async fetchConsultantData({ commit }, { categoryId }) {
        const data = await fetchConsultant(categoryId);
        commit(types.SET_CONSULTANT_DATA, data);
    },
    setNewPageLimit({ dispatch }, limit) {
        dispatch('setActiveFilter', {
            filterToActivate: {
                filterFieldName: 'pageSize',
                filterFieldSymbol: 'limit',
                filterItemName: limit,
                filterItemSymbol: limit,
                filterType: 'url',
            },
        });
        dispatch('setPaginationMetaLinks');
    },
    setNewPageNumber({ commit, dispatch }, { pageNumber, withFetch = true }) {
        commit(types.SET_FIRST_PAGE_FLAG, false);
        dispatch('setActiveFilter', {
            filterToActivate: {
                filterFieldName: 'currentPage',
                filterFieldSymbol: 'p',
                filterItemName: pageNumber,
                filterItemSymbol: pageNumber,
                filterType: 'url',
            },
            withFetch,
        });
        dispatch('setPaginationMetaLinks');
    },
    presetCategory({ dispatch }) {
        const hashName = getHash();
        if (hashName) {
            const activeTabData = CATEGORY_TABS.find(tabData => tabData.name === hashName);
            if (activeTabData) {
                dispatch('setActiveTabCategory', activeTabData);
            }
        }
    },
    sortSeries({ commit }, dir) {
        commit(types.SET_SORTED_SERIES, dir);
    },
    fetchSortedProducts({ dispatch, commit }, { type, dir }) {
        commit(types.SET_FIRST_PAGE_FLAG, true);
        dispatch('setActiveFilter', {
            filterToActivate: {
                filterFieldName: 'Sortowanie po',
                filterFieldSymbol: 'order',
                filterItemName: `${type}`,
                filterItemSymbol: type,
                filterType: 'url',
            },
            withFetch: false,
        });
        dispatch('setActiveFilter', {
            filterToActivate: {
                filterFieldName: 'Kolejność',
                filterFieldSymbol: 'dir',
                filterItemName: `${dir}`,
                filterItemSymbol: dir,
                filterType: 'url',
            },
        });
    },
    setActiveTabCategory({ commit }, { name, type, symbol }) {
        if (type === 'url') {
            setHash(name);
        } else {
            removeHash();
        }
        commit(types.SET_ACTIVE_TAB, symbol);
    },
    async setInitialFiltersFromUrl({ dispatch, commit }, { query, categoryId }) {
        commit(types.REPLACE_ACTIVE_FILTERS, []);
        const urlFilters = [];
        Object.keys(query).forEach((filterFieldSymbol) => {
            urlFilters.push({
                filterFieldSymbol,
                filterItemSymbol: query[filterFieldSymbol],
            });
        });

        const { basicFilters, extendedFilters } = getFiltersToApplyObject(urlFilters);

        await basicFilters.forEach(({ filterFieldName, filterFieldSymbol, filterType, value }) => {
            dispatch('setActiveFilter', {
                filterToActivate: {
                    filterFieldName,
                    filterFieldSymbol,
                    filterItemName: value,
                    filterItemSymbol: value,
                    filterType,
                },
                withFetch: false,
            });
        });

        extendedFilters.forEach(({ filterFieldSymbol, filterItemSymbol }) => {
            commit(types.ADD_FILTER_TO_ACTIVE, {
                filterFieldSymbol,
                filterItemSymbol,
            });
        });

        dispatch('setActiveFilter', {
            filterToActivate: {
                filterFieldName: 'category',
                filterFieldSymbol: 'category',
                filterItemName: categoryId,
                filterItemSymbol: categoryId,
                filterType: 'basic',
            },
            withFetch: false,
        });
    },
    presetFilters({ dispatch, state }, { withFetch = false }) {
        if (state.firstPageFlag) {
            const currentPageFilterFound = state.activeFilters.find(activeFilter => activeFilter.filterFieldSymbol === 'p');
            const currentPageFilterType = currentPageFilterFound ? currentPageFilterFound.filterType : 'basic';
            dispatch('setActiveFilter', {
                filterToActivate: {
                    filterFieldName: 'currentPage',
                    filterFieldSymbol: 'p',
                    filterItemName: 1,
                    filterItemSymbol: 1,
                    filterType: currentPageFilterType,
                },
                withFetch,
            });
        }
    },
    async fetchProductsAndFiltersData({ commit, state, dispatch, rootState }, payload = { showLoader: true }) {
        const { showLoader } = payload;
        const { name } = state;
        const { selectedShopIds } = rootState.markets;
        const { levelPageUrl } = rootState.windowOptions;
        const currentStoreId = selectedShopIds.storeId || getDefaultShopIds().storeId;
        await dispatch('presetFilters', {});
        const filterApplied = getFiltersToApplyObject(state.activeFilters);
        if (showLoader) {
            dispatch('loader/showLoader', { type: 'CastoramaDefaultPreloader' }, { root: true });
        }
        const { items, all: allItemsCount, filters, categories } = await fetchProducts(filterApplied, currentStoreId);
        await dispatch('googleServices/setDataLayerProducts', {
            products: additionalParametersForDataLayer(productsForDataLayer(items), {
                category: name,
                list: `'Główna lista, ${levelPageUrl} poz. kategorii'`,
            }, true),
        },
        { root: true });
        if (showLoader) {
            dispatch('loader/hideLoader', null, { root: true });
        }
        dispatch('fetchFilters', { filtersFromRequest: filters });
        commit(types.SET_PRODUCTS_COUNT, allItemsCount);
        commit(types.SET_FIRST_PAGE_FLAG, true);
        commit(types.SET_CATEGORY_PRODUCTS, items);
        commit(types.SET_CATEGORY_MENU, categories);

        const filtersLikeInQuery = getFiltersLikeInQuery(state.activeFilters);
        commit(types.SET_SPECIAL_FILTERS_STATE, areSpecialFilters(filtersLikeInQuery));
    },
    validateFilterForUrl({ dispatch, getters }) {
        const { getNavigationData } = getters;
        const { maxPage, currentPage } = getNavigationData;
        if (currentPage > maxPage) {
            dispatch('setNewPageNumber', { pageNumber: maxPage, witchFetch: false });
        } else if (currentPage < 1) {
            dispatch('setNewPageNumber', { pageNumber: 1, witchFetch: false });
        }
    },
    setPaginationMetaLinks({ state, rootState, dispatch }) {
        const isCategoryWithPagination = rootState.windowOptions.levelPageUrl >= 4;
        if (isCategoryWithPagination) {
            const { query, path } = this.$router.history.current;
            const { p: currentPage = 1 } = query;
            if (currentPage > 1) {
                const queryWithLowerPagination = { ...query, p: query.p - 1 };
                dispatch('addLinkToHead', {
                    rel: 'prev',
                    href: queryWithLowerPagination.p === 1
                        ? `${process.env.baseUrl}${path}`
                        : `${process.env.baseUrl}${path}?${getStringFromParams(queryWithLowerPagination)}`,
                },
                { root: true });
            }

            const lowestNumberOfProductsPerPage = pageLimiter[0].limit;
            if (state.productsCount > lowestNumberOfProductsPerPage) {
                const activeLimit = query.limit || lowestNumberOfProductsPerPage;
                const isLastPage = activeLimit * currentPage >= state.productsCount;

                if (!isLastPage) {
                    const queryWithHigherPagination = { ...query, p: Number(currentPage) + 1 };
                    dispatch('addLinkToHead', {
                        rel: 'next',
                        href: `${process.env.baseUrl}${path}?${getStringFromParams(queryWithHigherPagination)}`,
                    },
                    { root: true });
                }
            }
        }
    },
    resetCategoryData({ commit }) {
        commit(types.SET_CURRENT_CATEGORY_ID, null);
        commit(types.SET_CATEGORY_NAME, null);
        commit(types.SET_CATEGORY_DESCRIPTION, null);
        commit(types.SET_CATEGORY_IMAGE, null);

        commit(types.SET_PROMO_BRANDS, []);
        commit(types.SET_CONSULTANT_DATA, null);
    },
};
