import axios from 'axios';
import apiConfig from './api.config';
import { productData as normalizeProductData } from '~/assets/services/product/normalizeData';

export default async function ({ productsSku }) {
    try {
        const { data } = await axios.get(apiConfig.GET_PRODUCTS_DATA_URL, {
            responseType: 'json',
            params: {
                sku: productsSku,
            },
        });

        const { products, attributesCodes } = data;

        return {
            products: products.map(item => ({ ...normalizeProductData(
                {
                    ...item,
                    entity_id: item.id,
                    url_path: item.url,
                },
            ),
            labelId: parseInt(item.label, 10),
            })),
            attributesCodes,
        };
    } catch {
        return { products: [] };
    }
}
