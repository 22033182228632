export default function (articleGrid) {
    const articles = articleGrid.map(item => ({
        title: item.category,
        imageNarrow: item.image,
        description: item.name,
        url: item.url,
    }));

    return articles;
}
