<template>
    <div
        class="wishlist-header"
        @mouseenter="getWislistAndUpdate"
    >
        <BaseLink
            :href="wishListUrl"
            class="wishlist-header__upper-link"
            title="Lista zakupów"
        >
            Lista zakupów {{ wishlistItemsCount ? `(${wishlistItemsCount})` : '' }}
        </BaseLink>
        <PopupWishlistHeader
            ref="wishlistHeader"
            :displayed="wasHovered"
            class="wishlist-header__popup"
            :class="{ 'wishlist-header__popup--empty': (!items.length && !needFetch), 'wishlist-header__popup--loading': needFetch}"
        />
    </div>
</template>

<script>
import { mapState, createNamespacedHelpers } from 'vuex';
import PopupWishlistHeader from '~/components/common/Header/WishlistHeader/components/PopupWishlistHeader';
import BaseLink from '~/components/common/Link/BaseLink';

const {
    mapActions: mapActionsWishlist,
    mapState: mapStateWishlist,
} = createNamespacedHelpers('wishlist');
const { mapActions: mapActionsLoader } = createNamespacedHelpers('loader');

export default {
    name: 'WishlistHeader',
    components: {
        BaseLink,
        PopupWishlistHeader,
    },
    data() {
        return {
            wasHovered: false,
        };
    },
    computed: {
        ...mapState({
            wishListUrl: state => `${state.url.baseUrl}/wishlist/index/`,
        }),
        ...mapStateWishlist({
            items: state => state.items,
            wishlistItemsCount: state => state.itemsCount,
            needFetch: state => state.needFetch,
        }),
    },
    methods: {
        ...mapActionsWishlist(['fetchData']),
        ...mapActionsLoader(['showLoader', 'hideLoader']),
        getWislistAndUpdate() {
            this.wasHovered = true;
            if (this.needFetch) {
                this.showLoader({
                    instance: this.$refs.wishlistHeader.$el,
                    type: 'CastoramaDefaultPreloader',
                });
                this.fetchData().finally(this.hideLoader);
            }
        },
    },
};
</script>

<style lang="scss">
// @todo copy of loginHeader component
.wishlist-header {
    padding: 11px 12px 10px 11px;
    background-color: $color-castoWhite;
    font-family: $goodHomeFontFamily;
    width: 100%;
    position: relative;
    text-align: left;
    cursor: pointer;
    top: -2px;

    &:hover {
        background-color: $color-castoBlue;
        color: $color-castoWhite;
    }

    &:hover & {
        &__popup {
            display: block;
        }
        &__upper-link {
            color: $color-castoWhite;
        }
    }

    &__upper-link {
        font: $font-weight-light 12px/25px $goodHomeFontFamily;
        margin: 0;
        color: $color-slate70;
        text-decoration: none;
    }

    &__popup {
        min-height: 60px;
        border: solid 3px $color-castoBlue;
        padding: 6px;
        color: $color-slate100;
        display: none;
        background-color: $color-castoWhite;
        position: absolute;
        top: 38px;
        min-width: 420px;
        right: 0;
        z-index: 500;

        &--loading {
            height: 200px;
        }
    }
}

@media screen and (max-width: $breakpoint-big-desktop) {
    .wishlist-header {
        &__upper-link {
            font-size: 10px;
        }
    }
}

@media screen and (max-width: $breakpoint-small-desktop) {
    .wishlist-header {
        &__upper-link {
            font-size: 12px;
        }
    }
}
</style>
