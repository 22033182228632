import axios from 'axios';
import apiConfig from './api.config';

export default async function () {
    try {
        const { data } = await axios({
            method: 'get',
            url: apiConfig.NEWSPAPERS_CATALOGS_OCCASIONS_URL,
            config: {
                headers: {
                    'Content-Type':
                        'application/x-www-form-urlencoded; charset=UTF-8',
                },
            },
        });
        return data;
    } catch (ignore) {
        // @todo log errors
        return {};
    }
}
