import axios from 'axios';
import 'url-search-params-polyfill';

import { VALIDATE_VULGARITY_URL } from './api.config';

export default async function (text) {
    const search = new URLSearchParams({
        text,
    });
    try {
        const { data } = await axios({
            method: 'post',
            data: search.toString(),
            url: VALIDATE_VULGARITY_URL,
        });
        return data;
    } catch {
        return {};
    }
}
