import loadScript from '~/assets/services/loadScript';
import { trackJs as trackJsLibUrl } from '~/src/config/externalLibsUrl';
import { loaderWrapperForExternals } from '~/src/utils/helpers';

// eslint-disable-next-line
loaderWrapperForExternals( (app) => {
    if (process.env.TRACKER_JS_TOKEN) {
        // eslint-disable-next-line
        app._trackJs = { token: process.env.TRACKER_JS_TOKEN, application: process.env.APP_NAME };
        loadScript({ url: trackJsLibUrl });
    }
});
