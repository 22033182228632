<template>
    <BaseLink
        v-bind="$attrs"
        @click.native="redirectPage($event)"
    >
        <slot />
    </BaseLink>
</template>

<script>
import { createNamespacedHelpers, mapGetters } from 'vuex';
import { getDataLayerByParameters } from '~/assets/services/dataLayer/dataLayerProducts';
import dataLayerForClick from '~/assets/services/dataLayer/dataLayerForClick';
import cutHostPart from '~/assets/utils/url/cutHostPart';
import BaseLink from '~/components/common/Link/BaseLink';

const { mapState: mapStateGoogleServices } = createNamespacedHelpers('googleServices');

export default {
    name: 'LinkDataLayer',
    components: {
        BaseLink,
    },
    props: {
        isRouterPush: {
            type: Boolean,
            default: true,
        },
    },
    computed: {
        ...mapGetters(['isModeTest']),
        ...mapStateGoogleServices({
            dataLayerProducts: state => state.products,
            isGA: state => state.GA,
            isGTM: state => state.GTM,
        }),
    },
    methods: {
        redirectPage(e) {
            const { dataLayerProducts, isGA, isGTM } = this;
            const { title: name, href: url } = this.$attrs;
            if (isGA && isGTM) {
                e.preventDefault();
                const dataLayer = getDataLayerByParameters(dataLayerProducts, { name, url });
                if (dataLayer) {
                    dataLayerForClick(dataLayer).then(() => {
                        this.loadPage();
                    });
                } else {
                    this.loadPage();
                }
            }
        },
        loadPage() {
            const { isRouterPush } = this;
            const { href: url } = this.$attrs;

            if (isRouterPush && this.isModeTest) {
                this.$router.push({ path: cutHostPart(url), query: { mode: this.mode } });
            } else {
                window.location.href = url;
            }
        },
    },
};
</script>
