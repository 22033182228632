import sortStringArray from '~/assets/services/array/sort';

export const types = {
    SET_CURRENT_CATEGORY_ID: 'SET_CURRENT_CATEGORY_ID',
    SET_MIN_PRICE_FILTER: 'SET_MIN_PRICE_FILTERV',
    SET_MAX_PRICE_FILTER: 'SET_MAX_PRICE_FILTER',
    SET_RANGE_PRICE_FILTER: 'SET_RANGE_PRICE_FILTER',
    SET_CATEGORY_TREE: 'SET_CATEGORY_TREE',
    SET_FILTER_OPTIONS: 'SET_FILTER_OPTIONS',
    SET_ELEMENT_HEIGHT: 'SET_ELEMENT_HEIGHT',
    SET_FILTER_HEIGHT: 'SET_FILTER_HEIGHT',
    SET_PAGE_NAVIGATION_CONFIG: 'SET_PAGE_NAVIGATION_CONFIG',
    SET_PAGE_PAGINATION_CONFIG: 'SET_PAGE_PAGINATION_CONFIG',
    SET_CURRENT_PAGE: 'SET_CURRENT_PAGE',
    SET_NEW_PAGE_NUMBER: 'SET_NEW_PAGE_NUMBER',
    SET_CATEGORY_NAME: 'SET_CATEGORY_NAME',
    SET_CATEGORY_ALTERNATIVE_NAME: 'SET_CATEGORY_ALTERNATIVE_NAME',
    SET_ACTIVE_TAB: 'SET_ACTIVE_TAB',
    SET_SERIES_DATA: 'SET_SERIES_DATA',
    SET_SORTED_SERIES: 'SET_SORTED_SERIES',
    SET_CATEGORY_DESCRIPTION: 'SET_CATEGORY_DESCRIPTION',
    SET_CATEGORY_BRAND_DESCRIPTION: 'SET_CATEGORY_BRAND_DESCRIPTION',
    SET_CATEGORY_IMAGE: 'SET_CATEGORY_IMAGE',
    SET_CATEGORY_BACKGROUND_IMAGE: 'SET_CATEGORY_BACKGROUND_IMAGE',
    SET_CATEGORY_PATH: 'SET_CATEGORY_PATH',
    SET_CATEGORY_PARENTS_IDS: 'SET_CATEGORY_PARENTS_IDS',
    SET_CATEGORY_PARENTS_TREE: 'SET_CATEGORY_PARENTS_TREE',
    SET_CATEGORY_META_DATA: 'SET_CATEGORY_META_DATA',
    SET_CATEGORY_CHILDREN: 'SET_CATEGORY_CHILDREN',
    REMOVE_ACTIVE_FILTER: 'REMOVE_ACTIVE_FILTER',
    ADD_FILTER_TO_ACTIVE: 'ADD_FILTER_TO_ACTIVE',
    SET_PROMO_BRANDS: 'SET_PROMO_BRANDS',
    SET_CONSULTANT_DATA: 'SET_CONSULTANT_DATA',
    SET_CATEGORY_MENU: 'SET_CATEGORY_MENU',
    SET_LEVEL_CATEGORY: 'SET_LEVEL_CATEGORY',
    SET_CATEGORY_PRODUCTS: 'SET_CATEGORY_PRODUCTS',
    SET_ARTICLE_GRID: 'SET_ARTICLE_GRID',
    SET_MAX_PAGE_NUMBER: 'SET_MAX_PAGE_NUMBER',
    SET_FIRST_PAGE_FLAG: 'SET_FIRST_PAGE_FLAG',
    SET_PRODUCTS_COUNT: 'SET_PRODUCTS_COUNT',
    SET_NO_SSR: 'SET_NO_SSR',
    SET_SPECIAL_FILTERS_STATE: 'SET_SPECIAL_FILTERS_STATE',
    REPLACE_ACTIVE_FILTERS: 'REPLACE_ACTIVE_FILTERS',
    SET_IS_SPECIAL: 'SET_IS_SPECIAL',
    SET_IS_SEASON_CATEGORY: 'SET_IS_SEASON_CATEGORY',
};

const mutations = {
    [types.SET_CURRENT_CATEGORY_ID](state, id) {
        state.currentCategoryId = id;
    },
    [types.SET_MIN_PRICE_FILTER](state, min) {
        const { priceSlider } = state;
        state.priceSlider = { ...priceSlider, min };
    },
    [types.SET_MAX_PRICE_FILTER](state, max) {
        const { priceSlider } = state;
        state.priceSlider = { ...priceSlider, max };
    },
    [types.SET_RANGE_PRICE_FILTER](state, value) {
        const { priceSlider } = state;
        state.priceSlider = { ...priceSlider, value };
    },
    [types.SET_CATEGORY_TREE](state, categoryTree) {
        state.categoryTree = categoryTree;
    },
    [types.REPLACE_ACTIVE_FILTERS](state, activeFilters) {
        state.activeFilters = activeFilters;
    },
    [types.SET_FILTER_OPTIONS](state, filterOptions) {
        state.filterOptions = filterOptions;
    },
    [types.ADD_FILTER_TO_ACTIVE](state, filterToActive) {
        state.activeFilters = [...state.activeFilters, filterToActive];
    },
    [types.REMOVE_ACTIVE_FILTER](state, filterIndex) {
        const activeFiltersLeft = state.activeFilters;
        activeFiltersLeft.splice(filterIndex, 1);
        state.activeFilters = activeFiltersLeft;
    },
    [types.SET_ELEMENT_HEIGHT](state, { blockId, height }) {
        state.componentHeights = { ...state.componentHeights, [blockId]: height };
    },
    [types.SET_FILTER_HEIGHT](state, { id, symbol, height }) {
        state.filterHeights = { ...state.filterHeights, ...{ [symbol]: { height, id } } };
    },
    [types.SET_PROMO_BRANDS](state, items) {
        state.categoryPromoBrands = items;
    },
    [types.SET_CATEGORY_NAME](state, name) {
        state.name = name;
    },
    [types.SET_CATEGORY_ALTERNATIVE_NAME](state, alternativeName) {
        state.alternativeName = alternativeName;
    },
    [types.SET_CATEGORY_PARENTS_IDS](state, parentsIds) {
        state.parentsIds = parentsIds;
    },
    [types.SET_ACTIVE_TAB](state, symbol) {
        state.activeTabSymbol = symbol;
    },
    [types.SET_SERIES_DATA](state, seriesData) {
        state.seriesData = seriesData;
    },
    [types.SET_SORTED_SERIES](state, dir) {
        const seriesToSort = state.seriesData;
        state.seriesData = sortStringArray(seriesToSort, dir, 'name');
    },
    [types.SET_CATEGORY_DESCRIPTION](state, description) {
        state.description = description;
    },
    [types.SET_CATEGORY_BRAND_DESCRIPTION](state, description) {
        state.brandDescription = description;
    },
    [types.SET_CATEGORY_IMAGE](state, image) {
        state.image = image;
    },
    [types.SET_CATEGORY_BACKGROUND_IMAGE](state, backgroundImage) {
        state.backgroundImage = backgroundImage;
    },
    [types.SET_CATEGORY_PATH](state, path) {
        state.path = path;
    },
    [types.SET_CATEGORY_META_DATA](state, meta) {
        state.meta = meta;
    },
    [types.SET_CATEGORY_PARENTS_TREE](state, parentsTree) {
        state.parentsTree = parentsTree;
    },
    [types.SET_CATEGORY_CHILDREN](state, children) {
        state.children = children;
    },
    [types.SET_CONSULTANT_DATA](state, consultantData) {
        state.consultantData = consultantData;
    },
    [types.SET_CATEGORY_MENU](state, categoryMenu) {
        state.categoryMenu = categoryMenu;
    },
    [types.SET_ARTICLE_GRID](state, items) {
        state.articleGrid = items;
    },
    [types.SET_CATEGORY_PRODUCTS](state, products) {
        state.products = products;
    },
    [types.SET_FIRST_PAGE_FLAG](state, flag) {
        state.firstPageFlag = flag;
    },
    [types.SET_PRODUCTS_COUNT](state, count) {
        state.productsCount = count;
    },
    [types.SET_NO_SSR](state, flag) {
        state.noSsr = flag;
    },
    [types.SET_SPECIAL_FILTERS_STATE](state, flag) {
        state.containSpecialFilters = flag;
    },
    [types.SET_IS_SPECIAL](state) {
        state.categoryIsSpecial = true;
    },
    [types.SET_IS_SEASON_CATEGORY](state, isSeasonCategory) {
        state.isSeasonCategory = isSeasonCategory;
    },
};

export default mutations;
