function normalizeItems(items) {
    return items
        .filter(item => item && item.label !== undefined)
        .map(item => (
            {
                count: item.count,
                name: item.label,
                symbol: item.value.toString(),
                iconUrl: item.paintImage,
            }
        ));
}

function filtersInterface(filter, symbol) {
    const filtersInterfaces = {
        price: () => ({
            symbol,
            name: filter.label || symbol,
            type: 'multiple',
            items: filter.items,
        }),
        default: () => ({
            symbol,
            name: filter.label || symbol,
            type: 'multiple',
            items: filter.items ? normalizeItems(filter.items) : [],
        }),
    };
    return filtersInterfaces[symbol] ? filtersInterfaces[symbol]() : filtersInterfaces.default();
}

export default function (filtersToNormalize) {
    return Object.keys(filtersToNormalize).map(filterFieldSymbol => (
        filtersInterface(filtersToNormalize[filterFieldSymbol], filterFieldSymbol)
    ));
}
