export const types = {
    SET_THUMBNAIL: 'SET_THUMBNAIL',
    SET_PRODUCT_ID: 'SET_ID',
    SET_NAME: 'SET_NAME',
    SET_SKU: 'SET_SKU',
    SET_DATA_TABLE_TABS: 'SET_DATA_TABLE_TABS',
    SET_AVAILABILITY: 'SET_AVAILABILITY',
    SET_BRAND: 'SET_BRAND',
    SET_BENEFITS: 'SET_BENEFITS',
    SET_PROMOTION_INFO: 'SET_PROMOTION_INFO',
    SET_FILES: 'SET_FILES',
    SET_PRICE: 'SET_PRICE',
    SET_URL: 'SET_URL',
    SET_ONLY_IN_STORE: 'SET_ONLY_IN_STORE',
    SET_QUANTITY: 'SET_QUANTITY',
    SET_SALES_UNITS_CHANGE: 'SET_SALES_UNITS_CHANGE',
    SET_IS_PRODUCT_AVAILABLE_IN: 'SET_IS_PRODUCT_AVAILABLE_IN',
    SET_IS_PRODUCT_SIMPLE: 'SET_IS_PRODUCT_SIMPLE',
    SET_UNIT: 'SET_UNIT',
    SET_CONVERSION: 'SET_CONVERSION',
    SET_PRODUCT_PARENTS_TREE: 'SET_PRODUCT_PARENTS_TREE',
    SET_PRODUCT_QUANTITY: 'SET_PRODUCT_QUANTITY',
    SET_CONSULTANT_DATA: 'SET_CONSULTANT_DATA',
    SET_EXCISE_DUTIES: 'SET_EXCISE_DUTIES',
    SET_VAT_RATE: 'SET_VAT_RATE',
    SET_STOCK_STATUS: 'SET_STOCK_STATUS',
    SET_LABEL: 'SET_LABEL',
    SET_SHIPPING_METHODS: 'SET_SHIPPING_METHODS',
    SET_VARIANTS: 'SET_VARIANTS',
    SET_PICTOGRAMS: 'SET_PICTOGRAMS',
    SET_CATEGORY_NAME: 'SET_CATEGORY_NAME',
    SET_FREE_DELIVERY: 'SET_FREE_DELIVERY',
    SET_MIX_VARIANT: 'SET_MIX_VARIANT',
    SET_MIN_ORDER_QTY: 'SET_MIN_ORDER_QTY',
    SET_QTY_PER_PACKAGE: 'SET_QTY_PER_PACKAGE',
};

const mutations = {
    [types.SET_THUMBNAIL](state, thumbnail) {
        state.thumbnail = thumbnail;
    },
    [types.SET_PRODUCT_ID](state, productId) {
        state.productId = productId;
    },
    [types.SET_SKU](state, sku) {
        state.sku = sku;
    },
    [types.SET_NAME](state, name) {
        state.name = name;
    },
    [types.SET_AVAILABILITY](state, availability) {
        state.availability = availability;
    },
    [types.SET_BRAND](state, brandData) {
        state.brand = brandData;
    },
    [types.SET_BENEFITS](state, benefits) {
        state.benefits = benefits;
    },
    [types.SET_PROMOTION_INFO](state, promotionInfo) {
        state.promotionInfo = promotionInfo;
    },
    [types.SET_FILES](state, files) {
        state.files = files;
    },
    [types.SET_PRICE](state, price) {
        state.price = price;
    },
    [types.SET_URL](state, productPageUrl) {
        state.url = productPageUrl;
    },
    [types.SET_ONLY_IN_STORE](state, isOnlyInStore) {
        state.onlyInStore = isOnlyInStore;
    },
    [types.SET_QUANTITY](state, quantity) {
        state.quantity = quantity;
    },
    [types.SET_CONVERSION](state, conversion) {
        state.conversion = conversion;
    },
    [types.SET_SALES_UNITS_CHANGE](state, salesUnitsChange) {
        state.salesUnitsChange = salesUnitsChange;
    },
    [types.SET_UNIT](state, unit) {
        state.unit = unit;
    },
    [types.SET_PRODUCT_PARENTS_TREE](state, parentsTree) {
        state.parentsTree = parentsTree;
    },
    [types.SET_CONSULTANT_DATA](state, consultantData) {
        state.consultantData = consultantData;
    },
    [types.SET_EXCISE_DUTIES](state, exciseDuties) {
        state.exciseDuties = exciseDuties;
    },
    [types.SET_VAT_RATE](state, vatRate) {
        state.vatRate = vatRate;
    },
    [types.SET_STOCK_STATUS](state, stockStatus) {
        state.stockStatus = stockStatus;
    },
    [types.SET_SHIPPING_METHODS](state, shippingMethods) {
        state.shippingMethods = shippingMethods;
    },
    [types.SET_VARIANTS](state, variants) {
        state.variants = variants;
    },
    [types.SET_PICTOGRAMS](state, pictograms) {
        state.pictograms = pictograms;
    },
    [types.SET_CATEGORY_NAME](state, categoryName) {
        state.categoryName = categoryName;
    },
    [types.SET_FREE_DELIVERY](state, freeDelivery) {
        state.freeDelivery = freeDelivery;
    },
    [types.SET_MIX_VARIANT](state, mixVariant) {
        state.mixVariant = mixVariant;
    },
    [types.SET_PRODUCT_QUANTITY](state, productQuantity) {
        state.productQuantity = productQuantity;
    },
    [types.SET_MIN_ORDER_QTY](state, minOrderQty) {
        state.minOrderQty = minOrderQty;
    },
    [types.SET_DATA_TABLE_TABS](state, dataTableTabs) {
        state.dataTableTabs = dataTableTabs;
    },
    [types.SET_QTY_PER_PACKAGE](state, qty) {
        state.qtyPerPackage = qty;
    },
};

export default mutations;
