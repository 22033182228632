<template>
    <div
        v-if="pageCount !== 0"
        class="mini-navigation"
    >
        <BaseButton
            class="mini-navigation__backward"
            name="Poprzednia"
            @click.native="navigateBackward"
        />
        <BaseButton
            class="mini-navigation__forward"
            name="Następna"
            @click.native="navigateForward"
        />
    </div>
</template>

<script>
import BaseButton from '~/components/common/Button/BaseButton';

export default {
    name: 'MiniNavigation',
    inject: [
        'carousel',
        'navigateForward',
        'navigateBackward',
    ],
    components: {
        BaseButton,
    },
    computed: {
        pageCount() {
            return this.carousel ? this.carousel.pageCount : 0;
        },
    },
};
</script>

<style lang="scss">
    .mini-navigation {
        cursor: pointer;
        position: absolute;
        right: 0;
        bottom: 36px;

        &__backward, &__forward {
            width: 36px;
            height: 36px;
            background: url("~static/images/svg/arrow-right-gray.svg") center $color-slate70-dark no-repeat;
            background-size: 15px;
            display: inline-block;
        }

        &__backward {
            transform: rotate(180deg);
        }
    }

    @media (max-width: $breakpoint-big-desktop) {
        .mini-navigation {
            bottom: 76px;
        }
    }

    @media (max-width: $breakpoint-small-desktop) {
        .mini-navigation {
            bottom: 116px;
        }
    }

</style>
