export const types = {
    SET_PRODUCTS_DATA: 'SET_PRODUCTS_DATA',
    SET_ATTRIBUTES_DATA: 'SET_ATTRIBUTES_DATA',
    REMOVE_ITEM_BY_SKU: 'REMOVE_ITEM_BY_SKU',
    REMOVE_ALL_ITEMS: 'REMOVE_ALL_ITEMS',
    SHIFT_PRODUCTS_ARRAY_LEFT: 'SHIFT_PRODUCTS_ARRAY_LEFT',
    SHIFT_PRODUCTS_ARRAY_RIGHT: 'SHIFT_PRODUCTS_ARRAY_RIGHT',
    SET_STORE_ID: 'SET_STORE_ID',
    SET_MOBILE_LEFT_SCROLL_OFFSET: 'SET_MOBILE_LEFT_SCROLL_OFFSET',
    SET_COMPARE_URL: 'SET_COMPARE_URL',
    PUT_ITEM_TO_COMPARE_GLOBAL: 'PUT_ITEM_TO_COMPARE_GLOBAL',
};

const mutations = {
    [types.SET_ATTRIBUTES_DATA](state, data) {
        state.attributesCodes = data;
    },
    [types.SET_PRODUCTS_DATA](state, data) {
        state.productsData = data;
    },
    [types.REMOVE_ITEM_BY_SKU](state, { sku }) {
        state.productsData.splice(
            state.productsData.findIndex(item => item.sku === sku),
            1,
        );
    },
    [types.REMOVE_ALL_ITEMS](state) {
        state.productsData = [];
    },
    [types.SHIFT_PRODUCTS_ARRAY_LEFT](state) {
        const firstProduct = state.productsData.shift();
        state.productsData.push(firstProduct);
    },
    [types.SHIFT_PRODUCTS_ARRAY_RIGHT](state) {
        const lastProduct = state.productsData.pop();
        state.productsData.unshift(lastProduct);
    },
    [types.SET_STORE_ID](state, { storeId }) {
        state.storeId = storeId;
    },
    [types.SET_MOBILE_LEFT_SCROLL_OFFSET](state, offset) {
        state.mobileScrollOffsetLeft = offset;
    },
    [types.SET_COMPARE_URL](state, compareUrl) {
        state.compareUrl = compareUrl;
    },
    [types.PUT_ITEM_TO_COMPARE_GLOBAL](state, items) {
        state.globalItems = items;
    },
};

export default mutations;
