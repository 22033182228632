<template>
    <span
        class="base-text"
        :class="getBemModifiers()"
    >
        {{ text }}
    </span>
</template>

<script>
export default {
    name: 'BaseText',
    props: {
        text: {
            type: String,
            required: true,
        },
    },
};
</script>

<style lang="scss">
    .base-text {
        &--color-deep-canyon {
            color: $color-deepCanynon;
        }

        &--product-page {
            display: inline-block;
            color: $color-slate100;
            font: $font-weight-light 14px/24px $goodHomeFontFamily;
        }

        &--customer-account {
            font-size: 14px;
            font-family: $goodHomeFontFamily;
        }

        &--font-bold {
            font-weight: $font-weight-bold;
        }

        &--new-line {
            display: block;
        }
    }

    @media screen and (min-width: $mf-breakpoint-tablet) {
        .base-text--product-page {
            font: $font-weight-light 15px/26px $goodHomeFontFamily;
        }
    }
</style>
