import axios from 'axios';
import 'url-search-params-polyfill';

import { CUSTOMER_LOG_OUT_URL } from './api.config';

export default function () {
    return axios({
        method: 'post',
        url: CUSTOMER_LOG_OUT_URL,
    });
}
