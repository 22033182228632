import httpCodes from '~/assets/services/httpCodes';
import fetchRouteData from '~/assets/services/routeData/fetchRouteData';
import { getName as getDynamicPageTemplateName, config as dynamicPageTemplateConfig } from '~/assets/services/dynamicPageTemplate';
import { isCategorySeasonPath } from '~/assets/services/category';
import { TYPES as ROUTE_TYPES, MESSAGES as ROUTE_MESSAGES } from '~/src/config/dictionary/route';

const { pageTypesWithDynamicTemplates } = dynamicPageTemplateConfig;

export default async function ({ route, redirect, store, error }) {
    try {
        const { path } = route;
        const routeData = await fetchRouteData({ path });
        let { id: pageId } = routeData;
        const { type: pageType, template: apiPageTemplateName } = routeData;
        const pageTemplate = apiPageTemplateName || getDynamicPageTemplateName({ path, pageType });

        if (pageType === ROUTE_TYPES.notFound) {
            error({ statusCode: httpCodes.NOT_FOUND, message: ROUTE_MESSAGES.notFoundRoute });
        }

        if (pageType === ROUTE_TYPES.redirectPermanent) {
            redirect(301, pageId);
        }

        if (pageTypesWithDynamicTemplates.indexOf(pageType) >= 0 && !pageTemplate) {
            error({ statusCode: httpCodes.NOT_FOUND, message: ROUTE_MESSAGES.wrongTemplatePage });
        }

        if (pageType === ROUTE_TYPES.category) {
            await store.dispatch('category/setCategoryAsSeasonal', isCategorySeasonPath(path));
        }

        if (pageType === ROUTE_TYPES.product) {
            pageId = parseInt(pageId, 10);
        }

        await store.dispatch('setRouteData', { pageId, pageType, pageTemplate });
    } catch {
        error({ statusCode: httpCodes.NOT_FOUND, message: ROUTE_MESSAGES.notFoundRoute });
    }
}
