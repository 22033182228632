import localStorageService from '~/assets/services/storages/local-storage';

const localStorageKey = 'compare';

export default function () {
    if (localStorageService.isAvailable) {
        const dataFromLocalStorage = JSON.parse(
            localStorageService.get(localStorageKey),
        );
        if (dataFromLocalStorage) {
            return dataFromLocalStorage.map(item => parseInt(item.id, 10));
        }
    }
    return [];
}
