export default function () {
    return {
        productId: null,
        thumbnail: '',
        availability: null,
        price: null,
        url: '',
        onlyInStore: null,
        quantity: null,
        salesUnitsChange: null,
        unit: null,
        conversion: '',
        sku: null,
        productQuantity: null,
        name: null,
        brand: null,
        benefits: [],
        promotionInfo: '',
        files: [],
        parentsTree: [],
        consultantData: {},
        exciseDuties: null,
        vatRate: null,
        stockStatus: null,
        shippingMethods: [],
        variants: [],
        pictograms: null,
        categoryName: null,
        freeDelivery: null,
        mixVariant: null,
        dataTableTabs: [],
        minOrderQty: null,
        qtyPerPackage: null,
    };
}
