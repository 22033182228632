export default function (a, b, isAscending, key) {
    let firstElement = a;
    let secondElement = b;
    if (key) {
        firstElement = a[key];
        secondElement = b[key];
    }
    return isAscending
        ? firstElement.localeCompare(secondElement, 'pl', { sensitivity: 'base' })
        : secondElement.localeCompare(firstElement, 'pl', { sensitivity: 'base' });
}
