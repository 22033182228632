import dictionaryPriceCurrency from '~/src/config/dictionary/priceCurrency';

export default function () {
    return {
        currentCurrency: dictionaryPriceCurrency.pl,
        email: null,
        firstName: null,
        isCustomerLogged: null,
        loginFormKey: null,
        cartCount: 0,
        wishlistCount: 0,
    };
}
