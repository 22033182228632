export default function (filtersCollection) {
    const magentoFriendlyCollection = {};
    Object.keys(filtersCollection).forEach((filterName) => {
        magentoFriendlyCollection[filterName] = filtersCollection[filterName];
        if (magentoFriendlyCollection[filterName] instanceof Array) {
            magentoFriendlyCollection[filterName] = magentoFriendlyCollection[filterName].join('__');
        }
    });
    return magentoFriendlyCollection;
}
