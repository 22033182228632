import { createNewCustomer } from '~/assets/services/storages/local-storage/index';

export default function (isLogged) {
    if (localStorage) {
        if (localStorage.getItem('customer') === null) {
            createNewCustomer();
        }
        const data = JSON.parse(localStorage.getItem('customer'));
        data.isLogged = isLogged;
        localStorage.removeItem('customer');
        localStorage.setItem('customer', JSON.stringify(data));
    }
}
