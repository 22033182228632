import stringStripHtml from 'string-strip-html';

function normalizeReview({ rating, text, nickname, answer, ...reviewData }) {
    const reviewItem = {
        ...reviewData,
        rating: parseInt(rating, 10),
        text: stringStripHtml(text),
        nickname: stringStripHtml(nickname) || '',
    };

    if (answer && stringStripHtml(answer)) {
        reviewItem.answer = stringStripHtml(answer);
    }

    return reviewItem;
}

function normalizeReviewsData({ avg_rating: average = 0, total: count = 0, reviews: items = [] }) {
    return {
        items: items.map(normalizeReview),
        average: parseFloat(average),
        count: parseInt(count, 10),
    };
}


export {
    normalizeReview,
    normalizeReviewsData,
};
