<template>
    <FisheArrowRight :class="[...classNamesByEnergyClasses, ...getBemModifiers()]">
        <EnergyClassesSeparator
            v-if="hasRangeThroughWords"
            :separator="wordRangeFrom"
        /><!--
        ---><template v-for="(energyClass, energyClassIndex) in energyClasses">
            <EnergyClassesItem
                :key="`${energyClass.letter}-${energyClass.quantifiers}-${energyClassIndex}`"
                :letter="energyClass.letter"
                :quantifiers="energyClass.quantifiers"
            /><!--
        ---><EnergyClassesSeparator
                v-if="energyClassIndex < energyClassesLastItemIndex"
                :key="`energy-classes-separator-${energyClassIndex}`"
                :separator="hasRangeThroughWords ? wordRangeTo : defaultSeparator"
            />
        </template>
    </FisheArrowRight>
</template>

<script>
import FisheArrowRight from '~/src/components/ui/Fiche/FisheArrowRight';
import EnergyClassesItem from './components/EnergyClassesItem';
import EnergyClassesSeparator from './components/EnergyClassesSeparator';
import energyClassesConfig from '~/src/utils/energyClass/config';

const {
    withShadow: energyClassesWithShadow,
    all: energyClassesAll,
    rangeWords: energyClassRangeWords,
    defaultSeparator: energyClassDefaultSeparator,
    cssOptions,
} = energyClassesConfig;
const [wordRangeFrom, wordRangeTo] = energyClassRangeWords;
const {
    specialClasses: energyClassSpecialClasses,
    base: energyClassCssBase,
} = cssOptions;
const {
    multiple: energyClassMultipleClassName,
    verbalRange: energyClassVerbalRangeClassName,
} = energyClassSpecialClasses;


export default {
    name: 'EnergyClasses',
    components: {
        FisheArrowRight,
        EnergyClassesItem,
        EnergyClassesSeparator,
    },
    props: {
        /**
         * Product energy class label
         */
        value: {
            type: String,
            required: true,
        },
    },
    data() {
        return {
            defaultSeparator: energyClassDefaultSeparator,
            wordRangeFrom,
            wordRangeTo,
        };
    },
    computed: {
        /**
         * Returns energy class has verbal range
         * @returns {boolean}
         */
        hasRangeThroughWords() {
            return this.checkEnergyClassContainVerbalRange(this.value);
        },
        /**
         * Returns energy classes list
         * @returns {Array}
         */
        energyClasses() {
            let energyClassNormalizedValue = this.value.replace(/\s/g, '');
            let energyClassesDivider = this.defaultSeparator;

            if (this.hasRangeThroughWords) {
                energyClassNormalizedValue = energyClassNormalizedValue.replace(wordRangeFrom, '');
                energyClassesDivider = wordRangeTo;
            }

            return energyClassNormalizedValue.split(energyClassesDivider).map(energyClass => ({
                source: energyClass,
                letter: energyClass[0],
                quantifiers: energyClass.slice(1),
            }));
        },
        /**
         * Returns index of last item in energy classes list
         * @returns {number}
         */
        energyClassesLastItemIndex() {
            return this.energyClasses.length - 1;
        },
        /**
         * Returns style class name based on energy class value
         * @returns {Array}
         */
        classNamesByEnergyClasses() {
            const classBase = energyClassCssBase;
            const classNames = [classBase];
            const energyClassesInOrder = this.getEnergyClassesInOrder(this.energyClasses);

            if (this.energyClassesLastItemIndex > 0) {
                classNames.push(`${energyClassMultipleClassName}`);
            }

            if (this.hasRangeThroughWords) {
                classNames.push(`${energyClassVerbalRangeClassName}`);
            }

            energyClassesInOrder.forEach(({ source, letter, quantifiers }) => {
                const countQuantifiers = quantifiers.length;
                const energyClassLetterLowerCase = letter.toLowerCase();
                const classSuffix = countQuantifiers > 0 ? countQuantifiers : '';

                if (energyClassesWithShadow.indexOf(source) >= 0) {
                    classNames.push(`${classBase}--with-shadow`);
                }
                classNames.push(`${classBase}--${energyClassLetterLowerCase}${classSuffix}`);
            });

            return classNames;
        },
    },
    methods: {
        /**
         * Check energy classes has correct form for verbal range
         * @param {string} energyClass
         * @returns {boolean}
         */
        checkEnergyClassContainVerbalRange(energyClass) {
            const pattern = new RegExp(`^(${wordRangeFrom})\\s[A-K][+]{0,3}\\s(${wordRangeTo})\\s[A-K][+]{0,3}$`);
            return pattern.test(energyClass);
        },
        /**
         * Returns energyClasses in order as in config
         * @param {array} energyClasses
         * @returns {Array}
         */
        getEnergyClassesInOrder(energyClasses) {
            return [...energyClasses].sort(
                (energyClassA, energyClassB) => energyClassesAll.indexOf(energyClassA) - energyClassesAll.indexOf(energyClassB),
            );
        },
    },
};
</script>

<style lang="scss">
    $energyClassA3: #33964E;
    $energyClassA2: #3A990A;
    $energyClassA1: #A8C824;
    $energyClassA: #FDE94C;
    $energyClassB: #FECD44;
    $energyClassC: #FC443C;
    $energyClassD: #E03C35;
    $energyClassE: #C9342E;
    $energyClassF: #AE2C27;
    $energyClassG: #AA0000;
    $energyClassK: #CC0000;

    $energyClassLettersWithColors: (
        #{$energyClassK}: "k",
        #{$energyClassG}: "g",
        #{$energyClassF}: "f",
        #{$energyClassE}: "e",
        #{$energyClassD}: "d",
        #{$energyClassC}: "c",
        #{$energyClassB}: "b",
        #{$energyClassA}: "a",
        #{$energyClassA1}: "a1",
        #{$energyClassA2}: "a2",
        #{$energyClassA3}: "a3"
    );

    .energy-classes {
        $self: &;

        width: 34px;
        padding-left: 2px;
        margin-right: 9px;
        height: 20px;
        font: $font-weight-bold 16px/20px $goodHomeFontFamily;
        border-left-color: rgba(0,0,0,1);
        color: $color-castoWhite;

        &--multiple {
            width: 43px;

            #{$self}__quantifiers {
                margin: 0 -2px;
            }
        }

        &--verbal-range {
            max-width: 56px;
            width: auto;
            padding-left: 1px;
            font-size: 11px;
            font-weight: $font-weight-regular;
            text-align: center;

            #{$self} {
                &__item {
                    margin: 0;
                    font-weight: $font-weight-bold;

                    &:last-child {
                        margin-right: 0;
                    }
                }

                &__quantifiers {
                    font-size: 9px;
                    margin: 0 0 0 -2px;
                    font-weight: $font-weight-regular;
                }

                &__separator {
                    font-size: 9px;
                    margin: 0 1px 0 0;
                }
            }
        }

        &__item {
            display: inline-block;
        }

        &__separator {
            margin: 0 -2px 0 0;
        }

        &__quantifiers {
            position: relative;
            font-size: 11px;
            line-height: 13px;
            margin: 0 -1px;
            vertical-align: top;
        }

        &#{&}--products-set-item {
            position: relative;
            display: block;
            height: 18px;
            line-height: 18px;

            &::after {
                position: absolute;
                top: 0;
                right: -8px;
                border-top-width: 9px;
                border-bottom-width: 9px;
                border-left-width: 8px;
            }
        }

        &#{&}--product-content-header {
            position: relative;
            display: inline-block;
            height: 38px;
            width: auto;
            font: $font-weight-bold 31px/38px $goodHomeFontFamily;
            min-width: 80px;
            padding-left: 10px;

            &::after {
                position: absolute;
                top: 0;
                right: -18px;
                border-top-width: 19px;
                border-bottom-width: 19px;
                border-left-width: 18px;
            }

            #{$self} {
                &__item {
                    font-size: 31px;
                }

                &__quantifiers {
                    font-size: 22px;
                    line-height: 27px;
                    margin: 0;
                }

                &__separator {
                    font-size: 31px;
                }
            }
        }

        &#{&}--product-content-header#{&}--with-shadow {
            box-shadow: none;

            &::before {
                display: none;
            }
        }

        &#{&}--product-content-header#{&}--verbal-range {
            max-width: none;

            #{$self}__separator {
                font-size: 22px;
            }
        }


        &--extinguished {
            opacity: 0.5;
        }

        &--with-shadow {
            box-shadow: 0 0 0 1px rgba(0,0,0,0.05);

            &::before {
                position: absolute;
                width: 0;
                height: 0;
                content: '';
                display: block;
                border-top: 9px solid transparent;
                border-bottom: 9px solid transparent;
                border-left: 8px solid rgba(0, 0, 0, 0.05);
                right: -9px;
                top: -1px;
            }
        }

        &::after {
            position: absolute;
            top: 0;
            right: -9px;
            border-top-width: 10px;
            border-bottom-width: 10px;
            border-left-width: 9px;
        }
    }

    @each $color, $letter in $energyClassLettersWithColors {
        .energy-classes.energy-classes--#{$letter} {
            background: $color;

            &::after {
                border-left-color: $color;
            }
        }
    }


    @media (min-width: $mf-breakpoint-tablet) {
        .energy-classes {
            $self: &;

            width: 41px;
            padding-left: 2px;
            height: 24px;
            font: 22px/24px $goodHomeFontFamily;
            margin-right: 12px;

            &--multiple {
                width: 63px;

                #{$self}__quantifiers {
                    margin: 0 0 0 -2px;
                }
            }

            &--verbal-range {
                max-width: 75px;

                #{$self} {
                    &__item {
                        font-size: 12px;
                    }

                    &__quantifiers {
                        font-size: 10px;
                    }

                    &__separator {
                        font-size: 10px;
                    }
                }
            }

            &#{&}--with-shadow {
                box-shadow: 0 0 0 1px rgba(0,0,0,0.05);

                &::before {
                    border-top: 12px solid transparent;
                    border-bottom: 12px solid transparent;
                    border-left: 12px solid rgba(0,0,0,0.05);
                    right: -13px;
                    top: 0;
                }

                &::after {
                    right: -10px;
                }
            }

            &#{&}--product-content-header#{&}--with-shadow::after {
                right: -18px;
            }

            &#{&}--products-set-item {
                position: relative;
                display: block;
                height: 24px;
                line-height: 24px;

                &::after {
                    position: absolute;
                    top: 0;
                    right: -11px;
                    border-top-width: 12px;
                    border-bottom-width: 12px;
                    border-left-width: 11px;
                }
            }

            &::after {
                border-top-width: 12px;
                border-bottom-width: 12px;
                border-left-width: 10px;
                right: -10px;
            }

            &__quantifiers {
                font-size: 14px;
                line-height: 16px;
            }

            &__separator {
                margin: 0;
            }
        }
    }
</style>
