export default function (unit) {
    switch (unit) {
        case 'szt.':
        case 'SZT':
            return 'sztuka';
        case 'm<sup>2</sup>':
            return 'm2';
        case 'm<sup>3</sup>':
            return 'm3';
        case 'kg':
            return 'kilogram';
        case 'l':
            return 'litr';
        case 'mb':
            return 'metr bieżący';
        default:
            return unit.toLowerCase();
    }
}
