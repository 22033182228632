export const types = {
    SET_CUSTOMER_LOGGED: 'SET_CUSTOMER_LOGGED',
    SET_LOGIN_FORM_KEY: 'SET_LOGIN_FORM_KEY',
    SET_CUSTOMER_EMAIL: 'SET_CUSTOMER_EMAIL',
    SET_CUSTOMER_NAME: 'SET_CUSTOMER_NAME',
};

const mutations = {
    [types.SET_CUSTOMER_LOGGED](state, isCustomerLogged) {
        state.isCustomerLogged = isCustomerLogged;
    },
    [types.SET_LOGIN_FORM_KEY](state, formKey) {
        state.loginFormKey = formKey;
    },
    [types.SET_CUSTOMER_EMAIL](state, email) {
        state.email = email;
    },
    [types.SET_CUSTOMER_NAME](state, firstName) {
        state.firstName = firstName;
    },
};

export default mutations;
