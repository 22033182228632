export const pageLimiter = [{
    limit: 14,
}, {
    limit: 29,
}, {
    limit: 47,
}];

export const specialFiltersNames = [
    'brand',
];

export const isSpecialFilterValueValid = {
    brand(value) {
        return value.indexOf('_') === -1;
    },
};

export const indexedFiltersNames = [
    ...specialFiltersNames,
];
