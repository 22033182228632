export const types = {
    SET_IS_ENABLED: 'SET_IS_ENABLED',
    SET_MIN_PRICE: 'SET_MIN_PRICE',
    SET_URL: 'SET_URL',
    SET_INSTALLMENTS_NUMBER: 'SET_INSTALLMENTS_NUMBER',
    SET_SHOP_NUMBER: 'SET_SHOP_NUMBER',
    SET_DATE_START: 'SET_DATE_START',
    SET_DATE_END: 'SET_DATE_END',
};

const mutations = {
    [types.SET_IS_ENABLED](state, isEnabled) {
        state.isEnabled = isEnabled;
    },
    [types.SET_MIN_PRICE](state, minPrice) {
        state.minPrice = minPrice;
    },
    [types.SET_URL](state, url) {
        state.url = url;
    },
    [types.SET_INSTALLMENTS_NUMBER](state, installmentsNumber) {
        state.installmentsNumber = installmentsNumber;
    },
    [types.SET_SHOP_NUMBER](state, shopNumber) {
        state.shopNumber = shopNumber;
    },
    [types.SET_DATE_START](state, dateStart) {
        state.dateStart = dateStart;
    },
    [types.SET_DATE_END](state, dateEnd) {
        state.dateEnd = dateEnd;
    },
};

export default mutations;
