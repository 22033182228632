import CookieUniversal from 'cookie-universal';
import cookieConfig from '~/assets/services/storages/cookies/cookie.config';

const cookies = new CookieUniversal();
const cookieValue = {
    name: 'managingCookies',
    key: 'GA,FUNC,OUT,APPNEXUS,EXACTAG,FACEBOOK,GOOGLE,LEMONPI,PLISTA,CRITEO,WP,SAVECART,PROXICLOUD,TRADETRACKER,ZPRMEDIA,NOKAUT,HOMEBOOK,RTBHOUSE,default',
};
const managingCookiesValue = {
    GA: ['acb', 'aw', '_aw_m_', '_dc_gtm_UA-29574191-1', 'exactag_new_user', '_ga', '_gat_UA-52945115-2', '_gat_UA-29574191-9', 'lpc', 'src0_xxxx', '__utma', '__utmb', '__utmc', '__utmz', '__utmZ', 'zcc', 'ztcc'],
    FUNC: ['applicationPopup', 'bId', 'et_uk', 'exactag_new_cpv', 'exactag_new_optout', 'exactag_new_uk', 'local_save_cart', 'sess', 'showBusinessSundays', '_br_uid_2'],
    APPNEXUS: ['anj', 'icu', 'uuid2', 'uuid2 (opt out)'],
    EXACTAG: ['et_uk', 'exactag_new_cpv', 'exactag_new_uk', 'exactag_new_user', 'exactag_new_optout', 'session_session'],
    FACEBOOK: ['fr'],
    GOOGLE: ['_ga', '_gat_UA-52945115-2', '_gat_UA-29574191-9', '_dc_gtm_UA-29574191-1', '__utma', '__utmb', '__utmc', '__utmz', '__utmZ'],
    LEMONPI: ['lpc', 'lpuid', 'src0_xxxx'],
    PLISTA: ['player', 'vuid', '_utma', '_umtb', '_utmc', '_utmz', '_utmt_player', 'Linkpulse (lp4_u)', 'Pookie', 'Ploptout', 'um', 'arv', 'rec', 'red', 'frc', 'imp', 'cls'],
    CRITEO: ['_ceg.s', '_ceg.u', '_dc_gtm_UA-#', '_ga', '_gat', '_gid', '@History/@scroll|#', 'eid', 'uid', 'zdi', 'contently_insights_user', 'criteo_write_test', 'cto_lwid', 'cto_tld_test', 'd-a8e6', 'pageViews', 'pardot [x2]', 'rex/match.aspx', 's-9da4', 'tk', 'visitor_id# [x2]', 'visitor_id#-hash [x2]', 'x_contently_id:a10fecf1e2938930e059af4aac1111fa [x2]'],
    WP: ['JSESSIONID', 'advTracking', 'BDseg', 'statid', 'ust'],
    SAVECART: ['sc_data', 'PHPSESSID', 'SERVERID', 'sc_elapsed', 'sc_elapsed_url'],
    PROXICLOUD: ['browser_id', 'roq_fid', 'opt_out'],
    TRADETRACKER: ['TT_allowCookies', 'ttagency*', 'ttuser_*', 'xmlhttp', 'affiliateSiteIDs', 'campaignIDs', 'companyID', 'locale_*', '_pk_*', 'pi*', '__tdat*', '__bctdat'],
    ZPRMEDIA: ['OPENTRACK'],
    NOKAUT: ['_CXIDLOC', '_CXIDLOCSES', 'nokaut', 'nokaut-ping'],
    HOMEBOOK: ['userId'],
    RTBHOUSE: ['_ljtrtb_86', 'am-uid', 'ayl_visitor', 'BDseg', 'c', 'cookieJartestCookie', 'gdpr_status', 'gtm_usr', 'ljt_reader', 'mg_sync', 'obuid', 'OTP', 'rtbhouseuid', 'rtbhs', 'statid', 'stx_user_id', 't_gid', 'targetcid7', 'tluid', 'tuuid', 'tuuid_lu', 'ust', 'viewer_token', 'ts', 'IDE', 'u'],
};

const managingCookiesGroupValue = {
    FUNC: ['FUNC'],
    GA: ['GA'],
    OUT: ['OUT', 'APPNEXUS', 'EXACTAG', 'FACEBOOK', 'GOOGLE', 'LEMONPI', 'PLISTA', 'CRITEO', 'WP', 'SAVECART', 'PROXICLOUD', 'TRADETRACKER', 'ZPRMEDIA', 'NOKAUT', 'HOMEBOOK', 'RTBHOUSE'],
};

const sensitiveCookies = ['GA', 'GOOGLE', 'OUT'];

const getCookie = () => cookies.get(cookieValue.name);

const getCookieValue = () => {
    try {
        return getCookie().split(',');
    } catch {
        return [];
    }
};

const setCookieValue = array => cookies.set(cookieValue.name, array.toString(), {
    ...cookieConfig.defaultOptions,
    maxAge: 365 * 24 * 60 * 60,
});

const overwriteManagingBrowserCookie = () => setCookieValue(cookieValue.key);

const getStatusCookie = el => getCookieValue().indexOf(el) > -1;

const getSensitiveDataCookies = () => {
    const cookiesList = [];
    sensitiveCookies.forEach((el) => {
        if (getCookieValue().indexOf(el) < 0) {
            cookiesList.push(el);
        }
    });
    return cookiesList;
};

const isGoogletagmanagerForRemoving = () => !!getSensitiveDataCookies().length;

const removeValueFromCookie = (cookiesValue) => {
    if (cookiesValue !== undefined) {
        cookiesValue.forEach((el) => {
            if (cookies.get(el)) {
                cookies.remove(el);
            }
        });
    }
};

const setStatusCookie = (val, acceptLegacy) => {
    const cookiesArray = getCookieValue();
    cookiesArray.forEach((index) => {
        if (index === val && cookiesArray.indexOf(val) !== -1) {
            if (!acceptLegacy && getStatusCookie(val)) {
                removeValueFromCookie(managingCookiesValue[val]);
                cookiesArray.splice(getCookieValue().indexOf(val), 1);
            }
        } else if (acceptLegacy && !getStatusCookie(val)) {
            cookiesArray.push(val);
        }
        return setCookieValue(cookiesArray);
    });
};

const setStatusGroupCookie = (val, acceptLegacy) => {
    const cookieNameArray = getCookieValue();
    const arrayCookies = ['FUNC', 'GA', 'OUT'];

    arrayCookies.forEach((index) => {
        if (index === val) {
            if (!acceptLegacy && getStatusCookie(val)) {
                managingCookiesGroupValue[index].forEach((valName) => {
                    if (cookieNameArray.indexOf(valName) !== -1) {
                        cookieNameArray.splice(cookieNameArray.indexOf(valName), 1);
                        removeValueFromCookie(managingCookiesValue[valName]);
                    }
                });
            } else if (acceptLegacy && !getStatusCookie(val)) {
                if (cookieNameArray.indexOf(val) === -1) {
                    managingCookiesGroupValue[index].forEach((valueName) => {
                        cookieNameArray.push(valueName);
                    });
                }
            }
            return setCookieValue(cookieNameArray);
        }
    });
};

const setManagingBrowserCookie = () => {
    if (!cookies.get(cookieValue.name)) {
        setCookieValue(cookieValue.key);
    }
};

export {
    setCookieValue,
    setStatusCookie,
    setStatusGroupCookie,
    setManagingBrowserCookie,
    getCookie,
    getStatusCookie,
    isGoogletagmanagerForRemoving,
    overwriteManagingBrowserCookie,
};
