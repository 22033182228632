import getFloatFromPriceString from '~/src/utils/helpers/getFloatFromPriceString';
import getTransformedPrice from './getTransformedPrice';
import getDecimal from './getDecimal';

export default function (currentValue) {
    const value = getFloatFromPriceString(currentValue);
    const fraction = getTransformedPrice(value) - getDecimal(value);
    if (fraction === 0) {
        return '00';
    }
    return (fraction * 100).toFixed();
}
