<template>
    <sup
        v-if="value.length"
        class="energy-classes__quantifiers"
    ><!--
    --->{{ value }}<!--
---></sup>
</template>

<script>
export default {
    name: 'EnergyClassesQuantifiers',
    props: {
        /**
         * Energy class quantifiers
         */
        value: {
            type: String,
            default: '',
        },
    },
};
</script>
