import setXMagentoTags from '~/src/utils/httpHeaders/setXMagentoTags';

export default function ({ res, store }) {
    if (process.server) {
        const { pageId = 0, pageType = 'unknown', pageTemplate = 'default' } = store.state;
        const tags = [
            pageType,
            `template-${pageTemplate.toLowerCase()}`,
            `${pageType}-id-${pageId}`,
        ];

        setXMagentoTags({ res, tags });
    }
}
