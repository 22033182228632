import { GET_PRICE_OF_PRODUCT_URL as url } from './api.config';
import ProductPriceCacheWrapper from '~/assets/services/axiosWrapper/ProductPriceCacheWrapper';
import { energyClass } from './normalizeData';
import { normalizeData as normalizePriceData } from '~/assets/services/price';

function normalizeData({ products }) {
    Object.keys(products).forEach((productId) => {
        products[productId] = normalizePriceData({ ...products[productId], productId });
    });

    return energyClass({ products });
}

function normalizeParams(params) {
    let { store } = params;
    if (store === 'default') {
        store = 1;
    }
    return {
        ...params,
        store,
    };
}

export default function ({
    productsIds,
    origin,
    storeId: store,
    isAjax = true,
    typeBlock = 'recommended',
    needType = 'needData',
    useCache = true,
}) {
    const params = normalizeParams({
        isAjax,
        store,
        typeBlock,
        [needType]: productsIds.join(','),
        origin,
    });

    return new ProductPriceCacheWrapper({
        method: 'get',
        url,
        params,
        config: {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
            },
        },
        timestamp: 2,
    })
        .getValue(productsIds, store, useCache)
        .then(({ products }) => normalizeData({ products }))
        .catch(() => {
            throw new Error('Cannot fetch price');
        });
}
