<template>
    <BaseLink
        v-bind="link"
        class="create-account-link"
    />
</template>

<script>
import BaseLink from '~/components/common/Link/BaseLink';

const link = {
    href: `${process.env.baseUrl}/customer/account/login/`,
    text: 'Nie masz konta? Zarejestruj się',
};
export default {
    name: 'CreateAccountLink',
    components: { BaseLink },
    data() {
        return { link };
    },
};
</script>

<style lang="scss">
    .create-account-link {
        padding: 0;
        margin: 0;
        width: 100%;
        display: inline-block;
        color: $color-castoWhite;
        line-height: normal;
        font: $font-weight-regular 13px/48px $goodHomeFontFamily;
        text-align: center;
        text-decoration: none;
        background-color: $color-castoBlue;
        cursor: pointer;

        &:hover {
            text-decoration: underline;
        }
    }
</style>
