<template>
    <span class="energy-classes__item"><!--
    --->{{ letter }}<!--
    ---><EnergyClassesQuantifiers
            v-if="hasQuantifiers"
            :value="quantifiers"
        /><!--
---></span>
</template>

<script>
import EnergyClassesQuantifiers from './EnergyClassesQuantifiers';

export default {
    name: 'EnergyClassesItem',
    components: {
        EnergyClassesQuantifiers,
    },
    props: {
        /**
         * Energy class letter
         * @enum {(A|B|C|D|E|F|G)}
         */
        letter: {
            type: String,
            required: true,
        },
        /**
         * Energy class quantifiers
         */
        quantifiers: {
            type: String,
            default: '',
        },
    },
    computed: {
        /**
         * Returns state whether quantifiers exist in energy class
         * @returns {boolean}
         */
        hasQuantifiers() {
            return this.quantifiers.indexOf('+') >= 0;
        },
    },
};
</script>
