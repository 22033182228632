import { types } from './mutations';
import CacheWrapper from '~/assets/services/axiosWrapper/cacheWrapper';
import ProductPriceCacheWrapper from '~/assets/services/axiosWrapper/ProductPriceCacheWrapper';
import { getStoreIdsFromUrl } from '~/assets/services/market';
import { getSelectedShopIds as getStoreIdsFromCookies, isShopSelected, getDefaultShopIds } from '~/assets/services/storages/cookies/shop';

export default {
    async nuxtServerInit({ dispatch }) {
        await Promise.all([
            dispatch('menu/fetchCatalogTree'),
            dispatch('menu/fetchInspirationsTree'),
            dispatch('menu/fetchForumTree'),
            dispatch('menu/fetchNewspaperData'),
            dispatch('footer/fetchBaseFooter'),
            dispatch('promotional/fetchPromotional'),
        ]);
    },
    async nuxtClientInit({ dispatch, commit }) {
        const externalJsBlocked = !window.strix || window.strix.advertising !== true;
        commit(types.SET_SAUTE, window.strix && window.strix.saute);

        await Promise.all([
            dispatch('setAdBlockDetected', externalJsBlocked),
            dispatch('googleServices/setAll'),
        ]);

        new CacheWrapper().clearExpiredKeys();
        new ProductPriceCacheWrapper().clearExpiredKeys();
    },
    setRouteData({ commit }, { pageType, pageId, pageTemplate }) {
        commit(types.SET_PAGE_TYPE, pageType);
        commit(types.SET_PAGE_ID, pageId);
        commit(types.SET_PAGE_TEMPLATE, pageTemplate);
    },
    setAdBlockDetected({ commit }, adBlockState) {
        commit(types.SET_ADBLOCK_STATE, adBlockState);
    },
    async setStoreIds({ dispatch }) {
        const shopIdsFromUrl = await getStoreIdsFromUrl(() => dispatch('markets/fetchAvailableMarkets'));
        let shopIdsToSet = shopIdsFromUrl;

        if (!shopIdsFromUrl) {
            const shopIdsFromCookies = getStoreIdsFromCookies();
            shopIdsToSet = isShopSelected(shopIdsFromCookies)
                ? shopIdsFromCookies
                : getDefaultShopIds();
        }

        await dispatch('markets/changeShopIds', shopIdsToSet);
        await dispatch('markets/setFetchStartStateDone');
    },
    async registerRequest({ dispatch, commit, state }, {
        requestHandler,
        loaderInstance,
        loaderType,
        successHandler,
        errorHandler,
        onError,
        forceGlobal = true,
        addLoader = true,
    }) {
        if ((!state.requestsCount || forceGlobal) && addLoader) {
            dispatch('loader/showLoader', { instance: loaderInstance, type: loaderType });
        }

        commit(types.INCREASE_REQUESTS);

        try {
            if (successHandler) {
                const requestResult = await requestHandler();
                successHandler(requestResult);
            } else {
                await requestHandler();
            }
        } catch (errorMessages) {
            if (errorHandler) {
                errorHandler(errorMessages);
            } else {
                dispatch('globalMessages/addMessage', {
                    texts: 'Wystąpił błąd. Spróbuj ponownie później',
                    type: 'warning',
                    hideButton: true,
                });
            }

            if (this.app && this.app.$logger) {
                this.app.$logger({
                    message: `Cannot fetch inside registerRequest - error: ${errorMessages}`,
                    source: 'store/actions.js',
                });
            }

            if (onError) {
                onError({ errorMessages });
            }
        }

        if (state.requestsCount <= 1 && addLoader) {
            dispatch('loader/hideLoader');
        }

        commit(types.DECREASE_REQUESTS);
    },
    addLinkToHead({ commit }, link) {
        commit(types.ADD_LINK_HEAD, link);
    },
};
