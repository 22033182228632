import { types } from './mutations';
import { GLOBAL_VARS } from '~/config/index';

function setStateAfterBlockLoaded({ name, commit, type, externalCallback }) {
    const blockState = window[GLOBAL_VARS.BLOCKS_LOADED_STATE].getStateByName(name);
    if (blockState !== null) {
        commit(type, blockState);
    } else {
        window[
            GLOBAL_VARS.EXTERNAL_CALLBACKS_WRAPPER_NAME
        ].add({
            source: name,
            callback(state) {
                commit(type, state);
            },
        });
    }
    if (externalCallback) {
        externalCallback();
    }
}

export default {
    setAll({ dispatch }) {
        if (!window.bold.module.managingBrowserCookies.isGoogletagmanagerForRemoving()) {
            dispatch('setGAPI');
            dispatch('setGTM');
            dispatch('setGA');
            dispatch('setGPT');
        }
    },
    setGAPI({ commit }) {
        setStateAfterBlockLoaded({
            name: 'gapi',
            type: types.SET_GAPI,
            commit,
        });
    },
    setGTM({ commit }) {
        setStateAfterBlockLoaded({
            name: 'gtm',
            type: types.SET_GTM,
            commit,
            externalCallback() {
                try {
                    const { GOOGLE_TAG_MANAGER_ID } = GLOBAL_VARS;
                    window.google_tag_manager[GOOGLE_TAG_MANAGER_ID].dataLayer.reset();
                } catch {
                    // @todo errors log
                }
            },
        });
    },
    setGA({ commit }) {
        setStateAfterBlockLoaded({
            name: 'ga',
            type: types.SET_GA,
            commit,
        });
    },
    setGPT({ commit }) {
        setStateAfterBlockLoaded({
            name: 'gpt',
            type: types.SET_GPT,
            commit,
        });
    },
    async setDataLayerProducts({ commit }, data) {
        if (data && data.products) {
            commit(types.SET_PRODUCTS_DATA_LAYER, { ...data });
        }
    },
    setProductPriceLoaded({ commit }, areLoaded) {
        commit(types.SET_IS_PRODUCT_PRICE_LOADED, areLoaded);
    },
    registerDoubleClickSlot({ commit }, data) {
        commit(types.ADD_DOUBLE_CLICK_SLOT, data);
    },
    unregisterDoubleClickSlot({ commit, state }, slotDataId) {
        const doubleClickSlotIndex = state.doubleClickSlots.findIndex(slot => slot.id === slotDataId);
        if (doubleClickSlotIndex >= 0) {
            commit(types.REMOVE_DOUBLE_CLICK_SLOT_ON_INDEX, doubleClickSlotIndex);
        }
    },
};
