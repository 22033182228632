<template>
    <div class="social-media-links">
        <BaseLink
            v-bind="links"
            class="link--facebook"
        />
        <GoogleSignIn />
    </div>
</template>

<script>
import BaseLink from '~/components/common/Link/BaseLink';
import GoogleSignIn from '~/components/LoginBox/components/GoogleSignIn';

const links = [
    { href: `${process.env.baseUrl}/social/facebook/login/`, title: 'Zaloguj przez facebooka', text: 'Facebook', class: ['link--facebook'] },
];
export default {
    name: 'SocialMediaLinks',
    components: {
        BaseLink,
        GoogleSignIn,
    },
    data() {
        return {
            links,
        };
    },
};
</script>

<style lang="scss">
    .social-media-links {
        padding: 24px 0 0 0;
        height: 60px;

        .link--facebook {
            display: inline-block;
            height: 35px;
            margin: 0 10px 0 0;
            line-height: 400px;
            overflow: hidden;
            cursor: pointer;
            float: left;
            width: calc(50% - 5px);
            background: url('~static/images/social-buttons/facebook.png')
            center -2px no-repeat $color-castoBlue-dark;
        }
    }
</style>
