<template>
    <div class="bold-preloader bold-preloader_blue-dots">
        <div class="bold-preloader_blue-dots-inner">
            <label>●</label> <label>●</label> <label>●</label>
        </div>
    </div>
</template>

<script>
export default {
    name: 'BlueDotsPreloader',
};
</script>

<style lang="scss">
.bold-preloader_blue-dots {
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.35);
    position: absolute;
    z-index: 101;
    text-align: center;
}

.bold-preloader_blue-dots-inner {
    transform: translateY(-50%);
    top: 50%;
    position: absolute;
    overflow: hidden;
    left: 0;
    width: 100%;
    color: $color-castoBlue;
    text-align: center;
}

.bold-preloader_blue-dots-inner label {
    font-size: 20px;
    line-height: 20px;
    opacity: 0;
    display: inline-block;
}

@keyframes blueDotsAnimation {
    0% {
        opacity: 0;
        transform: translateX(-300px);
    }
    33% {
        opacity: 1;
        transform: translateX(0px);
    }
    66% {
        opacity: 1;
        transform: translateX(0px);
    }
    100% {
        opacity: 0;
        transform: translateX(300px);
    }
}

@-webkit-keyframes blueDotsAnimation {
    0% {
        opacity: 0;
        -webkit-transform: translateX(-300px);
    }
    33% {
        opacity: 1;
        -webkit-transform: translateX(0px);
    }
    66% {
        opacity: 1;
        -webkit-transform: translateX(0px);
    }
    100% {
        opacity: 0;
        -webkit-transform: translateX(300px);
    }
}

.bold-preloader_blue-dots-inner label:nth-child(3) {
    -webkit-animation: blueDotsAnimation 3s 300ms infinite ease-in-out;
    animation: blueDotsAnimation 3s 300ms infinite ease-in-out;
}

.bold-preloader_blue-dots-inner label:nth-child(2) {
    -webkit-animation: blueDotsAnimation 3s 400ms infinite ease-in-out;
    animation: blueDotsAnimation 3s 400ms infinite ease-in-out;
}

.bold-preloader_blue-dots-inner label:nth-child(1) {
    -webkit-animation: blueDotsAnimation 3s 500ms infinite ease-in-out;
    animation: blueDotsAnimation 3s 500ms infinite ease-in-out;
}
</style>
