<template>
    <div
        :class="{ 'is-fixed': fixedHeader }"
        class="header-wrapper print-hide"
    >
        <div class="header mobile-hide">
            <div class="header-content container">
                <div class="row">
                    <div class="col-md-4 header-section product-logo-box">
                        <div class="header-info">
                            <div class="upper-link">
                                <PickShop />
                            </div>
                            <div class="bottom-link">
                                <Logo
                                    class="logo"
                                />
                            </div>
                        </div>
                        <CatalogMenu
                            v-if="catalogTree && catalogTree.length"
                            :catalog-tree="catalogTree"
                        />
                    </div>
                    <div class="col-md-5 col-xl-4 header-section search-box">
                        <div class="upper-link">
                            <a
                                href="/inspiracje-i-porady.html"
                                title="porady i inspiracje"
                            >
                                Porady i inspiracje
                            </a>
                            <a
                                href="/forum"
                                title="forum"
                            >
                                Forum
                            </a>
                            <a
                                href="/informacje/sklepy/"
                                title="znajdź sklep"
                            >
                                Znajdź sklep
                            </a>
                            <a
                                href="/informacje/pomoc/"
                                class="no-border"
                                title="pomoc"
                            >
                                Pomoc
                            </a>
                        </div>
                        <div class="bottom-link">
                            <div
                                v-if="!tabletFixedHeader"
                                class="form-search"
                            >
                                <SearchBar type="desktop" />
                            </div>
                        </div>
                    </div>
                    <div class="col-md-3 col-xl-4 header-section popup-box">
                        <div class="upper-link upper-link--user-area">
                            <div class="header-content__login">
                                <LoginHeader />
                            </div>
                            <div class="header-content__wishlist">
                                <WishlistHeader />
                            </div>
                        </div>
                        <div class="bottom-link">
                            <template v-if="tabletFixedHeader || desktopFixedHeader">
                                <SearchBar
                                    type="tablet"
                                    class="header-content__form"
                                />
                            </template>
                            <div class="header-content__catalogs">
                                <CatalogsHeader />
                            </div>
                            <div class="header-content__cart">
                                <CartHeader />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <MobileHeader
            class="header--mobile"
            :catalog-tree="catalogTree"
            :inspirations-tree="inspirationsTree"
            :forum-tree="forumTree"
        />
    </div>
</template>

<script>
import { createNamespacedHelpers, mapGetters } from 'vuex';
import CatalogMenu from './CatalogMenu';
import CartHeader from './CartHeader';
import CatalogsHeader from './CatalogsHeader';
import LoginHeader from './LoginHeader';
import WishlistHeader from './WishlistHeader';
import PickShop from './PickShop';
import MobileHeader from './mobile/MobileHeader';
import SearchBar from '~/components/common/SearchBar';
import Logo from './Logo';

const fixedHeaderStartOffset = 50;
const { mapState: mapStateWindowOptions } = createNamespacedHelpers('windowOptions');

export default {
    name: 'BaseHeader',
    components: {
        CatalogMenu,
        Logo,
        SearchBar,
        MobileHeader,
        CartHeader,
        CatalogsHeader,
        LoginHeader,
        WishlistHeader,
        PickShop,
    },
    props: {
        catalogTree: {
            type: Array,
            default: () => [],
        },
        inspirationsTree: {
            type: Array,
            default: () => [],
        },
        forumTree: {
            type: Array,
            default: () => [],
        },
        newspaperData: {
            type: Object,
            default: () => ({}),
        },
    },
    data() {
        return {
            scrollOffsetY: 0,
        };
    },
    computed: {
        ...mapGetters(['occupiedUpperPlace']),
        fixedHeader() {
            const offsetToStart =
                this.occupiedUpperPlace + fixedHeaderStartOffset;
            return this.scrollOffsetY > offsetToStart;
        },
        ...mapStateWindowOptions({
            isTablet: state => state.isTablet,
            isDesktop: state => state.isDesktop,
        }),
        tabletFixedHeader() {
            return this.fixedHeader && this.isTablet;
        },
        desktopFixedHeader() {
            return this.fixedHeader && this.isDesktop;
        },
    },
    mounted() {
        window.addEventListener('scroll', this.setScrollOffsetY);
    },
    beforeDestroy() {
        window.removeEventListener('scroll', this.setScrollOffsetY);
    },
    methods: {
        setScrollOffsetY() {
            this.scrollOffsetY = window.scrollY;
        },
    },
};
</script>

<style lang="scss" >
.header-wrapper {
    &.is-fixed  {
        .header {
            position: fixed;
            box-shadow: 1px 2px 10px rgba(0, 0, 0, 0.2);

            .header-info {
                height: 68px;
            }

            .search-box {
                padding: 10px 0 0 0;

                .form-search {
                    margin-left: 11px;
                }
            }

            .popup-box {
                padding: 13px 0 5px 0;

                .bottom-link .cart-header {
                    margin-left: -15px;
                }
            }

            .upper-link {
                display: none;
            }

            .header-content .logo {
                position: relative;
                top: 20px;
            }

            .products-wrapper--main-title {
                top: 30px;
                padding-top: 0;
            }
        }
    }

    .header {
        border-bottom: 1px solid $color-slate20;
        left: 0;
        right: 0;
        top: 0;
        position: static;
        background-color: $color-castoWhite;
        z-index: 100;

        &--mobile {
            display: none;
        }

        .header-content.container {
            padding: 0 6px;
        }

        .header-section {
            &.product-logo-box {
                padding-right: 0;
                .bottom-link {
                    margin-top: 0;
                    margin-left: 0;

                    a img {
                        margin-bottom: 0;
                    }
                }
            }
            &.search-box {
                padding-left: 0;
                position: relative;
                left: 12px;

                .upper-link {
                    height: 44%;
                    padding-top: 14px;
                    text-align: left;
                    margin-left: -4px;

                    a {
                        padding-right: 9px;
                        padding-left: 15px;
                    }
                }
                .form-search {
                    margin-left: 11px;

                    .sn-suggest-item {
                        font-family: $goodHomeFontFamily;
                    }
                }
            }
        }

        .upper-link {
            letter-spacing: 0;
            font-size: 0;
            height: 50%;

            > a {
                color: $color-slate70;
                font: $font-weight-light 12px/12px $goodHomeFontFamily;
                padding-right: 12px;
                padding-left: 12px;
                border-right: 1px solid $color-slate20;
                text-decoration: none;

                &:hover {
                    text-decoration: none;
                    color: $color-castoBlue;
                }

                &:last-child {
                    border-right: none;
                }
            }

            &--user-area {
                margin-top: -4px;
                margin-right: -6px;
            }
        }

        .upper-link,
        .bottom-link {
            display: block;
        }

        .bottom-link {
            white-space: nowrap;
            .header-content {
                &__catalogs,
                &__cart {
                    white-space: normal;
                    margin-top: -2px;
                }
            }
        }

        .header-info {
            width: auto;
            float: left;
            max-width: 231px;
            height: 113px;
            padding-left: 7px;

            .upper-link {
                text-align: left;
                height: 44px;
                margin-top: 8px;
            }

            .bottom-link {
                margin: 26px 5px 0 12px;

                a {
                    display: block;

                    img {
                        margin: 0 0 30px 0;
                    }
                }
            }

            .current-market-wrapper--selected {
                margin-left: -6px;
            }
        }

        .header-content {
            background-color: $color-castoWhite;
            text-align: right;

            .popup-box {
                padding-left: 0;
                .header-content__form {
                    display: none;
                }
            }

            .logo {
                position: relative;
                margin-top: auto;
                z-index: 20;
                margin-bottom: auto;
                width: 100%;
                right: -3px;
            }

            &__products {
                height: 100%;
                width: 150px;
                background-color: $color-castoBlue;
                display: inline-block;
                position: absolute;
                left: 231px;
                cursor: pointer;

                p {
                    color: $color-castoWhite;
                    font-size: 15px;
                    font-family: $goodHomeFontFamily;
                    margin-left: auto;
                    margin-right: auto;
                    left: 0;
                    text-align: center;
                    bottom: 14px;
                    right: 0;
                    position: absolute;
                }
            }

            &__cart {
                display: inline-block;
                width: 150px;
                text-align: left;
                position: relative;
            }

            &__login {
                display: inline-block;
            }

            &__wishlist {
                position: relative;
                display: inline-block;
                font-size: 0;
                margin-left: 1px;

                .wishlist-header {
                    margin-left: 1px;

                    &::before {
                        content: '';
                        width: 1px;
                        height: 12px;
                        background: $color-slate20;
                        position: absolute;
                        left: -2px;
                        top: calc(50% - 6px);
                    }
                }
            }

            &__catalogs {
                float: left;
                display: inline-block;
                color: $color-slate70-dark;
                font: $font-weight-regular 15px/15px $goodHomeFontFamily;
                text-decoration: none;
                position: relative;
            }

            &--none-padding {
                padding-left: 0;
                padding-right: 0;
            }
        }


        .form-search {
            padding: 0 0 0 0;
            margin-top: 2px;
            width: 346px;
            float: left;
        }
    }
}

@media screen and (max-width: $breakpoint-big-desktop) {
    .header-wrapper {
        &.is-fixed {
            .header {
                .header-section {
                    &.popup-box {
                        padding: 12px 4px 0 0;

                        .bottom-link .cart-header {
                            margin-left: -17px;
                        }
                    }

                    &.search-box {
                        padding: 10px 0 0 12px;
                    }
                }

                .header-content {
                    .header-info .bottom-link {
                        margin-top: 0;
                    }

                    .logo {
                        top: 7px;
                        left: 8px;
                        display: inline-block;
                    }
                }

                .header-info {
                    padding-left: 0;
                }
            }
        }

        .header {
            &-content {
                padding: 0 12px;
            }

            .header-info {
                right: 100px;
            }

            .upper-link {
                a {
                    font-size: 10px;
                }
            }

            .header-content {
                &__products {
                    p {
                        font-size: 12px;
                    }
                }

                &__catalogs {
                    font-size: 10px;
                }

                &__cart {
                    width: 132px;
                }

                .logo {
                    right: -10px;
                }
            }

            .form-search {
                width: 320px;

            }

            .header-section {
                &.search-box {
                    left: 0;
                    padding-right: 0;
                    padding-left: 14px;

                    .upper-link {
                        padding-top: 15px;

                        a {
                            padding-right: 9px;
                            padding-left: 16px;

                            &:first-child {
                                padding-left: 15px;
                            }

                            &:last-child {
                                padding-right: 0;
                            }
                        }
                    }
                }

                &.popup-box {
                    flex: 0 0 30%;
                    max-width: 30%;
                    margin-left: -5%;

                    .upper-link--user-area {
                        margin-top: -5px;
                        margin-right: 0;
                    }

                    .bottom-link {
                        margin-left: -10px;

                        .header-content__catalogs {
                            margin-top: -5px;
                            margin-left: -2px;

                            .catalogs-header::after {
                                right: 14px;
                            }
                        }

                        .cart-header {
                            margin-left: -6px;
                            margin-top: 1px;
                        }
                    }
                }
            }

            .current-market-wrapper--selected {
                margin-left: 0;
            }
        }
    }
}

@media screen and (max-width: $breakpoint-small-desktop) {
    .header-wrapper {
        &:not(.is-fixed) {
            .header .header-info {
                .upper-link {
                    width: 100%;
                    display: inline-block;
                    margin-top: 0;

                    .shop_select {
                        display: inline-block;
                    }
                }

                .logo {
                    right: 0;
                    top: 11px;
                }
            }
        }

        .header {
            .upper-link,
            .bottom-link {
                display: inline;
            }

            .upper-link a {
                font-size: 12px;
            }

            .bottom-link {
                float: left;

                a {
                    img {
                        transform: initial;
                    }
                }
            }

            .header-info {
                width: 100%;
            }

            .header-content {
                .header-info {
                    right: initial;
                }

                &__cart {
                    width: 148px;
                    float: right;
                    margin-left: 30px;
                }

                .row {
                    display: flex;

                    .header-section {
                        order: 3;
                        -webkit-box-flex: 0;
                        -ms-flex: 0 0 100%;
                        flex: 0 0 100%;
                        max-width: 100%;
                        padding-top: 16px;
                        padding-bottom: 15px;

                        &.popup-box {
                            margin-left: 0;
                            padding-right: 12px;

                            .upper-link--user-area {
                                .header-content__login, .header-content__wishlist {
                                    margin-top: -4px;
                                }
                            }

                            .bottom-link {
                                position: relative;
                                top: 10px;
                                right: 6px;
                                width: 100%;
                                margin-left: 0;

                                .header-content__catalogs {
                                    margin-left: 44px;

                                    .catalogs-header::after {
                                        top: 24px;
                                        right: 12px;
                                    }
                                }

                                .header-content__cart {
                                    width: 140px;
                                    margin-left: 0;

                                    .cart-header {
                                        margin-left: 0;
                                    }
                                }
                            }
                        }

                        &.search-box {
                            padding-bottom: 18px;
                            padding-right: 14px;

                            .upper-link {
                                position: relative;
                                top: 10px;
                                right: 2px;

                                a {
                                    font-size: 12px;
                                    line-height: 24px;
                                    padding: 0 7px 0 13px;
                                }
                            }

                            .form-search {
                                margin-left: 1px;
                                width: 346px;
                            }
                        }
                    }

                    .header-section:first-child {
                        order: 1;
                        padding-top: 0;
                        text-align: left;
                        height: 114px;
                        -webkit-box-flex: 0;
                        -ms-flex: 0 0 45%;
                        border-bottom: 1px solid $color-slate20;
                        flex: 0 0 45%;
                        width: 45%;
                        padding-left: 8px;
                    }

                    .header-section:last-child {
                        order: 2;
                        padding-top: 0;
                        height: 114px;
                        position: relative;
                        border-bottom: 1px solid $color-slate20;
                        -webkit-box-flex: 0;
                        -ms-flex: 0 0 55%;
                        flex: 0 0 55%;
                        width: 55%;
                    }
                }
            }
        }

        &.is-fixed {
            .header-content {
                .logo {
                    top: 7px;
                    display: block;
                    left: 0;
                }

                .row {
                    .search-box {
                        display: none;
                    }

                    .product-logo-box {
                        height: 68px;
                    }

                    .popup-box {
                        height: 68px;
                        padding: 0;

                        .bottom-link {
                            padding-left: 51px;

                            .search-bar__wrapper {
                                width: 210px;
                                margin-top: 3px;
                                float: left;
                            }

                            .header-content__cart .cart-header {
                                &::after {
                                    right: 14px;
                                }
                            }
                        }

                        .catalogs-header {
                            display: none;
                        }

                        .header-content__form {
                            width: auto;
                            display: inline-block;
                            height: 37px;

                            .sn-suggest {
                                height: 100%;
                            }
                        }
                    }

                    .cart-header::after {
                        top: 11px;
                    }
                }
            }

            .header-content .row {
                .header-section {
                    &:last-child,
                    &:first-child {
                        border-bottom: none;
                        height: 92px;
                    }

                    &.popup-box {
                        .bottom-link {
                            top: 22px;

                            .header-content__cart {
                                margin-top: 0;
                            }
                        }
                    }
                }
            }
        }
    }
}

@media screen and (max-width: $breakpoint-tablet) {
    .header-wrapper {
        .header {
            display: none;

            &--mobile {
                display: block;
            }
        }
    }
}

@media (min-width: $mf-breakpoint-tablet) {
    .header-wrapper.is-fixed::before {
        content: '';
        display: block;
        width: 100%;
        height: 194px;
    }
}

@media (min-width: $mf-breakpoint-small-desktop) {
    .header-wrapper.is-fixed::before {
        height: 114px;
    }
}

@media print {
    .header-content {
        display: none;
    }
}


</style>
