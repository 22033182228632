import axios from 'axios';
import { GET_AVAILABLE_STORES } from './api.config';

export default async function () {
    try {
        const { data } = await axios.get(GET_AVAILABLE_STORES);

        return data;
    } catch (e) {
        return [];
    }
}
