const PREFIX = 'headless';
const HTML_PREFIX = `${PREFIX}-html`;

const PAGE_CACHE_TAGS = {
    PREFIX,
    HTML_PREFIX,
    HTML: [PREFIX, HTML_PREFIX],
    FOR_BROWSER: {
        tag: 'X-Magento-Browser-Cache-TTL',
        seconds: 120,
    },
};

export default PAGE_CACHE_TAGS;
