import { GLOBAL_VARS } from '~/config/index';

const externalCallbacksWrapper = {
    callbacksStorage: [],
    add({ callback, source }) {
        this.callbacksStorage.push({
            source,
            callback,
        });
    },
    getBySource(sourceName) {
        return this.callbacksStorage.find(
            currentCallback => currentCallback.source === sourceName,
        );
    },
};
// eslint-disable-next-line
(function(app) {
    app[GLOBAL_VARS.EXTERNAL_CALLBACKS_WRAPPER_NAME] =
        app[GLOBAL_VARS.EXTERNAL_CALLBACKS_WRAPPER_NAME] ||
        externalCallbacksWrapper;
})(window);
