import { GLOBAL_VARS } from '~/config';

export default {
    occupiedUpperPlace({ cookieBar, appGlobalNotice, mobileApplicationBar }) {
        let occupiedSpace = 0;

        if (!cookieBar.closed && cookieBar.height) {
            occupiedSpace += cookieBar.height;
        }

        if (appGlobalNotice.displayed && appGlobalNotice.height) {
            occupiedSpace += appGlobalNotice.height;
        }

        if (mobileApplicationBar.showMonit && mobileApplicationBar.heightMonit) {
            occupiedSpace += mobileApplicationBar.heightMonit;
        }

        return occupiedSpace;
    },
    isModeTest({ mode }) {
        return mode === GLOBAL_VARS.MODE_TEST_VALUE;
    },
};
