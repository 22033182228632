import setCacheHeaderBySeconds from '~/assets/services/response/setCacheHeaderBySeconds';

const TEEN_MIN_IN_SECONDS = 600;

export default function ({ res }) {
    if (process.server) {
        setCacheHeaderBySeconds({
            res,
            seconds: TEEN_MIN_IN_SECONDS,
        });
    }
}
