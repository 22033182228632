export const types = {
    SET_CLOSED: 'SET_CLOSED',
    SET_HEIGHT: 'SET_HEIGHT',
};

const mutations = {
    [types.SET_CLOSED](state) {
        state.closed = true;
    },
    [types.SET_HEIGHT](state, height) {
        state.height = height;
    },
};

export default mutations;
