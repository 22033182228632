import axios from 'axios';
import normalizeData from './normalizeData';
import { FETCH_ITEMS_FROM_SERVER_URL } from './api.config';

export default async function () {
    const { data } = await axios({
        method: 'get',
        url: FETCH_ITEMS_FROM_SERVER_URL,
        responseType: 'json',
    });
    return normalizeData(data);
}
