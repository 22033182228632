// eslint-disable-next-line
const apiHost = process.browser ? process.env.apiHostBrowser : process.env.apiHostServer;
// eslint-disable-next-line
const apiPath = process.env.apiPath;

export const FETCH_CATEGORY_DATA = `${apiHost}${apiPath}categories/list?`;

export const FETCH_CONSULTANT_DATA = `${apiHost}${apiPath}categories/consultant`;

export const FETCH_PRODUCTS_DATA = `${apiHost}${apiPath}categories/products?`;

export const FETCH_PROMO_BRANDS_DATA = `${apiHost}${apiPath}categories/brands`;
