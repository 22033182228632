import axios from 'axios';
import { FETCH_CONSULTANT_DATA } from './api.config';

export default async function (categoryId) {
    const params = {
        category: categoryId,
    };
    try {
        const { data } = await axios({
            method: 'get',
            params,
            url: FETCH_CONSULTANT_DATA,
        });

        return data;
    } catch (message) {
        // @todo log errors
        return null;
    }
}
