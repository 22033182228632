import axios from 'axios';
import CacheWrapper from './cacheWrapper';
import 'url-search-params-polyfill';

function getAdditionalObjectDataForRequest(data) {
    return {
        data: data.toString(),
    };
}

function isTimeExpired(time) {
    if (Number.isInteger(time)) {
        return new Date().getTime() > time;
    }
    return true;
}

class ProductPriceByStoreWrapper extends CacheWrapper {
    constructor(config = {}) {
        super(config);

        this.keysStorageName = 'price_keys_cache';
        this.storageKey = 'price_values_cache';
        this.storeId = null;
    }

    getCachedProduct() {
        if (localStorage) {
            const productPrices = this.storageHandler.getItem(this.storageKey);
            return productPrices !== null ? JSON.parse(productPrices) : false;
        }
        return false;
    }

    getLocalStorageKeys() {
        if (!localStorage) {
            return false;
        }
        const localStorageKeys = this.storageHandler.getItem(
            this.keysStorageName,
        );
        if (localStorageKeys) {
            return JSON.parse(this.storageHandler.getItem(this.keysStorageName));
        }
        return false;
    }

    getLocalStorageKey(productId) {
        const localStorageKeys = this.getLocalStorageKeys() || [];
        if (
            localStorageKeys[this.storeId] &&
            localStorageKeys[this.storeId][productId]
        ) {
            return localStorageKeys[this.storeId][productId];
        }
        return false;
    }

    getLocalStorageValue(productId) {
        if (!localStorage) {
            return false;
        }
        const productPrices = JSON.parse(
            this.storageHandler.getItem(this.storageKey),
        );

        if (
            productPrices[this.storeId] &&
            productPrices[this.storeId][productId]
        ) {
            return productPrices[this.storeId][productId];
        }
        return false;
    }

    getPreparedResponse(response, cachedProductsArray) {
        if (!response.products) {
            response.products = {};
        }
        if (!response.productsWithData) {
            response.productsWithData = [];
        }
        cachedProductsArray.forEach((product) => {
            if (!response.products[product]) {
                response.products[product] = this.getLocalStorageValue(product);
            }
            if (!response.productsWithData[product]) {
                response.productsWithData.push(product);
            }
        });
        response.shipping = true;
        response.status = true;
        response.store = this.storeId;
        return response;
    }

    getCachedAndNotCachedArray() {
        const cachedProducts = this.getCachedProduct();
        const notCashedProductsArray = [];
        const cachedProductsArray = [];
        this.idsTable.forEach((item) => {
            if (
                cachedProducts[this.storeId] &&
                cachedProducts[this.storeId][item] &&
                !isTimeExpired(this.getLocalStorageKey(item))
            ) {
                cachedProductsArray.push(item);
            } else {
                notCashedProductsArray.push(item);
            }
        });
        return {
            notCashedProductsArray,
            cachedProductsArray,
        };
    }

    getUrlSearchParamsPolyfillObject(needData) {
        const search = new URLSearchParams({
            isAjax: true,
            store: this.storeId,
            typeBlock: 'recommended',
            needData,
        });
        return search;
    }

    getValue(idsTable, storeId, useCache = true) {
        this.storeId = storeId;
        this.idsTable = idsTable;
        return new Promise((resolve, reject) => {
            if (
                this.storageHandler &&
                useCache
            ) {
                const getCashedNotCashedArray = this.getCachedAndNotCachedArray();
                const {
                    notCashedProductsArray,
                    cachedProductsArray,
                } = getCashedNotCashedArray;
                const search = this.getUrlSearchParamsPolyfillObject(
                    notCashedProductsArray,
                );
                if (cachedProductsArray.length === 0) {
                    axios({
                        ...this.config,
                    })
                        .then((response) => {
                            this.setLocalStorageValues(response);
                            resolve(response.data);
                        })
                        .catch((error) => {
                            reject(error);
                        });
                } else if (cachedProductsArray.length === idsTable.length) {
                    const preparedResponse = this.getPreparedResponse(
                        [],
                        cachedProductsArray,
                    );
                    resolve(preparedResponse);
                } else {
                    axios({
                        ...this.config,
                        ...getAdditionalObjectDataForRequest(search),
                    })
                        .then((response) => {
                            this.setLocalStorageValues(response);
                            const preparedResponse = this.getPreparedResponse(
                                response.data,
                                cachedProductsArray,
                            );
                            resolve(preparedResponse);
                        })
                        .catch((error) => {
                            reject(error);
                        });
                }
            } else {
                axios({ ...this.config })
                    .then((response) => {
                        resolve(response.data);
                    })
                    .catch((error) => {
                        reject(error);
                    });
            }
        });
    }

    setLocalStorageKey(productId) {
        let localStorageKeys = this.getLocalStorageKeys(
            this.keysStorageName,
        );
        if (!localStorageKeys) {
            localStorageKeys = {};
        }
        let expireDate = new Date();
        if (this.tillSpecifiedHourNextDay) {
            expireDate.setTime(expireDate.getTime() + 24 * 60 * 60 * 1000);
            expireDate.setHours(this.tillSpecifiedHourNextDay, 0, 0, 0);
            expireDate = expireDate.getTime();
        } else {
            expireDate = expireDate.getTime() + this.timestamp * 60 * 1000;
        }
        if (!localStorageKeys[this.storeId]) {
            localStorageKeys[this.storeId] = {};
        }
        localStorageKeys[this.storeId][productId] = expireDate;
        this.storageHandler.setItem(
            this.keysStorageName,
            JSON.stringify(localStorageKeys),
        );
    }

    setLocalStorageValues({ data }) {
        const { products = {} } = data;
        Object.keys(products).forEach((key) => {
            this.setLocalStorageValue(key, data.products[key]);
        });
    }

    setLocalStorageValue(productId, value) {
        let localStorageValues = this.storageHandler.getItem(this.storageKey);
        if (!localStorageValues) {
            localStorageValues = {};
        } else {
            localStorageValues = JSON.parse(localStorageValues);
        }
        if (!localStorageValues[this.storeId]) {
            localStorageValues[this.storeId] = {};
        }
        localStorageValues[this.storeId][productId] = value;
        this.storageHandler.setItem(
            this.storageKey,
            JSON.stringify(localStorageValues),
        );
        this.setLocalStorageKey(productId);
    }

    removeExpiredLocalStorageValue(key, subkey) {
        let localStorageValues = this.storageHandler.getItem(this.storageKey);
        if (localStorageValues) {
            localStorageValues = JSON.parse(localStorageValues);
            if (localStorageValues[key] && localStorageValues[key][subkey]) {
                delete localStorageValues[key][subkey];

                if (
                    Object.keys(localStorageValues[key]).length === 0 &&
                    localStorageValues[key].constructor === Object
                ) {
                    delete localStorageValues[key];
                }
            }
            if (
                Object.keys(localStorageValues).length === 0 &&
                localStorageValues.constructor === Object
            ) {
                this.storageHandler.removeItem(this.storageKey);
            } else {
                this.storageHandler.setItem(
                    this.storageKey,
                    JSON.stringify(localStorageValues),
                );
            }
        }
    }

    clearExpiredKeys() {
        if (!localStorage) {
            return false;
        }
        let localStorageKeys = this.storageHandler.getItem(this.keysStorageName);
        if (localStorageKeys) {
            localStorageKeys = JSON.parse(localStorageKeys);
            Object.keys(localStorageKeys).forEach((keyName) => {
                Object.keys(localStorageKeys[keyName]).forEach((subKey) => {
                    const subKeyName = subKey;
                    const subValue = localStorageKeys[keyName][subKeyName];

                    if (isTimeExpired(subValue)) {
                        delete localStorageKeys[keyName][subKeyName];
                        this.removeExpiredLocalStorageValue(
                            keyName,
                            subKeyName,
                        );
                    }
                });
                if (
                    Object.keys(localStorageKeys[keyName]).length === 0 &&
                    localStorageKeys[keyName].constructor === Object
                ) {
                    delete localStorageKeys[keyName];
                }
            });

            if (
                Object.keys(localStorageKeys).length === 0 &&
                localStorageKeys.constructor === Object
            ) {
                this.storageHandler.removeItem(this.keysStorageName);
            } else {
                this.storageHandler.setItem(
                    this.keysStorageName,
                    JSON.stringify(localStorageKeys),
                );
            }
        }
        return false;
    }
}

export default ProductPriceByStoreWrapper;
