import { types } from './mutations';

export default {
    showLoader({ commit }, optionalProps = null) {
        commit(types.SET_OPTIONAL_PROPS, optionalProps);
        commit(types.SHOW_LOADER);
    },
    hideLoader({ commit }) {
        commit(types.HIDE_LOADER);
        commit(types.SET_OPTIONAL_PROPS, null);
    },
};
