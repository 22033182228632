export default function ({ groupedData, productId }) {
    return JSON.stringify({
        product: productId,
        super_group: groupedData
            .reduce(
                ((totalGroupedData, { currentQty, productId: singleProductId }) => (
                    currentQty ? ({ ...totalGroupedData, [singleProductId]: currentQty }) : totalGroupedData)),
                {},
            ),
    });
}
