<template>
    <div
        v-if="bargains && bargains.length"
        class="bargain-list"
    >
        <!-- eslint-disable-next-line vue/component-name-in-template-casing -->
        <client-only>
            <PerfectScrollbar
                ref="scrollbar"
                class="bargain-list__scrollbar-wrapper"
            >
                <div class="catalogs-header__section">
                    <p class="section-name">
                        Okazje
                    </p>
                    <div
                        v-for="(bargain, index) in bargains.slice(0, 4)"
                        :key="`baragin-${index}`"
                        class="catalogs-header__section--bargain"
                    >
                        <a
                            :href="bargain.url"
                            :title="bargain.title"
                        >
                            <BaseImage
                                :data="{ src: bargain.image, alt: 'Okazja w sklepie Castorama' }"
                            />
                        </a>
                    </div>
                </div>
            </PerfectScrollbar>
        </client-only>
    </div>
</template>

<script>
import { mapState } from 'vuex';
import BaseImage from '~/components/common/Image/BaseImage';

export default {
    name: 'BargainList',
    components: {
        BaseImage,
    },
    props: {
        showBargainList: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        ...mapState({
            bargains: state => state.promotional.bargain,
        }),
    },
    watch: {
        showBargainList() {
            this.$refs.scrollbar.update();
        },
    },
};
</script>

<style lang="scss">
.bargain-list {
    height: 100%;

    .catalogs-header {
        &__section {
            background: $color-slate5;
            box-sizing: content-box;

            &--bargain {
                line-height: 0;

                &:last-child a {
                    margin-bottom: 4px;
                }
            }

            .section-name {
                margin-bottom: 10px;
                color: $color-slate100;
                font: $font-weight-bold 15px/20px $goodHomeFontFamily;
            }

            a {
                min-width: 370px;
                margin-bottom: 14px;
                display: block;
            }
        }
    }

    &__scrollbar-wrapper {
        height: 100%;
    }
}

@media (min-width: $breakpoint-tablet) and (max-width: $breakpoint-small-desktop) {
    .bargain-list {
        .catalogs-header__section {
            width: 330px;
            padding: 2px 0 30px;

            a {
                min-width: 330px;

                img {
                    max-width: 100%;
                }
            }
        }
    }
}

</style>
