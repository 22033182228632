<template>
    <div class="pagination">
        <BaseButton
            v-for="index in pageCount"
            :key="index"
            class="pagination__button"
            :bem-modifiers="['horizontally-centered']"
            :class="{ active: isPageActive(index)}"
            :name="index"
            @click.native="goToPage(index)"
        >
            <span class="pagination__button--count">
                {{ index }}
            </span>
        </BaseButton>
    </div>
</template>

<script>
import BaseButton from '~/components/common/Button/BaseButton';

export default {
    name: 'CustomPagination',
    components: {
        BaseButton,
    },
    inject: ['carousel'],
    data() {
        return {
            pageActive: 1,
        };
    },
    computed: {
        pageCount() {
            return this.carousel ? this.carousel.pageCount : 0;
        },
    },
    mounted() {
        this.$parent.$on('pageChange', (index) => {
            this.pageActive = index + 1;
        });
    },
    methods: {
        goToPage(index) {
            this.carousel.goToPage(index - 1);
        },
        isPageActive(index) {
            return this.pageActive === index;
        },
    },
};
</script>

<style lang="scss">
    .pagination {
        position: absolute;
        top: 0;
        right: 0;
        display: flex;
        flex-direction: row;

        & &__button {
            background: none;
            border: none;
            cursor: pointer;
            line-height: 24px;
            &--count {
                margin: 8px 0;
                width: 30px;
                display: inline-block;
                border-right: 1px solid $color-slate10-dark;
                color: $color-slate70;
                font-weight: $font-weight-regular;
                &:last-child {
                    border: none;
                }
            }
            &.active {
                background: $color-castoWhite;
                border: 1px solid $color-slate10-dark;
                .pagination__button--count {
                    border: none;
                    color: $color-castoBlue-dark;
                }
            }
        }
    }
</style>
