export const ADD_TO_WISHLIST_URL = `${
    process.env.baseUrl
}/wishlist/index/addDesktopAjax`;
export const UPDATE_ITEM_QTY_URL = `${
    process.env.baseUrl
}/wishlist/index/updateQtyItemAjax`;
export const FETCH_ITEMS_URL = `${process.env.baseUrl}/wishlist/index/json`;
export const REMOVE_ITEM_URL = `${
    process.env.baseUrl
}/wishlist/index/removeDesktopAjax`;
export const ADD_ALL_TO_CART = `${process.env.baseUrl}/wishlist/index/allcart?vue`;
export const REMOVE_ALL_ITEMS = `${process.env.baseUrl}/wishlist/index/remove`;
