import axios from 'axios';
import { CHECK_AVAILABILITY_IN_STORES_URL } from './api.config';
import { availabilityInOtherStores } from '~/assets/services/availability';
import 'url-search-params-polyfill';

export default async function (province, qty, sku) {
    const search = new URLSearchParams({
        province,
        qty,
        sku,
    });

    try {
        const { data } = await axios({
            method: 'post',
            url: CHECK_AVAILABILITY_IN_STORES_URL,
            data: search.toString(),
            config: {
                headers: {
                    'Content-Type':
                        'application/x-www-form-urlencoded; charset=UTF-8',
                },
            },
        });
        const { success } = data;

        return availabilityInOtherStores(success);
    } catch (message) {
    // @todo log errors
        return null;
    }
}
