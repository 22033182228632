const clientId = process.env.GOOGLE_AUTH_CLIENT_ID;

export default (ignore, inject) => {
    inject('googleAuth2', () => {
        const gapiPromise = new Promise((resolve, reject) => {
            if (window.gapi.auth2) {
                resolve(window.gapi.auth2.getAuthInstance());
            } else {
                window.gapi.load('client:auth2', async () => {
                    try {
                        await window.gapi.client.init({
                            clientId,
                            scope: 'profile email',
                            prompt: 'select_account',
                        });
                        resolve(window.gapi.auth2.getAuthInstance());
                    } catch (errorMessage) {
                        reject(errorMessage);
                    }
                });
            }
        });

        return gapiPromise;
    });
};
