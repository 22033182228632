import testResultCode, { testCodes as codeDictionary } from './test';

export default {
    isAvailable: codeDictionary.enabled === testResultCode,
    code: testResultCode,
    get(key) {
        return localStorage.getItem(key);
    },
};

export function createNewCustomer() {
    const customer = {
        isLogged: null,
        cart: {
            items: [],
        },
        wishlist: {
            items: [],
        },
        paymentInfo: [],
    };
    localStorage.setItem('customer', JSON.stringify(customer));
}
