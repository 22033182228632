import APPS_NAMES from '~/config/apps/names';

const { preprod, test, dev1, stage } = APPS_NAMES;
const APPS_WITH_NOTICE = [preprod, test, dev1, stage];

export default function () {
    return {
        appName: process.env.appName,
        displayed: APPS_WITH_NOTICE.indexOf(process.env.appName) >= 0,
        height: null,
    };
}
