import Vue from 'vue'
import Router from 'vue-router'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _3c7e9c12 = () => interopDefault(import('../pages/category.vue' /* webpackChunkName: "pages/category" */))
const _419629ea = () => interopDefault(import('../pages/compare-page.vue' /* webpackChunkName: "pages/compare-page" */))
const _7969dbff = () => interopDefault(import('../pages/offline.vue' /* webpackChunkName: "pages/offline" */))
const _242f10da = () => interopDefault(import('../pages/customer/account/index.vue' /* webpackChunkName: "pages/customer/account/index" */))
const _b26541d4 = () => interopDefault(import('../pages/errorPages/500.vue' /* webpackChunkName: "pages/errorPages/500" */))
const _5b73586e = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _37e2ff21 = () => interopDefault(import('~/pages/compare-page.vue' /* webpackChunkName: "" */))
const _191b7ee0 = () => interopDefault(import('~/pages/category' /* webpackChunkName: "" */))
const _68066b35 = () => interopDefault(import('~/pages/product.vue' /* webpackChunkName: "" */))
const _11dad8cf = () => interopDefault(import('~/pages/rma/index.vue' /* webpackChunkName: "" */))
const _4d0a5a48 = () => interopDefault(import('~/pages/rma/confirmation.vue' /* webpackChunkName: "" */))
const _ac602b80 = () => interopDefault(import('~/pages/rma/success.vue' /* webpackChunkName: "" */))

// TODO: remove in Nuxt 3
const emptyFn = () => {}
const originalPush = Router.prototype.push
Router.prototype.push = function push (location, onComplete = emptyFn, onAbort) {
  return originalPush.call(this, location, onComplete, onAbort)
}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: decodeURI('/'),
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/category",
    component: _3c7e9c12,
    props: route => ({
                saute: Object.prototype.hasOwnProperty.call(route, 'saute'),
            }),
    name: "category"
  }, {
    path: "/compare-page",
    component: _419629ea,
    props: route => ({
                saute: Object.prototype.hasOwnProperty.call(route, 'saute'),
            }),
    name: "compare-page"
  }, {
    path: "/offline",
    component: _7969dbff,
    props: route => ({
                saute: Object.prototype.hasOwnProperty.call(route, 'saute'),
            }),
    name: "offline"
  }, {
    path: "/customer/account",
    component: _242f10da,
    props: route => ({
                saute: Object.prototype.hasOwnProperty.call(route, 'saute'),
            }),
    name: "customer-account"
  }, {
    path: "/errorPages/500",
    component: _b26541d4,
    props: route => ({
                saute: Object.prototype.hasOwnProperty.call(route, 'saute'),
            }),
    name: "errorPages-500"
  }, {
    path: "/",
    component: _5b73586e,
    props: route => ({
                saute: Object.prototype.hasOwnProperty.call(route, 'saute'),
            }),
    name: "index"
  }, {
    path: "/porownanie-produktow",
    component: _37e2ff21,
    props: route => ({
                saute: Object.prototype.hasOwnProperty.call(route, 'saute'),
            }),
    name: "compare page"
  }, {
    path: "/produkty/*",
    component: _191b7ee0,
    props: route => ({
                saute: Object.prototype.hasOwnProperty.call(route, 'saute'),
            })
  }, {
    path: "/:productSlugName(.*)-id-:productId(\\d+).html",
    component: _68066b35,
    props: route => ({
                saute: Object.prototype.hasOwnProperty.call(route, 'saute'),
            }),
    name: "product"
  }, {
    path: "/rma/return/create/order_id/:id",
    component: _11dad8cf,
    props: route => ({
                saute: Object.prototype.hasOwnProperty.call(route, 'saute'),
            }),
    name: "Rma create"
  }, {
    path: "/rma/return/confirmation/",
    component: _4d0a5a48,
    props: route => ({
                saute: Object.prototype.hasOwnProperty.call(route, 'saute'),
            }),
    name: "Rma confirmation"
  }, {
    path: "/rma/return/success/",
    component: _ac602b80,
    props: route => ({
                saute: Object.prototype.hasOwnProperty.call(route, 'saute'),
            }),
    name: "Rma success"
  }],

  fallback: false
}

export function createRouter () {
  return new Router(routerOptions)
}
