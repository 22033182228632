import axios from 'axios';
import { FETCH_ITEMS_URL } from './api.config';
import normalizeData from './normalizeData';

export default async function () {
    try {
        const { data } = await axios({
            method: 'get',
            url: FETCH_ITEMS_URL,
            responseType: 'json',
        });
        return normalizeData(data);
    } catch {
        // @todo log error
        return {};
    }
}
