<template>
    <div
        class="placeholder-no-price-found"
        :class="getBemModifiers()"
    >
        <SignWarn
            class="placeholder-no-price-found__warn"
            :bem-modifiers="bemModifiers"
        />
        <p class="placeholder-no-price-found__text">
            Cena chwilowo niedostępna. <br>
            Odśwież stronę.
        </p>
    </div>
</template>

<script>
import SignWarn from '~/src/components/ui/Sign/SignWarn';

export default {
    name: 'PlaceholderNoPriceFound',
    components: { SignWarn },
};
</script>

<style lang="scss">
    .placeholder-no-price-found {
        $self: &;
        display: flex;
        align-items: center;

        &__warn {
            display: flex;
            justify-content: center;
        }

        &__text {
            text-align: left;
            padding-left: 12px;
            color: $color-slate100;
            display: flex;
            align-items: center;
            font: $font-weight-regular 16px/16px $goodHomeFontFamily;
        }

        &--small-warn #{$self}__text {
            font-size: 12px;
            line-height: 12px;
        }
    }

    @media screen and (min-width: $mf-breakpoint-tablet) {
        .placeholder-no-price-found {
            $self: &;

            &__text {
                font-size: 10px;
            }

            &--big-warn #{$self}__text {
                font-size: 12px;
                line-height: 12px;
            }
        }
    }

    @media screen and (min-width: $mf-breakpoint-small-desktop) {
        .placeholder-no-price-found {
            $self: &;

            &__text {
                font-size: 12px;
            }

            &--big-warn #{$self}__text {
                font-size: 14px;
                line-height: 14px;
            }
        }
    }
</style>
