export const types = {
    SET_CATALOG_TREE: 'SET_CATALOG_TREE',
    SET_INSPIRATIONS_TREE: 'SET_INSPIRATIONS_TREE',
    SET_FORUM_TREE: 'SET_FORUM_TREE',
    SET_NEWSPAPER_DATA: 'SET_STATIC_BLOCK_HTML',
};

const mutations = {
    [types.SET_CATALOG_TREE](state, catalogTree) {
        state.catalogTree = catalogTree;
    },
    [types.SET_INSPIRATIONS_TREE](state, inspirationsTree) {
        state.inspirationsTree = inspirationsTree;
    },
    [types.SET_FORUM_TREE](state, forumTree) {
        state.forumTree = forumTree;
    },
    [types.SET_NEWSPAPER_DATA](state, data) {
        state.newspaperData = data;
    },
};

export default mutations;
