import CookieUniversal from 'cookie-universal';
import cookieConfig from '~/assets/services/storages/cookies/cookie.config';

const cookies = new CookieUniversal();
const cookieName = 'applicationPopup';
const cookieValue = 1;
const cookieMaxDays = 15;

function getMobileApplicationBarClosed() {
    return cookies.get(cookieName);
}

export function setMobileApplicationBarClosed() {
    cookies.set(cookieName, cookieValue, {
        ...cookieConfig.defaultOptions,
        maxAge: cookieMaxDays * 24 * 60 * 60,
    });
}
export function isMobileApplicationBarClosed() {
    return getMobileApplicationBarClosed() === cookieValue;
}
