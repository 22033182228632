import { types } from './mutations';
import normalizeArticleGrid from '~/assets/services/articleGrid/normalizeData';

import {
    getProductData,
    getShippingMethodsData,
} from '~/assets/services/product';

export default {
    async fetchData({ dispatch, commit }, { error: errorHandle, productId }) {
        try {
            const { productData, attributes } = await getProductData({ productId });
            const {
                sku,
                name,
                brand,
                url,
                unit,
                benefits,
                promotionInfo,
                files,
                parentsTree,
                consultant,
                categoryName,
                reviewsData,
                variants,
                hasExciseDuties,
                vatRate,
                thumbnail,
                freeDelivery,
                pictograms,
                inspirations,
                minOrderQty,
                qtyPerPackage,
                productId: id,
            } = productData;

            dispatch('articleGrid/setArticleGrid', normalizeArticleGrid(inspirations), { root: true });

            commit(types.SET_PRODUCT_ID, id);
            commit(types.SET_THUMBNAIL, thumbnail);
            commit(types.SET_SKU, sku);
            commit(types.SET_NAME, name);
            commit(types.SET_URL, url);
            commit(types.SET_BENEFITS, benefits);
            commit(types.SET_PROMOTION_INFO, promotionInfo);
            commit(types.SET_FILES, files);
            commit(types.SET_UNIT, unit);
            commit(types.SET_PRODUCT_PARENTS_TREE, parentsTree);
            commit(types.SET_CONSULTANT_DATA, consultant);
            commit(types.SET_VAT_RATE, vatRate);
            commit(types.SET_CATEGORY_NAME, categoryName);
            commit(types.SET_PICTOGRAMS, pictograms);
            commit(types.SET_FREE_DELIVERY, freeDelivery);
            commit(types.SET_MIN_ORDER_QTY, minOrderQty);
            commit(types.SET_QTY_PER_PACKAGE, qtyPerPackage);

            dispatch('setExciseDuties', hasExciseDuties);

            if (brand) {
                commit(types.SET_BRAND, brand);
            }

            commit(types.SET_VARIANTS, variants);
            dispatch('reviews/setReviewsToProduct', {
                productId,
                reviewsData,
            }, { root: true });

            if (parentsTree) {
                await dispatch('breadcrumbs/setLinks', [
                    ...parentsTree,
                    {
                        text: name,
                        relativePath: this.app.context.route.path,
                        id: productId,
                        current: true,
                    },
                ], { root: true });
            }

            return { productData, attributes };
        } catch (error) {
            if (this.app && this.app.$logger) {
                this.app.$logger(error);
            }
            errorHandle({ statusCode: 404, message: error.message });
        }
    },
    async setProductShippingMethods({ rootState, state, commit }) {
        const { productId } = state;
        const { selectedShopIds: { storeId } } = rootState.markets;
        const shippingMethods = await getShippingMethodsData({ productId, storeId });
        commit(types.SET_SHIPPING_METHODS, shippingMethods);
    },
    setProductPriceData({ commit }, priceData) {
        const {
            availability,
            price,
            qty,
            attrData,
            onlyInStore,
            conversion,
            stock_status: stockStatus,
            mix_variant: mixVariant,
        } = priceData || {};

        commit(types.SET_ONLY_IN_STORE, onlyInStore || false);
        commit(types.SET_SALES_UNITS_CHANGE, attrData ? attrData.sales_units_change : false);
        commit(types.SET_CONVERSION, parseFloat(conversion));
        commit(types.SET_MIX_VARIANT, mixVariant);
        commit(types.SET_QUANTITY, qty);
        commit(types.SET_AVAILABILITY, availability);
        commit(types.SET_PRICE, price);
        commit(types.SET_STOCK_STATUS, stockStatus);
    },
    setDataTableTabs({ commit }, dataTableTabs) {
        commit(types.SET_DATA_TABLE_TABS, dataTableTabs);
    },
    setDataTableTabsVisibility({ commit, state }, { symbol, isVisible }) {
        const { dataTableTabs } = state;

        const dataTabsVisibility = dataTableTabs.map((dataTableTab) => {
            if (dataTableTab.symbol === symbol) {
                dataTableTab.isExpanded = isVisible;
            }

            return dataTableTab;
        });

        commit(types.SET_DATA_TABLE_TABS, dataTabsVisibility);
    },
    setExciseDuties({ commit }, payload) {
        commit(types.SET_EXCISE_DUTIES, payload);
    },
};
