import getRecentlyViewedCategories from './getRecentlyViewedCategories';

export default function ({ image, title, url, id }) {
    try {
        if (!image || !title || !url || !id) {
            throw new Error('invalid data for recenlty viewed category');
        }
        const inStorage = getRecentlyViewedCategories();
        const categoriesWithoutCurrentToAdd = [];

        inStorage.forEach((categoryData) => {
            if (categoryData.url !== url && categoryData.id !== id) {
                categoriesWithoutCurrentToAdd.push(categoryData);
            }
        });

        localStorage.setItem('recentlyCategories', JSON.stringify([
            { image, title, url, id, time: Date.now() },
            ...categoriesWithoutCurrentToAdd,
        ]));
    } catch (e) {
        // @todo log errors
    }
}
