<template>
    <Component
        :is="nameDomTag"
        class="name-product-tail"
    >
        <LinkDataLayer
            class="name-product-tail__link"
            :href="url"
            :title="name"
        >
            {{ name }}
        </LinkDataLayer>
    </Component>
</template>

<script>
import LinkDataLayer from '~/components/common/Link/LinkDataLayer';

export default {
    name: 'NameProductTail',
    components: {
        LinkDataLayer,
    },
    props: {
        nameDomTag: {
            type: String,
            required: true,
        },
        url: {
            type: String,
            default: 'test',
        },
        name: {
            type: String,
            required: true,
        },
    },
};
</script>

<style lang="scss">
    .name-product-tail {
        width: 100%;
        height: 78px;
        line-height: 72px;
        padding: 4px 10px;
        text-align: center;
        overflow: hidden;

        &__link {
            display: inline-block;
            vertical-align: middle;
            font: $font-weight-regular 15px/18px $goodHomeFontFamily;
            color: $color-slate100;
        }
    }

    @media screen and (max-width: $breakpoint-tablet) {
        .name-product-tail__link {
            font-size: 16px;
        }
    }

    @media screen and (max-width: $breakpoint-smart-phones) {
        .name-product-tail__link {
            font-size: 13px;
            line-height: 15px;
        }
    }

</style>
