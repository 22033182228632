export default function () {
    let subscription = null;

    return {
        data() {
            return {
                unwatch: null,
                priceData: {},
            };
        },
        beforeDestroy() {
            this.$PriceProvider.unwatch(this.priceData);
            if (this.unwatch) {
                this.unwatch();
            }
        },
        mounted() {
            this.$nextTick(() => {
                this.unwatch = this.$watch('productsIdsForPriceProvider', (productIds) => {
                    if (productIds) {
                        if (subscription) {
                            this.$PriceProvider.unwatch(this.priceData);
                            subscription.unsubscribe();
                        }

                        subscription = this.$PriceProvider
                            .watch(productIds)
                            .data
                            .subscribe((value) => {
                                this.priceData = value;
                            });
                    }
                }, {
                    immediate: true,
                });
            });
        },
    };
}
