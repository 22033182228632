import axios from 'axios';
import { DELETE_FROM_CART_URL } from './api.config';

export default function ({ id, isAjax = true }) {
    return axios.get(DELETE_FROM_CART_URL, {
        params: {
            isAjax,
            id,
            vue: true,
        },
    });
}
