import Vue from 'vue';
import PriceProvider from './PriceProvider';
import getPrice from '~/assets/services/product/getPrice';

export default function ({ store }) {
    Vue.use(PriceProvider, {
        trigger: (callback) => {
            store.subscribe(({ type }) => { if (type === 'markets/SET_SHOP') callback(); });
        },
        getPriceHandler: async (productsIds) => {
            const { storeId } = store.state.markets.selectedShopIds;

            const { products } = await getPrice({ storeId, productsIds });

            return products;
        },
    });
}
