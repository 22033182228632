import debounce from 'lodash/debounce';

export default async ({ store }) => {
    const { innerWidth } = window;

    await store.dispatch('windowOptions/onWindowResize', { width: innerWidth });

    window.addEventListener('resize', debounce(() => {
        store.dispatch('windowOptions/onWindowResize', {
            width: window.innerWidth,
        });
    }, 100));
};
