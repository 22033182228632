import axios from 'axios';
import { filters as normalizeFilters, product as normalizeProduct, categoryFilterItem } from './normalizeData';
import { getSearchCriteriaPath } from '~/assets/services/searchCriteria';
import { fetchItemsFromObject } from '~/assets/services/helpers';
import { FETCH_PRODUCTS_DATA } from './api.config';

export default async function (attachedFilters, shopId) {
    try {
        const response = await axios({
            url: `${FETCH_PRODUCTS_DATA}${getSearchCriteriaPath(attachedFilters)}&storeId=${shopId}`,
            method: 'get',
            responseType: 'json',
        });
        const { data } = response;
        const { filters, items } = data;
        const { category } = filters.available;

        const separatedAvailableFilters = fetchItemsFromObject(filters.available, ['price', 'category']);

        return {
            ...data,
            filters: {
                selected: normalizeFilters(filters.selected),
                available: normalizeFilters(separatedAvailableFilters.objectWithoutFetchedItems),
                price: separatedAvailableFilters.fetchedItems.price,
            },
            items: items.map(item => normalizeProduct(item)),
            categories: category.map(categoryItem => categoryFilterItem(categoryItem)),
        };
    } catch (error) {
        // @todo log erros
        return [];
    }
}
