<template>
    <BaseButton
        :name="text"
        class="select-market-button"
        :bem-modifiers="bemModifiers"
        :class="getBemModifiers()"
        @click.native="showChangeShop"
    >
        {{ text }}
    </BaseButton>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';
import BaseButton from './BaseButton';
import { startLocalizationGA } from '~/assets/services/googleAnalytics';

const { mapActions } = createNamespacedHelpers('popup');
const { mapState: mapStateGoogleServices } = createNamespacedHelpers('googleServices');

export default {
    name: 'SelectMarketButton',
    components: {
        BaseButton,
    },
    props: {
        text: {
            type: String,
            default: 'Wybierz sklep',
        },
        sku: {
            type: [String, Number],
            default: null,
        },
        unavailableText: {
            type: String,
            default: ' (produkt niedostępny)',
        },
        isCartChecked: {
            type: Boolean,
            default: false,
        },
    },
    methods: {
        ...mapActions(['showPopup']),
        ...mapStateGoogleServices({
            isGA: state => state.GA,
        }),
        async showChangeShop() {
            if (this.isGA) {
                startLocalizationGA();
            }
            const { default: instance } = await import('~/components/common/Popup/ChangeShopPopup' /* webpackChunkName: "popup/change-shop" */);
            const { sku, unavailableText, isCartChecked } = this;

            this.showPopup({
                instance,
                optionalProps: {
                    sku,
                    isCartChecked,
                    unavailableText,
                },
            });
        },
    },
};
</script>

<style lang="scss">
    .select-market-button {
        cursor: pointer;
        margin: auto;

        &--product-page-sidebar {
            display: block;
            margin: 8px 0;
            font: $font-weight-regular 12px/15px $goodHomeFontFamily;
            color: $color-castoBlue;
            padding: 0;

            &:hover {
                text-decoration: underline;
            }
        }

        &--pick-shop {
            width: auto;
            padding: 3px 24px;
            margin: 14px 0 13px 0;
            background-color: $color-castoYellow;
            color: $color-slate100;
            font: $font-weight-light 12px/24px $goodHomeFontFamily;
            border: none;
            cursor: pointer;
            white-space: nowrap;
            text-transform: none;

            &:focus {
                outline: none;
            }
        }
    }

    @media screen and (min-width: $mf-breakpoint-tablet) {
        .select-market-button--product-page-sidebar {
            width: initial;
            margin: 6px auto 12px;
            font-family: $baseFontFamily;
        }
    }

    @media screen and (min-width: $mf-breakpoint-small-desktop) {
        .select-market-button--pick-shop {
            margin: 0;
        }
    }

    @media screen and (min-width: $mf-breakpoint-big-desktop) {
        .select-market-button--pick-shop {
            margin: 3px 0 0 0;
        }
    }
</style>
