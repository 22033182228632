<template>
    <div>
        <client-only>
            <AppGlobalNotice />
        </client-only>
        <CookieBar />
        <GlobalPopups />
        <GlobalMessages />
        <Loader :global-loader="true" />
        <SpecialHeader />
        <nuxt />
        <slot name="beforeFooter" />
        <BaseFooter class="print-hide" />
        <InteliwiseClient />
    </div>
</template>

<script>
import AppGlobalNotice from '~/components/AppGlobalNotice';
import CookieBar from '~/components/CookieBar';
import GlobalMessages from '~/components/common/Popup/GlobalMessages';
import GlobalPopups from '~/components/common/Popup/GlobalPopus';
import BaseFooter from '~/components/common/Footer';
import InteliwiseClient from '~/components/InteliwiseClient';
import SpecialHeader from '~/src/components/SpecialHeader';
import Loader from '~/components/common/Loader';

export default {
    name: 'LayoutSpecial',
    components: {
        AppGlobalNotice,
        CookieBar,
        GlobalMessages,
        GlobalPopups,
        BaseFooter,
        InteliwiseClient,
        SpecialHeader,
        Loader,
    },
};
</script>
