import { CODES } from '~/assets/services/availability';

export default {
    types: {
        product: 'Product',
        thing: 'Thing',
        offer: 'Offer',
        organization: 'Organization',
        aggregateRating: 'AggregateRating',
        rating: 'Rating',
        review: 'Review',
    },
    availability: {
        [CODES.in]: 'http://schema.org/InStock',
        [CODES.out]: 'http://schema.org/OutOfStock',
    },
    itemCondition: {
        new: 'https://schema.org/NewCondition',
    },
    context: {
        default: 'http://schema.org',
    },
};
