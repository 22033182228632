<template>
    <div
        class="base-popup"
        :class="getBemModifiers()"
    >
        <Transition name="fade">
            <BaseButton
                v-if="isShown"
                class="modal__overlay"
                name="Zamknij"
                @click.native="closeModal"
            />
        </Transition>
        <div class="base-popup__modal modal">
            <Transition :name="popUpSlide">
                <div
                    v-if="isShown"
                    class="base-popup__container modal__container"
                >
                    <BaseButton
                        v-if="popUpSlide"
                        class="modal__container--back"
                        name="Wróć"
                        @click.native="closeModal"
                    >
                        Wróć
                    </BaseButton>
                    <BaseButton
                        v-if="!popUpSlide && showDefaultCloseButton"
                        class="modal__close"
                        :class="styleButton && !popUpSlide ? styleButton : ''"
                        name="Zamknij"
                        @click.native="closeModal"
                    >
                        zamknij
                    </BaseButton>
                    <slot />
                </div>
            </Transition>
        </div>
    </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';
import BaseButton from '~/components/common/Button/BaseButton';

const { mapActions: mapActionsPopup } = createNamespacedHelpers('popup');

export default {
    name: 'BasePopup',
    components: {
        BaseButton,
    },
    props: {
        styleButton: {
            type: String,
            default: 'modal__close--default',
        },
        popUpSlide: {
            type: String,
            default: '',
        },
        showDefaultCloseButton: {
            type: Boolean,
            default: true,
        },
    },
    data() {
        return {
            isShown: false,
        };
    },
    mounted() {
        setTimeout(() => {
            this.isShown = true;
        }, 0);
    },
    methods: {
        closeModal() {
            this.isShown = false;
            if (this.popUpSlide) {
                setTimeout(() => {
                    this.$parent.$emit('close-pop-up');
                    this.$emit('close-pop-up');
                    this.hidePopup();
                }, 800);
            } else {
                this.$parent.$emit('close-pop-up');
                this.$emit('close-pop-up');
                this.hidePopup();
            }
        },
        ...mapActionsPopup(['hidePopup']),
    },
};
</script>

<style lang="scss">
@import '~assets/css/transitions/fade.scss';
@import '~assets/css/transitions/slide.scss';

.base-popup {
    $self: &;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100vw;
    height: 100vh;
    position: fixed;
    z-index: 103;
    top: 0;

    &--mobile-scrollable {
        #{$self}__modal {
            overflow: scroll;
        }

        #{$self}__container {
            overflow: initial;
        }
    }
}

.modal {
    font-family: $goodHomeFontFamily;
    display: block;
    position: fixed;
    background-color: transparent;
    z-index: 110;

    .content {
        width: 100%;
        height: 100%;
        background-color: $color-castoWhite;
    }

    &__overlay {
        display: block;
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 103;
        background-color: rgba(0, 0, 0, 0.35);
    }

    &__container {
        background: $color-castoWhite;
        position: relative;
        float: left;
        width: 100%;

        & &--back {
            display: none;
        }
    }

    & &__close {
        display: block;
        text-indent: -9999em;
        position: absolute;
        line-height: 12px;
        top: 25px;
        right: 30px;
        z-index: 110;
        width: 15px;
        background: url('~static/images/compare/close.svg') center center no-repeat;

        &:hover {
            fill: red;
            cursor: pointer;
        }

        &--default {
            filter: grayscale(100%) brightness(500%) brightness(60%);
        }

        &--white {
            filter: grayscale(100%) brightness(5) brightness(1);
        }

        &--grey {
            filter: grayscale(100%) brightness(30%) brightness(1);
        }
    }
}

@media screen and (min-width: $breakpoint-tablet) {
    .base-popup {
        $self: &;

        &--mobile-scrollable {
            #{$self}__modal {
                overflow: initial;
            }
        }
    }
}

@media screen and (max-width: $breakpoint-small-desktop) {
    .modal {
        &__container {
            & &--back {
                width: 100%;
                text-align: left;
                font: $font-weight-bold 15px/1 $goodHomeFontFamily;
                color: $color-slate70-dark;
                padding: 1.475em 32px;
                border-bottom: 1px solid $color-slate20-dark;
                background: url('~static/images/btn-back.png') 10px 20px no-repeat;
                background-size: 10px;
            }
        }
    }
}

@media screen and (max-width: $breakpoint-tablet) {
    .modal {
        top: 0;
        left: 0;
        @include transform(initial);
        width: initial;

        .content {
            width: 100%;
        }

        &__container {
            height: 100vh;
            width: initial;
            overflow-y: scroll;

            & &--back {
                display: block;
                width: 100%;
                text-align: left;
                font: bold 15px/1 $goodHomeFontFamily;
                color: $color-slate70-dark;
                padding: 1.475em 32px;
                border-bottom: 1px solid $color-slate20-dark;
                background: url('~static/images/btn-back.png') 10px 20px no-repeat;
                background-size: 10px;
            }
        }

        & &__close {
            top: 15px;
            right: 15px;
        }
    }
}
</style>
