<template>
    <div
        class="catalogs-header"
        @mouseenter="showPopup"
        @mouseleave="hidePopup"
    >
        <BaseLink
            class="catalogs-header__title"
            text="Gazetki, katalogi, okazje"
            :href="newspaperCatalogPageLink"
        />
        <div
            v-if="showPopupNewspaperBargainOcassion"
            :class="['catalogs-header__content', {'catalogs-header__content--with-bargains': bargains && bargains.length}]"
        >
            <div
                ref="leftBox"
                class="catalogs-header__content--left-section"
            >
                <Newspaper />
                <Catalog :show-catalog-list="showPopupNewspaperBargainOcassion" />
            </div>
            <div
                v-if="bargains && bargains.length"
                ref="rightBox"
                class="catalogs-header__content--right-section"
            >
                <Bargain :show-bargain-list="showPopupNewspaperBargainOcassion" />
            </div>
        </div>
    </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';
import Newspaper from '~/components/common/NewspaperList';
import Catalog from '~/components/common/CatalogList';
import Bargain from '~/components/common/BargainList';
import BaseLink from '~/components/common/Link/BaseLink';

const { mapState: mapStateWindowOptions } = createNamespacedHelpers('windowOptions');
const { mapState: mapStatePromotional } = createNamespacedHelpers('promotional');

export default {
    name: 'CatalogsHeader',
    components: {
        Newspaper,
        Catalog,
        Bargain,
        BaseLink,
    },
    data() {
        return {
            showPopupNewspaperBargainOcassion: false,
        };
    },
    computed: {
        ...mapStatePromotional({
            bargains: state => state.bargain,
        }),
        ...mapStateWindowOptions({
            isTablet: state => state.isTablet,
            isDesktop: state => state.isDesktop,
        }),
        newspaperCatalogPageLink() {
            return `${process.env.baseUrl}/gazetka-i-katalogi`;
        },
    },
    methods: {
        showPopup() {
            this.showPopupNewspaperBargainOcassion = true;
        },
        hidePopup() {
            this.showPopupNewspaperBargainOcassion = false;
        },
    },
};
</script>

<style lang="scss">
    @import '~/assets/css/sprites/header-desktop.scss';

    .catalogs-header {
        padding: 12px 22px 12px 11px;
        background-color: $color-castoWhite;
        font-family: $goodHomeFontFamily;
        height: 50px;
        line-height: 20px;
        width: 100%;
        position: relative;
        text-align: left;
        cursor: pointer;

        &::after {
            @include spriteHeaderDesktop('arrow-black-bottom', false, true);
            background-size: 7px 4px;
            width: 9px;
            height: 6px;
            position: absolute;
            content: '';
            right: 6px;
            top: 20px;
        }

        &:hover {
            background-color: $color-castoBlue;
            color: $color-castoWhite;

            .catalogs-header__title {
                color: $color-castoWhite;
            }

            .catalogs-header__content {
                right: -136px;
                left: auto;
                z-index: 10;
            }

            &::after {
                @include spriteHeaderDesktop('arrow-white-bottom', false, true);
                transform: rotate(180deg);
            }
        }

        &__title {
            margin-bottom: 0;
            font: $font-weight-light 15px/20px $goodHomeFontFamily;
            color: $color-slate70;
            text-decoration: none;
        }

        &__content {
            border: solid 3px $color-castoBlue;
            color: $color-slate100;
            background-color: $color-castoWhite;
            position: absolute;
            top: 50px;
            left: -9999px;
            z-index: -1;

            &--left-section{
                float:left;
                padding-top: 14px;
            }

            &--right-section {
                padding: 14px 8px 4px;
                float: left;
                background: $color-slate5;
            }
        }
    }

    @media screen and (min-width: $breakpoint-small-desktop) {
        .catalogs-header__content {
            width: 420px;

            &--with-bargains {
                width: 805px;
                display: flex;
            }
        }
    }

    @media screen and (max-width: $breakpoint-big-desktop) {
        .catalogs-header {
            padding: 15px 28px 15px 12px;

            &::after {
                top: 23px;
            }

            &__title {
                font-size: 11px;
            }
        }
    }

    @media screen and (max-width: $breakpoint-small-desktop) {
        .catalogs-header {
            &:hover {
                .catalogs-header__content{
                    right: -170px;
                }
            }

            &::after {
                top: 20px;
            }

            &__title {
                font-size: 15px;
            }

            &__content {
                width: 358px;

                &--with-bargains {
                    width: 702px;
                }
            }
        }
    }
</style>
