import shippingMethodsCodes from './shippingMethodsCodes';

const { PICKUP, CASTO, DPD, INPOST } = shippingMethodsCodes;

export default {
    [INPOST]: 'Paczkomaty InPost',
    [PICKUP]: 'Odbiór osobisty w sklepie',
    [DPD]: 'Kurier',
    [CASTO]: 'Firma transportowa',
};
