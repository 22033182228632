<template>
    <div
        class="footer-bottom"
        tabindex="0"
    >
        <div class="col-xs-12 col-md-6 col-lg-10 footer-bottom--left">
            <div class="row">
                <FirstMenuBottom
                    v-if="firstMenuSections.length"
                    :menu-sections="firstMenuSections"
                    tabindex="0"
                />
                <SecondMenuBottom
                    v-if="secondMenuSections.length"
                    :menu-sections="secondMenuSections"
                    tabindex="0"
                />
                <div class="col-xs-12 col-lg-2 footer-bottom--links">
                    <DownloadApp
                        class="download-app"
                        tabindex="0"
                    />
                    <SocialLink
                        class="social-link"
                        tabindex="0"
                    />
                </div>
            </div>
        </div>
        <Subscribes />
        <div class="col-xs-12 footer-bottom--links">
            <SocialLink
                class="social-link--only-mobile"
                tabindex="0"
            />
        </div>
    </div>
</template>

<script>
import FirstMenuBottom from '~/components/common/Footer/components/FirstMenuBottom';
import SecondMenuBottom from '~/components/common/Footer/components/SecondMenuBottom';
import SocialLink from '~/components/common/SocialLink/';
import Subscribes from '~/components/common/Subscribes/';
import DownloadApp from '~/components/common/DownloadApp/';

export default {
    name: 'MenuBottom',
    components: {
        FirstMenuBottom,
        SecondMenuBottom,
        SocialLink,
        Subscribes,
        DownloadApp,
    },
    props: {
        firstMenuSections: {
            type: Array,
            required: true,
        },
        secondMenuSections: {
            type: Array,
            required: true,
        },
    },
};
</script>

<style lang="scss">
.footer-bottom {
    width: 100%;
    padding-top: 36px;
    outline: none;

    &--left {
        float: left;

        .so-ico {
            display: none;
        }
    }

    .block-single__title {
        font-size: 14px;
        color: $color-castoWhite;
        font-weight: bold;
        margin: 0 0 13px;
        padding: 0;
        line-height: 19px;
    }

    &__section {
        float: left;
        flex-direction: column;
    }

    &::after {
        clear: both;
        content: '';
        display: table;
    }

    &--links {
        padding-left: 0;
        padding-right: 0;
    }
}

@media (min-width: $breakpoint-tablet) {
    .footer-bottom {
        &--left {
            .so-ico {
                display: block;
            }
        }

        .download-app,
        .social-link {
            margin-top: 22px;
            display: inline-block;
            width: unset;
            max-width: 280px;
        }

        .social-link--only-mobile {
            display: none;
        }
    }
}

@media (max-width: $breakpoint-small-desktop) {
    .footer-bottom {
        padding-top: 22px;
    }
}
@media (min-width: $breakpoint-tablet) and (max-width: $breakpoint-small-desktop) {
    .footer-bottom--links {
        min-width: 130%;
    }
}
@media (max-width: $breakpoint-tablet) {
    .footer-bottom {
        outline: none;

        &--left {
            float: none;
        }

        & &__section {
            padding-left: 0;
            padding-right: 0;
            border-bottom: 1px solid $color-slate70-dark;
            float: none;
            margin-bottom: 0;
            max-height: 39px;
            overflow: hidden;
            transition-property: max-height;
            transition-duration: 1s;
            transition-timing-function: linear;


            a, .disabled-list {
                padding-left: 30px;
                margin: 3px 0;
                font-size: 13px;
                line-height: 21px;

                &.mobile-app-links__link {
                    margin-left: 30px;
                    color: transparent;
                }
            }

            .block-single__title {
                width: 100%;
                padding: 10px 0 10px 20px;
                margin: 0;
                font-size: 12px;
                display: inline-block;
                text-align: left;

                &::after {
                    content: '';
                    margin-right: 10px;
                    display: inline-block;
                    float: right;
                    background: url('~static/images/icons/icon-sprite-mobile-e8441b9b.svg') no-repeat;
                    background-position: 20.330073349633253% 55.176308539944905%;
                    width: 16px;
                    height: 14px;
                    margin-top: 2px;
                }
            }

            &.expanded {
                max-height: 300px;

                .block-single__title::after {
                    background-position: 99.31051344743274% 67.29066666666667%;
                    height: 2px;
                    margin-top: 8px;
                }
            }

            &--social-media {
                display: none;
            }
        }
    }
}

@media screen and (min-width: $breakpoint-small-desktop) {
    .footer-bottom {
        .block-single__title {
            font: $font-weight-bold 16px/19px $goodHomeFontFamily;
        }

        &--links {
            .download-app,
            .social-link {
                padding-left: 0;
                margin-top: 0;
            }
        }

        .social-link--mobile {
            display: none;
        }
    }
}

</style>
